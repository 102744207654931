<template>
  <div :key="refresh">
    <h4 class="text-h6">Holding area</h4>
    <p class="text-caption">You can drop pages onto here.</p>
    <div style="clear: both"></div>
    <draggable
      id="ha_pages__container"
      ref="sortableContainer"
      v-model="pageIds"
      :item-key="(i) => i"
      group="pagesGroup"
      data-draggable-for="holdingArea"
      data-test-id="pages-container"
      class="d-inline-block"
      handle=".affordance"
      draggable=">*:not([data-locked='true'])"
      animation="0"
      swapThreshold="0.6"
      fallbackTolerance="0"
      :delay="isTouchDevice ? 150 : 0"
      touchStartThreshold="3"
      selectedClass="selected"
      ghostClass="draggable--ghost"
      swapClass="draggable--swap"
      @end="bp_pageSortable.onEnd"
      @add="bp_pageSortable.onAdd"
      @update="bp_pageSortable.onUpdate"
      @remove="bp_pageSortable.onRemove"
      :delayOnTouchOnly="true"
      :swap="true"
      :multi-drag="true"
      :scroll="true"
      :forceFallback="false"
      scrollSensitivity="150"
      :supportPointer="false"
    >
      <template v-slot:item="{ element }">
        <v-sheet :data-page-id="element" class="page__wrapper">
          <Page :pageId="element" />
        </v-sheet>
      </template>
    </draggable>
  </div>
</template>

<script>
import { ref } from "vue"

import { usePageSortable } from "@/modules/flatplan/usePageSortable"
import { useCategory } from "@/modules/category/use"

import draggable from "@/lib/draggable"
import Page from "@/modules/page/components/Page"

export default {
  name: "HoldingArea",
  components: {
    Page,
    draggable,
  },
  setup() {
    let sortableContainer = ref(null)

    let bp_pageSortable = usePageSortable({ sortableContainer, draggableFor: "holdingArea" })
    let bp_category = useCategory()

    return {
      isTouchDevice: window.__blinkplan_is_touch_device,
      sortableContainer,
      pageIds: bp_pageSortable.pageIds,
      bp_pageSortable,
      refresh: bp_category.colorHash,
    }
  },
}
</script>

<style lang="scss" scoped>
@import "@/styles/flatplan.scss";

#ha_pages__container {
  width: 100%;
  height: 100%;
  padding-bottom: 400px; /* to make it easier to find the dropzone on an empty holding area */
}

.selected :deep(.affordance) {
  background-color: rgb(var(--v-theme-tertiary));
  color: rgb(var(--v-theme-on-tertiary));
}

.draggable--ghost {
  opacity: 0.4;
}

.draggable--swap :deep(.affordance) {
  background-color: rgb(var(--v-theme-quaternary));
  color: rgb(var(--v-theme-on-quaternary));
}

.page__wrapper {
  height: calc(var(--bp-page-height) + #{$bp-page-affordance-height});
}
</style>
