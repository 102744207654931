<template>
  <div class="d-flex justify-end">
    <div v-if="!!thumbnail && !!thumbnail.key" class="d-flex align-end">
      <PageThumbnail :thumbnail="thumbnail" :permitQuickLook="true" />
      <v-icon @click="deleteThumbnail">{{ mdiDeleteOutline }}</v-icon>
    </div>
    <v-file-input
      v-else
      accept="image/*|application/pdf"
      label="Upload page thumbnail"
      :loading="loading"
      :disabled="disabled"
      :rules="fileRules"
      prepend-icon=""
      :prepend-inner-icon="mdiImage"
      @update:modelValue="uploadFile"
      @click:clear="cancel"
      show-size
      data-test-id="file-upload"
    ></v-file-input>
  </div>
</template>

<script>
import { ref, watchEffect } from "vue"
import { mdiImage, mdiDeleteOutline } from "@mdi/js"

import PageThumbnail from "./PageThumbnail"

import { upload } from "@/utils/upload"
import { sessionStorageObj } from "@/utils/storage"
import { cdn } from "@/lib/cdn"

import { useNotification } from "@/modules/notification/use"

export default {
  name: "EditPageThumbnail",
  emits: ["busy", "dirty"],
  components: {
    PageThumbnail,
  },
  setup(props, context) {
    let bp_notification = useNotification()

    // let's fetch the edit in-progress from sessionStorage. The EditPageView component has
    // placed a copy of the original into sessionStorage (Assumption: The EditPageView setup method runs before this setup method).
    // Let's save all changes back to sessionStorage,
    // and the EditPageView will pick it up again to save when done editing.

    let thumbnail = ref(sessionStorageObj.getItem("editPage", "thumbnail").data)
    let previewUrl = ref(null)

    watchEffect(() => {
      previewUrl.value = cdn.url(thumbnail.value, { width: 88, height: 114 })
    })

    let fileRules = [(value) => !value || !value.length || value[0].size < 3000000 || "Thumbnail size should be less than 3 MB"]
    let loading = ref(false)
    let disabled = ref(false)
    let uploadTask = null // so that we can cancel an upload task

    const saveToLocalStorage = (value) => {
      sessionStorageObj.setItem("editPage", { data: value, dirty: true }, "thumbnail")
      context.emit("dirty")
    }

    let cancel = () => {
      if (uploadTask) {
        uploadTask.cancel()
      }
    }

    let isFileValid = (file) => {
      let valid = true
      fileRules.forEach((rule) => {
        valid = valid && rule(file) === true
      })
      return valid
    }

    let deleteThumbnail = () => {
      // TODO: Delete on Firebase?
      thumbnail.value = null
      saveToLocalStorage(thumbnail.value)
    }

    let uploadFile = (file) => {
      // note: the html control used to send an array of files
      if (!!file && isFileValid(file)) {
        // let file = files[0]

        loading.value = true
        disabled.value = true
        context.emit("busy", true)

        let uploader = upload(file)

        uploader
          .then((data) => {
            disabled.value = false // now that we have connected to Firebase etc, now there is a possibility to cancel upload

            if (data.uploadTask) {
              uploadTask = data.uploadTask

              uploadTask
                .then((data) => {
                  if (data) {
                    thumbnail.value = { key: data.metadata.fullPath }
                    saveToLocalStorage(thumbnail.value)
                  }
                })
                .catch((err) => {
                  bp_notification.show({
                    type: "error",
                    message: err.message,
                  })
                })
                .finally(() => {
                  loading.value = false
                  context.emit("busy", false)
                })
            }
          })
          .catch(() => {
            loading.value = false
            disabled.value = false
            context.emit("busy", false)
          })
      }
    }

    return {
      fileRules,
      uploadFile,
      cancel,
      deleteThumbnail,
      loading,
      disabled,
      previewUrl,
      thumbnail,
      mdiImage,
      mdiDeleteOutline,
    }
  },
}
</script>

<style lang="scss" scoped></style>
