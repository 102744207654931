import { ref } from "vue"
import { defineStore } from "pinia"

import AccountService from "@/services/AccountService.js"

import { useAuthStore } from "@/modules/auth/store"

export const useInviteStore = defineStore("invite", () => {
  const invites = ref([])

  function getByToken(token) {
    return invites.value.find((p) => p.token === token)
  }

  function bootstrap(data) {
    invites.value = data
  }

  function del({ token }) {
    return AccountService.deleteInvite({ accountId: sessionStorage.getItem("currentAccountId"), token }).then(() => {
      let inviteIndex = invites.value.findIndex((c) => c.token === token)
      if (inviteIndex !== -1) {
        invites.value.splice(inviteIndex, 1)
      }
      return Promise.resolve()
    })
  }

  function add({ email, roles }) {
    return AccountService.addInvite({ accountId: sessionStorage.getItem("currentAccountId"), payload: { email, roles } }).then((response) => {
      let invite = response.data
      let inviteIndex = invites.value.findIndex((c) => c.token === invite.token)
      if (inviteIndex === -1) {
        // insert if new
        invites.value.splice(0, 0, invite)
      } else {
        // replace if exists
        invites.value.splice(inviteIndex, 1, invite)
      }
      return Promise.resolve(response)
    })
  }

  function fetch({ token }) {
    return AccountService.fetchInvite({ accountId: sessionStorage.getItem("currentAccountId"), token })
  }

  function accept({ token, email, name, password }) {
    return AccountService.acceptInvite({ token, payload: { email, name, password } }).then((response) => {
      const authStore = useAuthStore()
      authStore.setAuth(response.data)
      return Promise.resolve(response)
    })
  }

  return {
    invites,
    getByToken,
    bootstrap,
    del,
    add,
    fetch,
    accept,
  }
})
