<template>
  <svg :height="height" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 125.09 125.09">
    <path class="cls-1" d="M566.93,275.14a62.54,62.54,0,1,0-62.54-62.54,62.54,62.54,0,0,0,62.54,62.54" transform="translate(-504.39 -150.06)" />
    <polygon class="cls-2" points="36.49 32.43 20.89 48.48 20.89 88.25 62.2 88.25 62.2 32.16 36.49 32.43" />
    <polygon class="cls-2" points="88.3 32.43 103.9 48.48 103.9 88.25 62.59 88.25 62.59 32.16 88.3 32.43" />
    <path
      class="cls-3"
      d="M594,178.72H568.89V164.84H565v13.88H539.88l-17.75,17.74v39.13a6.25,6.25,0,0,0,6.24,6.24H565v13.88h3.93V241.83h36.6a6.24,6.24,0,0,0,6.23-6.24V196.46Zm-54.85,9.06v6.61a1.32,1.32,0,0,1-1.33,1.32h-6.61ZM564,236H528.37a.36.36,0,0,1-.36-.36V199.65h9.79a5.27,5.27,0,0,0,5.27-5.26v-9.8H564Zm30.74-48.17,7.93,7.93h-6.6a1.32,1.32,0,0,1-1.33-1.32Zm11.12,47.81a.36.36,0,0,1-.36.36H569.87V184.59h20.92v9.8a5.27,5.27,0,0,0,5.27,5.26h9.79Z"
      transform="translate(-504.39 -150.06)"
    />
  </svg>
</template>

<script>
export default {
  name: "LogoOnly",
  props: {
    height: {
      type: Number,
      default: 40,
    },
  },
}
</script>

<style scoped>
.cls-1 {
  fill: #8ff;
}
.cls-2 {
  fill: #fff;
}
.cls-3 {
  fill: #009faf;
}
</style>
