<template>
  <div class="mb-6">
    <span v-if="showIssue" class="text-secondary text-subtitle-1">{{ flatplanIssue }}</span> <span class="pl-4 text-h6 text-uppercase text-error">Archived</span>
  </div>
  <Flatplan />
</template>

<script>
import { computed } from "vue"

import Flatplan from "@/modules/viewOnly/components/Flatplan"

import { useFlatplan } from "../use"
import { useViewSetting } from "@/modules/viewSetting/use"

export default {
  name: "ArchivedFlatplan",
  components: {
    Flatplan,
  },
  setup() {
    let bp_flatplan = useFlatplan()
    let bp_viewSetting = useViewSetting()

    let showIssue = computed(() => {
      return !bp_viewSetting.showLeftDrawer.value
    })

    return {
      flatplanIssue: bp_flatplan.issue,
      showIssue,
    }
  },
}
</script>
