<template>
  <transition name="highlight" @before-leave="show = true">
    <v-sheet v-if="show" class="d-flex affordance noselect" @click="$emit('page-selected', page._id)" data-label="page affordance" color="">
      <v-spacer></v-spacer>
      <v-menu v-model="menu" offset-y transition="scroll-y-transition">
        <template v-slot:activator="{ props }">
          <v-icon v-bind="props" size="x-small" @mousedown.stop="swallow" data-test-id="menu">{{ mdiDotsVertical }}</v-icon>
        </template>
        <v-list density="compact">
          <v-list-item @click="copyToClipboard" data-label="copy page" v-once>
            <v-list-item-title>Copy</v-list-item-title>
          </v-list-item>
          <v-list-item @click="pasteFromClipboard" data-label="paste page" v-once>
            <v-list-item-title>Paste</v-list-item-title>
          </v-list-item>
          <v-menu v-if="!isCover" v-model="showMove" :close-on-content-click="false" :offset="[-40,-50]">
            <template v-slot:activator="{ props }">
              <v-list-item v-bind="props">
                <v-list-item-title>Move</v-list-item-title>
              </v-list-item>
            </template>
            <PageMove :pageId="page._id" v-model:open="showMove" @moved="move" />
          </v-menu>
          <v-menu v-model="showStretch" :close-on-content-click="false" :offset="[-40,-50]">
            <template v-slot:activator="{ props }">
              <v-list-item v-bind="props">
                <v-list-item-title>Copy content across next # pages</v-list-item-title>
              </v-list-item>
            </template>
            <PageStretch :pageId="page._id" v-model:open="showStretch" />
          </v-menu>
          <v-menu v-model="showPageNumbering" :close-on-content-click="false" :offset="[-40,-50]">
            <template v-slot:activator="{ props }">
              <v-list-item v-bind="props">
                <v-list-item-title>Change page numbering</v-list-item-title>
              </v-list-item>
            </template>
            <PageChangeNumbering :pageId="page._id" v-model:open="showPageNumbering" />
          </v-menu>
          <v-menu v-if="!isCover" v-model="showAddPages" :close-on-content-click="false" :offset="[-40,-50]">
            <template v-slot:activator="{ props }">
              <v-list-item v-bind="props">
                <v-list-item-title>Insert pages</v-list-item-title>
              </v-list-item>
            </template>
            <AddPages :insertAtIndex="insertAtIndex" v-model:open="showAddPages" />
          </v-menu>
          <v-list-item v-if="!isCover" @click="bp_page.deletePages({ pageIds: [page._id] })" data-test-id="delete-option" data-label="delete page">
            <v-list-item-title>Delete</v-list-item-title>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item v-if="!page.onHoldingArea && page.isLocked" @click="bp_page.changePageLock({ pageId: page._id, isLocked: false })" data-test-id="lock-option" data-label="lock page">
            <template v-slot:prepend>
              <v-icon>{{ mdiLockOpenVariantOutline }}</v-icon>
            </template>
            <v-list-item-title>Unlock</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="!page.onHoldingArea && !page.isLocked" @click="bp_page.changePageLock({ pageId: page._id, isLocked: true })" data-test-id="lock-option" data-label="unlock page">
            <template v-slot:prepend>
              <v-icon size="small">{{ mdiLockOutline }}</v-icon>
            </template>
            <v-list-item-title>Lock</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-sheet>
  </transition>
</template>

<script>
import { ref, computed, watch, onMounted, onUnmounted, nextTick } from "vue"
import { mdiDragVertical, mdiDotsVertical, mdiLockOutline, mdiLockOpenVariantOutline } from "@mdi/js"

import PageChangeNumbering from "./PageChangeNumbering"
import PageStretch from "./PageStretch"
import PageMove from "./PageMove"
import AddPages from "./AddPages"

import { Bus } from "@/Bus"

import { usePage } from "@/modules/page/use"
import { useClipboard } from "@/modules/clipboard/use"
import { useNotification } from "@/modules/notification/use"

export default {
  name: "PageAffordance",
  props: {
    page: {
      type: Object,
      required: true,
    },
  },
  emits: ["page-selected", "page-changed"],
  components: {
    PageChangeNumbering,
    PageStretch,
    PageMove,
    AddPages,
  },
  setup(props) {
    let bp_page = usePage()
    let bp_clipboard = useClipboard()
    let bp_notification = useNotification()

    let show = ref(true)

    let showPageNumbering = ref(false)
    let showStretch = ref(false)
    let showMove = ref(false)
    let showAddPages = ref(false)

    let insertAtIndex = computed(() => {
      return bp_page.orderForFlatplan.value.indexOf(props.page._id) + 1
    })

    let menu = ref(false)

    let copyToClipboard = () => {
      let pageContents = JSON.parse(JSON.stringify({ fragments: props.page.fragments, thumbnail: props.page.thumbnail ? props.page.thumbnail : null }))

      bp_clipboard.write({ type: "page", data: pageContents })

      bp_notification.show({
        type: "success",
        message: "Page contents copied to clipboard",
      })
    }

    let pasteFromClipboard = () => {
      let clipboardItem = bp_clipboard.read()

      menu.value = false // we need to close - else things turn weird

      if (!!clipboardItem && clipboardItem.type === "page") {
        bp_page.updatePage({ pageId: props.page._id, ...clipboardItem.data })
      }
    }

    let move = ({ order, affectedPageIds }) => {
      bp_page.reorderFlatplan(order)
      bp_page.reorder({})

      affectedPageIds.forEach((pageId) => {
        Bus.$emit("page-changed", { pageId })
      })
    }

    // when we open a popup from the menu,
    // then close the menu when we close the popup
    watch([showPageNumbering, showStretch, showMove, showAddPages], ([showPageNumbering, showStretch, showMove, showAddPages]) => {
      if (!showPageNumbering && !showStretch && !showMove && !showAddPages) {
        menu.value = false
      }
    })

    onMounted(() => {
      Bus.$on("page-changed", ({ pageId }) => {
        if (props.page._id === pageId) {
          nextTick(() => {
            // put in nextTick - works better like this when pasting
            show.value = false // trigger transition/animation
          })
        }
      })
    })

    onUnmounted(() => {
      Bus.$off("page-changed")
    })

    let swallow = () => {
      // noop
    }

    return {
      bp_page,
      insertAtIndex,
      isCover: bp_page.isCover(props.page._id),
      show,
      menu,
      showPageNumbering,
      showStretch,
      showMove,
      showAddPages,
      copyToClipboard,
      pasteFromClipboard,
      move,
      swallow,
      mdiDragVertical,
      mdiDotsVertical,
      mdiLockOutline,
      mdiLockOpenVariantOutline,
    }
  },
}
</script>

<style lang="scss" scoped>
.affordance {
  height: $bp-page-affordance-height;
  max-height: $bp-page-affordance-height;
  min-height: $bp-page-affordance-height;
  background-color: $bp-affordance-color;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  padding-top: 2px;
  cursor: pointer;
}

.highlight-enter-active,
.highlight-leave-active {
  transition: background-color 1.5s ease-out;
}

.highlight-enter-from,
.highlight-leave-to {
  background-color: yellow;
}

// if we don't have the .noselect, then Firefox can't do an alt-drag
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}
</style>
