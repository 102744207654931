<template>
  <div v-if="layout === 'profile'">
    <v-navigation-drawer @update:model-value="leftDrawerStateChange" :model-value="showLeftDrawer">
      <template v-slot:prepend>
        <div class="pa-3">
          <a href="/"><LogoFull class="ml-3" /></a>
        </div>
      </template>

      <template v-slot:append>
        <div class="d-flex justify-space-between align-baseline">
          <v-btn href="//www.blinkplan.com/docs" target="_blank">Documentation</v-btn>
        </div>
      </template>

    </v-navigation-drawer>

    <v-app-bar flat>
      <template v-slot:prepend>
        <v-app-bar-nav-icon @click.stop="setShowLeftDrawer">
          <v-icon color="primary">{{ showLeftDrawer ? mdiBackburger : mdiMenu }}</v-icon>
        </v-app-bar-nav-icon>
      </template>

      <v-app-bar-title>
        <a v-show="!showLeftDrawer" href="/"><LogoBlinkplan class="mt-3" /></a>
      </v-app-bar-title>

      <TheProgressBar />

      <template v-slot:append>
        <v-app-bar-nav-icon>
          <TheUserNav />
        </v-app-bar-nav-icon>
      </template>

      <template v-slot:extension v-if="toolbar !== null">
        <ProfileToolBar v-if="toolbar === 'profile'" />
      </template>
    </v-app-bar>

    <v-main>
      <slot></slot>
    </v-main>
  </div>
</template>

<script>
import { computed } from "vue"
import { useRouter } from "vue-router"
import { mdiMenu, mdiBackburger } from "@mdi/js"

import TheUserNav from "@/components/TheUserNav.vue"
import TheProgressBar from "@/components/TheProgressBar.vue"
import LogoFull from "@/components/Logos/LogoFull.vue"
import LogoBlinkplan from "@/components/Logos/LogoBlinkplan.vue"

import ProfileToolBar from "@/modules/auth/components/ProfileToolBar"

import { useToolbar } from "@/use/toolbar"
import { useViewSetting } from "@/modules/viewSetting/use"

export default {
  name: "ProfileLayout",
  components: {
    TheUserNav,
    TheProgressBar,
    LogoFull,
    LogoBlinkplan,
    ProfileToolBar,
  },
  setup() {
    let router = useRouter()
    let currentRoute = router.currentRoute

    let bp_toolbar = useToolbar()
    let bp_viewSetting = useViewSetting()
    let showLeftDrawer = bp_viewSetting.showLeftDrawer

    let leftDrawerStateChange = (state) => {
      bp_viewSetting.setShowLeftDrawer(state)
    }

    let setShowLeftDrawer = () => {
      bp_viewSetting.setShowLeftDrawer(!bp_viewSetting.showLeftDrawer.value)
    }

    return {
      showLeftDrawer,
      setShowLeftDrawer,
      toolbar: bp_toolbar.toolbar,
      leftDrawerStateChange,
      layout: computed(() => currentRoute.value.meta.layout),
      mdiMenu,
      mdiBackburger,
    }
  },
}
</script>
