import { ref, computed } from "vue"
import { defineStore } from "pinia"

import DataService from "@/services/DataService.js"
import { generateId } from "@/utils/generateId"
import { fragmentSizes, fragmentShapes } from "@/modules/page/render/fragmentUtils"

export const useContentStore = defineStore("content", () => {
  const contents = ref([])

  function compareText(a, b) {
    let fa = a.text.toLowerCase(),
      fb = b.text.toLowerCase()

    if (fa < fb) {
      return -1
    }
    if (fa > fb) {
      return 1
    }
    return 0
  }

  function getById(id) {
    return contents.value.find((c) => c._id === id)
  }

  function getByIds(ids) {
    return contents.value.filter((c) => ids.includes(c._id))
  }

  function getByIdentifiers(identifiers) {
    return contents.value.filter((c) => identifiers.includes(c.identifier))
  }

  function getByText(text) {
    return contents.value.find((c) => isEqualText(c.text, text))
  }

  function setContent({ contentId, text, pageNos, size, shape }) {
    let contentIndex = contents.value.findIndex((c) => c._id === contentId)
    if (contentIndex !== -1) {
      let content = contents.value[contentIndex]
      if (text !== undefined) {
        content.text = text
      }
      if (pageNos !== undefined) {
        content.pageNos = [...pageNos]
      }
      if (size !== undefined) {
        content.size = size
      }
      if (shape !== undefined) {
        content.shape = shape
      }
      contents.value.splice(contentIndex, 1, content)
    } else {
      // we have undeleted - so we end up here
      contents.value.splice(0, 0, { _id: contentId, text, size, shape })
    }
  }

  function removeContent({ contentId }) {
    let contentIndex = contents.value.findIndex((c) => c._id === contentId)
    if (contentIndex !== -1) {
      contents.value.splice(contentIndex, 1)
    }
  }

  function removeContents({ contentIds }) {
    contentIds.forEach((id) => {
      let contentIndex = contents.value.findIndex((c) => c._id === id)
      if (contentIndex !== -1) {
        contents.value.splice(contentIndex, 1)
      }
    })
  }

  function bootstrap(data) {
    contents.value = data
  }

  function update({ flatplanId, contentId, text, pageNos, size, shape, storeOnly }) {
    let prevAttributes = JSON.parse(JSON.stringify(getById(contentId) || {}))

    setContent({ contentId, text, pageNos, size, shape })

    if (!storeOnly) {
      return DataService.updateContent({ accountId: sessionStorage.getItem("currentAccountId"), flatplanId, contentId, payload: { text, pageNos, size, shape } }).catch((err) => {
        setContent({ contentId, ...prevAttributes })
        return Promise.reject(err)
      })
    } else {
      return Promise.resolve()
    }
  }

  function addContents({ data }) {
    data.forEach((content) => {
      let contentIndex = contents.value.findIndex((c) => c._id === content._id)
      if (contentIndex === -1) {
        // insert if new
        contents.value.splice(0, 0, content)
      } else {
        // replace if exists
        contents.value.splice(contentIndex, 1, content)
      }
    })
  }

  function batchAdd({ flatplanId, contents }) {
    setIdentifierForAllMissingIdentifiers(contents)

    return DataService.addContents({ accountId: sessionStorage.getItem("currentAccountId"), flatplanId, payload: { contents } }).then((response) => {
      addContents({ data: [...response.data.addedContents, ...response.data.updatedContents] })

      return Promise.resolve(response)
    })
  }

  function batchDel({ contentIds, flatplanId, storeOnly }) {
    let contents = getByIds(contentIds)
    let prevContents = JSON.parse(JSON.stringify(contents))

    removeContents({ contentIds })

    if (!storeOnly) {
      return DataService.deleteContents({ accountId: sessionStorage.getItem("currentAccountId"), flatplanId, identifiers: contents.map(content => content.identifier) }).catch((err) => {
        addContents({ data: prevContents })
        return Promise.reject(err)
      })
    } else {
      return Promise.resolve()
    }
  }

  // this method is used when contents were added via Liveupdate (so they already exist on the server)
  function addContentsFromLiveupdate({ addedContents, updatedContents }) {
    addContents({ data: [...(addedContents || []), ...(updatedContents || [])] })
  }

  const generateIdentifier = () => {
    return generateId(8)
  }

  const setIdentifierForAllMissingIdentifiers = (contents) => {
    contents.forEach((content) => {
      const identifier = cleanIdentifier(content.identifier) || generateIdentifier()
      if (content.identifier !== identifier) {
        content.identifier = cleanIdentifier(content.identifier) || generateIdentifier()
        content.ownIdentifier = false
      }
    })
  }

  const cleanIdentifier = (identifier) => {
    let ident = null

    if (identifier) {
      ident = identifier.trim()
      if (ident.length === 0) {
        ident = null
      }
    }
    return ident
  }

  const cleanText = (text) => {
    return (text || "").trim()
  }

  const cleanPageNos = (pageNos) => {
    // looks for zero or more spaces, followed by a semicolon or comma, followed by zero or more spaces—and, when found,
    // removes the spaces and the semicolon or comma from the string
    let re = /\s*(?:[;|,]|$)\s*/
    return (pageNos || "").split(re).filter((s) => s.length > 0)
  }

  const cleanSize = (size) => {
    let cleaned = (size || "").trim()
    return fragmentSizes.indexOf(cleaned) > -1 ? cleaned : "" 
  }

  const cleanShape = (shape) => {
    let cleaned = (shape || "").trim().toLowerCase()
    return fragmentShapes.map((i) => i.toLowerCase()).indexOf(cleaned) > -1 ? cleaned : "" 
  }

  const isDuplicateExistingItem = ({ identifier, text, size, shape }) => {
    let found = false
    contents.value.forEach((c) => {
      if (c.identifier !== identifier && isEqualText(c.text, text) && isEqualShape(c.shape, shape) && isEqualSize(c.size, size)) {
        found = true
      }
    })
    return found
  }

  const isEqualText = (a, b) => {
    return cleanText(a).toLowerCase() === cleanText(b).toLowerCase()
  }

  const isEqualShape = (a, b) => {
    return cleanShape(a) === cleanShape(b)
  }

  const isEqualSize = (a, b) => {
    return cleanSize(a) === cleanSize(b)
  }

  // eg a DPS content is acceptable on a full page template.
  const isAcceptableSize = (a, b) => {
    const cleanA = cleanSize(a)
    const cleanB = cleanSize(b)
    return isEqualSize(a, b) || (cleanA === "1" && cleanB === "2" || cleanA === "2" && cleanB === "1")
  }

  return {
    contents,
    getById,
    getByIds,
    getByIdentifiers,
    getByText,
    bootstrap,
    update,
    batchDel,
    batchAdd,
    addContentsFromLiveupdate,
    compareText,
    cleanIdentifier,
    cleanText,
    cleanPageNos,
    cleanSize,
    cleanShape,
    isEqualText,
    isEqualShape,
    isEqualSize,
    isAcceptableSize,
    isDuplicateExistingItem,
  }
})
