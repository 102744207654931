<template>
  <v-container v-if="apiKey" fluid>
    <h4 class="text-h4 mb-1">API key: {{ apiKey.name || "[blank]" }}</h4>
    <p><span class="text-subtitle-1">x-api-key: <span class="text-disabled">************</span>{{ apiKey.last_4 }}</span></p>
    <p><span class="text-subtitle-1">x-account-id: <strong>{{ apiKey.account_secret_id }}</strong></span></p>
    <hr class="mt-4 mb-4" />
    <v-row>
      <v-col cols="8" sm="6">
        <div v-if="!apiKey.last_used_at">
          <p><strong>This API key hasn't been used yet.</strong></p>
        </div>
        <div v-else>
          <p>This API key was last used <strong>{{ lastUsed }}</strong></p>
        </div>
        <p>
          <span class="text-medium-emphasis">Created: {{ fmtDateTime(apiKey.created_at) }}</span>
        </p>
      </v-col>
      <v-col cols="4" sm="2"><v-btn color="error" variant="text" @click="del">Delete</v-btn></v-col>
    </v-row>
  </v-container>
</template>

<script>
import { reactive, computed } from "vue"
import { useRouter } from "vue-router"
import { DateTime } from "luxon"

import { useApiKey } from "../use"

export default {
  name: "ApiKeyView",
  props: {
    key_hash: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    let router = useRouter()

    let bp_apiKey = useApiKey()

    let apiKey = reactive(bp_apiKey.getByKeyHash(props.key_hash))

    let lastUsed = computed(() => {
      return DateTime.fromISO(apiKey.last_used_at).toRelative()
    })

    let fmtDateTime = (date) => {
      return DateTime.fromISO(date).toFormat("DD, t")
    }

    let del = () => {
      bp_apiKey.del({ key_hash: props.key_hash }).then(() => {
        router.push({ name: "api-keys" })
      })
    }

    return {
      apiKey,
      lastUsed,
      fmtDateTime,
      del,
    }
  },
}
</script>

<style scoped></style>
