import { ref } from "vue"
import { defineStore } from "pinia"

import AccountService from "@/services/AccountService.js"

import { useAccountStore } from "@/modules/account/store"
import { useRoleStore } from "@/modules/role/store"

export const useBootstrapAppStore = defineStore("bootstrapApp", () => {
  const state = ref("ready")

  function fetch() {
    if (state.value === "done") {
      return Promise.resolve()
    } else {
      state.value = "fetching"
      return AccountService.bootstrap()
        .then(({ data }) => {
          state.value = "done"

          const accounts = data.accounts
          const roles = data.roles

          const accountStore = useAccountStore()
          const roleStore = useRoleStore()

          accountStore.bootstrap({ accounts })
          roleStore.bootstrap({ roles })

          return data
        })
        .catch((err) => {
          console.error(err)
          state.value = "ready"
          throw(err)
        })
    }
  }

  return {
    state,
    fetch,
  }
})
