<template>
  <v-row class="section" dense>
    <v-col cols="3">
      <v-text-field type="number" label="Count" v-model="count" data-test-id="count" @blur="update" required hint="How many of these section sizes?" />
    </v-col>
    <v-col cols="3">
      <v-text-field type="number" label="Size" v-model="size" data-test-id="size" @blur="update" required hint="How big is this section?" />
    </v-col>
    <v-col cols="2" align-self="center">
      <div class="text-body-1" data-test-id="calc">= {{ calc }}</div>
    </v-col>
    <v-col cols="1" offset="3" align-self="center">
      <v-btn :icon="mdiDeleteOutline" @click.stop="del" variant="text" size="small"></v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { computed, ref, watch } from "vue"
import { mdiDeleteOutline } from "@mdi/js"

import { useSection } from "../use"

export default {
  name: "SectionItem",
  props: {
    sectionId: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    let bp_section = useSection()

    const currentSection = bp_section.getById(props.sectionId)
    let count = ref(currentSection.count)
    let size = ref(currentSection.size)

    let del = () => {
      bp_section.del({ sectionId: props.sectionId })
    }

    let update = () => {
      bp_section.update({ sectionId: props.sectionId, type: props.type, size: size.value, count: count.value })
    }

    let calc = computed(() => {
      return count.value * size.value
    })

    // // when we update the store, reflect new values
    watch(currentSection, (currentSection) => {
      size.value = currentSection.size
      count.value = currentSection.count
    })

    return {
      count,
      size,
      del,
      update,
      calc,
      mdiDeleteOutline,
    }
  },
}
</script>

<style lang="scss" scoped>
.section {
  cursor: pointer;
}
</style>
