import { ref, computed } from "vue"
import { defineStore } from "pinia"

import DataService from "@/services/DataService.js"

const comparePosition = (a, b) => {
  return (a.position || 0) - (b.position || 0)
}

const noneId = "000000000000000000000000"

const noneCategory = {
  _id: noneId,
  name: "<none>",
  color: "transparent",
  position: 99999
}

export const useCategoryStore = defineStore("category", () => {
  const categories = ref([])
  const draggableOrder = ref([])

  const ordered = computed(() => {
    return categories.value.sort(comparePosition)
  })

  const currentOrderData = computed(() => {
    return ordered.value.map((c) => {
      let { _id, position } = c
      return {
        _id,
        position,
      }
    })
  })

  function collectOrder() {
    let orderData = []

    draggableOrder.value.forEach((_id, position) => {
      let category = getById(_id)

      orderData.push({
        _id,
        position,
      })
    })
    return orderData
  }

  function getById(id) {
    return categories.value.find((c) => c._id === id) || noneCategory
  }

  function getByIds(ids) {
    if (ids !== undefined) {
      return categories.value.concat(noneCategory).filter((c) => ids.includes(c._id))
    } else {
      return []
    }
  }

  function bootstrap(data) {
    categories.value = data
    draggableOrder.value = currentOrderData.value.map((c) => c._id)
  }

  function reorderDraggable(data) {
    draggableOrder.value = data
  }

  // we just pass in position for undeletes ...
  function setCategory({ categoryId, name, color, position }) {
    let categoryIndex = categories.value.findIndex((c) => c._id === categoryId)
    if (categoryIndex !== -1) {
      let category = categories.value[categoryIndex]
      if (name !== undefined) {
        category.name = name
      }
      if (color !== undefined) {
        category.color = color
      }
      categories.value.splice(categoryIndex, 1, category)
    } else {
      // we have undeleted - so we end up here
      categories.value.splice(0, 0, { _id: categoryId, name, color, position })
    }
  }

  function removeCategory({ categoryId }) {
    let categoryIndex = categories.value.findIndex((c) => c._id === categoryId)
    if (categoryIndex !== -1) {
      categories.value.splice(categoryIndex, 1)
    }
  }

  function addCategory(category) {
    let categoryIndex = categories.value.findIndex((c) => c._id === category._id)
    if (categoryIndex === -1) {
      // insert if new
      categories.value.splice(0, 0, category)
    } else {
      // replace if exists
      categories.value.splice(categoryIndex, 1, category)
    }
  }

  function setOrder(orderData) {
    let categoriesData = []
    orderData.forEach((item) => {
      let categoryIndex = categories.value.findIndex((c) => c._id === item._id)
      if (categoryIndex !== -1) {
        let category = categories.value[categoryIndex]
        category.position = item.position

        categoriesData.push(category)
      }
    })
    categories.value = categoriesData
  }

  function update({ flatplanId, categoryId, name, color, position, storeOnly }) {
    let prevAttributes = JSON.parse(JSON.stringify(this.getById(categoryId) || {}))

    // only pass in position for undeleted - else use reorder
    setCategory({ categoryId, name, color, position })

    // we may be doing this in response to an undelete - so let's update the draggable order too
    // as we may have inserted a category
    draggableOrder.value = currentOrderData.value.map((c) => c._id)

    if (!storeOnly) {
      return DataService.updateCategory({ accountId: sessionStorage.getItem("currentAccountId"), flatplanId, categoryId, payload: { name, color, position } }).catch((err) => {
        setCategory({ categoryId, ...prevAttributes })
        return Promise.reject(err)
      })
    } else {
      return Promise.resolve()
    }
  }

  function del({ categoryId, flatplanId, storeOnly }) {
    let prevCategory = JSON.parse(JSON.stringify(getById(categoryId)))

    removeCategory({ categoryId })

    // update the collection of order ids
    draggableOrder.value = currentOrderData.value.map((c) => c._id)

    if (!storeOnly) {
      return DataService.deleteCategory({ accountId: sessionStorage.getItem("currentAccountId"), flatplanId, categoryId }).catch((err) => {
        addCategory(prevCategory)

        // // update the collection of order ids
        draggableOrder.value = currentOrderData.value.map((c) => c._id)

        return Promise.reject(err)
      })
    } else {
      return Promise.resolve()
    }
  }

  // add - we generate on server first, then mutate store
  function add({ flatplanId, name, color }) {
    return DataService.addCategory({ accountId: sessionStorage.getItem("currentAccountId"), flatplanId, payload: { name, color } }).then((response) => {
      addCategory(response.data)

      // // update the collection of order ids
      draggableOrder.value = currentOrderData.value.map((c) => c._id)

      return Promise.resolve(response)
    })
  }

  // this method is used when category was added via Liveupdate (so it already exists on the server)
  function addFromLiveupdate(category) {
    addCategory(category)

    // // update the collection of order ids
    draggableOrder.value = currentOrderData.value.map((c) => c._id)
  }

  function reorder({ flatplanId, orderData, storeOnly }) {
    let prevOrderData = currentOrderData.value

    orderData = orderData || []

    if (orderData.length === 0) {
      // we didn't pass in orderData
      // let's build it up from state
      // if the reorder is due to an undo - then we're passing in an orderData
      orderData = collectOrder()
    }

    setOrder(orderData)
    draggableOrder.value = currentOrderData.value.map((c) => c._id)

    if (!storeOnly) {
      return DataService.reorderCategories({ accountId: sessionStorage.getItem("currentAccountId"), flatplanId, orderData }).catch((err) => {
        setOrder(prevOrderData)
        draggableOrder.value = currentOrderData.value.map((c) => c._id)
        return Promise.reject(err)
      })
    } else {
      return Promise.resolve()
    }
  }

  return {
    categories,
    ordered,
    draggableOrder,
    currentOrderData,
    getById,
    getByIds,
    bootstrap,
    reorderDraggable,
    update,
    del,
    add,
    addFromLiveupdate,
    reorder,
    noneId,
    noneCategory,
    orderFn: comparePosition
  }
})
