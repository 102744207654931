<template>
  <v-list density="compact">
    <v-list-subheader>SETUP</v-list-subheader>
    <v-list-item :to="{ name: 'categories-show' }" data-label="categories nav">
      <v-list-item-title>Categories</v-list-item-title>
    </v-list-item>
    <v-list-item :to="{ name: 'tags-show' }" data-label="tags nav">
      <v-list-item-title>Tags</v-list-item-title>
    </v-list-item>
    <v-list-item :to="{ name: 'contents-show' }" data-label="contents nav">
      <v-list-item-title>Ads/Content to be placed</v-list-item-title>
    </v-list-item>
    <v-list-item :to="{ name: 'sections-show' }" data-label="sections nav">
      <v-list-item-title>Sections</v-list-item-title>
    </v-list-item>
    <v-list-item :to="{ name: 'stitchIns-show' }" data-label="stitchIns nav">
      <v-list-item-title>Stitch-ins</v-list-item-title>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  name: "FlatplanSetupOptions",
}
</script>

<style lang="scss" scoped></style>
