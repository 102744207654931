<template>
  <v-app :class="'__layout__' + bp_layouts.layoutName.value">
    <component :is="bp_layouts.layout.value">
      <TheNotificationBar />
      <TheViewLoader v-if="waitingForRoute" />
      <router-view v-else />
    </component>
    <TheAppReloader />
  </v-app>
</template>

<script>
import { provide, watch, computed } from "vue"
import { useRouter } from "vue-router"
import { useDisplay } from "vuetify"

import TheNotificationBar from "@/modules/notification/components/TheNotificationBar"
import TheViewLoader from "@/components/TheViewLoader"
import TheAppReloader from "@/components/TheAppReloader.vue"

import { useLayouts } from "@/use/useLayouts"
import { useViewSetting } from "@/modules/viewSetting/use"

export default {
  name: "App",
  components: {
    TheViewLoader,
    TheNotificationBar,
    TheAppReloader,
  },
  setup() {
    let router = useRouter()
    let currentRoute = router.currentRoute

    let bp_layouts = useLayouts(currentRoute)
    let bp_viewSetting = useViewSetting()

    const { mobile } = useDisplay()

    let forceOpenLeftNavigationDrawer = false

    // when we first land on a url from the Internet,
    // and if a beforeEnter nav guard is running, the route may not have a name yet.
    // Especially for bootstrapping. In this case - so long as it doesn't have a name yet,
    // Show a loader.
    let waitingForRoute = computed(() => currentRoute.value.name === undefined)

    // for pdf layouts, let's have a white background.
    // we need to add a style to the .v-application element for this to happen.
    watch(bp_layouts.layoutName, (layoutName) => {
      if (layoutName === "pdf") {
        let appEl = document.getElementsByClassName("v-application")[0]
        appEl.setAttribute("style", "background-color: white")
      }
    })

    // we can force open the left drawer if we like - for example for support requests, we can make sure the user sees the left nav drawer
    watch(waitingForRoute, () => {
      if (currentRoute.value.query.nav === "open") {
        forceOpenLeftNavigationDrawer = true
      }
    })

    watch(
      () => currentRoute.value.name, () => {      
        if (forceOpenLeftNavigationDrawer) {
          bp_viewSetting.setShowLeftDrawer(true)
          forceOpenLeftNavigationDrawer = false
        } else {
          if (mobile.value) {
            // Make sure left navigation drawer is closed when navigating to a new screen
            bp_viewSetting.setShowLeftDrawer(false)
          }
        }
      }
    )

    provide("mobile", mobile)

    return {
      bp_layouts,
      waitingForRoute,
    }
  },
}
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.expand-enter-active,
.expand-leave-active {
  transition: all 0.2s ease;
}

.expand-enter-from,
.expand-leave-to {
  transform: scale(0);
}

.cursor-pointer {
  cursor: pointer;
}
</style>

<style scoped>
/* we need to put these into a scoped style with deep selectors */
/* I think it is because the Base components are just extended functional components */
/* and the order in which they get loaded and the CSS gets applied is a bit different */
:deep(.v-alert--outlined) {
  border: none !important;
}
</style>
