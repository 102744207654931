<template>
  <v-container v-if="invite" fluid>
    <h4 class="text-h4 mb-1">{{ invite.email }}</h4>
    <span class="text-subtitle-1">Invited by {{ invite.sender_name }}</span>
    <hr class="mt-4 mb-4" />
    <v-row>
      <v-col cols="8" sm="6">
        <div v-if="!invite.accepted_at">
          <strong>{{ invite.email }} hasn't logged into Blinkplan yet.</strong>
          <p class="mt-4">The invitation was sent {{ sent }} . If you'd like, you can email the invite link (below) yourself:</p>
          <v-text-field label="Invite link" :model-value="inviteUrl" readonly class="mt-4"/>
        </div>
        <div v-else>
          <p>This invite was accepted on: {{ fmtDateTime(invite.accepted_at) }}</p>
          <p class="mt-4 text-caption">You can safely delete the invite if you like. The person will continue to be linked to this account.</p>
        </div>
      </v-col>
      <v-col cols="4" sm="2"><v-btn color="error" variant="text" @click="del">Delete</v-btn></v-col>
    </v-row>
  </v-container>
</template>

<script>
import { reactive, computed } from "vue"
import { useRouter } from "vue-router"
import { DateTime } from "luxon"

import { useInvite } from "../use"

export default {
  name: "InviteView",
  props: {
    token: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    let router = useRouter()

    let bp_invite = useInvite()

    let invite = reactive(bp_invite.getByToken(props.token))

    let sent = computed(() => {
      return DateTime.fromISO(invite.updated_at).toRelative()
    })

    let fmtDateTime = (date) => {
      return DateTime.fromISO(date).toFormat("DD, t")
    }

    let del = () => {
      bp_invite.del({ token: props.token }).then(() => {
        router.push({ name: "invites" })
      })
    }

    let inviteUrl = computed(() => {
      let url = window.location.protocol + "//" + window.location.host
      url = url + router.resolve({ name: "invite-accept", params: { token: invite.token } }).href // needs to be the same as generated on the API
      return url
    })

    return {
      invite,
      sent,
      inviteUrl,
      fmtDateTime,
      del,
    }
  },
}
</script>

<style scoped></style>
