<template>
  <base-dialog :model-value="true" @keydown.esc="closeDialog" size="medium">
    <v-card-item>
      <v-card-title>
        <span class="text-h5" data-test-id="title">Email Flatplan</span>
      </v-card-title>
    </v-card-item>
    <v-card-text>
      <CreateNewEmailGroup class="mb-4" />
      <EmailPicker @pick="pick" class="mb-4" />
      <v-form ref="form" v-model="formValid" @submit.prevent="saveDialog">
        <v-textarea label="To" v-model="to" required rows="3" hint="Comma separated email addresses" :rules="toRules" />
        <v-textarea label="Message" v-model="message" required />
        <p>A link to the shareable <b>view-only</b> version is added to this email. The recipients require no login. They can export it to PDF. See the preview <a :href="shareUrl" target="_blank" class="text-primary text-decoration-none">here</a>.</p>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <cancel-btn @click="closeDialog">Close</cancel-btn>
      <submit-btn type="submit" @click="saveDialog">Send</submit-btn>
    </v-card-actions>
  </base-dialog>
</template>

<script>
import { ref } from "vue"
import { useRouter } from "vue-router"

import { useFlatplan } from "../use"
import { useShare } from "@/modules/share/use"
import { useNotification } from "@/modules/notification/use"

import EmailPicker from "@/modules/emailGroup/components/EmailPicker"
import CreateNewEmailGroup from "@/modules/emailGroup/components/CreateNewEmailGroup"

export default {
  name: "FlatplanEmailView",
  components: {
    EmailPicker,
    CreateNewEmailGroup,
  },
  setup() {
    let router = useRouter()
    
    let bp_flatplan = useFlatplan()
    let bp_share = useShare()
    let bp_notification = useNotification()

    let message = ref("\n\n\n" + bp_share.shareUrl.value)
    let to = ref("")
    let toRules = [(v) => !!v || "At least one email is required."]

    let form = ref(null)
    let formValid = ref(false)

    let pick = (pickedEmails) => {
      let emails = to.value
        .split(",")
        .concat(pickedEmails.split(","))
        .map((email) => email.trim())
      emails = emails.filter((e) => e) // remove empty values
      to.value = Array.from(new Set(emails)).join(",")
    }

    const closeDialog = () => {
      router.push({ name: "flatplan-show" })
    }

    const saveDialog = () => {
      form.value.validate()

      if (formValid.value || formValid.value === null) { // JD: HACK/BUG
        bp_flatplan
          .sendShareEmail({ to: to.value, message: message.value, issue: bp_flatplan.issue.value })
          .then(() => {
            closeDialog()
            bp_notification.show({
              type: "success",
              message: "Email sent :)",
            })
          })
          .catch((err) => {
            // noop
            throw err
          })
      }
    }

    return {
      form,
      formValid,
      closeDialog,
      saveDialog,
      message,
      to,
      toRules,
      pick,
      shareUrl: bp_share.shareUrl,
    }
  },
}
</script>

<style lang="scss" scoped></style>
