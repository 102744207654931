<template>
  <v-toolbar-items>
    <v-btn variant="text" color="primary" @click="deletePages" :prepend-icon="mdiDeleteOutline" data-label="bulk delete">
      <span v-if="!mobile">Delete</span>
    </v-btn>

    <v-btn variant="text" color="primary" @click="openBulkEditTags = true" :prepend-icon="mdiTagMultipleOutline" data-label="bulk edit tags">
      <span v-if="!mobile">Edit Tags</span>
    </v-btn>

    <v-btn v-if="canBulkEditCategories" variant="text" color="primary" @click="openBulkEditCategory = true" :prepend-icon="mdiLabelOutline" data-label="bulk edit category">
      <span v-if="!mobile">Edit Category</span>
    </v-btn>

    <v-btn v-if="!canBulkEditCategories" variant="text" color="primary" @click="openBulkShiftCategories = true" :prepend-icon="mdiLabelOutline" data-label="bulk shift categories">
      <span v-if="!mobile">Shift Categories</span>
    </v-btn>

    <v-btn variant="text" color="primary" @click="openBulkUpload = true" :prepend-icon="mdiUpload" data-label="bulk upload">
      <span v-if="!mobile">Upload PDF</span>
    </v-btn>

    <BulkEditTags v-if="openBulkEditTags" v-model:open="openBulkEditTags" :pageIds="pageIds" />
    <BulkEditCategory v-if="openBulkEditCategory" v-model:open="openBulkEditCategory" :pageIds="pageIds" />
    <BulkShiftCategories v-if="openBulkShiftCategories" v-model:open="openBulkShiftCategories" :pageIds="pageIds" />
    <BulkUpload v-if="openBulkUpload" v-model:open="openBulkUpload" :pageIds="pageIds" />
  </v-toolbar-items>
</template>

<script>
import { ref, computed, inject } from "vue"
import { mdiDeleteOutline, mdiTagMultipleOutline, mdiLabelOutline, mdiUpload } from "@mdi/js"

import { usePage } from "@/modules/page/use"
import { useBulkPageSelect } from "../useBulkPageSelect"

import BulkEditTags from "@/modules/page/components/BulkEditTags"
import BulkEditCategory from "@/modules/page/components/BulkEditCategory"
import BulkShiftCategories from "@/modules/page/components/BulkShiftCategories"
import BulkUpload from "@/modules/page/components/BulkUpload"

export default {
  name: "BulkOptions",
  components: {
    BulkEditTags,
    BulkEditCategory,
    BulkShiftCategories,
    BulkUpload,
  },
  setup() {
    let mobile = inject("mobile")

    let bp_page = usePage()
    let bp_bulkPageSelect = useBulkPageSelect()

    let openBulkEditTags = ref(false)
    let openBulkUpload = ref(false)
    let openBulkEditCategory = ref(false)
    let openBulkShiftCategories = ref(false)

    let canBulkEditCategories = computed(() => {
      let pages = bp_page.getByIds(bp_bulkPageSelect.pageIds.value)
      return pages.every((page) => page.template.toLowerCase() === "full")
    })

    let deletePages = () => {
      bp_page
        .deletePages({ pageIds: bp_bulkPageSelect.pageIds.value })
        .then(() => {
          bp_bulkPageSelect.clear()
        })
        .catch((err) => {
          // noop
          throw err
        })
    }

    return {
      mobile,
      canBulkEditCategories,
      deletePages,
      openBulkEditTags,
      openBulkUpload,
      openBulkEditCategory,
      openBulkShiftCategories,
      pageIds: bp_bulkPageSelect.pageIds,
      mdiDeleteOutline,
      mdiTagMultipleOutline,
      mdiLabelOutline,
      mdiUpload,
    }
  },
}
</script>

<style lang="scss" scoped></style>
