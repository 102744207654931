import { SVG } from "@svgdotjs/svg.js"
import fragments from "./fragments"
import { getNonOverlappingPaths, textWrap, scaleFontIfNeeded } from "./utils"
import { fragmentFullDescription } from "./fragmentUtils"
import { useFragment } from "@/modules/page/useFragment"

export default ({ categories, contents, withText, withFill, withAdCorners, withSizeTooltip, forceFillColor, pathColor, notifyOnClick, clickCallback }) => {
  const bp_fragment = useFragment()

  let highlightFragment = function ({ highlightFragmentIndex, fragmentIndex }) {
    return highlightFragmentIndex !== null && highlightFragmentIndex === fragmentIndex
  }

  let lowlightFragment = function ({ highlightFragmentIndex, fragmentIndex }) {
    return highlightFragmentIndex !== null && highlightFragmentIndex !== fragmentIndex
  }

  const drawText = ({ draw, drawnFragment, fullPhrase, fragmentItem }) => {
    const margin = 4

    // for L-shape we might want to lose some space where it will be too difficult to
    // render in the narrow sections - so we compress/reduce the available space.
    let textCompressFractionX = fragmentItem.textCompressFractionX || 1
    let textCompressFractionY = fragmentItem.textCompressFractionY || 1

    let isTextHorizontal = true

    if (!fragmentItem.forceHorizontal) {
      // figure out whether the text should be horizontal by the aspect ratio of the shape
      isTextHorizontal = fragmentItem.fractionX * textCompressFractionX >= fragmentItem.fractionY * textCompressFractionY
    }

    // let's get the dimensions of our drawn shape
    // we will need to fit the text inside here.
    // Calc line breaks, font size etc.
    let fragBBox = drawnFragment.bbox()

    let fragmentWidth = fragBBox.width * textCompressFractionX
    let fragmentHeight = fragBBox.height * textCompressFractionY

    let wrappedPhrase = isTextHorizontal ? textWrap(fullPhrase, fragmentWidth - 2 * margin) : textWrap(fullPhrase, fragmentHeight - 2 * margin)

    let textOffsetFractionX = parseFloat(fragmentItem.textOffsetFractionX)
    let textOffsetFractionY = parseFloat(fragmentItem.textOffsetFractionY)

    // let's place the text inside the bounding box of the drawn shape
    let x = textOffsetFractionX ? fragBBox.x + textOffsetFractionX * fragBBox.width : fragBBox.x
    let y = textOffsetFractionY ? fragBBox.y + textOffsetFractionY * fragBBox.height : fragBBox.y

    let text = draw
      .text(wrappedPhrase)
      .font({
        family: "Roboto",
        anchor: "middle",
        leading: "1.1em",
      })
      .fill("black")

    let xText = x
    let yText = y

    if (isTextHorizontal) {
      text = scaleFontIfNeeded(text, fragmentWidth, fragmentHeight)

      // center the text block inside the shape
      let bbox = text.bbox()
      xText += (fragmentWidth - bbox.width) / 2
      yText += (fragmentHeight - bbox.height) / 2

      text.move(xText, yText)
    } else {
      text = scaleFontIfNeeded(text, fragmentHeight, fragmentWidth) // note width, height swapped around

      // center the text block inside the shape
      let bbox = text.bbox()
      xText += (fragmentWidth - bbox.width) / 2
      yText += (fragmentHeight - bbox.height) / 2

      text.move(xText, yText).rotate(270)
    }
  }

  const drawFragment = ({ draw, fragment, fragmentIndex, width, height, highlightFragmentIndex }) => {
    let paths = []
    let cornerDiagonalPaths = []
    let fragmentItem = fragments.find((s) => s.name === fragment.shape)

    if (fragmentItem !== undefined) {
      let fragmentGroup = draw.group()

      let fragmentYSize = fragmentItem.shapeDrawPlan.length
      let fragmentXSize = fragmentItem.shapeDrawPlan[0].length

      let fragTopLeftX = fragment.topLeftX * width
      let fragTopLeftY = fragment.topLeftY * height

      let sqWidth = (fragmentItem.fractionX / fragmentXSize) * width
      let sqHeight = (fragmentItem.fractionY / fragmentYSize) * height

      let cornerDiagonalLength = fragmentXSize * sqWidth * 0.25
      let cornerSideLength = cornerDiagonalLength / Math.sqrt(2) // see pythagoras for 45-45-90 triangles

      // a shape might look like:
      // 01
      // 01
      // 11
      for (let row = 0; row < fragmentYSize; row++) {
        for (let col = 0; col < fragmentXSize; col++) {
          let sq = fragmentItem.shapeDrawPlan[row][col]
          if (sq === 1) {
            let sqTopLeftX = fragTopLeftX + col * sqWidth
            let sqTopLeftY = fragTopLeftY + row * sqHeight
            fragmentGroup.rect(sqWidth, sqHeight).move(sqTopLeftX, sqTopLeftY)

            // collect paths that make up the shape, to give it an outline
            let needsPathTop = row === 0 || fragmentItem.shapeDrawPlan[row - 1][col] === 0
            let needsPathBottom = row === fragmentYSize - 1 || fragmentItem.shapeDrawPlan[row + 1][col] === 0
            let needsPathLeft = col === 0 || fragmentItem.shapeDrawPlan[row][col - 1] === 0
            let needsPathRight = col === fragmentXSize - 1 || fragmentItem.shapeDrawPlan[row][col + 1] === 0

            if (needsPathTop) {
              paths.push([sqTopLeftX, sqTopLeftY, sqTopLeftX + sqWidth, sqTopLeftY])
            }
            if (needsPathBottom) {
              paths.push([sqTopLeftX, sqTopLeftY + sqHeight, sqTopLeftX + sqWidth, sqTopLeftY + sqHeight])
            }
            if (needsPathLeft) {
              paths.push([sqTopLeftX, sqTopLeftY, sqTopLeftX, sqTopLeftY + sqHeight])
            }
            if (needsPathRight) {
              paths.push([sqTopLeftX + sqWidth, sqTopLeftY, sqTopLeftX + sqWidth, sqTopLeftY + sqHeight])
            }

            // draw ad corner diagonals
            if (withAdCorners && fragment.type === "ad") {
              let needsTopLeftDiagonal = needsPathTop && needsPathLeft
              let needsTopRightDiagonal = needsPathTop && needsPathRight
              let needsBottomLeftDiagonal = needsPathBottom && needsPathLeft
              let needsBottomRightDiagonal = needsPathBottom && needsPathRight

              if (needsTopLeftDiagonal) {
                cornerDiagonalPaths.push([sqTopLeftX, sqTopLeftY, sqTopLeftX + cornerSideLength, sqTopLeftY + cornerSideLength])
              }
              if (needsTopRightDiagonal) {
                cornerDiagonalPaths.push([sqTopLeftX + sqWidth, sqTopLeftY, sqTopLeftX + sqWidth - cornerSideLength, sqTopLeftY + cornerSideLength])
              }
              if (needsBottomLeftDiagonal) {
                cornerDiagonalPaths.push([sqTopLeftX, sqTopLeftY + sqHeight, sqTopLeftX + cornerSideLength, sqTopLeftY + sqHeight - cornerSideLength])
              }
              if (needsBottomRightDiagonal) {
                cornerDiagonalPaths.push([sqTopLeftX + sqWidth, sqTopLeftY + sqHeight, sqTopLeftX + sqWidth - cornerSideLength, sqTopLeftY + sqHeight - cornerSideLength])
              }
            }
          }
        }
      }

      let category = categories.find((c) => c._id === fragment.categoryId)
      let fillColour = withFill ? (forceFillColor ? forceFillColor : category === undefined ? "transparent" : category.color) : "transparent"

      fragmentGroup.fill(fillColour)
      fragmentGroup.data("fragmentIndex", fragmentIndex)
      

      if (withText) {
        let text = bp_fragment.text(fragment)

        drawText({
          draw: fragmentGroup,
          drawnFragment: fragmentGroup,
          fullPhrase: text,
          fragmentItem,
        })
      }

      if (withSizeTooltip) {
        fragmentGroup.element("title").words(fragmentFullDescription(fragmentItem.size, fragmentItem.shapeDrawPlanDescription))
      }

      if (lowlightFragment({ highlightFragmentIndex, fragmentIndex })) {
        fragmentGroup.opacity(0.4)
      }

      if (notifyOnClick) {
        fragmentGroup.on("click", (e) => {
          clickCallback({ fragmentIndex })
        })
      }
    }

    return [paths, cornerDiagonalPaths]
  }

  let drawFragments = function ({ draw, fragments, width, height, highlightFragmentIndex }) {
    let paths = []
    let pathsToHighlight = []
    let cornerDiagonalPaths = []

    fragments.forEach(function (fragment, fragmentIndex) {
      let [fragmentPaths, fragmentCornerDiagonalPaths] = drawFragment({ draw, fragment, fragmentIndex, width, height, highlightFragmentIndex })
      paths.push(...fragmentPaths)
      cornerDiagonalPaths.push(...fragmentCornerDiagonalPaths)

      if (highlightFragment({ highlightFragmentIndex, fragmentIndex })) {
        pathsToHighlight = fragmentPaths.concat(fragmentCornerDiagonalPaths)
      }
    })

    let nonOverlappingPaths = getNonOverlappingPaths(paths)

    if (pathColor === undefined) {
      pathColor = "#444444"
    }

    nonOverlappingPaths.forEach(function (p) {
      let path = draw.line(...p)
      path.attr({ "stroke": pathColor, "stroke-width": 0.5 })
    })

    cornerDiagonalPaths.forEach(function (p) {
      let path = draw.line(...p)
      path.attr({ "stroke": pathColor, "stroke-width": 0.5 })
    })

    pathsToHighlight.forEach(function (p) {
      let path = draw.line(...p)
      path.attr({ "stroke": "#ff0101", "stroke-width": 1 })
    })

    return nonOverlappingPaths // return for testing
  }

  return ({ el, fragments, width, height, highlightFragmentIndex }) => {
    let draw = SVG()
      .addTo(el)
      .size("100%", "100%")
      .viewbox(-0.5, -0.5, width + 1, height + 1)

    return drawFragments({
      draw,
      fragments,
      width,
      height,
      highlightFragmentIndex,
    }) // return for testing
  }
}
