import { computed } from "vue"
import pageNumberTransformation from "./pageNumberTransformation"

import { usePage } from "@/modules/page/use"
import { usePageSetting } from "@/modules/pageSetting/use"
import { useFlatplan } from "@/modules/flatplan/use"

export const usePageNumbers = () => {
  let bp_page = usePage()
  let bp_flatplan = useFlatplan()
  let bp_pageSetting = usePageSetting()

  const pageNumbers = computed(() => {
    return pageNumberTransformation({
      pageNumberings: bp_flatplan.pageNumberings.value,
      noOfPages: bp_page.pages.value.filter((p) => !p.isSpace).length,
      displayCoverPageNumbering: bp_flatplan.displayCoverPageNumbering.value,
    })
  })

  const cover = computed(() => {
    if (bp_page.onFlatplan.value.length > 0 && !bp_pageSetting.singlePages.value) {
      return pageNumbers.value[0]
    } else {
      return null
    }
  })

  const inner = computed(() => {
    return pageNumbers.value.slice(bp_pageSetting.singlePages.value ? 0 : 1)
  })

  const pageNoFor = (pageId) => {
    let idx = bp_page.orderForFlatplan.value.indexOf(pageId)
    return pageNoByIdx(idx)
  }

  // be careful here: look at pageNoFor ... it's only for pages ON the flatplan
  const pageNoByIdx = (idx) => {
    return idx !== -1 ? pageNumbers.value[idx] : ""
  }

  // use this "cache" for pageByPageNo - else
  // the pageByPageNo is very slow for a 600 page flatplan ...
  const pageNumberToPageIdMappings = computed(() => {
    let mapping = {}
    bp_page.orderForFlatplan.value.forEach((pageId, idx) => {
      mapping[pageNoByIdx(idx)] = pageId
    })
    return mapping
  })

  const pageByPageNo = (pageNo) => {
    let pageId = pageNumberToPageIdMappings.value[pageNo]
    return pageId ? bp_page.getById(pageId) : null
  }

  return {
    cover,
    inner,
    pageNoFor,
    pageNoByIdx,
    pageByPageNo,
    all: pageNumbers,
  }
}
