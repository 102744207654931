import { ref } from "vue"
import { defineStore } from "pinia"

import AccountService from "@/services/AccountService.js"

export const useViewSettingStore = defineStore("viewSetting", () => {
  const showLeftDrawer = ref((localStorage.getItem("showLeftDrawer") || "true") === "true")
  const showRightDrawer = ref((localStorage.getItem("showRightDrawer") || "false") === "true")
  const showTypeStats = ref((localStorage.getItem("showTypeStats") || "false") === "true")
  const showCategoryStats = ref((localStorage.getItem("showCategoryStats") || "false") === "true")
  const showTagStats = ref((localStorage.getItem("showTagStats") || "false") === "true")
  const showTags = ref((localStorage.getItem("showTags") || "true") === "true")
  const showNotes = ref((localStorage.getItem("showNotes") || "true") === "true")
  const showSlideshowAsDoublePageSpreads = ref((localStorage.getItem("showSlideshowAsDoublePageSpreads") || "true") === "true")

  function setShowLeftDrawer(data) {
    showLeftDrawer.value = data
    localStorage.setItem("showLeftDrawer", showLeftDrawer.value)
  }

  function setShowRightDrawer(data) {
    showRightDrawer.value = data
    localStorage.setItem("showRightDrawer", showRightDrawer.value)
  }

  function setShowTypeStats(data) {
    showTypeStats.value = data
    localStorage.setItem("showTypeStats", showTypeStats.value)
  }

  function setShowCategoryStats(data) {
    showCategoryStats.value = data
    localStorage.setItem("showCategoryStats", showCategoryStats.value)
  }

  function setShowTagStats(data) {
    showTagStats.value = data
    localStorage.setItem("showTagStats", showTagStats.value)
  }

  function setShowTags(data) {
    showTags.value = data
    localStorage.setItem("showTags", showTags.value)
  }

  function setShowNotes(data) {
    showNotes.value = data
    localStorage.setItem("showNotes", showNotes.value)
  }

  function setShowSlideshowAsDoublePageSpreads(data) {
    showSlideshowAsDoublePageSpreads.value = data
    localStorage.setItem("showSlideshowAsDoublePageSpreads", showSlideshowAsDoublePageSpreads.value)
  }

  return {
    showLeftDrawer,
    showRightDrawer,
    showTypeStats,
    showCategoryStats,
    showTagStats,
    showTags,
    showNotes,
    showSlideshowAsDoublePageSpreads,
    setShowLeftDrawer,
    setShowRightDrawer,
    setShowTypeStats,
    setShowCategoryStats,
    setShowTagStats,
    setShowTags,
    setShowNotes,
    setShowSlideshowAsDoublePageSpreads,
  }
})
