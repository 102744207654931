import { ref } from "vue"
import { defineStore } from "pinia"

import AccountService from "@/services/AccountService.js"

export const usePersonStore = defineStore("person", () => {
  const people = ref([])

  function getByNiceId(nice_id) {
    return people.value.find((p) => p.nice_id.toString() === nice_id.toString())
  }

  function bootstrap(data) {
    people.value = data
  }

  function del({ niceId }) {
    return AccountService.deletePerson({ accountId: sessionStorage.getItem("currentAccountId"), niceId }).then(() => {
      let personIndex = people.value.findIndex((c) => c.nice_id === niceId)
      if (personIndex !== -1) {
        people.value.splice(personIndex, 1)
      }
      return Promise.resolve()
    })
  }

  function updatePermission({ niceId, role, value }) {
    return AccountService.updatePermission({ accountId: sessionStorage.getItem("currentAccountId"), niceId, payload: { role, value } }).then((response) => {
      let personIndex = people.value.findIndex((c) => c.nice_id === niceId)
      if (personIndex > -1) {
        // replace if exists
        people.value.splice(personIndex, 1, response.data)
      }
      return Promise.resolve(response)
    })
  }

  return {
    people,
    getByNiceId,
    bootstrap,
    del,
    updatePermission,
  }
})
