import { useRootStore } from "@/stores/root"

export function setupResponseInterceptors(axiosInstance) {

  // Response interceptor to ensure loading state is cleared
  axiosInstance.interceptors.response.use(
    (response) => {
      const rootStore = useRootStore()
      setTimeout(() => {
        rootStore.stopLoading()
      }, 100) // let us see the progress bar for a little longer
      return response
    },
    (error) => {
      const rootStore = useRootStore()
      rootStore.stopLoading()

      return Promise.reject(error)
    }
  )

  return axiosInstance
}