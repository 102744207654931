import { computed, toRefs } from "vue"
import { useRoute } from "vue-router"

export const useToolbar = () => {
  let currentRoute = toRefs(useRoute())

  let toolbar = computed(() => {
    let matched = currentRoute.matched.value ? [...currentRoute.matched.value] : []
    let closestRoute = matched.reverse().find((r) => r.meta.toolbar)
    return closestRoute ? closestRoute.meta.toolbar : null
  })

  return {
    toolbar,
  }
}
