<template>
  <v-row>
    <v-col cols="4">
      <PageSvg class="template" :fragments="fragments" :highlightFragmentIndex="currentFragmentIndex" @fragment-click="fragmentClicked" notifyOnClick highlightOnClick :data-template-name="template" :withSizeTooltip="true" data-test-id="svg" />
      <br />
      <v-btn variant="text" size="x-small" color="primary" @click="showTemplates = !showTemplates">Change template</v-btn>
    </v-col>
    <v-col cols="8" offset-sm="2" sm="6"> <EditPageThumbnail @busy="$emit('busy', $event)" @dirty="$emit('dirty')" /> </v-col>
  </v-row>
  <v-row v-show="showTemplates">
    <v-col cols="12">
      <v-list-subheader>Previously used</v-list-subheader>
      <DefaultPageTemplates @pick="pickTemplate" />
      <v-btn variant="text" size="x-small" color="primary" @click="showAllTemplates = !showAllTemplates">Show all</v-btn>
    </v-col>
    <v-col v-if="showAllTemplates" cols="12">
      <PageTemplates @pick="pickTemplate" />
    </v-col>
  </v-row>
  <v-row v-if="showOriginalContents">
    <v-col cols="12">
      <v-chip v-for="(content, idx) in originalContents" :key="idx" color="primary" @click="updateContent(content)">
        <v-icon size="small" start>{{ mdiContentDuplicate }}</v-icon
        >{{ content.text }}</v-chip
      >
    </v-col>
  </v-row>
  <v-row>
    <v-col cols="12">
      <v-checkbox :model-value="typeValue()" :false-value="'editorial'" :true-value="'ad'" @update:model-value="updateType($event)" label="Advert" hide-details="false" density="compact"></v-checkbox>
    </v-col>
  </v-row>
  <v-row>
    <v-col cols="12" sm="6">
      <v-text-field
        :model-value="textValue()"
        @update:model-value="updateText"
        @keydown="checkForDownArrow"
        :label="'Content (' + fragmentDescription + ')'"
        ref="contentCtrl"
        data-test-id="content"
        data-label="content textbox"
        autofocus
        @dragover.prevent
        :hint="hint"
        :persistent-hint="true"
      >
        <template v-if="anyContents" v-slot:append-inner>
          <PickContentActivator v-model="openPickContent" :pageId="pageId" :pageNo="pageNo" :pickedContent="pickedContent" :isContent="isContent()" @picked="pickContent" :fragmentName="fragmentName" />
        </template>
      </v-text-field>
    </v-col>
    <v-col cols="12" sm="6">
      <v-select label="Category" ref="categoryCtrl" :model-value="categoryValue()" @update:modelValue="updateCategory($event)" data-test-id="cagegory" data-label="content category" :items="categories" item-title="name" item-value="_id" />
    </v-col>
  </v-row>
</template>

<script>
import { ref, computed, nextTick, onMounted } from "vue"
import { useRouter } from "vue-router"
import { mdiContentDuplicate } from "@mdi/js"

import PageSvg from "./PageSvg"
import EditPageThumbnail from "./EditPageThumbnail"
import PageTemplates from "./PageTemplates"
import DefaultPageTemplates from "./DefaultPageTemplates"
import PickContentActivator from "@/modules/content/components/PickContentActivator"
import { sessionStorageObj } from "@/utils/storage"
import { htmlDecode } from "@/utils/general"

import { useCategory } from "@/modules/category/use"
import { useContent } from "@/modules/content/use"
import { useFragment } from "@/modules/page/useFragment"
import { usePageNumbers } from "@/modules/flatplan/usePageNumbers"
import { getFragment, fragmentFullDescription } from "@/modules/page/render/fragmentUtils"

// get query param and convert to number if exists. If we have no such query param, default to 0
const getHighlightFragmentIndex = (query) => {
  let highlightFragmentIndex = query.highlightFragmentIndex
  if (highlightFragmentIndex !== null) {
    highlightFragmentIndex = parseInt(highlightFragmentIndex)
  }
  return highlightFragmentIndex || 0
}

// get query param and convert to boolean if exists. If we have no such query param, default to false
const getOpenPickContent = (query) => {
  let openPickContent = query.openPickContent
  if (openPickContent !== null) {
    openPickContent = openPickContent == "true" || openPickContent === true
  }
  return openPickContent || false
}

export default {
  name: "EditPageContent",
  props: {
    pageId: {
      type: String,
      required: true,
    },
    flatplanId: {
      type: String,
      required: true,
    },
  },
  emits: ["busy", "dirty"],
  components: {
    PageSvg,
    EditPageThumbnail,
    PageTemplates,
    DefaultPageTemplates,
    PickContentActivator,
  },
  setup(props, context) {
    let router = useRouter()
    let currentRoute = router.currentRoute

    let contentCtrl = ref(null)
    let categoryCtrl = ref(null)
    let bp_category = useCategory()
    let bp_content = useContent()
    let bp_pageNumbers = usePageNumbers()
    let bp_fragment = useFragment()

    let currentFragmentIndex = ref(getHighlightFragmentIndex(currentRoute.value.query))
    let pageNo = ref(bp_pageNumbers.pageNoFor(props.pageId))

    let showTemplates = ref(false)
    let showAllTemplates = ref(false)
    let showOriginalContents = ref(false)

    let openPickContent = ref(false)

    // we may have created new content via the picker.
    // new content is not part of contents, nor does it have an id
    // let's keep a record of them here
    let newContentTexts = ref([])

    // let's fetch the edit in-progress from sessionStorage. The EditPageView component has
    // placed a copy of the original into sessionStorage (Assumption: The EditPageView setup method runs before this setup method).
    // Let's save all changes back to sessionStorage,
    // and the EditPageView will pick it up again to save when done editing.
    let fragments = ref(sessionStorageObj.getItem("editPage", "content").data.fragments || [])
    let template = ref(sessionStorageObj.getItem("editPage", "content").data.template)

    let originalContents = sessionStorageObj
      .getItem("editPage", "content")
      .data.fragments.map((f) => {
        return { contentId: f.contentId, text: bp_fragment.text(f) }
      })
      .filter((c) => c.text.length > 0) // we use this in case we change templates and want access to our contents

    let anyContents = computed(() => {
      return bp_content.contents.value.length > 0
    })

    const setFragment = (key, data) => {
      let fragment = fragments.value[currentFragmentIndex.value]
      if (fragment) {
        fragment[key] = data
        fragments.value.splice(currentFragmentIndex.value, 1, fragment)

        saveToLocalStorage({ template: template.value, fragments: fragments.value })
      }
    }

    const saveToLocalStorage = (value) => {
      sessionStorageObj.setItem("editPage", { data: value, dirty: true }, "content")
      context.emit("dirty")
    }

    const updateType = (data) => {
      setFragment("type", data)
    }

    // we can come to here via each keypress too
    // if we backspace for eg on a content, and rewrite it - then make sure we update the content
    const updateText = (text) => {
      let content = bp_content.getByText(text)
      if (content) {
        updateContent({
          text: content.text,
          contentId: content._id,
          _createContent: false,
        })
      } else {
        setFragment("text", text)
        setFragment("contentId", null)
        setFragment("_createContent", newContentTexts.value.includes(text))
      }
    }

    const updateContent = (data) => {
      setFragment("text", data.text)
      setFragment("contentId", data.contentId)
      setFragment("_createContent", !!data._createContent)
    }

    const updateCategory = (categoryId) => {
      setFragment("categoryId", categoryId)

      // let's keep focus on the dropdown - instead of the highlighted template fragment
      setTimeout(() => {
        if (categoryCtrl.value && categoryCtrl.value.$refs.select) {
          // sometimes I get errors from Sentry that select is undefined ...
          categoryCtrl.value.$refs.select.focus()
        }
      }, 75)
    }

    const categoryValue = () => {
      let fragment = fragments.value[currentFragmentIndex.value]
      return fragment ? htmlDecode(fragment["categoryId"] || bp_category.noneId) : ""
    }

    const textValue = () => {
      let fragment = fragments.value[currentFragmentIndex.value]
      return htmlDecode(bp_fragment.text(fragment))
    }

    const typeValue = () => {
      let fragment = fragments.value[currentFragmentIndex.value]
      return fragment ? htmlDecode(fragment["type"]) : ""
    }

    const fragmentDescription = computed(() => {
      let fragment = fragments.value[currentFragmentIndex.value]
      let fragmentItem = getFragment(fragment.shape)
      return fragmentFullDescription(fragmentItem.size, fragmentItem.shape)
    })

    const fragmentName = computed(() => {
      let fragment = fragments.value[currentFragmentIndex.value]
      return fragment.shape
    })

    let fragmentClicked = ({ fragmentIndex }) => {
      currentFragmentIndex.value = fragmentIndex
      nextTick(() => {
        focusContentText()
      })
    }

    let focusContentText = () => {
      if (contentCtrl.value !== null) { // mostly for tests
        contentCtrl.value.$el.getElementsByTagName("input")[0].focus()
      }
    }

    let pickTemplate = (pickedTemplate) => {
      showTemplates.value = false

      let frags = JSON.parse(JSON.stringify(pickedTemplate.fragments))

      // let's default text for each fragment
      frags.forEach((frag) => {
        frag.text = ""
        frag.type = "editorial"
      })

      template.value = pickedTemplate.name
      fragments.value.splice(0, fragments.value.length, ...frags)
      currentFragmentIndex.value = 0
      showOriginalContents.value = originalContents.length > 0

      saveToLocalStorage({ template: template.value, fragments: fragments.value })
    }

    let pickedContent = computed(() => {
      let fragment = fragments.value[currentFragmentIndex.value]

      if (fragment) {
        let content = bp_content.getById(fragment.contentId)
        if (content) {
          return content
        }
      }

      return null
    })

    let pickContent = (data) => {
      if (data === null) {
        updateContent({ text: "", contentId: null })
      } else {
        updateContent({ text: data.text, contentId: data._id, _createContent: data._createContent })
        if (data._createContent) {
          // let's keep track of any new contents here
          let idx = newContentTexts.value.findIndex((t) => bp_content.cleanText(data.text) === bp_content.cleanText(t))
          if (idx === -1) {
            newContentTexts.value = [...newContentTexts.value, bp_content.cleanText(data.text)]
          }
        }
      }
      focusContentText()
      openPickContent.value = false
    }

    const isContent = () => {
      return !!pickedContent.value || (!!contentCtrl.value && newContentTexts.value.includes(bp_content.cleanText(contentCtrl.value.modelValue)))
    }

    // we may have created new content via the picker.
    // new content is not part of contents, nor does it have an id
    // check if it is placed anywhere
    const isNewContentPlaced = computed(() => {
      let placedContents = fragments.value.map((fragment) => fragment.text.trim())
      return newContentTexts.value.map((text) => text).some((c) => placedContents.includes(c))
    })

    let checkForDownArrow = ({ key }) => {
      if (key === "ArrowDown") {
        openPickContent.value = true
      }
    }

    let hint = computed(() => {
      if (isNewContentPlaced.value > 0) {
        return "Content to be created: " + newContentTexts.value.join(',')
      }
      
      if (isContent()) {
        let txt = []
        if (!!pickedContent.value) {
          if (pickedContent.value.pageNos.length > 0) {
            txt.push("#" + pickedContent.value.pageNos.join(', '))
          }
          let sizeDescription = fragmentFullDescription(pickedContent.value.size, pickedContent.value.shape)
          if (sizeDescription.length > 0) {
            txt.push("(" + sizeDescription + ")")
          }

          if (txt.length > 0) {
            txt.unshift("Suggested:")
          } else {
            txt.unshift("Place anywhere")
          }
        }

        return txt.join(" ")
      } else {
        return ""
      }
    })

    onMounted(() => {
      nextTick(() => {
        setTimeout(() => {
          // I think these 300ms is the time it takes for the dialog to animate for mobile
          // Only then will the menu dropdown position itself most accurately
          openPickContent.value = getOpenPickContent(currentRoute.value.query)
        }, 300)
      })
    })

    return {
      contentCtrl,
      categoryCtrl,
      fragments,
      categories: bp_category.categories.value.concat(bp_category.noneCategory),
      currentFragmentIndex,
      fragmentClicked,
      updateType,
      updateText,
      updateCategory,
      updateContent,
      typeValue,
      categoryValue,
      textValue,
      fragmentDescription,
      fragmentName,
      showTemplates,
      showAllTemplates,
      pickTemplate,
      showOriginalContents,
      originalContents,
      template,
      openPickContent,
      pickedContent,
      pickContent,
      checkForDownArrow,
      pageNo,
      anyContents,
      isContent,
      hint,
      mdiContentDuplicate
    }
  },
}
</script>

<style lang="scss" scoped>
.template {
  height: 114px;
  width: 88px;
  cursor: pointer;
}
</style>
