<template>
  <div>
    <div class="loader">
      <div class="ball-grid-pulse">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <br />
      <LogoFull />
    </div>
  </div>
</template>

<script>
import LogoFull from "@/components/Logos/LogoFull.vue"

export default {
  name: "TheViewLoader",
  components: {
    LogoFull,
  },
}
</script>

<style lang="css" scoped>
@-webkit-keyframes ball-grid-pulse {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    opacity: 0.7;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes ball-grid-pulse {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    opacity: 0.7;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

.ball-grid-pulse {
  width: 57px;
}
.ball-grid-pulse > div:nth-child(1) {
  -webkit-animation-delay: 0.22s;
  animation-delay: 0.22s;
  -webkit-animation-duration: 0.9s;
  animation-duration: 0.9s;
}
.ball-grid-pulse > div:nth-child(2) {
  -webkit-animation-delay: 0.64s;
  animation-delay: 0.64s;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
}
.ball-grid-pulse > div:nth-child(3) {
  -webkit-animation-delay: -0.15s;
  animation-delay: -0.15s;
  -webkit-animation-duration: 0.63s;
  animation-duration: 0.63s;
}
.ball-grid-pulse > div:nth-child(4) {
  -webkit-animation-delay: -0.03s;
  animation-delay: -0.03s;
  -webkit-animation-duration: 1.24s;
  animation-duration: 1.24s;
}
.ball-grid-pulse > div:nth-child(5) {
  -webkit-animation-delay: 0.08s;
  animation-delay: 0.08s;
  -webkit-animation-duration: 1.37s;
  animation-duration: 1.37s;
}
.ball-grid-pulse > div:nth-child(6) {
  -webkit-animation-delay: 0.43s;
  animation-delay: 0.43s;
  -webkit-animation-duration: 1.55s;
  animation-duration: 1.55s;
}
.ball-grid-pulse > div:nth-child(7) {
  -webkit-animation-delay: 0.05s;
  animation-delay: 0.05s;
  -webkit-animation-duration: 0.7s;
  animation-duration: 0.7s;
}
.ball-grid-pulse > div:nth-child(8) {
  -webkit-animation-delay: 0.05s;
  animation-delay: 0.05s;
  -webkit-animation-duration: 0.97s;
  animation-duration: 0.97s;
}
.ball-grid-pulse > div:nth-child(9) {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
  -webkit-animation-duration: 0.63s;
  animation-duration: 0.63s;
}
.ball-grid-pulse > div {
  background-color: #fdff6e;
  width: 15px;
  height: 15px;
  border-radius: 10%;
  margin: 2px;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  display: inline-block;
  float: left;
  -webkit-animation-name: ball-grid-pulse;
  animation-name: ball-grid-pulse;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-delay: 0;
  animation-delay: 0;
}

.loader {
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 25%;
  height: 400px;
  align-items: center;
  justify-content: center;
}
</style>
