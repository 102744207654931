<template>
  <v-container fluid>
    <StitchInsList />
    <router-view name="dialog" />
  </v-container>
</template>

<script>
import StitchInsList from "./StitchInsList"

export default {
  name: "StitchInsShowView",
  components: {
    StitchInsList,
  },
}
</script>
