<template>
  <div class="page mr-3" ref="el" :style="{ width: width + 'px', height: height + 'px' }"></div>
</template>

<script>
import { ref, watch, computed, nextTick } from "vue"

import { useStitchIn } from "@/modules/stitchIn/use"
import { usePageSetting } from "@/modules/pageSetting/use"
import renderStitchIn from "@/modules/stitchIn/render"

export default {
  name: "StitchIn",
  props: {
    stitchInId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    let bp_stitchIn = useStitchIn()
    let bp_pageSetting = usePageSetting()

    let el = ref(null)

    let stitchIn = bp_stitchIn.getById(props.stitchInId)

    let render = renderStitchIn({})

    // can't watch the styles of the DOM element - so need to watch this getter instead
    watch(
      [bp_pageSetting.pageWidth],
      ([pageWidth]) => {
        nextTick(() => {
          if (el.value) {
            let svgEl = el.value.querySelector("svg")
            if (svgEl) {
              svgEl.remove()
            }

            render({
              el: el.value,
              frontContent: stitchIn.frontContent,
              backContent: stitchIn.backContent,
              pageNumber: bp_stitchIn.translatePageNumber(stitchIn.pageNumber),
              width: bp_pageSetting.pageWidth.value * 2,
              height: bp_pageSetting.pageHeight.value,
            })
          }
        })
      },
      { immediate: true, deep: true }
    )

    return {
      el,
      width: computed(() => {
        return bp_pageSetting.pageWidth.value * 2
      }),
      height: bp_pageSetting.pageHeight,
    }
  },
}
</script>

<style lang="scss" scoped></style>
