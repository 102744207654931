import { computed } from "vue"
import { usePageStore } from "./store"
import { useContentStore } from "@/modules/content/store"
import { useContext } from "@/use/context"
import { useCommand } from "@/modules/command/use"
import { usePageSetting } from "@/modules/pageSetting/use"
import { asyncSeries } from "@/utils/general"

export const usePage = function () {
  let bp_command = useCommand()
  let bp_pageSetting = usePageSetting()
  let bp_context = useContext()

  const pageStore = usePageStore()
  const contentStore = useContentStore()
  const { getById, getByIds, getByPosition, setSize, reorderFlatplan, reorderHoldingArea, addTmpSpacesToFlatplan, setCommentCount } = pageStore

  let flatplanId = bp_context.flatplanId.value

  const isCover = (id) => {
    if (!bp_pageSetting.singlePages.value) {
      let firstPage = pageStore.onFlatplan[0]
      return firstPage && firstPage._id === id
    }
    return false
  }

  const changePageLock = ({ pageId, isLocked, fromLiveUpdate }) => {
    if (fromLiveUpdate) {
      return pageStore.changePageLock({ flatplanId, pageId, isLocked, storeOnly: true })
    }

    return bp_command.add({
      execute: () => {
        return pageStore.changePageLock({ flatplanId, pageId, isLocked })
      },
      undo: () => {
        return pageStore.changePageLock({ flatplanId, pageId, isLocked: !isLocked })
      },
    })
  }

  // this method lumps several updates together, so that only one undo command is created.
  // eg when we edit fragments and thumbnails - they're on the same page.
  // from the UI perspective we made ONE change.
  // tags: { tagRefs, createTags }
  const updatePage = ({ pageId, thumbnail, template, notes, fragments, tags, order, fromLiveUpdate }) => {
    let updates = []
    let undoUpdates = []

    if (fromLiveUpdate) {
      if (fragments !== undefined) {
        updates.push(() => pageStore.updateFragments({ flatplanId, pageId, template, fragments, storeOnly: true }))
      }
      if (thumbnail !== undefined) {
        updates.push(() => pageStore.updateThumbnail({ flatplanId, pageId, thumbnail, storeOnly: true }))
      }
      if (tags !== undefined) {
        updates.push(() => pageStore.updateTags({ flatplanId, pageId, tags, storeOnly: true }))
      }
      if (notes !== undefined) {
        updates.push(() => pageStore.updateNotes({ flatplanId, pageId, notes, storeOnly: true }))
      }
      return asyncSeries(updates) // these need to be run sequentially, else Mongo's versioning will fail with a 409 Conflict
    }

    const page = pageStore.getById(pageId)

    if (page) {
      const prevFragments = JSON.parse(JSON.stringify(page.fragments))
      const prevTemplate = page.template
      const prevThumbnail = page.thumbnail
      const prevTagRefs = JSON.parse(JSON.stringify(page.tagRefs))
      const prevNotes = page.notes
      const prevOrderData = pageStore.order

      let createdContents = []

      return bp_command.add({
        execute: () => {
          if (fragments !== undefined) {
            // we can create content on the fly
            // do this before saving fragments, as the fragments may need to be updated with the new contentIds first
            let fragmentCreateTexts = new Set([...fragments.filter((fragment) => fragment._createContent).map((fragment) => fragment.text)])
            let createContents = [...fragmentCreateTexts].map((text) => {
              return { text }
            })
            if (createContents.length > 0) {
              updates.push(() => {
                return contentStore.batchAdd({ flatplanId, contents: createContents }).then((response) => {
                  createdContents = response.data.addedContents

                  fragments.forEach((fragment) => {
                    if (fragment._createContent) {
                      let contentIndex = createdContents.findIndex((content) => content.text === fragment.text)
                      if (contentIndex !== -1) {
                        // set it to the newly created content
                        fragment.text = ""
                        fragment._createContent = false
                        fragment.contentId = createdContents[contentIndex]._id
                      }
                    }
                  })
                  return Promise.resolve(response)
                })
              })
            }
            updates.push(() => pageStore.updateFragments({ flatplanId, pageId, template, fragments }))
          }
          if (thumbnail !== undefined) {
            updates.push(() => pageStore.updateThumbnail({ flatplanId, pageId, thumbnail }))
          }
          if (tags !== undefined) {
            updates.push(() => pageStore.updateTags({ flatplanId, pageId, tags }))
          }
          if (notes !== undefined) {
            updates.push(() => pageStore.updateNotes({ flatplanId, pageId, notes }))
          }
          if (order !== undefined) {
            updates.push(() => pageStore.reorderFlatplan(order))
            updates.push(() => pageStore.reorder({ flatplanId }))
          }
          return asyncSeries(updates) // these need to be run sequentially, else Mongo's versioning will fail with a 409 Conflict
        },
        undo: () => {
          if (createdContents.length > 0) {
            undoUpdates.push(() => contentStore.batchDel({ flatplanId, contentIds: createdContents.map((c) => c._id) }))
          }
          if (fragments !== undefined) {
            undoUpdates.push(() => pageStore.updateFragments({ flatplanId, pageId, template: prevTemplate, fragments: prevFragments }))
          }
          if (thumbnail !== undefined) {
            undoUpdates.push(() => pageStore.updateThumbnail({ flatplanId, pageId, thumbnail: prevThumbnail }))
          }
          if (tags !== undefined) {
            undoUpdates.push(() => pageStore.updateTags({ flatplanId, pageId, tags: { tagRefs: prevTagRefs } }))
          }
          if (notes !== undefined) {
            undoUpdates.push(() => pageStore.updateNotes({ flatplanId, pageId, notes: prevNotes }))
          }
          if (order !== undefined) {
            undoUpdates.push(() => pageStore.reorder({ flatplanId, orderData: prevOrderData }))
          }
          return asyncSeries(undoUpdates) // these need to be run sequentially, else Mongo's versioning will fail with a 409 Conflict
        },
      })
    } else {
      return Promise.resolve()
    }
  }

  // pass in orderData if from LiveUpdate.
  const reorder = ({ fromHoldingArea, orderData, fromLiveUpdate }) => {
    if (fromLiveUpdate) {
      return pageStore.reorder({ flatplanId, orderData, storeOnly: true })
    }

    const prevOrderData = pageStore.order

    return bp_command.add({
      execute: () => {
        return pageStore
          .reorder({ flatplanId, fromHoldingArea })
          .then((response) => {
            // if we created or deleted pages via specialOp, then we need to add
            // the reverse operation to prevOrderData so that we can undo the reorder
            response.data.createdSpaces.forEach((space) => {
              prevOrderData.push({
                _id: space._id,
                specialOp: "delete",
              })
            })
            response.data.deletedSpaces.forEach((space) => {
              let data = prevOrderData.find((o) => o._id === space._id)
              data.specialOp = "create"
            })
            return Promise.resolve(response)
          })
          .catch((err) => {
            return Promise.reject(err)
          })
      },
      undo: () => {
        return pageStore.reorder({ flatplanId, orderData: prevOrderData, fromHoldingArea })
      },
    })
  }

  const deletePages = ({ pageIds, fromLiveUpdate }) => {
    if (fromLiveUpdate) {
      return pageStore.deletePages({ flatplanId, pageIds, storeOnly: true })
    }

    const prevPages = pageStore.getByIds(pageIds)

    if (prevPages.length > 0) {
      return bp_command.add({
        execute: () => {
          return pageStore.deletePages({ flatplanId, pageIds })
        },
        undo: () => {
          return pageStore.addNoOfPages({ flatplanId, pages: prevPages, shiftRemainingPages: false })
        },
      })
    } else {
      return Promise.resolve()
    }
  }

  const addNoOfPages = ({ noOfPages, insertAtIndex }) => {
    let addedPages = []

    let pages = []

    for (let i = 0; i < noOfPages; i++) {
      pages.push({})
    }

    return bp_command.add({
      execute: () => {
        return pageStore.addNoOfPages({ flatplanId, pages, insertAtIndex, shiftRemainingPages: true }).then((response) => {
          addedPages = response.data.addedPages
          return Promise.resolve(response)
        })
      },
      undo: () => {
        return pageStore.deletePages({ flatplanId, pageIds: addedPages.map((p) => p._id) })
      },
    })
  }

  // the idea is to add pages on the server first - so we pass on how many using noOfPages
  // but when we know what pages to add (an undo on a delete via Liveupdate, or a new set coming through
  // from another session via Liveupdate) - that's when we call this method
  // This method won't have an undo as this is in response to a liveupdate - so the data has already changed on the server
  const addPagesFromLiveupdate = ({ addedPages, shiftedPageData }) => {
    return pageStore.addPagesFromLiveupdate({ addedPages, shiftedPageData })
  }

  const stretch = ({ pageId, noOfPages }) => {
    const possiblePages = pageStore.onFlatplan.filter((p) => !p.isSpace)
    const idxOfPage = possiblePages.map((p) => p._id).indexOf(pageId)
    let originalPages = []
    for (let i = 1; i <= noOfPages; i++) {
      let page = possiblePages[idxOfPage + i]
      if (page) {
        originalPages.push(JSON.parse(JSON.stringify(page)))
      }
    }

    return bp_command.add({
      execute: () => {
        return pageStore.stretch({ flatplanId, pageId, pagesData: originalPages })
      },
      undo: () => {
        return pageStore.batchUpdate({ flatplanId, pagesData: originalPages })
      },
    })
  }

  const batchUpdate = ({ pages, createTags, fromLiveUpdate }) => {
    if (fromLiveUpdate) {
      return pageStore.batchUpdate({ flatplanId, pagesData: pages, storeOnly: true })
    }

    const prevPages = JSON.parse(JSON.stringify(pageStore.getByIds(pages.map((p) => p._id))))

    if (prevPages.length > 0) {
      return bp_command.add({
        execute: () => {
          return pageStore.batchUpdate({ flatplanId, pagesData: pages, createTags })
        },
        undo: () => {
          return pageStore.batchUpdate({ flatplanId, pagesData: prevPages })
        },
      })
    } else {
      return Promise.resolve()
    }
  }

  const coverPageId = computed(() => {
    if (pageStore.orderForFlatplan.length > 0 && !bp_pageSetting.singlePages.value) {
      return pageStore.orderForFlatplan[0]
    } else {
      return null
    }
  })

  const orderForInnerPageIds = computed(() => {
    return pageStore.orderForFlatplan.slice(bp_pageSetting.singlePages.value ? 0 : 1)
  })

  // store's state is reactively passed on (computed for readonly) - all state changes should occur within store
  return {
    pages: computed(() => pageStore.ordered),
    orderForFlatplan: computed(() => pageStore.orderForFlatplan),
    orderForHoldingArea: computed(() => pageStore.orderForHoldingArea),
    onFlatplan: computed(() => pageStore.onFlatplan),
    onHoldingArea: computed(() => pageStore.onHoldingArea),
    withNotes: computed(() => pageStore.withNotes),
    coverPageId,
    orderForInnerPageIds,
    getById,
    getByIds,
    getByPosition,
    isCover,
    setSize,
    reorder,
    reorderFlatplan,
    reorderHoldingArea,
    addTmpSpacesToFlatplan,
    setCommentCount,
    changePageLock,
    updatePage,
    deletePages,
    addNoOfPages,
    addPagesFromLiveupdate,
    stretch,
    batchUpdate,
  }
}
