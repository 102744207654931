import { useRoleStore } from "./store"

export const useRole = function () {
  const roleStore = useRoleStore()
  const { hasRole } = roleStore

  return {
    hasRole,
  }
}
