<template>
  <v-row>
    <v-col cols="8">
      <v-chip variant="flat" :prepend-icon="mdiDragVertical" :color="category.color" closable :close-icon="mdiDeleteOutline" @click:close.prevent="del" :to="{ name: 'category-edit', params: { categoryId: category._id } }" data-test-id="category">
        <div class="pr-3" data-test-id="name">{{ category.name }}</div>
      </v-chip>
    </v-col>
    <v-col cols="2" data-test-id="fraction">{{ Math.round((stats.fraction + Number.EPSILON) * 100) / 100 }}</v-col>
    <v-col cols="2" data-test-id="percentage">{{ Math.round((stats.percentage + Number.EPSILON) * 100) }}%</v-col>
  </v-row>
</template>

<script>
import { computed, reactive } from "vue"
import { mdiDragVertical, mdiDeleteOutline } from "@mdi/js"

import { useCategory } from "../use"
import { useStats } from "../useStats"

export default {
  name: "CategoryItem",
  props: {
    categoryId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    let bp_stats = useStats()
    let bp_category = useCategory()

    let category = reactive(bp_category.getById(props.categoryId))

    let del = () => {
      bp_category.del({ categoryId: props.categoryId })
    }

    return {
      category,
      del,
      stats: computed(() => bp_stats.detailsFor(category)),
      mdiDragVertical,
      mdiDeleteOutline,
    }
  },
}
</script>

<style lang="scss" scoped>
  :deep(.v-chip--variant-flat) {
    color: black;
  }
</style>
