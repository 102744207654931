<template>
  <div class="d-flex flex-column justify-center align-center" style="min-height: 100vh">
    <a href="/"><LogoOnly :height="100" /></a>
    <div class="text-h3 text-center mt-8">Uh-oh</div>
    <div class="text-h5 mt-8">It looks like you are experiencing {{ type }} issues. This may just be a temporary issue. Please try again later.</div>
  </div>
</template>

<script>
import LogoOnly from "@/components/Logos/LogoOnly.vue"

export default {
  name: "NetworkIssueView",
  props: {
    type: {
      type: String,
      default: "network",
    }
  },
  components: {
    LogoOnly,
  },
}
</script>

<style lang="scss" scoped></style>
