<template>
  <v-container fluid>
    <h4 class="text-h4">Profile</h4>
    <v-form class="mt-6" ref="form" v-model="formValid" @submit.prevent="save">
      <v-row>
        <v-col cols="12" sm="4">
          <v-text-field label="Email" type="email" v-model="email" data-test-id="email" :rules="emailRules" required />
          <v-text-field label="Name" type="text" v-model="name" data-test-id="name" :rules="nameRules" required />
          <v-text-field label="Initials" type="text" v-model="initials" data-test-id="initials" :rules="initialsRules" required />
          <v-text-field
            label="Change password"
            :type="showPassword ? 'text' : 'password'"
            :append-inner-icon="showPassword ? mdiEye : mdiEyeOff"
            @click:append-inner="showPassword = !showPassword"
            data-test-id="password"
            v-model="password"
            :rules="passwordRules"
            hint="leave blank if you don't want to change it"
          />

          <v-list-subheader>Please confirm using your current password</v-list-subheader>

          <v-text-field
            label="Current password"
            :type="showCurrentPassword ? 'text' : 'password'"
            :append-inner-icon="showCurrentPassword ? mdiEye : mdiEyeOff"
            @click:append-inner="showCurrentPassword = !showCurrentPassword"
            data-test-id="current-password"
            v-model="currentPassword"
            :rules="currentPasswordRules"
            hint="we need your current password to confirm your changes"
          />
          <submit-btn type="submit" class="mr-4 float-right" :loading="loading">Save</submit-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { ref, reactive, toRefs } from "vue"
import { mdiEye, mdiEyeOff } from "@mdi/js"

import { useAuth } from "@/modules/auth/use"
import { useNotification } from "@/modules/notification/use"

export default {
  name: "ProfileView",
  setup() {
    const bp_auth = useAuth()
    const bp_notification = useNotification()

    let form = ref(null)
    let loading = ref(false)

    const state = reactive({
      email: bp_auth.email.value,
      emailRules: [
        (v) => !!v || "Email is required.",
        (v) => (!!v && v.indexOf("@") !== 0) || "Email should have a username.",
        (v) => (!!v && v.includes("@")) || "Email should include an @ symbol.",
        (v) => (!!v && v.includes(".")) || "Email should include a period symbol.",
        (v) => (!!v && v.indexOf(".") <= v.length - 3) || "Email should contain a valid domain extension.",
      ],
      name: bp_auth.name.value,
      nameRules: [(v) => !!v || "Name is required.", (v) => (!!v && v.length >= 2) || "Name should be at more than 2 characters long."],
      initials: bp_auth.initials.value,
      initialsRules: [(v) => !!v || "Initials are required.", (v) => (!!v && v.length <= 3) || "Initials should be at max 3 characters long."],
      password: "",
      passwordRules: [(v) => (!!v && (v.length >= 6 || v.length === 0)) || "Password should be at least six long."],
      currentPassword: "",
      currentPasswordRules: [(v) => !!v || "Password is required."],
      formValid: false,
      showPassword: false,
      showCurrentPassword: false,
    })

    const save = () => {
      form.value.validate()
      if (state.formValid) {
        let credentials = { email: state.email.trim(), name: state.name.trim(), initials: state.initials.trim(), current_password: state.currentPassword }
        if (state.password.length > 0) {
          credentials.password = state.password
        }
        loading.value = true
        bp_auth
          .update(credentials)
          .then(() => {
            bp_notification.show({
              type: "success",
              message: "Profile saved :)",
            })
          })
          .finally(() => {
            loading.value = false
          })
      }
    }

    return {
      ...toRefs(state),
      save,
      loading,
      form,
      mdiEye,
      mdiEyeOff,
    }
  },
}
</script>

<style lang="scss" scoped></style>
