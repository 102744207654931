<template>
  <base-toolbar>
    <v-menu v-model="showPdfOptions" :close-on-content-click="false" max-width="300" color="primary">
      <template v-slot:activator="{ props }">
        <v-btn v-bind="props" variant="text" :prepend-icon="mdiDownloadOutline" color="primary">
          Export as Pdf &hellip;</v-btn>
      </template>
      <PdfOptions v-model:open="showPdfOptions" />
    </v-menu>

    <ViewOptions />
  </base-toolbar>
</template>

<script>
import { ref } from "vue"
import { mdiDownloadOutline } from "@mdi/js"

import ViewOptions from "@/modules/flatplan/components/ViewOptions"
import PdfOptions from "@/modules/flatplan/components/PdfOptions"

export default {
  name: "ToolBar",
  components: {
    ViewOptions,
    PdfOptions,
  },
  setup() {
    let showPdfOptions = ref(false)

    return {
      showPdfOptions,
      mdiDownloadOutline
    }
  },
}
</script>

<style lang="scss" scoped>
.sticky {
  position: sticky;
  top: 64px;
  margin-top: -60px;
  z-index: 5; // else - because of the absolute/fixed positioning we lose it underneath the flatplan
}
</style>
