<template>
  <v-container fluid>
    <h4 class="text-h4 mb-4">Summary: Notes</h4>
    <template v-if="anyNotes()" v-for="pageId in pageIds" :key="'page-' + pageId">
      <v-sheet v-if="hasNotes(pageId)" class="d-flex mb-2">
        <div>
          <Page :pageId="pageId" class="mr-4 clickable" @click="visitPage(pageId)" />
          <div class="text-caption page-number">{{ pageNoFor(pageId) }}</div>
        </div>
        <div class="text-body-1 decoded-markup mb-2 border-s-thin pl-2" v-html="decodedNotes(pageId)"></div>
      </v-sheet>
    </template>
    <template v-else>Once you have pages with <strong>notes</strong>, they will be listed here.</template>
  </v-container>
</template>

<script>
import { computed } from "vue"

import router from "@/router"
import { decode } from "@/utils/general"
import { usePageNumbers } from "@/modules/flatplan/usePageNumbers"
import { usePage } from "@/modules/page/use"

import Page from "./Page"

export default {
  name: "SummaryNotesView",
  components: {
    Page
  },
  setup() {

    let bp_pageNumbers = usePageNumbers()
    let bp_page = usePage()

    let pageIdsWithNotes = computed(() => {
      return bp_page.pages.value.filter((page) => !!page.notes).map((page) => page._id)
    })

    let hasNotes = (pageId) => {
      return pageIdsWithNotes.value.indexOf(pageId) > -1
    }

    let anyNotes = () => {
      return pageIdsWithNotes.value.length > 0
    }

    let visitPage = (pageId) => {
      router.push({
        name: "page-edit-notes",
        params: { pageId },
        query: { returnTo: "summary-notes" }
      })
    }

    let decodedNotes = (pageId) => {
      let page = bp_page.getById(pageId)

      return !!page ? decode(page.notes) : ""
    }

    return {
      pageIds: bp_page.orderForFlatplan,
      hasNotes,
      anyNotes,
      visitPage,
      decodedNotes,
      pageNoFor: bp_pageNumbers.pageNoFor
    }
  },
}
</script>

<style lang="scss" scoped>
.clickable {
  cursor: pointer;
}

</style>
