import { useRole } from "./use"
import { useAccount } from "@/modules/account/use"
import { useAuth } from "@/modules/auth/use"

export default (router) => {
  router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.restrictTo)) {
      const bp_role = useRole()
      const bp_account = useAccount()
      const bp_auth = useAuth()

      // this route requires to have certain roles, check if logged in
      // if not, redirect to login page.
      let permit = () => {
        let roles = to.matched.reverse().find((r) => r.meta.restrictTo).meta.restrictTo
        let permitted = false

        roles.forEach((role) => {
          if (bp_role.hasRole({ accountId: bp_account.id.value, role })) {
            permitted = true
          }
        })

        return permitted
      }

      if (!permit()) {
        // let's remove the currentAccountId in case it was the billing page for an expired account
        // Else the home link on the not-enough-permissions page will redirect back to not-enough-permissions
        sessionStorage.removeItem("currentAccountId")
        router.push({ name: "not-enough-permissions" })
      }
    }

    next() // make sure to always call next()!
  })
}
