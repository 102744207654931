import { computed, reactive, toRefs } from "vue"
import { useAuthStore } from "./store"
import router from "@/router"

export const useAuth = function () {
  const authStore = useAuthStore()
  const { update, logout } = authStore

  const state = reactive({
    loading: false,
    httpStatus: null,
    errors: [],
  })

  const reset = () => {
    state.loading = false
    state.httpStatus = null
    state.errors = []
  }

  const setErrors = (err) => {
    if (err.response && err.response.data && err.response.data.error) {
      let error = err.response.data.error
      if (typeof error === "string") {
        state.errors = [error]
      } else if (error.message) {
        state.errors = [error.message]
      } else {
        state.errors = fullErrorMessages(error)
      }
    } else {
      state.errors = [err.message]
    }
  }

  const fullErrorMessages = (errors) => {
    let messages = []
    Object.keys(errors.account).forEach((field) => {
      messages.push(field + " " + errors.account[field].join(", "))
    })
    Object.keys(errors.user).forEach((field) => {
      messages.push(field + " " + errors.user[field].join(", "))
    })
    return messages
  }

  const setHttpStatus = (err) => {
    if (err.response) {
      state.httpStatus = err.response.status
    }
  }

  const login = ({ email, password, redirect }) => {
    reset()
    state.loading = true
    return authStore
      .login({
        email,
        password,
      })
      .then(() => {
        if (redirect) {
          location.href = redirect
        } else {
          redirectToHome()
        }
      })
      .catch((err) => {
        setHttpStatus(err)
        setErrors(err)
        throw err
      })
      .finally(() => {
        state.loading = false
      })
  }

  const signup = ({ accountName, name, email, password }) => {
    reset()
    state.loading = true
    return authStore
      .signup({
        accountName,
        name,
        email,
        password,
      })
      .then(() => {
        redirectToHome()
      })
      .catch((err) => {
        console.log(err.response)
        setHttpStatus(err)
        setErrors(err)
        throw err
      })
      .finally(() => {
        state.loading = false
      })
  }

  const forgotPassword = ({ email }) => {
    reset()
    state.loading = true
    return authStore
      .forgotPassword({ email })
      .then(() => {
        redirectToLogin(email)
      })
      .catch((err) => {
        setHttpStatus(err)
        setErrors(err)
        throw err
      })
      .finally(() => {
        state.loading = false
      })
  }

  const resetPassword = ({ password, reset_password_token }) => {
    reset()
    state.loading = true
    return authStore
      .resetPassword({
        password,
        reset_password_token,
      })
      .then(() => {
        redirectToHome()
      })
      .catch((err) => {
        setHttpStatus(err)
        setErrors(err)
        throw err
      })
      .finally(() => {
        state.loading = false
      })
  }

  const impersonate = ({ email }) => {
    reset()
    state.loading = true
    return authStore
      .impersonate({ email })
      .then(() => {
        location.href = "/"
      })
      .catch((err) => {
        setHttpStatus(err)
        setErrors(err)
        throw err
      })
      .finally(() => {
        state.loading = false
      })
  }

  const redirectToHome = function () {
    location.href = router.resolve({
      name: "home",
    }).href
  }

  // useful if you're displaying a notification, and redirecting
  const softRedirectToHome = function () {
    router.push({
      name: "home",
    })
  }

  const redirectToLogin = function (useEmail) {
    router.push({
      name: "login",
      query: { useEmail },
    })
  }

  // store's state is reactively passed on (computed for readonly) - all state changes should occur within store
  return {
    ...toRefs(state),
    name: computed(() => authStore.name),
    email: computed(() => authStore.email),
    initials: computed(() => authStore.initials),
    niceId: computed(() => authStore.niceId),
    currentRefreshToken: computed(() => authStore.currentRefreshToken),
    token: computed(() => authStore.token),
    isDemo: computed(() => authStore.isDemo),
    isAdm2n: computed(() => authStore.isAdm2n),
    enableDiagnostics: computed(() => authStore.enableDiagnostics),
    loggedIn: computed(() => authStore.loggedIn),
    login,
    update,
    logout,
    signup,
    forgotPassword,
    resetPassword,
    impersonate,
    redirectToHome,
    softRedirectToHome,
  }
}
