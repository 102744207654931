import { useAuth } from "./use"

export default (router) => {
  router.beforeEach((to, from) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      const bp_auth = useAuth()

      // this route requires auth, check if logged in
      // if not, redirect to login page.
      if (!bp_auth.loggedIn.value) {
        let query = { redirect: to.fullPath }

        if (to.query.loggedOutReason) {
          query.loggedOutReason = to.query.loggedOutReason
        }

        return { name: "login", query }
      }

      if (to.matched.some((record) => record.meta.requiresAdm2n)) {
        if (!bp_auth.isAdm2n.value) {
          let query = { redirect: to.fullPath }

          return { name: "login", query }
        }
      }
    }
  })
}
