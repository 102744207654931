<template>
  <base-dialog :model-value="true" @keydown.esc="closeDialog" size="small">
    <v-card-item>
      <v-card-title>
        <span class="text-h5" data-test-id="title">Flatplan Settings</span>
      </v-card-title>
      <v-card-subtitle><div class="text-disabled"><small>flatplanId: {{ flatplanId }}</small></div></v-card-subtitle>
    </v-card-item>
    <v-card-text>
      <v-form ref="form" v-model="formValid" @submit.prevent="saveDialog">
        <v-text-field label="Issue" v-model="issue" data-test-id="issue" autofocus required :rules="issueRules" />
        <v-checkbox v-model="singlePages" label="Single pages" hide-details="false"></v-checkbox>
        <v-radio-group v-model="pageOrientation">
          <v-radio key="portrait" label="Portrait" value="portrait"></v-radio>
          <v-radio key="landscape" label="Landscape" value="landscape"></v-radio>
          <v-radio key="square" label="Square" value="square"></v-radio>
        </v-radio-group>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <cancel-btn @click="closeDialog">Close</cancel-btn>
      <submit-btn type="submit" @click="saveDialog">Save</submit-btn>
    </v-card-actions>
  </base-dialog>
</template>

<script>
import { ref } from "vue"
import { useRouter } from "vue-router"

import { useFlatplan } from "../use"

export default {
  name: "FlatplanEditView",
  setup() {
    let router = useRouter()
    let bp_flatplan = useFlatplan()

    const form = ref(null)
    let formValid = ref(false)

    let issueRules = [(v) => !!v || "Issue is required."]

    let issue = ref(bp_flatplan.issue.value)
    let singlePages = ref(bp_flatplan.singlePages.value)
    let pageOrientation = ref(bp_flatplan.pageOrientation.value)

    const closeDialog = () => {
      router.push({ name: "flatplan-show" })
    }

    const saveDialog = () => {
      form.value.validate()

      if (formValid.value || formValid.value === null) { // JD: Hack: it shouldn't be null ...
        bp_flatplan.update({ issue: issue.value, singlePages: singlePages.value, pageOrientation: pageOrientation.value })
        closeDialog()
      }
    }

    return {
      closeDialog,
      saveDialog,
      form,
      formValid,
      issue,
      issueRules,
      singlePages,
      pageOrientation,
      flatplanId: bp_flatplan.id
    }
  },
}
</script>

<style lang="scss" scoped></style>
