import axiosRetry from 'axios-retry'
import { useNotification } from "@/modules/notification/use"
import { NetworkMetrics } from '../metrics/index'

export function setupRetryInterceptors(axiosInstance) {
  const REFRESH_TOKEN_PATH = '/token'

  const RETRIES = 5
  const RETRYABLE_METHODS = ['get', 'head', 'options', 'put', 'delete']
  const RETRYABLE_ERROR_CODES = [
    'ECONNABORTED',
    'ERR_CANCELED',
    'ERR_NETWORK',
    'ETIMEDOUT',
    'ECONNRESET',
    'EADDRINUSE',
    'ECONNREFUSED',
    'EPIPE',
    'ENOTFOUND',
    'ENETUNREACH',
    'EAI_AGAIN'
  ]

  const DEFAULT_REQUEST_TIMEOUT = 5000
  const requestTimeout = Number(import.meta.env.VITE_REQUEST_TIMEOUT) || DEFAULT_REQUEST_TIMEOUT

  const isRefreshTokenRequest = (url) => {
    try {
      const pathname = new URL(url, window.location.origin).pathname
      return pathname.endsWith(REFRESH_TOKEN_PATH)
    } catch (e) {
      console.error('Invalid URL in isRefreshTokenRequest:', url)
      return false
    }
  }
  
  const metrics = NetworkMetrics.getInstance()

  axiosRetry(axiosInstance, {
    retries: RETRIES,
    shouldResetTimeout: true,
    retryDelay: (retryCount, error) => {
      const delay = axiosRetry.exponentialDelay(retryCount, error)
      console.log(`Retry delay: ${delay}ms for attempt ${retryCount}`)
      return delay
    },
    retryCondition: (error) => {
      let shouldRetry = false

      // Check for network errors and specific error codes
      const isRetryableError = (
        axiosRetry.isNetworkOrIdempotentRequestError(error) ||
        RETRYABLE_ERROR_CODES.includes(error.code)
      )

      if (isRetryableError) {
        // Check if it was a timeout (using our custom property)
        const wasTimeout = error.config?.signal?.isTimeoutSignal
        
        if (!wasTimeout) {
          console.log('Request was cancelled by user - will not retry')
          shouldRetry = false
        } else {
          // Check if method is retryable
          const isRetryableMethod = RETRYABLE_METHODS.includes(error.config?.method)

          shouldRetry = isRetryableMethod || isRefreshTokenRequest(error.config?.url) // make an exception for the POST request to /token
          
          if (shouldRetry) {
            metrics.logRetry(error, error.config)
            console.log(`Will retry request to ${error.config.url}`, {
              method: error.config?.method,
              errorCode: error.code,
              errorMessage: error.message
            })
          }

        }
      }

      return shouldRetry
    },
    onRetry: (retryCount, error, requestConfig) => {
      console.log(`Executing retry attempt ${retryCount} for ${requestConfig.url}`, {
        originalStartTime: requestConfig.timing?.startTime,
        elapsed: Date.now() - requestConfig.timing?.startTime,
        method: requestConfig.method,
        originalError: error.message
      })

      // Create a fresh AbortSignal for the retry
      if (typeof AbortSignal?.timeout === 'function') {
        try {
          const adjustedTimeout = requestTimeout + axiosRetry.exponentialDelay(retryCount, error) // to avoid (unlikely) race conditions
          requestConfig.signal = AbortSignal.timeout(adjustedTimeout) 
          requestConfig.signal.isTimeoutSignal = true // Add a custom property to identify timeout signals
          console.log(`Set timeout for retry ${retryCount}: ${adjustedTimeout}ms`, {
            method: requestConfig.method,
            url: requestConfig.url
          })
        } catch (err) {
          console.warn('Failed to create new AbortSignal for retry:', err)
        }
      }

      const notification = useNotification()
      notification.show({
        type: "warning",
        message: `Connection issue - retrying (${retryCount}/${RETRIES})...`,
      })

      // Ensure we're not carrying over any problematic headers or states
      delete requestConfig.timestamp
      if (requestConfig.headers) {
        delete requestConfig.headers['If-None-Match']
        delete requestConfig.headers['If-Modified-Since']
      }
    },
    onMaxRetryTimesExceeded: (error, retryCount) => {
      console.log(`All ${RETRIES} retry attempts failed for ${error.config.url}`)

      const notification = useNotification()
      notification.show({
        type: "error",
        message: "Connection failed after multiple attempts.",
      })
    }
  })

  return axiosInstance
}