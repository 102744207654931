export default [
  {
    name: "Full",
    columns: 1,
    fragments: [
      {
        shape: "full",
        topLeftX: 0,
        topLeftY: 0,
      },
    ],
  },
  {
    name: "HorizontalSplit",
    columns: 1,
    fragments: [
      {
        shape: "horizontal_1/2",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "horizontal_1/2",
        topLeftX: 0,
        topLeftY: 0.5,
      },
    ],
  },
  {
    name: "ThirdTop",
    columns: 1,
    fragments: [
      {
        shape: "horizontal_1/3",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "horizontal_2/3",
        topLeftX: 0,
        topLeftY: 1 / 3,
      },
    ],
  },
  {
    name: "ThirdBottom",
    columns: 1,
    fragments: [
      {
        shape: "horizontal_2/3",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "horizontal_1/3",
        topLeftX: 0,
        topLeftY: 2 / 3,
      },
    ],
  },
  {
    name: "ThreeQuarterTop",
    columns: 1,
    fragments: [
      {
        shape: "horizontal_3/4",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "horizontal_1/4",
        topLeftX: 0,
        topLeftY: 0.75,
      },
    ],
  },
  {
    name: "ThreeQuarterBottom",
    columns: 1,
    fragments: [
      {
        shape: "horizontal_1/4",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "horizontal_3/4",
        topLeftX: 0,
        topLeftY: 0.25,
      },
    ],
  },
  {
    name: "HorizontalThirds",
    columns: 1,
    fragments: [
      {
        shape: "horizontal_1/3",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "horizontal_1/3",
        topLeftX: 0,
        topLeftY: 1 / 3,
      },
      {
        shape: "horizontal_1/3",
        topLeftX: 0,
        topLeftY: 2 / 3,
      },
    ],
  },
  {
    name: "QuarterRows",
    columns: 1,
    fragments: [
      {
        shape: "horizontal_1/4",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "horizontal_1/4",
        topLeftX: 0,
        topLeftY: 0.25,
      },
      {
        shape: "horizontal_1/4",
        topLeftX: 0,
        topLeftY: 0.5,
      },
      {
        shape: "horizontal_1/4",
        topLeftX: 0,
        topLeftY: 0.75,
      },
    ],
  },
  {
    name: "HalfTopQuartersBottom",
    columns: 1,
    fragments: [
      {
        shape: "horizontal_1/2",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "horizontal_1/4",
        topLeftX: 0,
        topLeftY: 0.5,
      },
      {
        shape: "horizontal_1/4",
        topLeftX: 0,
        topLeftY: 0.75,
      },
    ],
  },
  {
    name: "QuartersTopHalfBottom",
    columns: 1,
    fragments: [
      {
        shape: "horizontal_1/4",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "horizontal_1/4",
        topLeftX: 0,
        topLeftY: 0.25,
      },
      {
        shape: "horizontal_1/2",
        topLeftX: 0,
        topLeftY: 0.5,
      },
    ],
  },
  {
    name: "QuartersTopBottom",
    columns: 1,
    fragments: [
      {
        shape: "horizontal_1/4",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "horizontal_1/2",
        topLeftX: 0,
        topLeftY: 0.25,
      },
      {
        shape: "horizontal_1/4",
        topLeftX: 0,
        topLeftY: 0.75,
      },
    ],
  },
  {
    name: "SixHorizontals",
    columns: 1,
    fragments: [
      {
        shape: "horizontal_1/6",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "horizontal_1/6",
        topLeftX: 0,
        topLeftY: 1 / 6,
      },
      {
        shape: "horizontal_1/6",
        topLeftX: 0,
        topLeftY: 2 / 6,
      },
      {
        shape: "horizontal_1/6",
        topLeftX: 0,
        topLeftY: 3 / 6,
      },
      {
        shape: "horizontal_1/6",
        topLeftX: 0,
        topLeftY: 4 / 6,
      },
      {
        shape: "horizontal_1/6",
        topLeftX: 0,
        topLeftY: 5 / 6,
      },
    ],
  },
  {
    name: "HalfThreeHorizontals",
    columns: 1,
    fragments: [
      {
        shape: "horizontal_1/2",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "horizontal_1/6",
        topLeftX: 0,
        topLeftY: 3 / 6,
      },
      {
        shape: "horizontal_1/6",
        topLeftX: 0,
        topLeftY: 4 / 6,
      },
      {
        shape: "horizontal_1/6",
        topLeftX: 0,
        topLeftY: 5 / 6,
      },
    ],
  },
  {
    name: "SeventhHorizontals",
    columns: 1,
    fragments: [
      {
        shape: "horizontal_1/7",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "horizontal_1/7",
        topLeftX: 0,
        topLeftY: 1 / 7,
      },
      {
        shape: "horizontal_1/7",
        topLeftX: 0,
        topLeftY: 2 / 7,
      },
      {
        shape: "horizontal_1/7",
        topLeftX: 0,
        topLeftY: 3 / 7,
      },
      {
        shape: "horizontal_1/7",
        topLeftX: 0,
        topLeftY: 4 / 7,
      },
      {
        shape: "horizontal_1/7",
        topLeftX: 0,
        topLeftY: 5 / 7,
      },
      {
        shape: "horizontal_1/7",
        topLeftX: 0,
        topLeftY: 6 / 7,
      },
    ],
  },
  {
    name: "TenthStripHorizontalTop",
    columns: 1,
    fragments: [
      {
        shape: "horizontal_1/10",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "horizontal_9/10",
        topLeftX: 0,
        topLeftY: 1 / 10,
      },
    ],
  },
  {
    name: "TenthStripHorizontalBottom",
    columns: 1,
    fragments: [
      {
        shape: "horizontal_9/10",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "horizontal_1/10",
        topLeftX: 0,
        topLeftY: 9 / 10,
      },
    ],
  },
  {
    name: "VerticalSplit",
    columns: 2,
    fragments: [
      {
        shape: "vertical_1/2",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "vertical_1/2",
        topLeftX: 0.5,
        topLeftY: 0,
      },
    ],
  },
  {
    name: "Quarters",
    columns: 2,
    fragments: [
      {
        shape: "square_1/4",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "square_1/4",
        topLeftX: 0.5,
        topLeftY: 0,
      },
      {
        shape: "square_1/4",
        topLeftX: 0,
        topLeftY: 0.5,
      },
      {
        shape: "square_1/4",
        topLeftX: 0.5,
        topLeftY: 0.5,
      },
    ],
  },
  {
    name: "QuarterTopLeft",
    columns: 2,
    fragments: [
      {
        shape: "square_1/4",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "bottomRightCorner_3/4",
        topLeftX: 0,
        topLeftY: 0,
      },
    ],
  },
  {
    name: "QuarterTopRight",
    columns: 2,
    fragments: [
      {
        shape: "bottomLeftCorner_3/4",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "square_1/4",
        topLeftX: 0.5,
        topLeftY: 0,
      },
    ],
  },
  {
    name: "QuarterBottomLeft",
    columns: 2,
    fragments: [
      {
        shape: "topRightCorner_3/4",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "square_1/4",
        topLeftX: 0,
        topLeftY: 0.5,
      },
    ],
  },
  {
    name: "QuarterBottomRight",
    columns: 2,
    fragments: [
      {
        shape: "topLeftCorner_3/4",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "square_1/4",
        topLeftX: 0.5,
        topLeftY: 0.5,
      },
    ],
  },
  {
    name: "TwoQuartersBottom",
    columns: 2,
    fragments: [
      {
        shape: "horizontal_1/2",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "square_1/4",
        topLeftX: 0,
        topLeftY: 0.5,
      },
      {
        shape: "square_1/4",
        topLeftX: 0.5,
        topLeftY: 0.5,
      },
    ],
  },
  {
    name: "TwoQuartersTop",
    columns: 2,
    fragments: [
      {
        shape: "square_1/4",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "square_1/4",
        topLeftX: 0.5,
        topLeftY: 0,
      },
      {
        shape: "horizontal_1/2",
        topLeftX: 0,
        topLeftY: 0.5,
      },
    ],
  },
  {
    name: "TwoQuartersLeft",
    columns: 2,
    fragments: [
      {
        shape: "square_1/4",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "square_1/4",
        topLeftX: 0,
        topLeftY: 0.5,
      },
      {
        shape: "vertical_1/2",
        topLeftX: 0.5,
        topLeftY: 0,
      },
    ],
  },
  {
    name: "TwoQuartersRight",
    columns: 2,
    fragments: [
      {
        shape: "vertical_1/2",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "square_1/4",
        topLeftX: 0.5,
        topLeftY: 0,
      },
      {
        shape: "square_1/4",
        topLeftX: 0.5,
        topLeftY: 0.5,
      },
    ],
  },
  {
    name: "SixthsLeftTwoQuartersRight",
    columns: 2,
    fragments: [
      {
        shape: "blockHorizontal_1/6_2col",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/6_2col",
        topLeftX: 0,
        topLeftY: 1 / 3,
      },
      {
        shape: "blockHorizontal_1/6_2col",
        topLeftX: 0,
        topLeftY: 2 / 3,
      },
      {
        shape: "square_1/4",
        topLeftX: 0.5,
        topLeftY: 0,
      },
      {
        shape: "square_1/4",
        topLeftX: 0.5,
        topLeftY: 0.5,
      },
    ],
  },
  {
    name: "TwoQuartersLeftSixthsRight",
    columns: 2,
    fragments: [
      {
        shape: "square_1/4",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "square_1/4",
        topLeftX: 0,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/6_2col",
        topLeftX: 0.5,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/6_2col",
        topLeftX: 0.5,
        topLeftY: 1 / 3,
      },
      {
        shape: "blockHorizontal_1/6_2col",
        topLeftX: 0.5,
        topLeftY: 2 / 3,
      },
    ],
  },
  {
    name: "TwoColumnsEighthTopRight",
    columns: 2,
    fragments: [
      {
        shape: "vertical_1/2",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0.5,
        topLeftY: 0,
      },
      {
        shape: "blockVertical_3/8",
        topLeftX: 0.5,
        topLeftY: 0.25,
      },
    ],
  },
  {
    name: "TwoColumnsEighthTopLeft",
    columns: 2,
    fragments: [
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "vertical_1/2",
        topLeftX: 0.5,
        topLeftY: 0,
      },
      {
        shape: "blockVertical_3/8",
        topLeftX: 0,
        topLeftY: 0.25,
      },
    ],
  },
  {
    name: "TwoColumnsEighthBottomRight",
    columns: 2,
    fragments: [
      {
        shape: "vertical_1/2",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockVertical_3/8",
        topLeftX: 0.5,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0.5,
        topLeftY: 0.75,
      },
    ],
  },
  {
    name: "TwoColumnsEighthBottomLeft",
    columns: 2,
    fragments: [
      {
        shape: "blockVertical_3/8",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "vertical_1/2",
        topLeftX: 0.5,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0,
        topLeftY: 0.75,
      },
    ],
  },
  {
    name: "LeftThreeSixteenthRightEightsBottomQuarterStrip",
    columns: 2,
    fragments: [
      {
        shape: "blockHorizontal_3/16_fat",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_3/16_fat",
        topLeftX: 0,
        topLeftY: 3 / 8,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0.5,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0.5,
        topLeftY: 0.25,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0.5,
        topLeftY: 0.5,
      },
      {
        shape: "horizontal_1/4",
        topLeftX: 0,
        topLeftY: 0.75,
      },
    ],
  },
  {
    name: "StackedEightsLeft",
    columns: 2,
    fragments: [
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0,
        topLeftY: 0.25,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0,
        topLeftY: 0.75,
      },
      {
        shape: "vertical_1/2",
        topLeftX: 0.5,
        topLeftY: 0,
      },
    ],
  },
  {
    name: "StackedEightsRight",
    columns: 2,
    fragments: [
      {
        shape: "vertical_1/2",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0.5,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0.5,
        topLeftY: 0.25,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0.5,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0.5,
        topLeftY: 0.75,
      },
    ],
  },
  {
    name: "StackedEightsRightQuartersLeft",
    columns: 2,
    fragments: [
      {
        shape: "square_1/4",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "square_1/4",
        topLeftX: 0,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0.5,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0.5,
        topLeftY: 0.25,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0.5,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0.5,
        topLeftY: 0.75,
      },
    ],
  },
  {
    name: "StackedEightsLeftQuartersRight",
    columns: 2,
    fragments: [
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0,
        topLeftY: 0.25,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0,
        topLeftY: 0.75,
      },
      {
        shape: "square_1/4",
        topLeftX: 0.5,
        topLeftY: 0,
      },
      {
        shape: "square_1/4",
        topLeftX: 0.5,
        topLeftY: 0.5,
      },
    ],
  },
  {
    name: "EightsQuarterTopRight",
    columns: 2,
    fragments: [
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0,
        topLeftY: 0.25,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0,
        topLeftY: 0.75,
      },
      {
        shape: "square_1/4",
        topLeftX: 0.5,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0.5,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0.5,
        topLeftY: 0.75,
      },
    ],
  },
  {
    name: "Eighths",
    columns: 2,
    fragments: [
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0,
        topLeftY: 0.25,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0,
        topLeftY: 0.75,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0.5,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0.5,
        topLeftY: 0.25,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0.5,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0.5,
        topLeftY: 0.75,
      },
    ],
  },
  {
    name: "EighthsTopQuartersBottom",
    columns: 2,
    fragments: [
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0.5,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0,
        topLeftY: 0.25,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0.5,
        topLeftY: 0.25,
      },
      {
        shape: "square_1/4",
        topLeftX: 0,
        topLeftY: 0.5,
      },
      {
        shape: "square_1/4",
        topLeftX: 0.5,
        topLeftY: 0.5,
      },
    ],
  },
  {
    name: "EighthsTopQuarterHorizontalQuartersBottomLeft",
    columns: 2,
    fragments: [
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0.5,
        topLeftY: 0,
      },
      {
        shape: "horizontal_1/4",
        topLeftX: 0,
        topLeftY: 0.25,
      },
      {
        shape: "square_1/4",
        topLeftX: 0,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0.5,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0.5,
        topLeftY: 0.75,
      },
    ],
  },
  {
    name: "EighthsTopQuarterHorizontalQuartersBottomRight",
    columns: 2,
    fragments: [
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0.5,
        topLeftY: 0,
      },
      {
        shape: "horizontal_1/4",
        topLeftX: 0,
        topLeftY: 0.25,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0,
        topLeftY: 0.75,
      },
      {
        shape: "square_1/4",
        topLeftX: 0.5,
        topLeftY: 0.5,
      },
    ],
  },
  {
    name: "EighthsTopQuarterHorizontalBottomQuarters",
    columns: 2,
    fragments: [
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0.5,
        topLeftY: 0,
      },
      {
        shape: "horizontal_1/4",
        topLeftX: 0,
        topLeftY: 0.25,
      },
      {
        shape: "square_1/4",
        topLeftX: 0,
        topLeftY: 0.5,
      },
      {
        shape: "square_1/4",
        topLeftX: 0.5,
        topLeftY: 0.5,
      },
    ],
  },
  {
    name: "EighthsTopQuarterHorizontalBottomHalf",
    columns: 2,
    fragments: [
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0.5,
        topLeftY: 0,
      },
      {
        shape: "horizontal_1/4",
        topLeftX: 0,
        topLeftY: 0.25,
      },
      {
        shape: "horizontal_1/2",
        topLeftX: 0,
        topLeftY: 0.5,
      },
    ],
  },
  {
    name: "TwoEighthsBottom",
    columns: 2,
    fragments: [
      {
        shape: "blockHorizontal_3/4",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0,
        topLeftY: 0.75,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0.5,
        topLeftY: 0.75,
      },
    ],
  },
  {
    name: "QuarterBottomEighthBottomLeft",
    columns: 2,
    fragments: [
      {
        shape: "topRightCorner_5/8",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0,
        topLeftY: 0.5,
      },
      {
        shape: "horizontal_1/4",
        topLeftX: 0,
        topLeftY: 0.75,
      },
    ],
  },
  {
    name: "QuarterBottomEighthBottomRight",
    columns: 2,
    fragments: [
      {
        shape: "topLeftCorner_5/8",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0.5,
        topLeftY: 0.5,
      },
      {
        shape: "horizontal_1/4",
        topLeftX: 0,
        topLeftY: 0.75,
      },
    ],
  },
  {
    name: "Twelfths",
    columns: 2,
    fragments: [
      {
        shape: "blockHorizontal_1/12_2cols",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/12_2cols",
        topLeftX: 0.5,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/12_2cols",
        topLeftX: 0,
        topLeftY: 1 / 6,
      },
      {
        shape: "blockHorizontal_1/12_2cols",
        topLeftX: 0.5,
        topLeftY: 1 / 6,
      },
      {
        shape: "blockHorizontal_1/12_2cols",
        topLeftX: 0,
        topLeftY: 2 / 6,
      },
      {
        shape: "blockHorizontal_1/12_2cols",
        topLeftX: 0.5,
        topLeftY: 2 / 6,
      },
      {
        shape: "blockHorizontal_1/12_2cols",
        topLeftX: 0,
        topLeftY: 3 / 6,
      },
      {
        shape: "blockHorizontal_1/12_2cols",
        topLeftX: 0.5,
        topLeftY: 3 / 6,
      },
      {
        shape: "blockHorizontal_1/12_2cols",
        topLeftX: 0,
        topLeftY: 4 / 6,
      },
      {
        shape: "blockHorizontal_1/12_2cols",
        topLeftX: 0.5,
        topLeftY: 4 / 6,
      },
      {
        shape: "blockHorizontal_1/12_2cols",
        topLeftX: 0,
        topLeftY: 5 / 6,
      },
      {
        shape: "blockHorizontal_1/12_2cols",
        topLeftX: 0.5,
        topLeftY: 5 / 6,
      },
    ],
  },
  {
    name: "TwoSixthsBottom",
    columns: 2,
    fragments: [
      {
        shape: "blockHorizontal_2/3",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/6_2col",
        topLeftX: 0,
        topLeftY: 2 / 3,
      },
      {
        shape: "blockHorizontal_1/6_2col",
        topLeftX: 0.5,
        topLeftY: 2 / 3,
      },
    ],
  },
  {
    name: "TwoByThree",
    columns: 2,
    fragments: [
      {
        shape: "blockHorizontal_1/6_2col",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/6_2col",
        topLeftX: 0.5,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/6_2col",
        topLeftX: 0,
        topLeftY: 1 / 3,
      },
      {
        shape: "blockHorizontal_1/6_2col",
        topLeftX: 0.5,
        topLeftY: 1 / 3,
      },
      {
        shape: "blockHorizontal_1/6_2col",
        topLeftX: 0,
        topLeftY: 2 / 3,
      },
      {
        shape: "blockHorizontal_1/6_2col",
        topLeftX: 0.5,
        topLeftY: 2 / 3,
      },
    ],
  },
  {
    name: "HalfVerticalSixthsRight",
    columns: 2,
    fragments: [
      {
        shape: "vertical_1/2",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/6_2col",
        topLeftX: 0.5,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/6_2col",
        topLeftX: 0.5,
        topLeftY: 1 / 3,
      },
      {
        shape: "blockHorizontal_1/6_2col",
        topLeftX: 0.5,
        topLeftY: 2 / 3,
      },
    ],
  },
  {
    name: "HalfVerticalSixthsLeft",
    columns: 2,
    fragments: [
      {
        shape: "blockHorizontal_1/6_2col",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/6_2col",
        topLeftX: 0,
        topLeftY: 1 / 3,
      },
      {
        shape: "blockHorizontal_1/6_2col",
        topLeftX: 0,
        topLeftY: 2 / 3,
      },
      {
        shape: "vertical_1/2",
        topLeftX: 0.5,
        topLeftY: 0,
      },
    ],
  },
  {
    name: "HalfVerticalTwelfthsSixthsRight",
    columns: 2,
    fragments: [
      {
        shape: "vertical_1/2",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/12_2cols",
        topLeftX: 0.5,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/12_2cols",
        topLeftX: 0.5,
        topLeftY: 1 / 6,
      },
      {
        shape: "blockHorizontal_1/6_2col",
        topLeftX: 0.5,
        topLeftY: 1 / 3,
      },
      {
        shape: "blockHorizontal_1/6_2col",
        topLeftX: 0.5,
        topLeftY: 2 / 3,
      },
    ],
  },
  {
    name: "HalfVerticalThreeTwelfthsTopRightAndQuarter",
    columns: 2,
    fragments: [
      {
        shape: "vertical_1/2",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/12_2cols",
        topLeftX: 0.5,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/12_2cols",
        topLeftX: 0.5,
        topLeftY: 1 / 6,
      },
      {
        shape: "blockHorizontal_1/12_2cols",
        topLeftX: 0.5,
        topLeftY: 1 / 3,
      },
      {
        shape: "square_1/4",
        topLeftX: 0.5,
        topLeftY: 1 / 2,
      },
    ],
  },
  {
    name: "HalfVerticalTwelfthTopRightSixthAndQuarter",
    columns: 2,
    fragments: [
      {
        shape: "vertical_1/2",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/12_2cols",
        topLeftX: 0.5,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/6_2col",
        topLeftX: 0.5,
        topLeftY: 1 / 6,
      },
      {
        shape: "square_1/4",
        topLeftX: 0.5,
        topLeftY: 1 / 2,
      },
    ],
  },
  {
    name: "HalfVerticalThreeTwelfthsTopRightAndEights",
    columns: 2,
    fragments: [
      {
        shape: "vertical_1/2",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/12_2cols",
        topLeftX: 0.5,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/12_2cols",
        topLeftX: 0.5,
        topLeftY: 1 / 6,
      },
      {
        shape: "blockHorizontal_1/12_2cols",
        topLeftX: 0.5,
        topLeftY: 1 / 3,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0.5,
        topLeftY: 1 / 2,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0.5,
        topLeftY: 3 / 4,
      },
    ],
  },
  {
    name: "QuarterAndEightsLeftVerticalSixthsRight",
    columns: 2,
    fragments: [
      {
        shape: "square_1/4",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0,
        topLeftY: 0.75,
      },
      {
        shape: "blockHorizontal_1/6_2col",
        topLeftX: 0.5,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/6_2col",
        topLeftX: 0.5,
        topLeftY: 1 / 3,
      },
      {
        shape: "blockHorizontal_1/6_2col",
        topLeftX: 0.5,
        topLeftY: 2 / 3,
      },
    ],
  },
  {
    name: "ThirdBottomQuarterLeft",
    columns: 2,
    fragments: [
      {
        shape: "topRightCorner_5/12_thin",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "square_1/4",
        topLeftX: 0,
        topLeftY: 1 / 6,
      },
      {
        shape: "horizontal_1/3",
        topLeftX: 0,
        topLeftY: 2 / 3,
      },
    ],
  },
  {
    name: "ThirdBottomQuarterRight",
    columns: 2,
    fragments: [
      {
        shape: "topLeftCorner_5/12_thin",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "square_1/4",
        topLeftX: 0.5,
        topLeftY: 1 / 6,
      },
      {
        shape: "horizontal_1/3",
        topLeftX: 0,
        topLeftY: 2 / 3,
      },
    ],
  },
  {
    name: "ThirdBottomVerticalThirds",
    columns: 2,
    fragments: [
      {
        shape: "blockVertical_1/3",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockVertical_1/3",
        topLeftX: 0.5,
        topLeftY: 0,
      },
      {
        shape: "horizontal_1/3",
        topLeftX: 0,
        topLeftY: 2 / 3,
      },
    ],
  },
  {
    name: "ThirdBottomFourSixths",
    columns: 2,
    fragments: [
      {
        shape: "blockHorizontal_1/6_2col",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/6_2col",
        topLeftX: 0.5,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/6_2col",
        topLeftX: 0,
        topLeftY: 1 / 3,
      },
      {
        shape: "blockHorizontal_1/6_2col",
        topLeftX: 0.5,
        topLeftY: 1 / 3,
      },
      {
        shape: "horizontal_1/3",
        topLeftX: 0,
        topLeftY: 2 / 3,
      },
    ],
  },
  {
    name: "TwoByFive",
    columns: 2,
    fragments: [
      {
        shape: "blockHorizontal_1/10",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/10",
        topLeftX: 0,
        topLeftY: 1 / 5,
      },
      {
        shape: "blockHorizontal_1/10",
        topLeftX: 0,
        topLeftY: 2 / 5,
      },
      {
        shape: "blockHorizontal_1/10",
        topLeftX: 0,
        topLeftY: 3 / 5,
      },
      {
        shape: "blockHorizontal_1/10",
        topLeftX: 0,
        topLeftY: 4 / 5,
      },
      {
        shape: "blockHorizontal_1/10",
        topLeftX: 0.5,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/10",
        topLeftX: 0.5,
        topLeftY: 1 / 5,
      },
      {
        shape: "blockHorizontal_1/10",
        topLeftX: 0.5,
        topLeftY: 2 / 5,
      },
      {
        shape: "blockHorizontal_1/10",
        topLeftX: 0.5,
        topLeftY: 3 / 5,
      },
      {
        shape: "blockHorizontal_1/10",
        topLeftX: 0.5,
        topLeftY: 4 / 5,
      },
    ],
  },
  {
    name: "HalfRightStackedTenthLeft",
    columns: 2,
    fragments: [
      {
        shape: "blockHorizontal_1/10",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/10",
        topLeftX: 0,
        topLeftY: 1 / 5,
      },
      {
        shape: "blockHorizontal_1/10",
        topLeftX: 0,
        topLeftY: 2 / 5,
      },
      {
        shape: "blockHorizontal_1/10",
        topLeftX: 0,
        topLeftY: 3 / 5,
      },
      {
        shape: "blockHorizontal_1/10",
        topLeftX: 0,
        topLeftY: 4 / 5,
      },
      {
        shape: "vertical_1/2",
        topLeftX: 0.5,
        topLeftY: 0,
      },
    ],
  },
  {
    name: "HalfLeftStackedTenthRight",
    columns: 2,
    fragments: [
      {
        shape: "vertical_1/2",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/10",
        topLeftX: 0.5,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/10",
        topLeftX: 0.5,
        topLeftY: 1 / 5,
      },
      {
        shape: "blockHorizontal_1/10",
        topLeftX: 0.5,
        topLeftY: 2 / 5,
      },
      {
        shape: "blockHorizontal_1/10",
        topLeftX: 0.5,
        topLeftY: 3 / 5,
      },
      {
        shape: "blockHorizontal_1/10",
        topLeftX: 0.5,
        topLeftY: 4 / 5,
      },
    ],
  },
  {
    name: "StackedLeftQuartersRightTenths",
    columns: 2,
    fragments: [
      {
        shape: "square_1/4",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "square_1/4",
        topLeftX: 0,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/10",
        topLeftX: 0.5,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/10",
        topLeftX: 0.5,
        topLeftY: 1 / 5,
      },
      {
        shape: "blockHorizontal_1/10",
        topLeftX: 0.5,
        topLeftY: 2 / 5,
      },
      {
        shape: "blockHorizontal_1/10",
        topLeftX: 0.5,
        topLeftY: 3 / 5,
      },
      {
        shape: "blockHorizontal_1/10",
        topLeftX: 0.5,
        topLeftY: 4 / 5,
      },
    ],
  },
  {
    name: "StackedRightQuartersLeftTenths",
    columns: 2,
    fragments: [
      {
        shape: "square_1/4",
        topLeftX: 0.5,
        topLeftY: 0,
      },
      {
        shape: "square_1/4",
        topLeftX: 0.5,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/10",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/10",
        topLeftX: 0,
        topLeftY: 1 / 5,
      },
      {
        shape: "blockHorizontal_1/10",
        topLeftX: 0,
        topLeftY: 2 / 5,
      },
      {
        shape: "blockHorizontal_1/10",
        topLeftX: 0,
        topLeftY: 3 / 5,
      },
      {
        shape: "blockHorizontal_1/10",
        topLeftX: 0,
        topLeftY: 4 / 5,
      },
    ],
  },
  {
    name: "LeftQuarterEightsBottomRightFifths",
    columns: 2,
    fragments: [
      {
        shape: "square_1/4",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0,
        topLeftY: 0.75,
      },
      {
        shape: "blockHorizontal_1/10",
        topLeftX: 0.5,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/10",
        topLeftX: 0.5,
        topLeftY: 1 / 5,
      },
      {
        shape: "blockHorizontal_1/10",
        topLeftX: 0.5,
        topLeftY: 2 / 5,
      },
      {
        shape: "blockHorizontal_1/10",
        topLeftX: 0.5,
        topLeftY: 3 / 5,
      },
      {
        shape: "blockHorizontal_1/10",
        topLeftX: 0.5,
        topLeftY: 4 / 5,
      },
    ],
  },
  {
    name: "HalfBottomEighthLeft",
    columns: 2,
    fragments: [
      {
        shape: "topRightCorner_3/8",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0,
        topLeftY: 0.25,
      },
      {
        shape: "horizontal_1/2",
        topLeftX: 0,
        topLeftY: 0.5,
      },
    ],
  },
  {
    name: "HalfBottomEighthRight",
    columns: 2,
    fragments: [
      {
        shape: "topLeftCorner_3/8",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0.5,
        topLeftY: 0.25,
      },
      {
        shape: "horizontal_1/2",
        topLeftX: 0,
        topLeftY: 0.5,
      },
    ],
  },
  {
    name: "QuarterBottomQuarterLeft",
    columns: 2,
    fragments: [
      {
        shape: "topRightCorner_1/2",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "square_1/4",
        topLeftX: 0,
        topLeftY: 0.25,
      },
      {
        shape: "horizontal_1/4",
        topLeftX: 0,
        topLeftY: 0.75,
      },
    ],
  },
  {
    name: "QuarterBottomQuarterRight",
    columns: 2,
    fragments: [
      {
        shape: "topLeftCorner_1/2",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "square_1/4",
        topLeftX: 0.5,
        topLeftY: 0.25,
      },
      {
        shape: "horizontal_1/4",
        topLeftX: 0,
        topLeftY: 0.75,
      },
    ],
  },
  {
    name: "QuarterLeftEighthRight",
    columns: 2,
    fragments: [
      {
        shape: "topRightCorner_5/8",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "square_1/4",
        topLeftX: 0,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0.5,
        topLeftY: 0.75,
      },
    ],
  },
  {
    name: "QuarterRightEighthLeft",
    columns: 2,
    fragments: [
      {
        shape: "topLeftCorner_5/8",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0,
        topLeftY: 0.75,
      },
      {
        shape: "square_1/4",
        topLeftX: 0.5,
        topLeftY: 0.5,
      },
    ],
  },
  {
    name: "QuarterRightEighthRightAndBottom",
    columns: 2,
    fragments: [
      {
        shape: "topLeftCorner_1/2",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0,
        topLeftY: 0.75,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0.5,
        topLeftY: 0.25,
      },
      {
        shape: "square_1/4",
        topLeftX: 0.5,
        topLeftY: 0.5,
      },
    ],
  },
  {
    name: "TwoQuartersTopTwoHorizontalsBottom",
    columns: 2,
    fragments: [
      {
        shape: "square_1/4",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "square_1/4",
        topLeftX: 0.5,
        topLeftY: 0,
      },
      {
        shape: "horizontal_1/4",
        topLeftX: 0,
        topLeftY: 0.5,
      },
      {
        shape: "horizontal_1/4",
        topLeftX: 0,
        topLeftY: 0.75,
      },
    ],
  },
  {
    name: "ThreeQuartersBottomLeftEighths",
    columns: 2,
    fragments: [
      {
        shape: "square_1/4",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "square_1/4",
        topLeftX: 0.5,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0,
        topLeftY: 0.75,
      },
      {
        shape: "square_1/4",
        topLeftX: 0.5,
        topLeftY: 0.5,
      },
    ],
  },
  {
    name: "ThreeQuartersBottomRightEighths",
    columns: 2,
    fragments: [
      {
        shape: "square_1/4",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "square_1/4",
        topLeftX: 0.5,
        topLeftY: 0,
      },
      {
        shape: "square_1/4",
        topLeftX: 0,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0.5,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0.5,
        topLeftY: 0.75,
      },
    ],
  },
  {
    name: "TwoQuartersTopLeftBottomRightEighths",
    columns: 2,
    fragments: [
      {
        shape: "square_1/4",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0.5,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0.5,
        topLeftY: 0.25,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0,
        topLeftY: 0.75,
      },
      {
        shape: "square_1/4",
        topLeftX: 0.5,
        topLeftY: 0.5,
      },
    ],
  },
  {
    name: "TopHalfQuarterRightEighthsLeft",
    columns: 2,
    fragments: [
      {
        shape: "horizontal_1/2",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0,
        topLeftY: 0.75,
      },
      {
        shape: "square_1/4",
        topLeftX: 0.5,
        topLeftY: 0.5,
      },
    ],
  },
  {
    name: "TopHalfQuarterLeftEighthsRight",
    columns: 2,
    fragments: [
      {
        shape: "horizontal_1/2",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "square_1/4",
        topLeftX: 0,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0.5,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0.5,
        topLeftY: 0.75,
      },
    ],
  },
  {
    name: "QuarterBottomRightEighthsStacked",
    columns: 2,
    fragments: [
      {
        shape: "topRightCorner_3/8",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0,
        topLeftY: 0.25,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0,
        topLeftY: 0.75,
      },
      {
        shape: "square_1/4",
        topLeftX: 0.5,
        topLeftY: 0.5,
      },
    ],
  },
  {
    name: "QuarterBottomLeftEighthsStacked",
    columns: 2,
    fragments: [
      {
        shape: "topLeftCorner_3/8",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0.5,
        topLeftY: 0.25,
      },
      {
        shape: "square_1/4",
        topLeftX: 0,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0.5,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0.5,
        topLeftY: 0.75,
      },
    ],
  },
  {
    name: "TopHalfBottomHorizontalEighths",
    columns: 2,
    fragments: [
      {
        shape: "horizontal_1/2",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0,
        topLeftY: 0.75,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0.5,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0.5,
        topLeftY: 0.75,
      },
    ],
  },
  {
    name: "TopHorizontalEighthsBottomHalf",
    columns: 2,
    fragments: [
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0,
        topLeftY: 0.25,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0.5,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0.5,
        topLeftY: 0.25,
      },
      {
        shape: "horizontal_1/2",
        topLeftX: 0,
        topLeftY: 0.5,
      },
    ],
  },
  {
    name: "LeftHalfTopQuarterBottomEighths",
    columns: 2,
    fragments: [
      {
        shape: "vertical_1/2",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "square_1/4",
        topLeftX: 0.5,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0.5,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0.5,
        topLeftY: 0.75,
      },
    ],
  },
  {
    name: "LeftHalfTopEighthsBottomQuarter",
    columns: 2,
    fragments: [
      {
        shape: "vertical_1/2",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0.5,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0.5,
        topLeftY: 0.25,
      },
      {
        shape: "square_1/4",
        topLeftX: 0.5,
        topLeftY: 0.5,
      },
    ],
  },
  {
    name: "BottomHalfQuarterLeftEighths",
    columns: 2,
    fragments: [
      {
        shape: "square_1/4",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0.5,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0.5,
        topLeftY: 0.25,
      },
      {
        shape: "horizontal_1/2",
        topLeftX: 0,
        topLeftY: 0.5,
      },
    ],
  },
  {
    name: "BottomHalfQuarterRightEighths",
    columns: 2,
    fragments: [
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0,
        topLeftY: 0.25,
      },
      {
        shape: "square_1/4",
        topLeftX: 0.5,
        topLeftY: 0,
      },
      {
        shape: "horizontal_1/2",
        topLeftX: 0,
        topLeftY: 0.5,
      },
    ],
  },
  {
    name: "BottomQuartersQuarterLeftEighths",
    columns: 2,
    fragments: [
      {
        shape: "square_1/4",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0.5,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0.5,
        topLeftY: 0.25,
      },
      {
        shape: "square_1/4",
        topLeftX: 0,
        topLeftY: 0.5,
      },
      {
        shape: "square_1/4",
        topLeftX: 0.5,
        topLeftY: 0.5,
      },
    ],
  },
  {
    name: "BottomQuartersQuarterRightEighths",
    columns: 2,
    fragments: [
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0,
        topLeftY: 0.25,
      },
      {
        shape: "square_1/4",
        topLeftX: 0.5,
        topLeftY: 0,
      },
      {
        shape: "square_1/4",
        topLeftX: 0,
        topLeftY: 0.5,
      },
      {
        shape: "square_1/4",
        topLeftX: 0.5,
        topLeftY: 0.5,
      },
    ],
  },
  {
    name: "ThreeQuartersStaggeredEighthsLeft",
    columns: 2,
    fragments: [
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "square_1/4",
        topLeftX: 0.5,
        topLeftY: 0,
      },
      {
        shape: "square_1/4",
        topLeftX: 0,
        topLeftY: 0.25,
      },
      {
        shape: "square_1/4",
        topLeftX: 0.5,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0,
        topLeftY: 0.75,
      },
    ],
  },
  {
    name: "TwoEightTopTwoQuarterColumnsQuarterStripBottom",
    columns: 2,
    fragments: [
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0.5,
        topLeftY: 0,
      },
      {
        shape: "square_1/4",
        topLeftX: 0,
        topLeftY: 0.25,
      },
      {
        shape: "square_1/4",
        topLeftX: 0.5,
        topLeftY: 0.25,
      },
      {
        shape: "horizontal_1/4",
        topLeftX: 0,
        topLeftY: 0.75,
      },
    ],
  },
  {
    name: "EightsTopBottomStaggeredQuarterEighths",
    columns: 2,
    fragments: [
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0.5,
        topLeftY: 0,
      },
      {
        shape: "square_1/4",
        topLeftX: 0,
        topLeftY: 0.25,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0,
        topLeftY: 0.75,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0.5,
        topLeftY: 0.25,
      },
      {
        shape: "square_1/4",
        topLeftX: 0.5,
        topLeftY: 0.5,
      },
    ],
  },
  {
    name: "TopStaggeredQuarterEighthsBottomQuarterStrip",
    columns: 2,
    fragments: [
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "square_1/4",
        topLeftX: 0,
        topLeftY: 0.25,
      },
      {
        shape: "square_1/4",
        topLeftX: 0.5,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0.5,
        topLeftY: 0.5,
      },
      {
        shape: "horizontal_1/4",
        topLeftX: 0,
        topLeftY: 0.75,
      },
    ],
  },
  {
    name: "TopRightQuarterEighthsBottomQuarterStrip",
    columns: 2,
    fragments: [
      {
        shape: "blockVertical_3/8",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0.5,
        topLeftY: 0,
      },
      {
        shape: "square_1/4",
        topLeftX: 0.5,
        topLeftY: 0.25,
      },
      {
        shape: "horizontal_1/4",
        topLeftX: 0,
        topLeftY: 0.75,
      },
    ],
  },
  {
    name: "TopLeftQuarterEighthsBottomQuarterStrip",
    columns: 2,
    fragments: [
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "square_1/4",
        topLeftX: 0,
        topLeftY: 0.25,
      },
      {
        shape: "blockVertical_3/8",
        topLeftX: 0.5,
        topLeftY: 0,
      },
      {
        shape: "horizontal_1/4",
        topLeftX: 0,
        topLeftY: 0.75,
      },
    ],
  },
  {
    name: "BottomQuarterStripTwoEighthsLeft",
    columns: 2,
    fragments: [
      {
        shape: "topRightCorner_1/2",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0,
        topLeftY: 0.25,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0,
        topLeftY: 0.5,
      },
      {
        shape: "horizontal_1/4",
        topLeftX: 0,
        topLeftY: 0.75,
      },
    ],
  },
  {
    name: "BottomQuarterStripTwoEighthsRight",
    columns: 2,
    fragments: [
      {
        shape: "topLeftCorner_1/2",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0.5,
        topLeftY: 0.25,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0.5,
        topLeftY: 0.5,
      },
      {
        shape: "horizontal_1/4",
        topLeftX: 0,
        topLeftY: 0.75,
      },
    ],
  },
  {
    name: "BottomQuarterStripTwoEighthsRow",
    columns: 2,
    fragments: [
      {
        shape: "horizontal_1/2",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0.5,
        topLeftY: 0.5,
      },
      {
        shape: "horizontal_1/4",
        topLeftX: 0,
        topLeftY: 0.75,
      },
    ],
  },
  {
    name: "HalfVerticalRightQuarterEighthsStacked",
    columns: 2,
    fragments: [
      {
        shape: "square_1/4",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0,
        topLeftY: 0.75,
      },
      {
        shape: "vertical_1/2",
        topLeftX: 0.5,
        topLeftY: 0,
      },
    ],
  },
  {
    name: "EighthBottomLeft",
    columns: 2,
    fragments: [
      {
        shape: "topRightCorner_7/8",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0,
        topLeftY: 0.75,
      },
    ],
  },
  {
    name: "EighthBottomRight",
    columns: 2,
    fragments: [
      {
        shape: "topLeftCorner_7/8",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0.5,
        topLeftY: 0.75,
      },
    ],
  },
  {
    name: "EighthsBottom",
    columns: 2,
    fragments: [
      {
        shape: "horizontal_3/4",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0,
        topLeftY: 0.75,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0.5,
        topLeftY: 0.75,
      },
    ],
  },
  {
    name: "FifthTopBannerFifthBottomRight",
    columns: 2,
    fragments: [
      {
        shape: "horizontal_1/5",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "topLeftCorner_3/5",
        topLeftX: 0,
        topLeftY: 1 / 5,
      },
      {
        shape: "blockHorizontal_1/5",
        topLeftX: 0.5,
        topLeftY: 3 / 5,
      },
    ],
  },
  {
    name: "ThirdLeft",
    columns: 3,
    fragments: [
      {
        shape: "vertical_1/3",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "vertical_2/3",
        topLeftX: 1 / 3,
        topLeftY: 0,
      },
    ],
  },
  {
    name: "ThirdRight",
    columns: 3,
    fragments: [
      {
        shape: "vertical_2/3",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "vertical_1/3",
        topLeftX: 2 / 3,
        topLeftY: 0,
      },
    ],
  },
  {
    name: "VerticalThirds",
    columns: 3,
    fragments: [
      {
        shape: "vertical_1/3",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "vertical_1/3",
        topLeftX: 1 / 3,
        topLeftY: 0,
      },
      {
        shape: "vertical_1/3",
        topLeftX: 2 / 3,
        topLeftY: 0,
      },
    ],
  },
  {
    name: "TwoVerticalThirdsMiddleStackedSixths",
    columns: 3,
    fragments: [
      {
        shape: "vertical_1/3",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockVertical_1/6",
        topLeftX: 1 / 3,
        topLeftY: 0,
      },
      {
        shape: "blockVertical_1/6",
        topLeftX: 1 / 3,
        topLeftY: 0.5,
      },
      {
        shape: "vertical_1/3",
        topLeftX: 2 / 3,
        topLeftY: 0,
      },
    ],
  },
  {
    name: "MiddleVerticalFourSixths",
    columns: 3,
    fragments: [
      {
        shape: "blockVertical_1/6",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockVertical_1/6",
        topLeftX: 0,
        topLeftY: 0.5,
      },
      {
        shape: "vertical_1/3",
        topLeftX: 1 / 3,
        topLeftY: 0,
      },
      {
        shape: "blockVertical_1/6",
        topLeftX: 2 / 3,
        topLeftY: 0,
      },
      {
        shape: "blockVertical_1/6",
        topLeftX: 2 / 3,
        topLeftY: 0.5,
      },
    ],
  },
  {
    name: "MiddleVerticalEightTwelfths",
    columns: 3,
    fragments: [
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 0,
        topLeftY: 0.25,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 0,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 0,
        topLeftY: 0.75,
      },
      {
        shape: "vertical_1/3",
        topLeftX: 1 / 3,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 2 / 3,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 2 / 3,
        topLeftY: 0.25,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 2 / 3,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 2 / 3,
        topLeftY: 0.75,
      },
    ],
  },
  {
    name: "MiddleVerticalLeftTwoSixthsFourTwelfths",
    columns: 3,
    fragments: [
      {
        shape: "blockVertical_1/6_3cols",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockVertical_1/6_3cols",
        topLeftX: 0,
        topLeftY: 0.5,
      },
      {
        shape: "vertical_1/3",
        topLeftX: 1 / 3,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 2 / 3,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 2 / 3,
        topLeftY: 0.25,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 2 / 3,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 2 / 3,
        topLeftY: 0.75,
      },
    ],
  },
  {
    name: "MiddleVerticalLeftFourTwelfthsTwoSixths",
    columns: 3,
    fragments: [
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 0,
        topLeftY: 0.25,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 0,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 0,
        topLeftY: 0.75,
      },
      {
        shape: "vertical_1/3",
        topLeftX: 1 / 3,
        topLeftY: 0,
      },
      {
        shape: "blockVertical_1/6_3cols",
        topLeftX: 2 / 3,
        topLeftY: 0,
      },
      {
        shape: "blockVertical_1/6_3cols",
        topLeftX: 2 / 3,
        topLeftY: 0.5,
      },
    ],
  },
  {
    name: "MiddleVerticalTopLeftTwelfthsThreeSixths",
    columns: 3,
    fragments: [
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 0,
        topLeftY: 0.25,
      },
      {
        shape: "blockVertical_1/6_3cols",
        topLeftX: 0,
        topLeftY: 0.5,
      },
      {
        shape: "vertical_1/3",
        topLeftX: 1 / 3,
        topLeftY: 0,
      },
      {
        shape: "blockVertical_1/6_3cols",
        topLeftX: 2 / 3,
        topLeftY: 0,
      },
      {
        shape: "blockVertical_1/6_3cols",
        topLeftX: 2 / 3,
        topLeftY: 0.5,
      },
    ],
  },
  {
    name: "MiddleVerticalTopRightTwelfthsThreeSixths",
    columns: 3,
    fragments: [
      {
        shape: "blockVertical_1/6_3cols",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockVertical_1/6_3cols",
        topLeftX: 0,
        topLeftY: 0.5,
      },
      {
        shape: "vertical_1/3",
        topLeftX: 1 / 3,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 2 / 3,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 2 / 3,
        topLeftY: 0.25,
      },
      {
        shape: "blockVertical_1/6_3cols",
        topLeftX: 2 / 3,
        topLeftY: 0.5,
      },
    ],
  },
  {
    name: "TwoVerticalThirdsRightBottomNinth",
    columns: 3,
    fragments: [
      {
        shape: "vertical_1/3",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "vertical_1/3",
        topLeftX: 1 / 3,
        topLeftY: 0,
      },
      {
        shape: "blockVertical_2/9",
        topLeftX: 2 / 3,
        topLeftY: 0,
      },
      {
        shape: "square_1/9",
        topLeftX: 2 / 3,
        topLeftY: 2 / 3,
      },
    ],
  },
  {
    name: "ThirdRightThirdsLeft",
    columns: 3,
    fragments: [
      {
        shape: "blockHorizontal_1/3",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/3",
        topLeftX: 0,
        topLeftY: 0.5,
      },
      {
        shape: "vertical_1/3",
        topLeftX: 2 / 3,
        topLeftY: 0,
      },
    ],
  },
  {
    name: "ThirdLeftThirdsRight",
    columns: 3,
    fragments: [
      {
        shape: "vertical_1/3",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/3",
        topLeftX: 1 / 3,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/3",
        topLeftX: 1 / 3,
        topLeftY: 0.5,
      },
    ],
  },
  {
    name: "SixthsLeftThirdsRight",
    columns: 3,
    fragments: [
      {
        shape: "blockVertical_1/6",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockVertical_1/6",
        topLeftX: 0,
        topLeftY: 1 / 2,
      },
      {
        shape: "blockHorizontal_1/3",
        topLeftX: 1 / 3,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/3",
        topLeftX: 1 / 3,
        topLeftY: 0.5,
      },
    ],
  },
  {
    name: "QuarterStripsTopBottomThirdLeft",
    columns: 3,
    fragments: [
      {
        shape: "horizontal_1/4",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockVertical_1/6",
        topLeftX: 0,
        topLeftY: 1 / 4,
      },
      {
        shape: "blockHorizontal_1/3",
        topLeftX: 1 / 3,
        topLeftY: 1 / 4,
      },
      {
        shape: "horizontal_1/4",
        topLeftX: 0,
        topLeftY: 3 / 4,
      },
    ],
  },
  {
    name: "ThirdRightTopBottom",
    columns: 3,
    fragments: [
      {
        shape: "blockHorizontal_2/9",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockVertical_4/9",
        topLeftX: 0,
        topLeftY: 1 / 3,
      },
      {
        shape: "vertical_1/3",
        topLeftX: 2 / 3,
        topLeftY: 0,
      },
    ],
  },
  {
    name: "ThirdLeftTopBottom",
    columns: 3,
    fragments: [
      {
        shape: "vertical_1/3",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_2/9",
        topLeftX: 1 / 3,
        topLeftY: 0,
      },
      {
        shape: "blockVertical_4/9",
        topLeftX: 1 / 3,
        topLeftY: 1 / 3,
      },
    ],
  },
  {
    name: "ThirdRightSixthHalfBottom",
    columns: 3,
    fragments: [
      {
        shape: "blockHorizontal_1/6_3cols",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockVertical_1/2_3cols",
        topLeftX: 0,
        topLeftY: 0.25,
      },
      {
        shape: "vertical_1/3",
        topLeftX: 2 / 3,
        topLeftY: 0,
      },
    ],
  },
  {
    name: "ThirdLeftSixthHalfBottom",
    columns: 3,
    fragments: [
      {
        shape: "vertical_1/3",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/6_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0,
      },
      {
        shape: "blockVertical_1/2_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0.25,
      },
    ],
  },
  {
    name: "ThirdsGridVerticalSixthsLeftNinthsBottomRight",
    columns: 3,
    fragments: [
      {
        shape: "blockVertical_1/6_3cols",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockVertical_1/6_3cols",
        topLeftX: 0,
        topLeftY: 0.5,
      },
      {
        shape: "blockVertical_4/9",
        topLeftX: 1 / 3,
        topLeftY: 0,
      },
      {
        shape: "square_1/9",
        topLeftX: 1 / 3,
        topLeftY: 2 / 3,
      },
      {
        shape: "square_1/9",
        topLeftX: 2 / 3,
        topLeftY: 2 / 3,
      },
    ],
  },
  {
    name: "ThirdsGridVerticalSixthsRightNinthsBottomLeft",
    columns: 3,
    fragments: [
      {
        shape: "blockVertical_4/9",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "square_1/9",
        topLeftX: 0,
        topLeftY: 2 / 3,
      },
      {
        shape: "square_1/9",
        topLeftX: 1 / 3,
        topLeftY: 2 / 3,
      },
      {
        shape: "blockVertical_1/6_3cols",
        topLeftX: 2 / 3,
        topLeftY: 0,
      },
      {
        shape: "blockVertical_1/6_3cols",
        topLeftX: 2 / 3,
        topLeftY: 0.5,
      },
    ],
  },
  {
    name: "TwoSixthsLeft",
    columns: 3,
    fragments: [
      {
        shape: "vertical_2/3",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockVertical_1/6_3cols",
        topLeftX: 2 / 3,
        topLeftY: 0,
      },
      {
        shape: "blockVertical_1/6_3cols",
        topLeftX: 2 / 3,
        topLeftY: 0.5,
      },
    ],
  },
  {
    name: "TwoSixthsRight",
    columns: 3,
    fragments: [
      {
        shape: "blockVertical_1/6_3cols",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockVertical_1/6_3cols",
        topLeftX: 0,
        topLeftY: 0.5,
      },
      {
        shape: "vertical_2/3",
        topLeftX: 1 / 3,
        topLeftY: 0,
      },
    ],
  },
  {
    name: "StackedSixthsLeft",
    columns: 3,
    fragments: [
      {
        shape: "blockHorizontal_1/6_3cols",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/6_3cols",
        topLeftX: 0,
        topLeftY: 0.25,
      },
      {
        shape: "blockHorizontal_1/6_3cols",
        topLeftX: 0,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/6_3cols",
        topLeftX: 0,
        topLeftY: 0.75,
      },
      {
        shape: "vertical_1/3",
        topLeftX: 2 / 3,
        topLeftY: 0,
      },
    ],
  },
  {
    name: "StackedSixthsRight",
    columns: 3,
    fragments: [
      {
        shape: "vertical_1/3",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/6_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/6_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0.25,
      },
      {
        shape: "blockHorizontal_1/6_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/6_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0.75,
      },
    ],
  },
  {
    name: "VerticalSixthsLeftStackedSixthsRight",
    columns: 3,
    fragments: [
      {
        shape: "blockVertical_1/6",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockVertical_1/6",
        topLeftX: 0,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/6_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/6_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0.25,
      },
      {
        shape: "blockHorizontal_1/6_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/6_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0.75,
      },
    ],
  },
  {
    name: "VerticalTwelfthsLeftStackedSixthsRight",
    columns: 3,
    fragments: [
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 0,
        topLeftY: 0.25,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 0,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 0,
        topLeftY: 0.75,
      },
      {
        shape: "blockHorizontal_1/6_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/6_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0.25,
      },
      {
        shape: "blockHorizontal_1/6_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/6_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0.75,
      },
    ],
  },
  {
    name: "VerticalTwelfthsLeftStackedSixthsTopRightHalfBottom",
    columns: 3,
    fragments: [
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/6_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 0,
        topLeftY: 0.25,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0.25,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 2 / 3,
        topLeftY: 0.25,
      },
      {
        shape: "horizontal_1/2",
        topLeftX: 0,
        topLeftY: 0.5,
      },
    ],
  },
  {
    name: "VerticalSixthsLeftStackedSixthsRightTopVerticalSixthsBottom",
    columns: 3,
    fragments: [
      {
        shape: "blockVertical_1/6",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockVertical_1/6",
        topLeftX: 0,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/6_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/6_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0.25,
      },
      {
        shape: "blockVertical_1/6",
        topLeftX: 1 / 3,
        topLeftY: 0.5,
      },
      {
        shape: "blockVertical_1/6",
        topLeftX: 2 / 3,
        topLeftY: 0.5,
      },
    ],
  },
  {
    name: "VerticalSixthsLeftStackedSixthsRightTopTwelfthsBottom",
    columns: 3,
    fragments: [
      {
        shape: "blockVertical_1/6",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockVertical_1/6",
        topLeftX: 0,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/6_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/6_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0.25,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 2 / 3,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0.75,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 2 / 3,
        topLeftY: 0.75,
      },
    ],
  },
  {
    name: "SixHorizontalsSplitThirds",
    columns: 3,
    fragments: [
      {
        shape: "blockHorizontal_1/18",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/18",
        topLeftX: 1 / 3,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/18",
        topLeftX: 2 / 3,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/18",
        topLeftX: 0,
        topLeftY: 1 / 6,
      },
      {
        shape: "blockHorizontal_1/18",
        topLeftX: 1 / 3,
        topLeftY: 1 / 6,
      },
      {
        shape: "blockHorizontal_1/18",
        topLeftX: 2 / 3,
        topLeftY: 1 / 6,
      },
      {
        shape: "blockHorizontal_1/18",
        topLeftX: 0,
        topLeftY: 2 / 6,
      },
      {
        shape: "blockHorizontal_1/18",
        topLeftX: 1 / 3,
        topLeftY: 2 / 6,
      },
      {
        shape: "blockHorizontal_1/18",
        topLeftX: 2 / 3,
        topLeftY: 2 / 6,
      },
      {
        shape: "blockHorizontal_1/18",
        topLeftX: 0,
        topLeftY: 3 / 6,
      },
      {
        shape: "blockHorizontal_1/18",
        topLeftX: 1 / 3,
        topLeftY: 3 / 6,
      },
      {
        shape: "blockHorizontal_1/18",
        topLeftX: 2 / 3,
        topLeftY: 3 / 6,
      },
      {
        shape: "blockHorizontal_1/18",
        topLeftX: 0,
        topLeftY: 4 / 6,
      },
      {
        shape: "blockHorizontal_1/18",
        topLeftX: 1 / 3,
        topLeftY: 4 / 6,
      },
      {
        shape: "blockHorizontal_1/18",
        topLeftX: 2 / 3,
        topLeftY: 4 / 6,
      },
      {
        shape: "blockHorizontal_1/18",
        topLeftX: 0,
        topLeftY: 5 / 6,
      },
      {
        shape: "blockHorizontal_1/18",
        topLeftX: 1 / 3,
        topLeftY: 5 / 6,
      },
      {
        shape: "blockHorizontal_1/18",
        topLeftX: 2 / 3,
        topLeftY: 5 / 6,
      },
    ],
  },
  {
    name: "ThreeBottomHorizontalsSplitThirds",
    columns: 3,
    fragments: [
      {
        shape: "horizontal_1/2",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/18",
        topLeftX: 0,
        topLeftY: 3 / 6,
      },
      {
        shape: "blockHorizontal_1/18",
        topLeftX: 1 / 3,
        topLeftY: 3 / 6,
      },
      {
        shape: "blockHorizontal_1/18",
        topLeftX: 2 / 3,
        topLeftY: 3 / 6,
      },
      {
        shape: "blockHorizontal_1/18",
        topLeftX: 0,
        topLeftY: 4 / 6,
      },
      {
        shape: "blockHorizontal_1/18",
        topLeftX: 1 / 3,
        topLeftY: 4 / 6,
      },
      {
        shape: "blockHorizontal_1/18",
        topLeftX: 2 / 3,
        topLeftY: 4 / 6,
      },
      {
        shape: "blockHorizontal_1/18",
        topLeftX: 0,
        topLeftY: 5 / 6,
      },
      {
        shape: "blockHorizontal_1/18",
        topLeftX: 1 / 3,
        topLeftY: 5 / 6,
      },
      {
        shape: "blockHorizontal_1/18",
        topLeftX: 2 / 3,
        topLeftY: 5 / 6,
      },
    ],
  },
  {
    name: "ThreeByThree",
    columns: 3,
    fragments: [
      {
        shape: "square_1/9",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "square_1/9",
        topLeftX: 1 / 3,
        topLeftY: 0,
      },
      {
        shape: "square_1/9",
        topLeftX: 2 / 3,
        topLeftY: 0,
      },
      {
        shape: "square_1/9",
        topLeftX: 0,
        topLeftY: 1 / 3,
      },
      {
        shape: "square_1/9",
        topLeftX: 1 / 3,
        topLeftY: 1 / 3,
      },
      {
        shape: "square_1/9",
        topLeftX: 2 / 3,
        topLeftY: 1 / 3,
      },
      {
        shape: "square_1/9",
        topLeftX: 0,
        topLeftY: 2 / 3,
      },
      {
        shape: "square_1/9",
        topLeftX: 1 / 3,
        topLeftY: 2 / 3,
      },
      {
        shape: "square_1/9",
        topLeftX: 2 / 3,
        topLeftY: 2 / 3,
      },
    ],
  },
  {
    name: "ThreeByFive",
    columns: 3,
    fragments: [
      {
        shape: "blockHorizontal_1/15",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/15",
        topLeftX: 1 / 3,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/15",
        topLeftX: 2 / 3,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/15",
        topLeftX: 0,
        topLeftY: 1 / 5,
      },
      {
        shape: "blockHorizontal_1/15",
        topLeftX: 1 / 3,
        topLeftY: 1 / 5,
      },
      {
        shape: "blockHorizontal_1/15",
        topLeftX: 2 / 3,
        topLeftY: 1 / 5,
      },
      {
        shape: "blockHorizontal_1/15",
        topLeftX: 0,
        topLeftY: 2 / 5,
      },
      {
        shape: "blockHorizontal_1/15",
        topLeftX: 1 / 3,
        topLeftY: 2 / 5,
      },
      {
        shape: "blockHorizontal_1/15",
        topLeftX: 2 / 3,
        topLeftY: 2 / 5,
      },
      {
        shape: "blockHorizontal_1/15",
        topLeftX: 0,
        topLeftY: 3 / 5,
      },
      {
        shape: "blockHorizontal_1/15",
        topLeftX: 1 / 3,
        topLeftY: 3 / 5,
      },
      {
        shape: "blockHorizontal_1/15",
        topLeftX: 2 / 3,
        topLeftY: 3 / 5,
      },
      {
        shape: "blockHorizontal_1/15",
        topLeftX: 0,
        topLeftY: 4 / 5,
      },
      {
        shape: "blockHorizontal_1/15",
        topLeftX: 1 / 3,
        topLeftY: 4 / 5,
      },
      {
        shape: "blockHorizontal_1/15",
        topLeftX: 2 / 3,
        topLeftY: 4 / 5,
      },
    ],
  },
  {
    name: "ThreeByFour",
    columns: 3,
    fragments: [
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 2 / 3,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 0,
        topLeftY: 0.25,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0.25,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 2 / 3,
        topLeftY: 0.25,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 0,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 2 / 3,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 0,
        topLeftY: 0.75,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0.75,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 2 / 3,
        topLeftY: 0.75,
      },
    ],
  },
  {
    name: "ThirdsGridHalfSixthLeft",
    columns: 3,
    fragments: [
      {
        shape: "blockVertical_5/9",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/9",
        topLeftX: 0,
        topLeftY: 5 / 6,
      },
      {
        shape: "vertical_1/3",
        topLeftX: 2 / 3,
        topLeftY: 0,
      },
    ],
  },
  {
    name: "ThirdsGridHalfSixthRight",
    columns: 3,
    fragments: [
      {
        shape: "blockVertical_5/9",
        topLeftX: 1 / 3,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/9",
        topLeftX: 1 / 3,
        topLeftY: 5 / 6,
      },
      {
        shape: "vertical_1/3",
        topLeftX: 0,
        topLeftY: 0,
      },
    ],
  },
  {
    name: "ThirdsGridLeftStripSixthBannerTop",
    columns: 3,
    fragments: [
      {
        shape: "horizontal_1/6",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockVertical_5/18",
        topLeftX: 0,
        topLeftY: 1 / 6,
      },
      {
        shape: "blockVertical_5/9",
        topLeftX: 1 / 3,
        topLeftY: 1 / 6,
      },
    ],
  },
  {
    name: "ThirdsGridHalfSixthTopRight",
    columns: 3,
    fragments: [
      {
        shape: "blockHorizontal_1/3",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockVertical_1/6",
        topLeftX: 2 / 3,
        topLeftY: 0,
      },
      {
        shape: "horizontal_1/2",
        topLeftX: 0,
        topLeftY: 0.5,
      },
    ],
  },
  {
    name: "ThirdsGridHalfSixthTopLeft",
    columns: 3,
    fragments: [
      {
        shape: "blockVertical_1/6",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/3",
        topLeftX: 1 / 3,
        topLeftY: 0,
      },
      {
        shape: "horizontal_1/2",
        topLeftX: 0,
        topLeftY: 0.5,
      },
    ],
  },
  {
    name: "ThirdsGridHalfTwelfthsTopRight",
    columns: 3,
    fragments: [
      {
        shape: "blockVertical_1/6",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 2 / 3,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0.25,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 2 / 3,
        topLeftY: 0.25,
      },
      {
        shape: "horizontal_1/2",
        topLeftX: 0,
        topLeftY: 0.5,
      },
    ],
  },
  {
    name: "ThirdsGridHalfSixthBottomRight",
    columns: 3,
    fragments: [
      {
        shape: "horizontal_1/2",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/3",
        topLeftX: 0,
        topLeftY: 0.5,
      },
      {
        shape: "blockVertical_1/6",
        topLeftX: 2 / 3,
        topLeftY: 0.5,
      },
    ],
  },
  {
    name: "ThirdsGridHalfSixthBottomLeft",
    columns: 3,
    fragments: [
      {
        shape: "horizontal_1/2",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockVertical_1/6",
        topLeftX: 0,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/3",
        topLeftX: 1 / 3,
        topLeftY: 0.5,
      },
    ],
  },
  {
    name: "ThirdsGridVerticalLeftSquareTopSixths",
    columns: 3,
    fragments: [
      {
        shape: "vertical_1/3",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/3",
        topLeftX: 1 / 3,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/6_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/6_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0.75,
      },
    ],
  },
  {
    name: "ThirdsGridVerticalRightSquareTopSixths",
    columns: 3,
    fragments: [
      {
        shape: "blockHorizontal_1/3",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/6_3cols",
        topLeftX: 0,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/6_3cols",
        topLeftX: 0,
        topLeftY: 0.75,
      },
      {
        shape: "vertical_1/3",
        topLeftX: 2 / 3,
        topLeftY: 0,
      },
    ],
  },
  {
    name: "ThirdsGridVerticalLeftVerticalSixths",
    columns: 3,
    fragments: [
      {
        shape: "vertical_1/3",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockVertical_1/6_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0,
      },
      {
        shape: "blockVertical_1/6_3cols",
        topLeftX: 2 / 3,
        topLeftY: 0,
      },
      {
        shape: "blockVertical_1/6_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0.5,
      },
      {
        shape: "blockVertical_1/6_3cols",
        topLeftX: 2 / 3,
        topLeftY: 0.5,
      },
    ],
  },
  {
    name: "ThirdsGridVerticalRightVerticalSixths",
    columns: 3,
    fragments: [
      {
        shape: "blockVertical_1/6_3cols",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockVertical_1/6_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0,
      },
      {
        shape: "blockVertical_1/6_3cols",
        topLeftX: 0,
        topLeftY: 0.5,
      },
      {
        shape: "blockVertical_1/6_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0.5,
      },
      {
        shape: "vertical_1/3",
        topLeftX: 2 / 3,
        topLeftY: 0,
      },
    ],
  },
  {
    name: "ThirdsGridVerticalLeftSquareTopVerticalSixths",
    columns: 3,
    fragments: [
      {
        shape: "vertical_1/3",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/3",
        topLeftX: 1 / 3,
        topLeftY: 0,
      },
      {
        shape: "blockVertical_1/6_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0.5,
      },
      {
        shape: "blockVertical_1/6_3cols",
        topLeftX: 2 / 3,
        topLeftY: 0.5,
      },
    ],
  },
  {
    name: "ThirdsGridVerticalRightSquareTopVerticalSixths",
    columns: 3,
    fragments: [
      {
        shape: "blockHorizontal_1/3",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockVertical_1/6_3cols",
        topLeftX: 0,
        topLeftY: 0.5,
      },
      {
        shape: "blockVertical_1/6_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0.5,
      },
      {
        shape: "vertical_1/3",
        topLeftX: 2 / 3,
        topLeftY: 0,
      },
    ],
  },
  {
    name: "ThirdLeftsTwoQuartersTopRightTwoTwelfthsRight",
    columns: 3,
    fragments: [
      {
        shape: "vertical_1/3",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/4_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/4_3cols",
        topLeftX: 1 / 3,
        topLeftY: 3 / 8,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 1 / 3,
        topLeftY: 3 / 4,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 2 / 3,
        topLeftY: 3 / 4,
      },
    ],
  },
  {
    name: "ThirdsGridVerticalLeftVerticalHorizontalSixthsTop",
    columns: 3,
    fragments: [
      {
        shape: "vertical_1/3",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/6_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/6_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0.25,
      },
      {
        shape: "blockVertical_1/6_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0.5,
      },
      {
        shape: "blockVertical_1/6_3cols",
        topLeftX: 2 / 3,
        topLeftY: 0.5,
      },
    ],
  },
  {
    name: "ThirdsGridVerticalRightVerticalHorizontalSixthsTop",
    columns: 3,
    fragments: [
      {
        shape: "blockHorizontal_1/6_3cols",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/6_3cols",
        topLeftX: 0,
        topLeftY: 0.25,
      },
      {
        shape: "blockVertical_1/6_3cols",
        topLeftX: 0,
        topLeftY: 0.5,
      },
      {
        shape: "blockVertical_1/6_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0.5,
      },
      {
        shape: "vertical_1/3",
        topLeftX: 2 / 3,
        topLeftY: 0,
      },
    ],
  },
  {
    name: "ThirdsGridVerticalLeftSixthsTwelfthsInThirdRow",
    columns: 3,
    fragments: [
      {
        shape: "vertical_1/3",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/6_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/6_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0.25,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 2 / 3,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/6_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0.75,
      },
    ],
  },
  {
    name: "ThirdsGridVerticalRightSixthsTwelfthsInSecondRow",
    columns: 3,
    fragments: [
      {
        shape: "blockHorizontal_1/6_3cols",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 0,
        topLeftY: 0.25,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0.25,
      },
      {
        shape: "blockHorizontal_1/6_3cols",
        topLeftX: 0,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/6_3cols",
        topLeftX: 0,
        topLeftY: 0.75,
      },
      {
        shape: "vertical_1/3",
        topLeftX: 2 / 3,
        topLeftY: 0,
      },
    ],
  },
  {
    name: "FourSixthsTwelfthsInBottomRow",
    columns: 3,
    fragments: [
      {
        shape: "blockVertical_1/6_3cols",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/6_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/6_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0.25,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 0,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/6_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 0,
        topLeftY: 0.75,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0.75,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 2 / 3,
        topLeftY: 0.75,
      },
    ],
  },
  {
    name: "ThirdsGridVerticalLeftSixthsTwelfthsMiddle",
    columns: 3,
    fragments: [
      {
        shape: "vertical_1/3",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/6_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0.25,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 2 / 3,
        topLeftY: 0.25,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 2 / 3,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/6_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0.75,
      },
    ],
  },
  {
    name: "ThirdsGridVerticalRightSixthsTwelfthsMiddle",
    columns: 3,
    fragments: [
      {
        shape: "blockHorizontal_1/6_3cols",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 0,
        topLeftY: 0.25,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0.25,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 0,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/6_3cols",
        topLeftX: 0,
        topLeftY: 0.75,
      },
      {
        shape: "vertical_1/3",
        topLeftX: 2 / 3,
        topLeftY: 0,
      },
    ],
  },
  {
    name: "ThirdsGridVerticalLeftSixthTopTwelfths",
    columns: 3,
    fragments: [
      {
        shape: "vertical_1/3",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/6_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0.25,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 2 / 3,
        topLeftY: 0.25,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 2 / 3,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0.75,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 2 / 3,
        topLeftY: 0.75,
      },
    ],
  },
  {
    name: "ThirdsGridVerticalRightSixthTopTwelfths",
    columns: 3,
    fragments: [
      {
        shape: "blockHorizontal_1/6_3cols",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 0,
        topLeftY: 0.25,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0.25,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 0,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0.75,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 0,
        topLeftY: 0.75,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0.5,
      },
      {
        shape: "vertical_1/3",
        topLeftX: 2 / 3,
        topLeftY: 0,
      },
    ],
  },
  {
    name: "ThirdsGridVerticalLeftTwelfths",
    columns: 3,
    fragments: [
      {
        shape: "vertical_1/3",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 2 / 3,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0.25,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 2 / 3,
        topLeftY: 0.25,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 2 / 3,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0.75,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 2 / 3,
        topLeftY: 0.75,
      },
    ],
  },
  {
    name: "ThirdsGridVerticalRightTwelfths",
    columns: 3,
    fragments: [
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 0,
        topLeftY: 0.25,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0.25,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 0,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0.75,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 0,
        topLeftY: 0.75,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0.5,
      },
      {
        shape: "vertical_1/3",
        topLeftX: 2 / 3,
        topLeftY: 0,
      },
    ],
  },
  {
    name: "TwoBottomHorizontalsSplitThirds",
    columns: 3,
    fragments: [
      {
        shape: "horizontal_1/2",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 0,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 2 / 3,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 0,
        topLeftY: 0.75,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0.75,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 2 / 3,
        topLeftY: 0.75,
      },
    ],
  },
  {
    name: "SevenTwelfthsTopRightCorner",
    columns: 3,
    fragments: [
      {
        shape: "topLeftCorner_5/12_fat",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 2 / 3,
        topLeftY: 0.25,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 0,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 2 / 3,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 0,
        topLeftY: 0.75,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0.75,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 2 / 3,
        topLeftY: 0.75,
      },
    ],
  },
  {
    name: "SixTwelfthsBottomSixthsTopVerticalLeft",
    columns: 3,
    fragments: [
      {
        shape: "blockVertical_1/6_3cols",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/6_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/6_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0.25,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 0,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 2 / 3,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 0,
        topLeftY: 0.75,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0.75,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 2 / 3,
        topLeftY: 0.75,
      },
    ],
  },
  {
    name: "ThirdsGridVerticalLeftThirdTwelfths",
    columns: 3,
    fragments: [
      {
        shape: "vertical_2/3",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 2 / 3,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 2 / 3,
        topLeftY: 0.25,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 2 / 3,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 2 / 3,
        topLeftY: 0.75,
      },
    ],
  },
  {
    name: "ThirdsGridVerticalRightThirdTwelfths",
    columns: 3,
    fragments: [
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 0,
        topLeftY: 0.25,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 0,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 0,
        topLeftY: 0.75,
      },
      {
        shape: "vertical_2/3",
        topLeftX: 1 / 3,
        topLeftY: 0,
      },
    ],
  },
  {
    name: "ThirdsGridVerticalLeftThirdVerticalSixths",
    columns: 3,
    fragments: [
      {
        shape: "vertical_2/3",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockVertical_1/6_3cols",
        topLeftX: 2 / 3,
        topLeftY: 0,
      },
      {
        shape: "blockVertical_1/6_3cols",
        topLeftX: 2 / 3,
        topLeftY: 0.5,
      },
    ],
  },
  {
    name: "ThirdsGridVerticalRightThirdVerticalSixths",
    columns: 3,
    fragments: [
      {
        shape: "blockVertical_1/6_3cols",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockVertical_1/6_3cols",
        topLeftX: 0,
        topLeftY: 0.5,
      },
      {
        shape: "vertical_2/3",
        topLeftX: 1 / 3,
        topLeftY: 0,
      },
    ],
  },
  {
    name: "ThirdsGridVerticalLeftThirdSixthAndTwelfthsTop",
    columns: 3,
    fragments: [
      {
        shape: "vertical_2/3",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 2 / 3,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 2 / 3,
        topLeftY: 0.25,
      },
      {
        shape: "blockVertical_1/6_3cols",
        topLeftX: 2 / 3,
        topLeftY: 0.5,
      },
    ],
  },
  {
    name: "ThirdsGridVerticalRightThirdSixthAndTwelfthsTop",
    columns: 3,
    fragments: [
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 0,
        topLeftY: 0.25,
      },
      {
        shape: "blockVertical_1/6_3cols",
        topLeftX: 0,
        topLeftY: 0.5,
      },
      {
        shape: "vertical_2/3",
        topLeftX: 1 / 3,
        topLeftY: 0,
      },
    ],
  },
  {
    name: "ThirdsGridSixthVerticalsLeftSixthHorizontalsTopRight",
    columns: 3,
    fragments: [
      {
        shape: "blockVertical_1/6_3cols",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/6_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/6_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0.25,
      },
      {
        shape: "blockVertical_1/6_3cols",
        topLeftX: 0,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/3",
        topLeftX: 1 / 3,
        topLeftY: 0.5,
      },
    ],
  },
  {
    name: "ThirdsGridSixthVerticalsRightSixthHorizontalsTopLeft",
    columns: 3,
    fragments: [
      {
        shape: "blockHorizontal_1/6_3cols",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/6_3cols",
        topLeftX: 0,
        topLeftY: 0.25,
      },
      {
        shape: "blockVertical_1/6_3cols",
        topLeftX: 2 / 3,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/3",
        topLeftX: 0,
        topLeftY: 0.5,
      },
      {
        shape: "blockVertical_1/6_3cols",
        topLeftX: 2 / 3,
        topLeftY: 0.5,
      },
    ],
  },
  {
    name: "ThirdsBottom",
    columns: 3,
    fragments: [
      {
        shape: "horizontal_1/2",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockVertical_1/6_3cols",
        topLeftX: 0,
        topLeftY: 0.5,
      },
      {
        shape: "blockVertical_1/6_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0.5,
      },
      {
        shape: "blockVertical_1/6_3cols",
        topLeftX: 2 / 3,
        topLeftY: 0.5,
      },
    ],
  },
  {
    name: "ThirdsTop",
    columns: 3,
    fragments: [
      {
        shape: "blockVertical_1/6_3cols",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockVertical_1/6_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0,
      },
      {
        shape: "blockVertical_1/6_3cols",
        topLeftX: 2 / 3,
        topLeftY: 0,
      },
      {
        shape: "horizontal_1/2",
        topLeftX: 0,
        topLeftY: 0.5,
      },
    ],
  },
  {
    name: "SixthsBottomTwoQuartersTop",
    columns: 3,
    fragments: [
      {
        shape: "square_1/4",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "square_1/4",
        topLeftX: 0.5,
        topLeftY: 0,
      },
      {
        shape: "blockVertical_1/6_3cols",
        topLeftX: 0,
        topLeftY: 0.5,
      },
      {
        shape: "blockVertical_1/6_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0.5,
      },
      {
        shape: "blockVertical_1/6_3cols",
        topLeftX: 2 / 3,
        topLeftY: 0.5,
      },
    ],
  },
  {
    name: "ThirdsTopBottom",
    columns: 3,
    fragments: [
      {
        shape: "blockVertical_1/6_3cols",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockVertical_1/6_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0,
      },
      {
        shape: "blockVertical_1/6_3cols",
        topLeftX: 2 / 3,
        topLeftY: 0,
      },
      {
        shape: "blockVertical_1/6_3cols",
        topLeftX: 0,
        topLeftY: 0.5,
      },
      {
        shape: "blockVertical_1/6_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0.5,
      },
      {
        shape: "blockVertical_1/6_3cols",
        topLeftX: 2 / 3,
        topLeftY: 0.5,
      },
    ],
  },
  {
    name: "SixthsRightSixthMiddle",
    columns: 3,
    fragments: [
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockVertical_1/6_3cols",
        topLeftX: 0,
        topLeftY: 0.25,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 0,
        topLeftY: 0.75,
      },
      {
        shape: "blockVertical_1/6_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0,
      },
      {
        shape: "blockVertical_1/6_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0.5,
      },
      {
        shape: "blockVertical_1/6_3cols",
        topLeftX: 2 / 3,
        topLeftY: 0,
      },
      {
        shape: "blockVertical_1/6_3cols",
        topLeftX: 2 / 3,
        topLeftY: 0.5,
      },
    ],
  },
  {
    name: "SixthsLeftSixthTop",
    columns: 3,
    fragments: [
      {
        shape: "blockVertical_1/6_3cols",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockVertical_1/6_3cols",
        topLeftX: 0,
        topLeftY: 0.5,
      },
      {
        shape: "blockVertical_1/6_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0,
      },
      {
        shape: "blockVertical_1/6_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0.5,
      },
      {
        shape: "blockVertical_1/6_3cols",
        topLeftX: 2 / 3,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 2 / 3,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 2 / 3,
        topLeftY: 0.75,
      },
    ],
  },
  {
    name: "SixthsThirdSquareBottomRight",
    columns: 3,
    fragments: [
      {
        shape: "vertical_1/3",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockVertical_1/6_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0,
      },
      {
        shape: "blockVertical_1/6_3cols",
        topLeftX: 2 / 3,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/3",
        topLeftX: 1 / 3,
        topLeftY: 0.5,
      },
    ],
  },
  {
    name: "SixthsThirdSquareBottomLeft",
    columns: 3,
    fragments: [
      {
        shape: "blockVertical_1/6_3cols",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockVertical_1/6_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/3",
        topLeftX: 0,
        topLeftY: 0.5,
      },
      {
        shape: "vertical_1/3",
        topLeftX: 2 / 3,
        topLeftY: 0,
      },
    ],
  },
  {
    name: "HalfBottomSixthsTopVerticalLeft",
    columns: 3,
    fragments: [
      {
        shape: "blockVertical_1/6_3cols",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/6_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/6_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0.25,
      },
      {
        shape: "horizontal_1/2",
        topLeftX: 0,
        topLeftY: 0.5,
      },
    ],
  },
  {
    name: "HalfBottomSixthsTopVerticalRight",
    columns: 3,
    fragments: [
      {
        shape: "blockHorizontal_1/6_3cols",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/6_3cols",
        topLeftX: 0,
        topLeftY: 0.25,
      },
      {
        shape: "blockVertical_1/6_3cols",
        topLeftX: 2 / 3,
        topLeftY: 0,
      },
      {
        shape: "horizontal_1/2",
        topLeftX: 0,
        topLeftY: 0.5,
      },
    ],
  },
  {
    name: "TopHalfBottomSixthsVerticalRight",
    columns: 3,
    fragments: [
      {
        shape: "horizontal_1/2",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/6_3cols",
        topLeftX: 0,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/6_3cols",
        topLeftX: 0,
        topLeftY: 0.75,
      },
      {
        shape: "blockVertical_1/6_3cols",
        topLeftX: 2 / 3,
        topLeftY: 0.5,
      },
    ],
  },
  {
    name: "TopHalfBottomSixthsVerticalLeft",
    columns: 3,
    fragments: [
      {
        shape: "horizontal_1/2",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockVertical_1/6_3cols",
        topLeftX: 0,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/6_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/6_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0.75,
      },
    ],
  },
  {
    name: "TopQuartersVerticalLeftVerticalSixthBottomRightSixthAndTwelfths",
    columns: 3,
    fragments: [
      {
        shape: "square_1/4",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "square_1/4",
        topLeftX: 0.5,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 0,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0.5,
      },
      {
        shape: "blockVertical_1/6_3cols",
        topLeftX: 2 / 3,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/6_3cols",
        topLeftX: 0,
        topLeftY: 0.75,
      },
    ],
  },
  {
    name: "CornerThirdSquareBottomLeft",
    columns: 3,
    fragments: [
      {
        shape: "topRightCorner_2/3",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/3",
        topLeftX: 0,
        topLeftY: 0.5,
      },
    ],
  },
  {
    name: "CornerThirdSquareBottomRight",
    columns: 3,
    fragments: [
      {
        shape: "topLeftCorner_2/3",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/3",
        topLeftX: 1 / 3,
        topLeftY: 0.5,
      },
    ],
  },
  {
    name: "CornerSixthsSquareBottomLeft",
    columns: 3,
    fragments: [
      {
        shape: "topRightCorner_2/3",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/6_3cols",
        topLeftX: 0,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/6_3cols",
        topLeftX: 0,
        topLeftY: 0.75,
      },
    ],
  },
  {
    name: "CornerSixthsSquareBottomRight",
    columns: 3,
    fragments: [
      {
        shape: "topLeftCorner_2/3",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/6_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/6_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0.75,
      },
    ],
  },
  {
    name: "CornerThreeSixthsBottomLeft",
    columns: 3,
    fragments: [
      {
        shape: "topRightCorner_1/2_3cols",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/6_3cols",
        topLeftX: 0,
        topLeftY: 0.25,
      },
      {
        shape: "blockHorizontal_1/6_3cols",
        topLeftX: 0,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/6_3cols",
        topLeftX: 0,
        topLeftY: 0.75,
      },
    ],
  },
  {
    name: "CornerThreeSixthsBottomRight",
    columns: 3,
    fragments: [
      {
        shape: "topLeftCorner_1/2_3cols",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/6_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0.25,
      },
      {
        shape: "blockHorizontal_1/6_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/6_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0.75,
      },
    ],
  },
  {
    name: "CornerVerticalSixthBottomLeft",
    columns: 3,
    fragments: [
      {
        shape: "topRightCorner_5/6",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockVertical_1/6_3cols",
        topLeftX: 0,
        topLeftY: 0.5,
      },
    ],
  },
  {
    name: "CornerVerticalSixthBottomRight",
    columns: 3,
    fragments: [
      {
        shape: "topLeftCorner_5/6",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockVertical_1/6_3cols",
        topLeftX: 2 / 3,
        topLeftY: 0.5,
      },
    ],
  },
  {
    name: "CornerHalfIslandBottomLeft",
    columns: 3,
    fragments: [
      {
        shape: "topRightCorner_1/2_3cols",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockVertical_1/2",
        topLeftX: 0,
        topLeftY: 0.25,
      },
    ],
  },
  {
    name: "CornerHalfIslandBottomRight",
    columns: 3,
    fragments: [
      {
        shape: "topLeftCorner_1/2_3cols",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockVertical_1/2",
        topLeftX: 1 / 3,
        topLeftY: 0.25,
      },
    ],
  },
  {
    name: "CornerHalfIslandTopLeft",
    columns: 3,
    fragments: [
      {
        shape: "blockVertical_1/2",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "bottomRightCorner_1/2_3cols",
        topLeftX: 0,
        topLeftY: 0,
      },
    ],
  },
  {
    name: "CornerHalfIslandTopRight",
    columns: 3,
    fragments: [
      {
        shape: "bottomLeftCorner_1/2_3cols",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockVertical_1/2",
        topLeftX: 1 / 3,
        topLeftY: 0,
      },
    ],
  },
  {
    name: "TwoThirdSquaresSixthsVerticalsRight",
    columns: 3,
    fragments: [
      {
        shape: "blockHorizontal_1/3",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockVertical_1/6_3cols",
        topLeftX: 2 / 3,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/3",
        topLeftX: 0,
        topLeftY: 0.5,
      },
      {
        shape: "blockVertical_1/6_3cols",
        topLeftX: 2 / 3,
        topLeftY: 0.5,
      },
    ],
  },
  {
    name: "TwoThirdSquaresSixthsVerticalsLeft",
    columns: 3,
    fragments: [
      {
        shape: "blockVertical_1/6_3cols",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/3",
        topLeftX: 1 / 3,
        topLeftY: 0,
      },
      {
        shape: "blockVertical_1/6_3cols",
        topLeftX: 0,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/3",
        topLeftX: 1 / 3,
        topLeftY: 0.5,
      },
    ],
  },
  {
    name: "ThirdTwoThirdsBricks",
    columns: 3,
    fragments: [
      {
        shape: "blockVertical_1/6_3cols",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/3",
        topLeftX: 1 / 3,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/3",
        topLeftX: 0,
        topLeftY: 0.5,
      },
      {
        shape: "blockVertical_1/6_3cols",
        topLeftX: 2 / 3,
        topLeftY: 0.5,
      },
    ],
  },
  {
    name: "CornerQuarterBottomLeft",
    columns: 3,
    fragments: [
      {
        shape: "topRightCorner_3/4_3cols",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/4_3cols",
        topLeftX: 0,
        topLeftY: 5 / 8,
      },
    ],
  },
  {
    name: "CornerQuarterBottomRight",
    columns: 3,
    fragments: [
      {
        shape: "topLeftCorner_3/4_3cols",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/4_3cols",
        topLeftX: 1 / 3,
        topLeftY: 5 / 8,
      },
    ],
  },
  {
    name: "CornerTwoTwelfthsBottomRight",
    columns: 3,
    fragments: [
      {
        shape: "topLeftCorner_10/12_3cols",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 1 / 3,
        topLeftY: 3 / 4,
      },
      {
        shape: "blockHorizontal_1/12_3cols",
        topLeftX: 2 / 3,
        topLeftY: 3 / 4,
      },
    ],
  },
  {
    name: "CornerQuartersBottomLeft",
    columns: 3,
    fragments: [
      {
        shape: "topRightCorner_1/2_3cols",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/4_3cols",
        topLeftX: 0,
        topLeftY: 0.25,
      },
      {
        shape: "blockHorizontal_1/4_3cols",
        topLeftX: 0,
        topLeftY: 5 / 8,
      },
    ],
  },
  {
    name: "CornerQuartersBottomRight",
    columns: 3,
    fragments: [
      {
        shape: "topLeftCorner_1/2_3cols",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/4_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0.25,
      },
      {
        shape: "blockHorizontal_1/4_3cols",
        topLeftX: 1 / 3,
        topLeftY: 5 / 8,
      },
    ],
  },
  {
    name: "ThirdStripRightTwoQuartersSixthStacked",
    columns: 3,
    fragments: [
      {
        shape: "blockHorizontal_1/6_3cols",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/4_3cols",
        topLeftX: 0,
        topLeftY: 0.25,
      },
      {
        shape: "blockHorizontal_1/4_3cols",
        topLeftX: 0,
        topLeftY: 5 / 8,
      },
      {
        shape: "vertical_1/3",
        topLeftX: 2 / 3,
        topLeftY: 0,
      },
    ],
  },
  {
    name: "ThirdStripLeftTwoQuartersSixthStacked",
    columns: 3,
    fragments: [
      {
        shape: "vertical_1/3",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/6_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/4_3cols",
        topLeftX: 1 / 3,
        topLeftY: 0.25,
      },
      {
        shape: "blockHorizontal_1/4_3cols",
        topLeftX: 1 / 3,
        topLeftY: 5 / 8,
      },
    ],
  },
  {
    name: "CornerQuarterLeftBottomHalf",
    columns: 3,
    fragments: [
      {
        shape: "topRightCorner_1/4_3cols",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/4_3cols",
        topLeftX: 0,
        topLeftY: 1 / 8,
      },
      {
        shape: "horizontal_1/2",
        topLeftX: 0,
        topLeftY: 0.5,
      },
    ],
  },
  {
    name: "CornerQuarterRightBottomHalf",
    columns: 3,
    fragments: [
      {
        shape: "topLeftCorner_1/4_3cols",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/4_3cols",
        topLeftX: 1 / 3,
        topLeftY: 1 / 8,
      },
      {
        shape: "horizontal_1/2",
        topLeftX: 0,
        topLeftY: 0.5,
      },
    ],
  },
  {
    name: "TwoThirdsTopNinthsBottom",
    columns: 3,
    fragments: [
      {
        shape: "horizontal_2/3",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "square_1/9",
        topLeftX: 0,
        topLeftY: 2 / 3,
      },
      {
        shape: "square_1/9",
        topLeftX: 1 / 3,
        topLeftY: 2 / 3,
      },
      {
        shape: "square_1/9",
        topLeftX: 2 / 3,
        topLeftY: 2 / 3,
      },
    ],
  },
  {
    name: "TwoThirdsRightNinthsLeft",
    columns: 3,
    fragments: [
      {
        shape: "square_1/9",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "square_1/9",
        topLeftX: 0,
        topLeftY: 1 / 3,
      },
      {
        shape: "square_1/9",
        topLeftX: 0,
        topLeftY: 2 / 3,
      },
      {
        shape: "vertical_2/3",
        topLeftX: 1 / 3,
        topLeftY: 0,
      },
    ],
  },
  {
    name: "TwoThirdsLeftNinthsRight",
    columns: 3,
    fragments: [
      {
        shape: "vertical_2/3",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "square_1/9",
        topLeftX: 2 / 3,
        topLeftY: 0,
      },
      {
        shape: "square_1/9",
        topLeftX: 2 / 3,
        topLeftY: 1 / 3,
      },
      {
        shape: "square_1/9",
        topLeftX: 2 / 3,
        topLeftY: 2 / 3,
      },
    ],
  },
  {
    name: "TwoThirdColumnsRightNinthsLeft",
    columns: 3,
    fragments: [
      {
        shape: "square_1/9",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "square_1/9",
        topLeftX: 0,
        topLeftY: 1 / 3,
      },
      {
        shape: "square_1/9",
        topLeftX: 0,
        topLeftY: 2 / 3,
      },
      {
        shape: "vertical_1/3",
        topLeftX: 1 / 3,
        topLeftY: 0,
      },
      {
        shape: "vertical_1/3",
        topLeftX: 2 / 3,
        topLeftY: 0,
      },
    ],
  },
  {
    name: "TwoThirdColumnsLeftNinthsRight",
    columns: 3,
    fragments: [
      {
        shape: "vertical_1/3",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "vertical_1/3",
        topLeftX: 1 / 3,
        topLeftY: 0,
      },
      {
        shape: "square_1/9",
        topLeftX: 2 / 3,
        topLeftY: 0,
      },
      {
        shape: "square_1/9",
        topLeftX: 2 / 3,
        topLeftY: 1 / 3,
      },
      {
        shape: "square_1/9",
        topLeftX: 2 / 3,
        topLeftY: 2 / 3,
      },
    ],
  },
  {
    name: "QuarterBottomSixthCornerRight",
    columns: 3,
    fragments: [
      {
        shape: "bottomLeftCorner_7/12",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockVertical_1/6_3cols",
        topLeftX: 2 / 3,
        topLeftY: 0,
      },
      {
        shape: "horizontal_1/4",
        topLeftX: 0,
        topLeftY: 0.75,
      },
    ],
  },
  {
    name: "QuarterBottomSixthCornerLeft",
    columns: 3,
    fragments: [
      {
        shape: "blockVertical_1/6_3cols",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "bottomRightCorner_7/12",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "horizontal_1/4",
        topLeftX: 0,
        topLeftY: 0.75,
      },
    ],
  },
  {
    name: "QuarterLeft",
    columns: 4,
    fragments: [
      {
        shape: "vertical_1/4",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "vertical_3/4",
        topLeftX: 0.25,
        topLeftY: 0,
      },
    ],
  },
  {
    name: "QuarterRight",
    columns: 4,
    fragments: [
      {
        shape: "vertical_3/4",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "vertical_1/4",
        topLeftX: 0.75,
        topLeftY: 0,
      },
    ],
  },
  {
    name: "QuarterVerticals",
    columns: 4,
    fragments: [
      {
        shape: "vertical_1/4",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "vertical_1/4",
        topLeftX: 0.25,
        topLeftY: 0,
      },
      {
        shape: "vertical_1/4",
        topLeftX: 0.5,
        topLeftY: 0,
      },
      {
        shape: "vertical_1/4",
        topLeftX: 0.75,
        topLeftY: 0,
      },
    ],
  },
  {
    name: "Sixteenths",
    columns: 4,
    fragments: [
      {
        shape: "blockHorizontal_1/16",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/16",
        topLeftX: 0.25,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/16",
        topLeftX: 0.5,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/16",
        topLeftX: 0.75,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/16",
        topLeftX: 0,
        topLeftY: 0.25,
      },
      {
        shape: "blockHorizontal_1/16",
        topLeftX: 0.25,
        topLeftY: 0.25,
      },
      {
        shape: "blockHorizontal_1/16",
        topLeftX: 0.5,
        topLeftY: 0.25,
      },
      {
        shape: "blockHorizontal_1/16",
        topLeftX: 0.75,
        topLeftY: 0.25,
      },
      {
        shape: "blockHorizontal_1/16",
        topLeftX: 0,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/16",
        topLeftX: 0.25,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/16",
        topLeftX: 0.5,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/16",
        topLeftX: 0.75,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/16",
        topLeftX: 0,
        topLeftY: 0.75,
      },
      {
        shape: "blockHorizontal_1/16",
        topLeftX: 0.25,
        topLeftY: 0.75,
      },
      {
        shape: "blockHorizontal_1/16",
        topLeftX: 0.5,
        topLeftY: 0.75,
      },
      {
        shape: "blockHorizontal_1/16",
        topLeftX: 0.75,
        topLeftY: 0.75,
      },
    ],
  },
  {
    name: "TwentyFourths",
    columns: 4,
    fragments: [
      {
        shape: "blockHorizontal_1/24",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/24",
        topLeftX: 0.25,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/24",
        topLeftX: 0.5,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/24",
        topLeftX: 0.75,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/24",
        topLeftX: 0,
        topLeftY: 1/6,
      },
      {
        shape: "blockHorizontal_1/24",
        topLeftX: 0.25,
        topLeftY: 1/6,
      },
      {
        shape: "blockHorizontal_1/24",
        topLeftX: 0.5,
        topLeftY: 1/6,
      },
      {
        shape: "blockHorizontal_1/24",
        topLeftX: 0.75,
        topLeftY: 1/6,
      },
      {
        shape: "blockHorizontal_1/24",
        topLeftX: 0,
        topLeftY: 2/6,
      },
      {
        shape: "blockHorizontal_1/24",
        topLeftX: 0.25,
        topLeftY: 2/6,
      },
      {
        shape: "blockHorizontal_1/24",
        topLeftX: 0.5,
        topLeftY: 2/6,
      },
      {
        shape: "blockHorizontal_1/24",
        topLeftX: 0.75,
        topLeftY: 2/6,
      },
      {
        shape: "blockHorizontal_1/24",
        topLeftX: 0,
        topLeftY: 3/6,
      },
      {
        shape: "blockHorizontal_1/24",
        topLeftX: 0.25,
        topLeftY: 3/6,
      },
      {
        shape: "blockHorizontal_1/24",
        topLeftX: 0.5,
        topLeftY: 3/6,
      },
      {
        shape: "blockHorizontal_1/24",
        topLeftX: 0.75,
        topLeftY: 3/6,
      },
      {
        shape: "blockHorizontal_1/24",
        topLeftX: 0,
        topLeftY: 4/6,
      },
      {
        shape: "blockHorizontal_1/24",
        topLeftX: 0.25,
        topLeftY: 4/6,
      },
      {
        shape: "blockHorizontal_1/24",
        topLeftX: 0.5,
        topLeftY: 4/6,
      },
      {
        shape: "blockHorizontal_1/24",
        topLeftX: 0.75,
        topLeftY: 4/6,
      },
      {
        shape: "blockHorizontal_1/24",
        topLeftX: 0,
        topLeftY: 5/6,
      },
      {
        shape: "blockHorizontal_1/24",
        topLeftX: 0.25,
        topLeftY: 5/6,
      },
      {
        shape: "blockHorizontal_1/24",
        topLeftX: 0.5,
        topLeftY: 5/6,
      },
      {
        shape: "blockHorizontal_1/24",
        topLeftX: 0.75,
        topLeftY: 5/6,
      },
    ],
  },
  {
    name: "TwoEighthsLeft",
    columns: 4,
    fragments: [
      {
        shape: "blockVertical_1/8",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockVertical_1/8",
        topLeftX: 0,
        topLeftY: 0.5,
      },
      {
        shape: "vertical_3/4",
        topLeftX: 0.25,
        topLeftY: 0,
      },
    ],
  },
  {
    name: "TwoEighthsRight",
    columns: 4,
    fragments: [
      {
        shape: "vertical_3/4",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockVertical_1/8",
        topLeftX: 0.75,
        topLeftY: 0,
      },
      {
        shape: "blockVertical_1/8",
        topLeftX: 0.75,
        topLeftY: 0.5,
      },
    ],
  },
  {
    name: "BottomEighthsStackedLeftVerticalsRight",
    columns: 4,
    fragments: [
      {
        shape: "horizontal_1/2",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0,
        topLeftY: 0.75,
      },
      {
        shape: "blockVertical_1/8",
        topLeftX: 0.5,
        topLeftY: 0.5,
      },
      {
        shape: "blockVertical_1/8",
        topLeftX: 0.75,
        topLeftY: 0.5,
      },
    ],
  },
  {
    name: "EighthsVerticals",
    columns: 4,
    fragments: [
      {
        shape: "blockVertical_1/8",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockVertical_1/8",
        topLeftX: 0.25,
        topLeftY: 0,
      },
      {
        shape: "blockVertical_1/8",
        topLeftX: 0.5,
        topLeftY: 0,
      },
      {
        shape: "blockVertical_1/8",
        topLeftX: 0.75,
        topLeftY: 0,
      },
      {
        shape: "blockVertical_1/8",
        topLeftX: 0,
        topLeftY: 0.5,
      },
      {
        shape: "blockVertical_1/8",
        topLeftX: 0.25,
        topLeftY: 0.5,
      },
      {
        shape: "blockVertical_1/8",
        topLeftX: 0.5,
        topLeftY: 0.5,
      },
      {
        shape: "blockVertical_1/8",
        topLeftX: 0.75,
        topLeftY: 0.5,
      },
    ],
  },
  {
    name: "FourEighthsVerticalsBottom",
    columns: 4,
    fragments: [
      {
        shape: "horizontal_1/2",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockVertical_1/8",
        topLeftX: 0,
        topLeftY: 0.5,
      },
      {
        shape: "blockVertical_1/8",
        topLeftX: 0.25,
        topLeftY: 0.5,
      },
      {
        shape: "blockVertical_1/8",
        topLeftX: 0.5,
        topLeftY: 0.5,
      },
      {
        shape: "blockVertical_1/8",
        topLeftX: 0.75,
        topLeftY: 0.5,
      },
    ],
  },
  {
    name: "TwoEighthsVerticalsBottomLeft",
    columns: 4,
    fragments: [
      {
        shape: "horizontal_1/2",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockVertical_1/8",
        topLeftX: 0,
        topLeftY: 0.5,
      },
      {
        shape: "blockVertical_1/8",
        topLeftX: 0.25,
        topLeftY: 0.5,
      },
      {
        shape: "square_1/4",
        topLeftX: 0.5,
        topLeftY: 0.5,
      },
    ],
  },
  {
    name: "TwoEighthsVerticalsBottomRight",
    columns: 4,
    fragments: [
      {
        shape: "horizontal_1/2",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "square_1/4",
        topLeftX: 0,
        topLeftY: 0.5,
      },
      {
        shape: "blockVertical_1/8",
        topLeftX: 0.5,
        topLeftY: 0.5,
      },
      {
        shape: "blockVertical_1/8",
        topLeftX: 0.75,
        topLeftY: 0.5,
      },
    ],
  },
  {
    name: "TwoQuartersBottomEighthLeft",
    columns: 4,
    fragments: [
      {
        shape: "blockVertical_1/8",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_3/8",
        topLeftX: 0.25,
        topLeftY: 0,
      },
      {
        shape: "square_1/4",
        topLeftX: 0,
        topLeftY: 0.5,
      },
      {
        shape: "square_1/4",
        topLeftX: 0.5,
        topLeftY: 0.5,
      },
    ],
  },
  {
    name: "TwoQuartersBottomEighthRight",
    columns: 4,
    fragments: [
      {
        shape: "blockHorizontal_3/8",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockVertical_1/8",
        topLeftX: 0.75,
        topLeftY: 0,
      },
      {
        shape: "square_1/4",
        topLeftX: 0,
        topLeftY: 0.5,
      },
      {
        shape: "square_1/4",
        topLeftX: 0.5,
        topLeftY: 0.5,
      },
    ],
  },
  {
    name: "BottomEighthTwoSixteenth",
    columns: 4,
    fragments: [
      {
        shape: "blockHorizontal_3/4",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0,
        topLeftY: 0.75,
      },
      {
        shape: "blockHorizontal_1/16",
        topLeftX: 0.5,
        topLeftY: 0.75,
      },
      {
        shape: "blockHorizontal_1/16",
        topLeftX: 0.75,
        topLeftY: 0.75,
      },
    ],
  },
  {
    name: "SixteenthRowThreeQuarterDown",
    columns: 4,
    fragments: [
      {
        shape: "horizontal_1/2",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/16",
        topLeftX: 0,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/16",
        topLeftX: 0.25,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/16",
        topLeftX: 0.5,
        topLeftY: 0.5,
      },
      {
        shape: "blockHorizontal_1/16",
        topLeftX: 0.75,
        topLeftY: 0.5,
      },
      {
        shape: "horizontal_1/4",
        topLeftX: 0,
        topLeftY: 0.75,
      },
    ],
  },
  {
    name: "SixteenthBottomLeftWithFractionalColRow",
    columns: 4,
    fragments: [
      {
        shape: "blockVertical_3/16",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "square_9/16",
        topLeftX: 0.25,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/16",
        topLeftX: 0,
        topLeftY: 0.75,
      },
      {
        shape: "blockHorizontal_3/16_thin",
        topLeftX: 0.25,
        topLeftY: 0.75,
      },
    ],
  },
  {
    name: "SixteenthBottomLeftRightWithFractionalColRow",
    columns: 4,
    fragments: [
      {
        shape: "blockVertical_3/16",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "square_9/16",
        topLeftX: 0.25,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/16",
        topLeftX: 0,
        topLeftY: 0.75,
      },
      {
        shape: "blockHorizontal_1/8",
        topLeftX: 0.25,
        topLeftY: 0.75,
      },
      {
        shape: "blockHorizontal_1/16",
        topLeftX: 0.75,
        topLeftY: 0.75,
      },
    ],
  },
  {
    name: "TwentyfourthBottomRightThirdCorner",
    columns: 4,
    fragments: [
      {
        shape: "topRightCorner_11/24",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/2",
        topLeftX: 0,
        topLeftY: 1 / 3,
      },
      {
        shape: "blockHorizontal_1/24",
        topLeftX: 0.75,
        topLeftY: 5 / 6,
      },
    ],
  },
  {
    name: "TwentyfourthBottomLeftThirdCorner",
    columns: 4,
    fragments: [
      {
        shape: "topLeftCorner_11/24",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/24",
        topLeftX: 0,
        topLeftY: 5 / 6,
      },
      {
        shape: "blockHorizontal_1/2",
        topLeftX: 0.25,
        topLeftY: 1 / 3,
      },
    ],
  },
  {
    name: "BottomThirdQuarterStripLeft",
    columns: 4,
    fragments: [
      {
        shape: "blockVertical_1/6_4cols",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockHorizontal_1/2",
        topLeftX: 0.25,
        topLeftY: 0,
      },
      {
        shape: "horizontal_1/3",
        topLeftX: 0,
        topLeftY: 2 / 3,
      },
    ],
  },
  {
    name: "BottomThirdQuarterStripRight",
    columns: 4,
    fragments: [
      {
        shape: "blockHorizontal_1/2",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockVertical_1/6_4cols",
        topLeftX: 0.75,
        topLeftY: 0,
      },
      {
        shape: "horizontal_1/3",
        topLeftX: 0,
        topLeftY: 2 / 3,
      },
    ],
  },
  {
    name: "VerticalTwelfthsRight",
    columns: 4,
    fragments: [
      {
        shape: "vertical_3/4",
        topLeftX: 0,
        topLeftY: 0,
      },
      {
        shape: "blockVertical_1/12_4cols",
        topLeftX: 0.75,
        topLeftY: 0,
      },
      {
        shape: "blockVertical_1/12_4cols",
        topLeftX: 0.75,
        topLeftY: 1 / 3,
      },
      {
        shape: "blockVertical_1/12_4cols",
        topLeftX: 0.75,
        topLeftY: 2 / 3,
      },
    ],
  },
]
