<template>
  <div>
    <v-form v-if="editMode">
      <v-textarea label="Notes" v-model="notes" rows="8" data-test-id="edit-notes" />
      <v-btn color="primary" variant="text" @click="editMode = false">View</v-btn>
    </v-form>
    <div v-else>
      <div class="text-body-1 decoded-markup" v-html="decodedNotes" data-test-id="view-notes"></div>
      <v-btn color="primary" class="mt-4" variant="text" @click="editMode = true">Edit</v-btn>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch } from "vue"
import { useRouter } from "vue-router"

import { sessionStorageObj } from "@/utils/storage"
import { decode } from "@/utils/general"

export default {
  name: "EditPageNotes",
  props: {
    pageId: {
      type: String,
      required: true,
    },
  },
  setup(props, context) {
    let router = useRouter()

    // let's fetch the edit in-progress from sessionStorage. The EditPageView component has
    // placed a copy of the original into sessionStorage (Assumption: The EditPageView setup method runs before this setup method).
    // Let's save all changes back to sessionStorage,
    // and the EditPageView will pick it up again to save when done editing.
    let notes = ref(sessionStorageObj.getItem("editPage", "notes").data)

    let editMode = ref(true)

    const saveToLocalStorage = (value) => {
      sessionStorageObj.setItem("editPage", { data: value, dirty: true }, "notes")
      context.emit("dirty")
    }

    const determineEditMode = () => {
      let query = router.currentRoute.value.query
      if (!!query.edit && query.edit) {
        editMode.value = true
      } else {
        editMode.value = !notes.value
      }
    }

    watch(notes, (notes) => {
      saveToLocalStorage(notes)
    })

    determineEditMode()

    return {
      notes,
      editMode,
      decodedNotes: computed(() => decode(notes.value)),
    }
  },
}
</script>

<style lang="scss" scoped></style>
