import { computed } from "vue"
import { useTag } from "@/modules/tag/use"
import { usePage } from "@/modules/page/use"

const pagesTagCount = (pages, tag) => {
  return (
    pages.reduce((acc, page) => {
      return acc + (page.tagRefs.indexOf(tag._id) === -1 ? 0 : 1)
    }, 0) || 0
  )
}

export const useStats = () => {
  let bp_tag = useTag()
  let bp_page = usePage()

  let items = computed(() => {
    let collect = []
    let pages = bp_page.pages.value.filter((p) => !p.isSpace)
    let totalPages = pages.length || 1

    bp_tag.tags.value.forEach((tag) => {
      let count = pagesTagCount(pages, tag)
      let percentage = count / totalPages
      // totalFraction += fraction
      // totalPercentage += percentage
      collect.push({
        tagId: tag._id,
        count,
        percentage,
      })
    })
    return collect
  })

  const detailsFor = (tag) => {
    return items.value.find((item) => item.tagId === tag._id)
  }

  return {
    detailsFor,
  }
}
