<template>
  <div>
    <v-menu v-model="open" :close-on-content-click="false">
      <template v-slot:activator="{ props }">
        <v-btn v-bind="props" size="x-small" variant="text" color="primary"> create new email group </v-btn>
      </template>
      <v-card>
        <v-form ref="form" v-model="formValid" @submit.prevent="saveDialog">
          <EmailGroupFormFields v-model:name="name" v-model:emails="emails" />
          <v-card-actions>
            <v-spacer></v-spacer>
            <cancel-btn @click="closeDialog"> Cancel </cancel-btn>
            <submit-btn type="submit">Save</submit-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-menu>
      
    <span class="text-caption">eg <i>Editorial</i>, or <i>Advertising</i></span>
  </div>
</template>

<script>
import { ref } from "vue"

import { useEmailGroup } from "../use"

import EmailGroupFormFields from "./EmailGroupFormFields"

export default {
  name: "CreateNewEmailGroup",
  components: {
    EmailGroupFormFields,
  },
  setup() {
    let bp_emailGroup = useEmailGroup()

    const form = ref(null)
    let formValid = ref(false)
    let open = ref(false)

    let name = ref("")
    let emails = ref("")

    let closeDialog = () => {
      name.value = ""
      emails.value = ""

      open.value = false
    }

    let saveDialog = () => {
      form.value.validate()

      if (formValid.value || formValid.value === null) { // HACK: first time the value might be null? Bug?
        bp_emailGroup.add({ name: name.value, emails: emails.value })
        .then(() => {
          closeDialog()
        })
        .catch(() => {
          // noop
        })
      }
    }

    return {
      open,
      name,
      emails,
      form,
      formValid,
      saveDialog,
      closeDialog
    }
  },
}
</script>

<style lang="scss" scoped></style>
