import axios from 'axios'
import { setupInterceptors } from '@/lib/axios/interceptors'

const responseTimeout = import.meta.env.VITE_RESPONSE_TIMEOUT || 10000

// Configure axios defaults
axios.defaults.timeout = responseTimeout
axios.defaults.headers.common['Accept'] = 'application/json'
axios.defaults.headers.common['Content-Type'] = 'application/json'
axios.defaults.withCredentials = false

// Add interceptor to set X-Token dynamically
axios.interceptors.request.use(config => {
  config.headers['X-Token'] = sessionStorage.getItem("sessionId")
  return config
})

console.log("Response timeout: " + responseTimeout + "ms")

setupInterceptors(axios)