<template>
  <div class="mb-4">
    <span class="text-heading-6 font-weight-medium">{{ pageCount }} pages</span>
    <div class="d-inline-block ml-2" v-if="coverSections.length > 0 || contentSections.length > 0">
      <span class="text-subtitle-1">Sections</span>
      <div v-if="coverSections.length > 0" class="d-inline-block">
        <span class="text-subtitle-2 ml-2">Cover</span>:
        <SectionStat v-for="section in coverSections" :key="section._id" :section="section" />
      </div>
      <div v-if="contentSections.length > 0" class="d-inline-block">
        <span class="text-subtitle-2 ml-2">Content</span>:
        <SectionStat v-for="section in contentSections" :key="section._id" :section="section" />
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue"

import { useSection } from "../use"
import { usePage } from "@/modules/page/use"

import SectionStat from "./SectionStat"

export default {
  name: "SectionStats",
  components: {
    SectionStat,
  },
  setup() {
    let bp_section = useSection()
    let bp_page = usePage()

    return {
      coverSections: bp_section.coverSections,
      contentSections: bp_section.contentSections,
      pageCount: computed(() => {
        return bp_page.pages.value.filter((p) => !p.isSpace).length
      }),
    }
  },
}
</script>

<style lang="scss" scoped></style>
