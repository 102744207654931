<template>
  <v-tooltip location="bottom">
    <template v-slot:activator="{ props }">
      <v-badge bordered :color="color" dot location="bottom end"
        ><v-avatar v-bind="props" color="grey" size="32">
          <span class="text-white text-caption">{{ user.initials }}</span>
        </v-avatar>
      </v-badge>
    </template>
    <span>{{ user.email }}</span></v-tooltip
  >
</template>

<script>
import { computed } from "vue"

export default {
  name: "PresentUser",
  props: {
    user: {
      type: Object,
      required: true,
    },
    status: {
      type: String,
      required: true,
      default: "idle",
    },
  },
  setup(props) {
    return {
      color: computed(() => {
        return props.status === "active" ? "green" : "orange"
      }),
    }
  },
}
</script>

<style lang="scss" scoped></style>
