import { computed } from "vue"
import { useNotificationStore } from "./store"

export const useNotification = function () {
  const notificationStore = useNotificationStore()
  const { show, hide } = notificationStore

  return {
    show,
    hide,
    type: computed(() => notificationStore.type),
    message: computed(() => notificationStore.message),
  }
}
