<template>
  <div class="mb-6 border-s-thin">
    <div class="ml-2">
      <div class="text-body-1 decoded-markup" v-html="decodedComment"></div>
      <div class="text-caption text-disabled">
        <strong>{{comment.commenter}}</strong> {{ fmtDateTime(comment.meta.createdAt) }}
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue"
import { DateTime } from "luxon"

import { decode } from "@/utils/general"

export default {
  name: "Comment",
  props: {
    comment: {
      type: Object,
      required: true,
      default: () => {
        return {}
      },
    },
  },
  setup(props) {
    let fmtDateTime = (date) => {
      return DateTime.fromISO(date).toFormat("DD t")
    }

    return {
      fmtDateTime,
      decodedComment: computed(() => {
        return decode(props.comment.text)
      }),
    }
  },
}
</script>

<style lang="scss" scoped></style>
