<template>
  <v-toolbar v-bind="$attrs" class="flex-grow-1 rounded-ts-xl rounded-te-xl px-3 mx-3" data-test-id="toolbar">
    <slot></slot>
  </v-toolbar>
</template>

<script>
export default {
  name: "BaseToolbar",
}
</script>

<style lang="scss" scoped></style>
