<template>
  <div class="stitch-in-block">
    <h6 class="text-subtitle-1">Stitch-ins</h6>
    <div class="d-flex justify-start">
      <StitchIn v-for="stitchIn in stitchIns" :key="stitchIn._id" :stitchInId="stitchIn._id" />
    </div>
  </div>
</template>

<script>
import StitchIn from "./StitchIn"

import { useStitchIn } from "@/modules/stitchIn/use"

export default {
  name: "StitchIns",
  components: {
    StitchIn,
  },
  setup() {
    let bp_stitchIn = useStitchIn()

    return {
      stitchIns: bp_stitchIn.stitchIns,
    }
  },
}
</script>

<style lang="scss" scoped>
.stitch-in-block {
  break-inside: avoid;
}
</style>
