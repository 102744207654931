<template>
  <v-card-text>
    <v-text-field label="Name" :model-value="name" @update:modelValue="$emit('update:name', $event)" autofocus required :rules="nameRules" />
    <v-textarea label="Emails" :model-value="emails" @update:modelValue="$emit('update:emails', $event)" required rows="3" :rules="emailsRules" hint="Comma separated email addresses" />
  </v-card-text>
</template>

<script>
export default {
  name: "EmailGroupFormFields",
  props: {
    name: {
      type: String,
      required: true,
    },
    emails: {
      type: String,
      required: true,
    },
  },
  emits: ["update:name", "update:emails"],
  setup() {
    let nameRules = [(v) => !!v || "Name is required."]
    let emailsRules = [(v) => !!v || "At least one email is required."]

    return {
      nameRules,
      emailsRules,
    }
  },
}
</script>

<style lang="scss" scoped></style>
