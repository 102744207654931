import axios from 'axios'
import { useMonitoring } from "@/use/useMonitoring"
import { MetricsLogger } from './logger'
import { ConnectionTester } from './connection-tester'
import { MetricsStore } from './store'
import { MetricTypes } from './types'

export class NetworkMetrics {
  static instance = null

  constructor() {
    this.store = new MetricsStore()
    this.bp_monitoring = useMonitoring()
  }

  static getInstance() {
    if (!NetworkMetrics.instance) {
      NetworkMetrics.instance = new NetworkMetrics()
    }
    return NetworkMetrics.instance
  }

  async measureConnectionDetails() {
    return ConnectionTester.measureConnectionDetails()
  }

  logRequest(config, startTime) {
    const endpoint = `${config.method}:${config.url}`
    
    if (this.bp_monitoring.captureErrors()) {
      MetricsLogger.logToSentry({
        breadcrumb: {
          category: 'http',
          type: 'http',
          level: 'info',
          message: `Starting ${config.method.toUpperCase()} request to ${endpoint}`,
          data: {
            url: config.url,
            method: config.method,
            timestamp: new Date().toISOString()
          }
        }
      })
    }

    return { endpoint, startTime }
  }

  logResponse(endpoint, startTime) {
    const now = Date.now()
    const duration = now - startTime
    
    this.store.addResponseTime({ 
      endpoint, 
      duration,
      timestamp: new Date().toISOString()
    })
    
    // MetricsLogger.logToConsole(
    //   MetricTypes.RESPONSE, 
    //   this.store.data.responseTimes
    // )
  }

  logError(error, endpoint, startTime, additionalContext = {}) {
    // Get metrics
    const metrics = this.store.getRecentMetrics();

    const errorDetails = {
      endpoint,
      duration: Date.now() - startTime,
      isCanceled: error.code === 'ERR_CANCELED',
      isTimeout: error.code === 'ECONNABORTED',
      isNetwork: axios.isAxiosError(error) && !error.response,
      errorCode: error.code,
      errorMessage: error.message,
      status: error.response?.status,
      timestamp: new Date().toISOString(),
      hasResponse: !!error.response,
      hasResponseHeaders: !!error.response?.headers,
      connectionTestResults: error.config?.timing?.connectionDetails,
      requestConfig: {
        method: error.config?.method,
        url: error.config?.url,
        timeout: error.config?.timeout
      },
      // requestLogs: {
      //   recentErrors: metrics.recentErrors,
      //   recentRetries: metrics.recentRetries,
      //   recentResponseTimes: metrics.recentResponseTimes
      // },
      ...additionalContext
    }

    this.store.addError(errorDetails)

    if (this.bp_monitoring.captureErrors()) {

      MetricsLogger.logToSentry(error, {
        tags: {
          endpoint,
          error_type: error.code || 'unknown',
          http_method: error.config?.method,
          http_status: error.response?.status
        },
        contexts: {
          requestConfig: errorDetails.requestConfig,
          requestLogs: errorDetails.requestLogs,
          responseDetails: {
            duration: errorDetails.duration,
            hasResponse: errorDetails.hasResponse,
            hasResponseHeaders: errorDetails.hasResponseHeaders,
          },
          requestLogs: {
            recentErrors: JSON.stringify(metrics.recentErrors, null, 2),
            recentRetries: JSON.stringify(metrics.recentRetries, null, 2),
            recentResponseTimes: JSON.stringify(metrics.recentResponseTimes, null, 2)
          },
          additionalContext,
          connectionDetails: error.config?.timing?.connectionDetails ? 
            JSON.stringify(error.config.timing.connectionDetails, null, 2) : null,
        }
      })
    }

    MetricsLogger.logToConsole(MetricTypes.ERROR, errorDetails)
  }

  logRetry(error, config) {
    if (this.bp_monitoring.captureErrors()) {
      MetricsLogger.logToSentry({
        breadcrumb: {
          category: 'http',
          type: 'http',
          level: 'warning',
          message: `Retrying request`,
          data: {
            url: config.url,
            method: config.method,
            errorCode: error.code,
            timestamp: new Date().toISOString()
          }
        }
      })
    }

    this.store.addRetry({
      endpoint: `${config.method}:${config.url}`,
      errorCode: error.code,
      timestamp: new Date().toISOString()
    })
  }
}