<template>
  <PresentUser class="mr-1 mb-1" v-for="user in users" :key="user.userId" :user="user" :status="status(user)" />
</template>

<script>
import { usePresence } from "../use"

import PresentUser from "./PresentUser"

export default {
  name: "PresentUsers",
  components: {
    PresentUser,
  },
  setup() {
    let bp_presence = usePresence()

    return {
      users: bp_presence.users,
      status: bp_presence.status,
    }
  },
}
</script>

<style lang="scss" scoped></style>
