<template>
  <svg xmlns="http://www.w3.org/2000/svg" :height="height" viewBox="0 0 547.42 125.09">
    <path
      class="cls-1"
      d="M1002.86,239.68c0,4.56,3.23,8.2,7.88,8.2s7.89-3.64,7.89-8.2v-25c0-5.46,3.24-9.51,8.7-9.51s8.7,4.05,8.7,9.51v25a7.9,7.9,0,1,0,15.78,0V210.45c0-12.95-8.7-18.61-19.62-18.61-6.78,0-11,2.12-13.56,6.07a8.5,8.5,0,0,0-7.89-5.06c-4.65,0-7.88,3.64-7.88,8.2Z"
      transform="translate(-504.39 -150.06)"
    />
    <path
      class="cls-1"
      d="M987.29,192.85a8.15,8.15,0,0,0-7.38,5.06c-3.54-4.35-7.59-6.07-13.56-6.07-13.75,0-24.37,12.64-24.37,28.52s10.62,28.53,24.37,28.53c6,0,10-1.72,13.56-6.07a8.15,8.15,0,0,0,7.38,5.06,7.85,7.85,0,0,0,7.89-8.2V201.05A7.85,7.85,0,0,0,987.29,192.85Zm-18.71,40.87c-7,0-10.83-6.07-10.83-13.36S961.6,207,968.58,207s10.82,6.07,10.82,13.35S975.56,233.72,968.58,233.72Z"
      transform="translate(-504.39 -150.06)"
    />
    <path class="cls-1" d="M921.15,239.68a7.9,7.9,0,1,0,15.78,0v-62.1a7.9,7.9,0,1,0-15.78,0Z" transform="translate(-504.39 -150.06)" />
    <path
      class="cls-1"
      d="M891.62,191.84c-6,0-10,1.72-13.56,6.07a8.15,8.15,0,0,0-7.38-5.06,7.85,7.85,0,0,0-7.89,8.2v60.48a7.9,7.9,0,1,0,15.78,0V244.44c2.83,2.32,6.27,4.45,13,4.45,13.75,0,24.37-12.65,24.37-28.53S905.37,191.84,891.62,191.84Zm-2.23,41.88c-7,0-10.82-6.07-10.82-13.36S882.41,207,889.39,207s10.83,6.07,10.83,13.35S896.37,233.72,889.39,233.72Z"
      transform="translate(-504.39 -150.06)"
    />
    <path
      class="cls-1"
      d="M811.8,239.68a7.9,7.9,0,1,0,15.78,0v-17.8h.2l15.78,20.64c3.34,4.24,5,5.36,8.6,5.36a7.41,7.41,0,0,0,7.79-7.29c0-2.32-2.13-5-3.74-7L843,216.82l8.5-8.39c3.74-3.64,5.56-5.46,5.56-8.3a7.35,7.35,0,0,0-7.68-7.28c-3.85,0-6.17,2.73-8.9,5.46l-12.85,12.85V177.58a7.9,7.9,0,1,0-15.78,0Z"
      transform="translate(-504.39 -150.06)"
    />
    <path
      class="cls-1"
      d="M755.19,239.68a7.9,7.9,0,1,0,15.78,0v-25c0-5.46,3.24-9.51,8.7-9.51s8.7,4.05,8.7,9.51v25c0,4.56,3.23,8.2,7.89,8.2s7.88-3.64,7.88-8.2V210.45c0-12.95-8.69-18.61-19.62-18.61-6.77,0-11,2.12-13.55,6.07a8.5,8.5,0,0,0-7.89-5.06,7.85,7.85,0,0,0-7.89,8.2Z"
      transform="translate(-504.39 -150.06)"
    />
    <path class="cls-1" d="M730.54,178.79a9.41,9.41,0,1,0,9.41-9.4,9.39,9.39,0,0,0-9.41,9.4" transform="translate(-504.39 -150.06)" />
    <path class="cls-1" d="M732.06,239.68a7.9,7.9,0,1,0,15.78,0V201.05a7.9,7.9,0,1,0-15.78,0Z" transform="translate(-504.39 -150.06)" />
    <path class="cls-1" d="M708.91,239.68c0,4.56,3.23,8.2,7.89,8.2a7.85,7.85,0,0,0,7.89-8.2v-62.1a7.9,7.9,0,1,0-15.78,0Z" transform="translate(-504.39 -150.06)" />
    <path
      class="cls-1"
      d="M679.37,191.84c-6.77,0-10.21,2.12-13,4.45V177.58a7.9,7.9,0,1,0-15.78,0v62.1c0,4.56,3.23,8.2,7.89,8.2a8.15,8.15,0,0,0,7.38-5.06c3.54,4.35,7.59,6.07,13.55,6.07,13.76,0,24.38-12.65,24.38-28.53S693.13,191.84,679.37,191.84Zm-2.22,41.88c-7,0-10.82-6.07-10.82-13.36S670.17,207,677.15,207,688,213.08,688,220.36,684.13,233.72,677.15,233.72Z"
      transform="translate(-504.39 -150.06)"
    />
    <path class="cls-2" d="M566.93,275.14a62.54,62.54,0,1,0-62.54-62.54,62.54,62.54,0,0,0,62.54,62.54" transform="translate(-504.39 -150.06)" />
    <polygon class="cls-3" points="36.49 32.43 20.89 48.48 20.89 88.25 62.2 88.25 62.2 32.16 36.49 32.43" />
    <polygon class="cls-3" points="88.3 32.43 103.9 48.48 103.9 88.25 62.59 88.25 62.59 32.16 88.3 32.43" />
    <path
      class="cls-1"
      d="M594,178.72H568.89V164.84H565v13.88H539.88l-17.75,17.74v39.13a6.25,6.25,0,0,0,6.24,6.24H565v13.88h3.93V241.83h36.6a6.24,6.24,0,0,0,6.23-6.24V196.46Zm-54.85,9.06v6.61a1.32,1.32,0,0,1-1.33,1.32h-6.61ZM564,236H528.37a.36.36,0,0,1-.36-.36V199.65h9.79a5.27,5.27,0,0,0,5.27-5.26v-9.8H564Zm30.74-48.17,7.93,7.93h-6.6a1.32,1.32,0,0,1-1.33-1.32Zm11.12,47.81a.36.36,0,0,1-.36.36H569.87V184.59h20.92v9.8a5.27,5.27,0,0,0,5.27,5.26h9.79Z"
      transform="translate(-504.39 -150.06)"
    />
  </svg>
</template>

<script>
export default {
  name: "LogoFull",
  props: {
    height: {
      type: Number,
      default: 40,
    },
  },
}
</script>

<style lang="scss" scoped>
.cls-1 {
  fill: #009faf;
}
.cls-2 {
  fill: #8ff;
}
.cls-3 {
  fill: #fff;
}
</style>
