import { computed } from "vue"
import { useInviteStore } from "./store"

export const useInvite = function () {
  const inviteStore = useInviteStore()
  const { getByToken, del, add, fetch, accept } = inviteStore

  // store's state is reactively passed on (computed for readonly) - all state changes should occur within store
  return {
    invites: computed(() => inviteStore.invites),
    getByToken,
    del,
    add,
    fetch,
    accept,
  }
}
