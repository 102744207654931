import { createApp } from "vue"
import { createPinia } from "pinia"

import App from "./App.vue"
import router from "./router"

import "@/initializers"
import "./registerServiceWorker"

import "@/styles/main.css"
import "@/styles/global.css"

import { useAuthStore } from "@/modules/auth/store"
import { usePageSettingStore } from "@/modules/pageSetting/store"

// Plugins
import vuetify from "./plugins/vuetify"

import BaseDialog from "@/components/Base/BaseDialog.vue"
import BaseToolbar from "@/components/Base/BaseToolbar.vue"

const app = createApp(App)

app.component("BaseDialog", BaseDialog)
app.component("BaseToolbar", BaseToolbar)

app.use(createPinia())
app.use(router)
app.use(vuetify)

const authString = localStorage.getItem("auth")
const authStore = useAuthStore()

if (authString) {
  let authData = JSON.parse(authString)
  authStore.setAuth(authData)
}

// init the css styles for the current page size (found in localStorage)
const pageSettingStore = usePageSettingStore()
pageSettingStore.setCssPageStyles()



app.mount("#app")
