import { computed } from "vue"
import { useCategory } from "@/modules/category/use"
import { usePage } from "@/modules/page/use"
import { fragmentSize } from "@/modules/page/render/fragmentUtils"

const pageCategorySize = (page, category) => {
  return (
    page.fragments
      .filter((fragment) => fragment.categoryId === category._id)
      .reduce((acc, fragment) => {
        return acc + fragmentSize(fragment.shape)
      }, 0) || 0
  )
}

const pagesCategorySize = (pages, category) => {
  return (
    pages.reduce((acc, curr) => {
      return acc + pageCategorySize(curr, category)
    }, 0) || 0
  )
}

export const useStats = () => {
  let bp_category = useCategory()
  let bp_page = usePage()

  let items = computed(() => {
    let collect = []
    // let totalFraction = 0
    // let totalPercentage = 0
    let pages = bp_page.pages.value.filter((p) => !p.isSpace)
    let totalPages = pages.length || 1

    bp_category.categories.value.forEach((category) => {
      let fraction = pagesCategorySize(pages, category)
      let percentage = fraction / totalPages
      // totalFraction += fraction
      // totalPercentage += percentage
      collect.push({
        categoryId: category._id,
        fraction,
        percentage,
      })
    })
    return collect
  })

  const detailsFor = (category) => {
    return items.value.find((item) => item.categoryId === category._id)
  }

  return {
    detailsFor,
  }
}
