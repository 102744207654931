<template>
  <base-dialog :model-value="openPicker" @keydown.esc="closeDialog" @click:outside="closeDialog" size="small">
    <v-card-item>
      <v-card-title>Archived Plans</v-card-title>
    </v-card-item>
    <v-card-text>
      <v-alert type="info" class="mb-4">You can view and share archived plans, but you can <strong>no longer edit</strong> them.</v-alert>
      <v-card v-for="flatplan in archived" :key="flatplan._id" @click="changeFlatplan(flatplan)" class="mb-4">
        <v-card-item>
          <v-card-title>{{ flatplan.issue }} </v-card-title>
          <v-card-subtitle>Archived: {{ fmtArchivedDate(flatplan.archivedAt) }}</v-card-subtitle>
        </v-card-item>
      </v-card>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <cancel-btn @click="closeDialog"> Cancel </cancel-btn>
    </v-card-actions>
  </base-dialog>
</template>

<script>
import { useRouter } from "vue-router"
import { DateTime } from "luxon"

import { useFlatplan } from "../use"

export default {
  name: "FlatplanArchivedPicker",
  props: {
    openPicker: {
      type: Boolean,
    },
  },
  emits: ["update:openPicker"],
  setup(props, context) {
    let bp_flatplan = useFlatplan()

    const router = useRouter()

    let closeDialog = () => {
      context.emit("update:openPicker", false)
    }

    let changeFlatplan = (flatplan) => {
      router.push({
        params: { flatplanId: flatplan._id },
      }).then(() => {
        location.reload()
      })
      closeDialog() // we need ths in case we are already on the chosen plan (no route change would have taken place)
    }

    let fmtArchivedDate = (date) => {
      return DateTime.fromISO(date).toFormat("DD, t")
    }

    return {
      archived: bp_flatplan.archived,
      changeFlatplan,
      closeDialog,
      fmtArchivedDate,
    }
  },
}
</script>

<style lang="scss" scoped></style>
