<template>
  <div>
    <NotificationBar v-if="message" :message="message" :type="type" />
  </div>
</template>

<script>
import { useNotification } from "@/modules/notification/use"
import NotificationBar from "./NotificationBar"

export default {
  name: "TheNotificationBar",
  components: {
    NotificationBar,
  },
  setup() {
    const bp_notification = useNotification()

    return {
      type: bp_notification.type,
      message: bp_notification.message,
    }
  },
}
</script>
