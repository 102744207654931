<template>
  <div class="d-flex flex-column justify-center align-center" style="min-height: 100vh">
    <div class="text-h3 text-center">Your browser is not compatible with Blinkplan.</div>
    <div class="text-h5 mt-8">Please use a newer version of either Chrome, Safari, Firefox, Brave, or Edge.</div>
  </div>
</template>

<script>
export default {
  name: "IncompatibleView",
}
</script>
