<template>
  <v-container fluid>
    <h4 class="text-h4 text-error">Welcome to the Danger Zone</h4>
    <h4 class="text-h5 my-10">
      You are deleting account: <strong class="text-warning">{{ accountName }}</strong>
    </h4>
    <div class="mt-4">We’ll be sorry to see you go! You won’t be charged again once you’ve canceled.</div>
    <div class="mt-4">There is <strong>no way</strong> to restore your data once you have cancelled your account.</div>
    <v-alert class="mt-8" color="error" max-width="400">Cancellation is <strong>immediate</strong>. All of your data will be deleted!</v-alert>
    <div class="mt-8"><strong>This is the final step.</strong> Click wisely :)</div>
    <v-btn v-if="!confirm" class="mt-10" color="error" @click="confirm = true">Delete account: {{ accountName }}</v-btn>
    <div v-else>
      <div class="mt-10">Canceling your account is <strong>irreversible!</strong></div>
      <p class="mt-4">
        <v-row>
          <v-col cols="12" sm="4">
            To <strong>delete</strong>, please enter the name of your account <i>exactly</i> as it is shown below the textfield.
            <div class="my-6"><v-text-field label="Confirm account name to delete" v-model="confirmName" :messages="[accountName]" required /></div>
          </v-col>
        </v-row>
      </p>
      <v-btn class="mt-2" color="error" @click="del" :loading="loading" :disabled="!nameConfirmed">Yes, delete all my data!</v-btn>
    </div>
  </v-container>
</template>

<script>
import { ref, computed } from "vue"

import { useBilling } from "../use"
import { useNotification } from "@/modules/notification/use"
import { useAuth } from "@/modules/auth/use"

export default {
  name: "AccountCancelView",
  setup() {
    let bp_billing = useBilling()
    let bp_auth = useAuth()
    let bp_notification = useNotification()

    let confirm = ref(false)
    let loading = ref(false)

    let confirmName = ref("")

    let account = bp_billing.account

    let del = () => {
      loading.value = true
      bp_billing
        .deleteAccount()
        .then(() => {
          bp_notification.show({
            type: "success",
            message: "Ok, good bye :(",
          })
          setTimeout(() => {
            bp_auth.logout({ loggedOutReason: "Your account has been deleted." })
          }, 3000)
        })
        .finally(() => {
          loading.value = false
        })
    }

    let nameConfirmed = computed(() => {
      return bp_billing.accountName.value.replace(/\s/g, "").toLowerCase() === confirmName.value.replace(/\s/g, "").toLowerCase()
    })

    return {
      accountName: bp_billing.accountName,
      account,
      confirm,
      loading,
      del,
      confirmName,
      nameConfirmed,
    }
  },
}
</script>
