<template>
  <v-card color="background" width="205">
    <v-form ref="form" v-model="formValid" @submit.prevent="saveDialog">
      <v-card-text><v-text-field ref="noOfPagesCtrl" type="number" label="# of pages" v-model="noOfPages" autofocus required hint="Copy content across the next # pages" data-label="stretch noOfPages textbox" @focus="$event.target.select()" /></v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <cancel-btn @click="closeDialog">Cancel</cancel-btn>
        <submit-btn type="submit">Copy</submit-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import { ref, onMounted } from "vue"

import { usePage } from "@/modules/page/use"

export default {
  name: "PageStretch",
  props: {
    pageId: {
      type: String,
      required: true,
    },
    open: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:open"],
  setup(props, context) {
    let noOfPagesCtrl = ref(null)
    let bp_page = usePage()

    let form = ref(null)
    let formValid = ref(false)

    let noOfPages = ref(1)

    let closeDialog = () => {
      context.emit("update:open", false)
    }

    let saveDialog = () => {
      form.value.validate()

      if (formValid.value) {
        bp_page.stretch({ pageId: props.pageId, noOfPages: noOfPages.value })
        closeDialog()
      }
    }

    let focusNoOfPagesText = () => {
      noOfPagesCtrl.value.$el.getElementsByTagName("input")[0].focus()
    }

    onMounted(() => {
      setTimeout(() => {
        focusNoOfPagesText()
      }, 50)
    })

    return {
      noOfPagesCtrl,
      form,
      formValid,
      noOfPages,
      saveDialog,
      closeDialog,
    }
  },
}
</script>

<style lang="scss" scoped></style>
