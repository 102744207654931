export default [
  "#c9a87f",
  "#c89d80",
  "#ddb79e",
  "#e3cdb5",
  "#eed7c6",
  "#fac68c",
  "#fcd5ae",
  "#fff988",
  "#fffab4",
  "#fffbcb",
  "#e88f92",
  "#f586c7",
  "#edb5b8",
  "#f8b7d5",
  "#fcded6",
  "#8f9ec6",
  "#afb2d2",
  "#7dd5f7",
  "#88dff9",
  "#c5ebfb",
  "#9ed6a2",
  "#7dc9a1",
  "#a7d5b7",
  "#bee4d1",
  "#d1e7cc",
  "#8f8d8d",
  "#aaaaab",
  "#bebfc1",
  "#d2d3d5",
  "#ffffff",
]
