import { ref } from "vue"
import { defineStore } from "pinia"

export const useNotificationStore = defineStore("notification", () => {
  const type = ref(null)
  const message = ref(null)

  function show(notification) {
    type.value = notification.type
    message.value = notification.message
  }

  function hide() {
    type.value = null
    message.value = null
  }

  return {
    type,
    message,
    show,
    hide,
  }
})
