<template>
  <v-container fluid>
    <h4 class="text-h4">Flatplan Notes</h4>
    <br />
    <v-alert type="info">These notes will also appear on the PDF.</v-alert>
    <v-form @submit.prevent="save" class="mt-4">
      <v-textarea label="Notes" v-model="notes" rows="5" />
      <submit-btn type="submit" class="float-right">Save</submit-btn>
    </v-form>
  </v-container>
</template>

<script>
import { ref } from "vue"

import { useFlatplan } from "../use"
import { useNotification } from "@/modules/notification/use"

export default {
  name: "FlatplanNotesView",
  setup() {
    let bp_flatplan = useFlatplan()
    let bp_notification = useNotification()

    let notes = ref(bp_flatplan.notes.value)

    let save = () => {
      bp_flatplan.update({ notes: notes.value }).then(() => {
        bp_notification.show({
          type: "success",
          message: "Notes saved :)",
        })
      })
    }

    return {
      notes,
      save,
    }
  },
}
</script>

<style lang="scss" scoped></style>
