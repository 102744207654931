<template>
  <v-container fluid>
    <TagsList />
    <router-view name="dialog" />
  </v-container>
</template>

<script>
import TagsList from "./TagsList"

export default {
  name: "TagsShowView",
  props: {
    flatplanId: {
      type: String,
      required: true,
    },
  },
  components: {
    TagsList,
  },
}
</script>
