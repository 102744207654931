import fragments from "./fragments"
import { flatten } from "@/utils/general"

export const fragmentSize = (fragmentName) => {
  let fragment = fragments.find((fragment) => fragment.name === fragmentName)
  let sqWidth = fragment.fractionX / fragment.shapeDrawPlan[0].length
  let sqHeight = fragment.fractionY / fragment.shapeDrawPlan.length
  let sqArea = sqWidth * sqHeight
  let squaresCount = flatten(fragment.shapeDrawPlan).reduce((acc, curr) => {
    return acc + curr
  }, 0)
  return squaresCount * sqArea || 0
}

export const getFragment = (fragmentName) => {
  return fragments.find((fragment) => fragment.name === fragmentName)
}

export const fragmentFullDescription = (size, shape) => {
  shape = (shape || "").toLowerCase()
  if (shape === "l-shape") {
    shape = "L-shape"
  }
  
  switch (size) {
    case "1":
      return "full"
    case "2":
      return "dps"
    default:
      return [size, shape].filter((i) => !!i).join(" ")
  }
}

// const calcAreaFromSize = (size) => {
//   let area = 0

//   if (size == "1") {
//     return 1
//   }
  
//   let x, y

//   [x, y] = size.split("/").map((s) => parseInt(s))

//   return x/y
// }

export const fragmentSizes = (() => {
  let sizes = [...new Set(fragments.map((fragment) => fragment.size)), "2"]

  sizes.sort()

  return sizes
})()

export const fragmentShapes = (() => {
  let descriptions = [...new Set(fragments.map((fragment) => fragment.shape))]

  return descriptions
})()