import { useAuth } from "@/modules/auth/use"
import { useAccount } from "@/modules/account/use"
import { useBootstrapFlatplan } from "@/modules/bootstrapFlatplan/use"
import { useBootstrapAccount } from "@/modules/bootstrapAccount/use"
import { useBootstrapApp } from "@/modules/bootstrapApp/use"
import { useBootstrapShare } from "@/modules/bootstrapShare/use"

export default (router) => {
  router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.bootstrapApp) || to.matched.some((record) => record.meta.bootstrapShare) || to.matched.some((record) => record.meta.bootstrapAccount)) {
      let bootstrap = bootstrapApp // default

      if (to.matched.some((record) => record.meta.bootstrapShare)) {
        bootstrap = bootstrapShare // use this instead
      }

      bootstrap(to, next)
        .then(() => {
          if (to.matched.some((record) => record.meta.bootstrapFlatplan)) {
            let bp_account = useAccount()
            let bp_bootstrapFlatplan = useBootstrapFlatplan()

            const accountId = bp_account.currentAccountId.value
            const flatplanId = to.params.flatplanId || bp_bootstrapFlatplan.lastFlatplanId(accountId)
            if (activeAccount(accountId, next)) {
              return bootstrapFlatplan(accountId, flatplanId, to, next)
            }
          } else if (to.matched.some((record) => record.meta.bootstrapAccount)) {
            let bp_account = useAccount()
            const accountId = bp_account.currentAccountId.value

            if (validAccount(accountId, next)) {
              return bootstrapAccount(accountId, to, next)
            }
          }
        })
        .then(() => {
          next()
        })
        .catch((err) => {
          next(err)
        })
    } else {
      next() // make sure to always call next()
    }
  })
}

export const bootstrapApp = (to, next) => {
  let bp_bootstrapApp = useBootstrapApp()
  let bp_account = useAccount()
  let bp_auth = useAuth()

  return bp_bootstrapApp.fetch().then(() => {
    if (!bp_account.anyAccounts.value) {
      bp_auth.logout({ loggedOutReason: "There are no accounts linked to this user." })
      next(false)
    }
  })
}

export const bootstrapShare = (to, next) => {
  let bp_bootstrapShare = useBootstrapShare()
  let bp_account = useAccount()

  return bp_bootstrapShare.fetch(to.params.accountId).then(() => {
    if (!bp_account.anyAccounts.value) {
      next(false)
    }
  })
}

export const bootstrapAccount = (accountId, to, next) => {
  let bp_bootstrapAccount = useBootstrapAccount()
  return bp_bootstrapAccount.fetch(accountId)
}

export const bootstrapFlatplan = (accountId, flatplanId, to, next) => {
  // if we don't have a flatplanId by here - it's fine, the data API will find a flatplan for us, or even create one for us.
  let bp_bootstrapFlatplan = useBootstrapFlatplan()

  return bp_bootstrapFlatplan
    .init({
      accountId,
      flatplanId,
    })
    .catch((error) => {
      if (error.response) {
        if (!to.params.flatplanId && flatplanId && error.response.status === 404) {
          // we might come here if the url params don't include a flatplanId,
          // so we then took it from localStorage. But if we the API returns with
          // a 404 - this could be if the flatplan from localstorage no longer exists.
          // In this case - delete localStorage, and try again.
          // The accountId - if we have one - should always be valid. See the getter
          // to get the default accountId.
          // let's delete the localStorage, else we end up in an infinite loop
          // if we then redirect
          bp_bootstrapFlatplan.forgetLastFlatplan({ accountId })

          // let's try again, but without specifiying a flatplanId
          next({ name: "editor" })
          return
        } else {
          // we have put a special meta field _ignore404 onto the request
          // so we can deal with the retry logic here (with the meta tag
          // we remove the 404 error handling from the axios interceptor)
          // But therefore we need to deal with the 404 here then.
          if (error.response.status === 404) {
            next({ name: "404" })
            return
          } else {
            console.error(error)
            next(error)
            return
          }
        }
      } else {
        next(error)        
        return
      }
    })
}

export const activeAccount = (accountId, next) => {
  if (accountId) {
    let bp_account = useAccount()
    let account = bp_account.getAccountById(accountId)

    if (!account) {
      next({
        name: "404",
      })
      return false
    }

    if (!account.active) {
      next({
        name: "billing",
      })
      return false
    }

    return true
  } else {
    next(new Error("No account"))
    // need to push to a route to inform that we don't have any accounts attached to this user?
  }
}

export const validAccount = (accountId, next) => {
  if (accountId) {
    let bp_account = useAccount()
    let account = bp_account.getAccountById(accountId)

    if (!account) {
      next({
        name: "404",
      })
      return false
    }

    return true
  } else {
    next(new Error("No account"))
    // need to push to a route to inform that we don't have any accounts attached to this user?
  }
}
