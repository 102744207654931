import { computed } from "vue"

import router from "@/router"

import { useAccount } from "@/modules/account/use"
import { useFlatplan } from "@/modules/flatplan/use"
import { useRole } from "@/modules/role/use"

export const useContext = () => {
  let bp_account = useAccount()
  let bp_flatplan = useFlatplan()
  let bp_role = useRole()

  let pageId = computed(() => {
    return router.currentRoute.value.params.pageId
  })

  let permitBilling = computed(() => {
    let allowedRoles = ["billing"]
    let permitted = false

    allowedRoles.forEach((role) => {
      if (bp_role.hasRole({ accountId: bp_account.id.value, role })) {
        permitted = true
      }
    })

    return permitted
  })

  let permitAdmin = computed(() => {
    let allowedRoles = ["admin"]
    let permitted = false

    allowedRoles.forEach((role) => {
      if (bp_role.hasRole({ accountId: bp_account.id.value, role })) {
        permitted = true
      }
    })

    return permitted
  })

  return {
    accountName: computed(() => bp_account.currentAccount.value.name),
    accountId: bp_account.currentAccountId,
    flatplanId: bp_flatplan.id,
    pageId,
    permitBilling,
    permitAdmin,
  }
}
