import axios from 'axios'
import router from "@/router"
import { useNotification } from "@/modules/notification/use"
import { NetworkMetrics } from '../metrics/index'

const handleNetworkError = (error) => {
  const metrics = NetworkMetrics.getInstance()

  if (error.config?.timing) {
    metrics.logError(
      error,
      error.config.timing.endpoint,
      error.config.timing.startTime
    )
  } else {
    metrics.logError(
      error,
      `${error.config?.method || 'unknown'}:${error.config?.url || 'unknown'}`,
      Date.now()
    )
  }
  
  console.info("Redirecting to network-issue page")

  if (error.code === 'ECONNABORTED') {
    router.push({ name: "network-issue", query: { type: 'timeout' } })
  } else if (!navigator.onLine) {
    router.push({ name: "network-issue", query: { type: 'offline' } })
  } else {
    router.push({ name: "network-issue", query: { type: 'connection' } })
  }
}

export function setupErrorInterceptors(axiosInstance) {
  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response) {
        const bp_notification = useNotification()

        switch (error.response.status) {
          case 400:
          case 402:
          case 405:
          case 406:
            bp_notification.show({
              type: "error",
              message: error.response.data.error.message,
            })
            break
          case 429:
            bp_notification.show({
              type: "error",
              message: error.response.data,
            })
            break
          case 404:
            if (!error.config._ignore404) {
              router.push({ name: "404" })
            }
            break
          case 503:
            router.push({ name: "maintenance-mode" })
            break
          case 500:
            router.push({ name: "500" })
            break
        }
      } else {
        handleNetworkError(error)
      }

      return Promise.reject(error)
    }
  )

  return axiosInstance
}