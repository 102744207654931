import Sortable from "@/lib/sortable/Sortable"
import { insertNodeAt, removeNode } from "./util/htmlHelper";
import { console } from "./util/console";
import {
  getComponentAttributes,
  createSortableOption,
  getValidSortableEntries
} from "./core/componentBuilderHelper";
import { computeComponentStructure } from "./core/renderHelper";
import { events } from "./core/sortableEvents";
import { h, defineComponent, nextTick } from "vue";

function emit(evtName, evtData) {
  nextTick(() => this.$emit(evtName.toLowerCase(), evtData));
}

function manage(evtName) {
  return (evtData, originalElement) => {
    if (this.realList !== null) {
      return this[`onDrag${evtName}`](evtData, originalElement);
    }
  };
}

function manageAndEmit(evtName) {
  const delegateCallBack = manage.call(this, evtName);
  return (evtData, originalElement) => {
    delegateCallBack.call(this, evtData, originalElement);
    emit.call(this, evtName, evtData);
  };
}

let draggingElement = null;

const props = {
  list: {
    type: Array,
    required: false,
    default: null
  },
  modelValue: {
    type: Array,
    required: false,
    default: null
  },
  itemKey: {
    type: [String, Function],
    required: true
  },
  clone: {
    type: Function,
    default: original => {
      return original;
    }
  },
  tag: {
    type: String,
    default: "div"
  },
  move: {
    type: Function,
    default: null
  },
  componentData: {
    type: Object,
    required: false,
    default: null
  },
  // JD
  multiDrag: {
    type: Boolean,
    required: false,
    default: false,
  },
  // JD
  selectedClass: {
    type: String,
    required: false,
    default: null,
  },
  // JD
  swap: {
    type: Boolean,
    default: false,
  },
  // JD
  swapClass: {
    type: String,
  },
  // JD
  scroll: {
    type: Boolean,
    default: true,
  },
};

const emits = [
  "update:modelValue",
  "change",
  ...[...events.manageAndEmit, ...events.emit].map(evt => evt.toLowerCase())
];

const draggableComponent = defineComponent({
  name: "draggable",

  inheritAttrs: false,

  props,

  emits,

  data() {
    return {
      error: false
    };
  },

  render() {
    try {
      this.error = false;
      const { $slots, $attrs, tag, componentData, realList, getKey } = this;
      const componentStructure = computeComponentStructure({
        $slots,
        tag,
        realList,
        getKey
      });
      this.componentStructure = componentStructure;
      const attributes = getComponentAttributes({ $attrs, componentData });
      return componentStructure.render(h, attributes);
    } catch (err) {
      this.error = true;
      return h("pre", { style: { color: "red" } }, err.stack);
    }
  },

  created() {
    if (this.list !== null && this.modelValue !== null) {
      console.error(
        "modelValue and list props are mutually exclusive! Please set one or another."
      );
    }

    // JD
    if (this.multiDrag && (this.selectedClass || "") === "") {
      console.warn("selected-class must be set when multi-drag mode. See https://github.com/SortableJS/Sortable/wiki/Dragging-Multiple-Items-in-Sortable#enable-multi-drag")
    }
  },

  mounted() {
    if (this.error) {
      return;
    }

    const { $attrs, $el, componentStructure } = this;
    componentStructure.updated();

    const sortableOptions = createSortableOption({
      $attrs,
      callBackBuilder: {
        manageAndEmit: event => manageAndEmit.call(this, event),
        emit: event => emit.bind(this, event),
        manage: event => manage.call(this, event)
      }
    });

    // JD (not sure why these aren't part of $attrs)
    if (this.multiDrag) {
      sortableOptions.multiDrag = true
      sortableOptions.selectedClass = this.selectedClass
    }

    // JD (not sure why these aren't part of $attrs)
    if (this.swap) {
      sortableOptions.swap = true
      sortableOptions.swapClass = this.swapClass
    }

    const targetDomElement = $el.nodeType === 1 ? $el : $el.parentElement;
    this._sortable = new Sortable(targetDomElement, sortableOptions);
    this.targetDomElement = targetDomElement;
    targetDomElement.__draggable_component__ = this;
  },

  updated() {
    this.componentStructure.updated();
  },

  beforeUnmount() {
    if (this._sortable !== undefined) this._sortable.destroy();
  },

  computed: {
    realList() {
      const { list } = this;
      return list ? list : this.modelValue;
    },

    getKey() {
      const { itemKey } = this;
      if (typeof itemKey === "function") {
        return itemKey;
      }
      return element => element[itemKey];
    }
  },

  watch: {
    $attrs: {
      handler(newOptionValue) {
        const { _sortable } = this;
        if (!_sortable) return;
        getValidSortableEntries(newOptionValue).forEach(([key, value]) => {
          _sortable.option(key, value);
        });
      },
      deep: true
    }
  },

  methods: {
    getUnderlyingVm(domElement) {
      return this.componentStructure.getUnderlyingVm(domElement) || null;
    },

    // JD
    getUnderlyingVmList(domElements) {
      return this.componentStructure.getUnderlyingVmList(domElements) || null;
    },

    getUnderlyingPotencialDraggableComponent(htmElement) {
      //TODO check case where you need to see component children
      return htmElement.__draggable_component__;
    },

    emitChanges(evt) {
      nextTick(() => this.$emit("change", evt));
    },

    alterList(onList) {
      if (this.list) {
        onList(this.list);
        return;
      }
      const newList = [...this.modelValue];
      onList(newList);
      this.$emit("update:modelValue", newList);
    },

    spliceList() {
      const spliceList = list => list.splice(...arguments);
      this.alterList(spliceList);
    },

    updatePosition(oldIndex, newIndex) {
      const updatePosition = list =>
        list.splice(newIndex, 0, list.splice(oldIndex, 1)[0]);
      this.alterList(updatePosition);
    },

    // JD
    swapPosition(oldIndex, newIndex) {
      const swapPosition = (list) => (list[oldIndex] = list.splice(newIndex, 1, list[oldIndex])[0])
      this.alterList(swapPosition)
    },

    getRelatedContextFromMoveEvent({ to, related }) {
      const component = this.getUnderlyingPotencialDraggableComponent(to);
      if (!component) {
        return { component };
      }
      const list = component.realList;
      const context = { list, component };
      if (to !== related && list) {
        const destination = component.getUnderlyingVm(related) || {};
        return { ...destination, ...context };
      }
      return context;
    },

    getVmIndexFromDomIndex(domIndex) {
      return this.componentStructure.getVmIndexFromDomIndex(
        domIndex,
        this.targetDomElement
      );
    },

    // JD
    locked() {
      let ll = []
      let childrenNodes = this.componentStructure.children
      if (childrenNodes) {
        childrenNodes.forEach((node, index) => {
          if (!!node.el && !!node.el.dataset && node.el.dataset.locked === "true") {
            ll.push({ index, id: node.el.dataset.pageId, elm: node.el })
          }
        })
      }
      return ll
    },

    // JD
    revertNodes(el, items, event, getIndex) {
      // let's reverse all the dom manipulations Sortable.js did for us
      let lockedItems = this.locked()

      // let's remove all the locked elements, so that all items can
      // shift toward the front past the locked elements
      let removedItems = []
      lockedItems.forEach((item) => {
        removeNode(item.elm)
        removedItems.push({ index: item.index, elm: item.elm })
      })

      // remove the moved items
      items.forEach((item, index) => {
        removeNode(item)
        if (event !== "add") {
          const c = getIndex(index)
          if (c) {
            removedItems.push({ index: c.index, elm: item })
          }
        }
      })

      // now we need to reinsert the removed nodes - beginning from the front
      removedItems
        .sort((a, b) => a.index - b.index)
        .forEach((item) => {
          insertNodeAt(el, item.elm, item.index)
        })
    },

    // JD
    removeLockedItems(list, lockedItems) {
      lockedItems.forEach(({ id }) => {
        let lockedIndex = list.indexOf(id)
        if (lockedIndex !== -1) {
          list.splice(lockedIndex, 1)
        }
      })
    },

    // JD
    revertLockedItems(list, lockedItems) {
      // but this hasn't really taken locked elements into account
      // let's remove all the locked elements, so that all items can
      // shift toward the front past the locked elements
      this.removeLockedItems(list, lockedItems)

      // let's add the locked elements back into their fixed positions
      lockedItems
        .sort((a, b) => a.index - b.index)
        .forEach(({ index, id }) => {
          list.splice(index, 0, id)
        })
    },

    // JD
    onDragStart(evt) {
      if (evt.items && evt.items.length) {
        this.doDragStartList(evt)
      } else {
        this.doDragStart(evt)
      }
    },

    // JD
    doDragStart(evt) {
      this.context = this.getUnderlyingVm(evt.item);
      evt.item._underlying_vm_ = this.clone(this.context.element);
      draggingElement = evt.item;
    },

    // JD
    doDragStartList(evt) {
      this.context = this.getUnderlyingVmList(evt.items);
      evt.item._underlying_vm_ = this.clone(this.context.map((e) => e.element))
      draggingElement = evt.item;
    },

    // JD
    onDragAdd(evt) {
      const element = evt.item._underlying_vm_
      if (element === undefined) {
        return
      }
      if (Array.isArray(element)) {
        this.doDragAddList(evt, element)
      } else {
        this.doDragAdd(evt, element)
      }
    },

    // JD
    doDragAdd(evt, element) {
      if (this._sortable.option("swap")) {
        this.doDragAddSwap(evt)
      } else {
        this.revertNodes(evt.to, [evt.item], "add", null)
        const newIndex = this.getVmIndexFromDomIndex(evt.newIndex);
        let lockedItems = this.locked()

        this.alterList((list) => {
          // first let's move the element to the new place in the list
          list.splice(newIndex, 0, element)
          this.revertLockedItems(list, lockedItems)
        })

        const added = { element, newIndex };
        this.emitChanges({ added });
      }
    },

    // JD
    doDragAddList(evt, elements) {
      if (elements.length === 0) {
        return
      }
      // removeNode(evt.item);
      this.revertNodes(evt.to, evt.items, "add", null)
      // const newIndex = this.getVmIndexFromDomIndex(evt.newIndex);
      const newIndexFrom = this.getVmIndexFromDomIndex(evt.newIndicies[0].index) // JD!! don't pick evt.newIndex
      let lockedItems = this.locked()

      this.alterList((list) => {
        // first let's move the element to the new place in the list
        list.splice(newIndexFrom, 0, ...elements)
        this.revertLockedItems(list, lockedItems)
      })

      const added = elements.map((element, index) => {
        const newIndex = newIndexFrom + index
        return { element, newIndex }
      })

      // const added = { element, newIndex };
      this.emitChanges({ added })
    },


    doDragAddSwap(evt) {
      // swapItem is the item we're dropping onto
      // item is the item we are dragging
      const swapContext = this.getUnderlyingVm(evt.swapItem)
      evt.swapItem._underlying_vm_ = this.clone(swapContext.element)

      insertNodeAt(evt.to, evt.swapItem, evt.newIndex)
      insertNodeAt(evt.from, evt.item, evt.oldIndex)

      // do the list work
      const element = evt.item._underlying_vm_
      const newIndex = this.getVmIndexFromDomIndex(evt.newIndex)
      this.spliceList(newIndex, 1, element)
    },


    // JD
    onDragRemove(evt) {
      if (Array.isArray(this.context)) {
        this.doDragRemoveList(evt)
      } else {
        this.doDragRemove(evt)
      }
    },

    // JD
    doDragRemove(evt) {
      if (this._sortable.option("swap")) {
        this.doDragRemoveSwap(evt)
      } else {
        insertNodeAt(this.$el, evt.item, evt.oldIndex);
        if (evt.pullMode === "clone") {
          removeNode(evt.clone);
          return;
        }
        const { index: oldIndex, element } = this.context;
        
        this.spliceList(oldIndex, 1);
        const removed = { element, oldIndex };
        this.emitChanges({ removed });
      }
    },

    // JD
    doDragRemoveList(evt) {
      evt.items.forEach((item, index) => {
        insertNodeAt(this.$el, item, evt.oldIndicies[index].index)
      })
      // insertNodeAt(this.$el, evt.item, evt.oldIndex);
      if (evt.pullMode === "clone") {
        removeNode(evt.clone);
        return;
      }
      const reversed = this.context.sort((a, b) => b.index - a.index)
      const removed = reversed.map((item) => {
        const oldIndex = item.index
        return { element: item.element, oldIndex }
      })

      // const { index: oldIndex, element } = this.context;
      this.alterList((list) => {
        removed.forEach((removedItem) => {
          list.splice(removedItem.oldIndex, 1)
        })
      })

      // this.spliceList(oldIndex, 1);
      // const removed = { element, oldIndex };
      this.emitChanges({ removed });
    },

    doDragRemoveSwap(evt) {
      const { oldIndex, newIndex, from, to } = evt
      const swapElement = evt.swapItem._underlying_vm_
      if (evt.pullMode === "clone") {
        removeNode(evt.clone)
        this.spliceList(oldIndex, 0, swapElement)
      } else {
        this.spliceList(oldIndex, 1, swapElement)
      }
      const swapped = {
        element: this.context.element,
        swapElement,
        fromIndex: oldIndex,
        toIndex: newIndex,
        to,
        from,
      }
      this.emitChanges({ swapped })
    },

    // JD
    onDragUpdate(evt) {
      if (Array.isArray(this.context)) {
        this.doDragUpdateList(evt)
      } else {
        this.doDragUpdate(evt)
      }
    },

    // JD
    doDragUpdate(evt) {
      if (this._sortable.option("swap")) {
        this.doDragUpdateSwap(evt)
      } else {
        this.revertNodes(evt.from, [evt.item], "update", () => {
          return { index: evt.oldIndex }
        })

        // now let's mimic those sortable.js dom manipulations,
        // but in the list - not the dom.
        const oldIndex = this.context.index;
        const newIndex = this.getVmIndexFromDomIndex(evt.newIndex);
        let lockedItems = this.locked()

        this.alterList((list) => {
          // first let's move the item to the new place in the list
          list.splice(newIndex, 0, list.splice(oldIndex, 1)[0])
          this.revertLockedItems(list, lockedItems)
        })

        const moved = { element: this.context.element, oldIndex, newIndex };
        this.emitChanges({ moved });
      }
    },

    // JD
    doDragUpdateList(evt) {
      // // let's reverse all the dom manipulations Sortable.js did for us
      this.revertNodes(evt.from, evt.items, "update", (index) => this.context[index])

      const newIndexFrom = this.getVmIndexFromDomIndex(evt.newIndex) - evt.items.indexOf(evt.item)
      const moved = this.context.map((item, index) => {
        const oldIndex = item.index
        const newIndex = newIndexFrom + index
        return { element: item.element, oldIndex, newIndex }
      })
      let lockedItems = this.locked()

      this.alterList((list) => {
        const target = moved.slice()
        // // remove moved elements from old index
        target.sort((a, b) => b.oldIndex - a.oldIndex)
        target.forEach((e) => list.splice(e.oldIndex, 1))
        // // add moved elements to new index
        target.sort((a, b) => a.newIndex - b.newIndex)
        target.forEach((e) => list.splice(e.newIndex, 0, e.element))
        this.revertLockedItems(list, lockedItems)
      })

      // const moved = { element: this.context.element, oldIndex, newIndex };
      this.emitChanges({ moved });
    },

    // JD: when swapping with a space - for example from HoldingArea to flatplan
    // the fact that the space doesn't end up on the HoldingArea happens in the store
    // when we filter for pages for not spaces to put onto the HoldingArea.
    // So techincally in here (and before already - from the Sortable), the space does end up on the Holding Area for a
    // fraction.
    doDragUpdateSwap(evt) {
      const { oldIndex, newIndex, from, to } = evt

      // dom work
      from.replaceChild(evt.swapItem, evt.item)
      insertNodeAt(from, evt.item, oldIndex)

      // list work
      this.swapPosition(oldIndex, newIndex)

      const swapContext = this.getUnderlyingVm(evt.swapItem)
      const swapElement = this.clone(swapContext.element)
      const swapped = {
        element: this.context.element,
        swapElement,
        fromIndex: oldIndex,
        toIndex: newIndex,
        to,
        from,
      }
      this.emitChanges({ swapped })
    },

    computeFutureIndex(relatedContext, evt) {
      if (!relatedContext.element) {
        return 0;
      }
      const domChildren = [...evt.to.children].filter(
        el => el.style["display"] !== "none"
      );
      const currentDomIndex = domChildren.indexOf(evt.related);
      const currentIndex = relatedContext.component.getVmIndexFromDomIndex(
        currentDomIndex
      );
      const draggedInList = domChildren.indexOf(draggingElement) !== -1;
      return draggedInList || !evt.willInsertAfter
        ? currentIndex
        : currentIndex + 1;
    },

    onDragMove(evt, originalEvent) {
      const { move, realList } = this;
      if (!move || !realList) {
        return true;
      }

      const relatedContext = this.getRelatedContextFromMoveEvent(evt);
      const futureIndex = this.computeFutureIndex(relatedContext, evt);
      const draggedContext = {
        ...this.context,
        futureIndex
      };
      const sendEvent = {
        ...evt,
        relatedContext,
        draggedContext
      };
      return move(sendEvent, originalEvent);
    },

    onDragEnd(evt) {
      // JD: This is part of a HACK to not deselect elements on outside click
      // on the pageSortable composable we switch off the document event listener for multi deselect
      // The Sortable.utils.deselect(item) method doesn't fire the deselect event on the draggable
      // So we're emitting it here.
      evt.items.forEach((item) => {
        Sortable.utils.deselect(item)
        this.$emit("deselect", { item })
      })
      draggingElement = null
    }
  }
});

export default draggableComponent;