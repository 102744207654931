import { ref, computed } from "vue"
import { defineStore } from "pinia"

import DataService from "@/services/DataService.js"
import { ProviderId } from "firebase/auth"

const comparePosition = (a, b) => {
  return a.pageNumber - b.pageNumber
}

export const useStitchInStore = defineStore("stitchIn", () => {
  const stitchIns = ref([])

  const ordered = computed(() => {
    return stitchIns.value.sort(comparePosition)
  })

  function getById(id) {
    return stitchIns.value.find((s) => s._id === id)
  }

  function getByPageNumber(pageNumber) {
    return stitchIns.value.find((s) => s.pageNumber === pageNumber)
  }

  function setStitchIn({ stitchInId, pageNumber, frontContent, backContent }) {
    let stitchInIndex = stitchIns.value.findIndex((c) => c._id === stitchInId)
    if (stitchInIndex !== -1) {
      let stitchIn = stitchIns.value[stitchInIndex]
      stitchIn.pageNumber = pageNumber
      stitchIn.frontContent = frontContent
      stitchIn.backContent = backContent
      stitchIns.value.splice(stitchInIndex, 1, stitchIn)
    } else {
      // we have undeleted - so we end up here
      stitchIns.value.splice(0, 0, { _id: stitchInId, pageNumber, frontContent, backContent })
    }
  }

  function removeStitchIn({ stitchInId }) {
    let stitchInIndex = stitchIns.value.findIndex((c) => c._id === stitchInId)
    if (stitchInIndex !== -1) {
      stitchIns.value.splice(stitchInIndex, 1)
    }
  }

  function addStitchIn(stitchIn) {
    let stitchInIndex = stitchIns.value.findIndex((c) => c._id === stitchIn._id)
    if (stitchInIndex === -1) {
      // insert if new
      stitchIns.value.splice(0, 0, stitchIn)
    } else {
      // replace if exists
      stitchIns.value.splice(stitchInIndex, 1, stitchIn)
    }
  }

  function bootstrap(data) {
    stitchIns.value = data
  }

  function update({ flatplanId, stitchInId, pageNumber, frontContent, backContent, storeOnly }) {
    let prevAttributes = JSON.parse(JSON.stringify(getById(stitchInId) || {}))

    setStitchIn({ stitchInId, pageNumber, frontContent, backContent })

    if (!storeOnly) {
      return DataService.updateStitchIn({ accountId: sessionStorage.getItem("currentAccountId"), flatplanId, stitchInId, payload: { pageNumber, frontContent, backContent } }).catch((err) => {
        setStitchIn({ stitchInId, ...prevAttributes })
        return Promise.reject(err)
      })
    } else {
      return Promise.resolve()
    }
  }

  function del({ stitchInId, flatplanId, storeOnly }) {
    let prevStitchIn = JSON.parse(JSON.stringify(getById(stitchInId)))

    removeStitchIn({ stitchInId })

    if (!storeOnly) {
      return DataService.deleteStitchIn({ accountId: sessionStorage.getItem("currentAccountId"), flatplanId, stitchInId }).catch((err) => {
        addStitchIn(prevStitchIn)
        return Promise.reject(err)
      })
    } else {
      return Promise.resolve()
    }
  }

  // add - we generate on server first, then mutate store
  function add({ flatplanId, pageNumber, frontContent, backContent }) {
    return DataService.addStitchIn({ accountId: sessionStorage.getItem("currentAccountId"), flatplanId, payload: { pageNumber, frontContent, backContent } }).then((response) => {
      addStitchIn(response.data)

      return Promise.resolve(response)
    })
  }

  // this method is used when stitchIn was added via Liveupdate (so it already exists on the server)
  function addFromLiveupdate(stitchIn) {
    addStitchIn(stitchIn)
  }

  return {
    stitchIns,
    ordered,
    getById,
    getByPageNumber,
    bootstrap,
    del,
    add,
    update,
    addFromLiveupdate,
  }
})
