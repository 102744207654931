<template>
  <div v-if="coverPageId" class="d-flex justify-space-between">
    <div class="flatplan__cover-wrapper" data-draggable-for="coverPage" data-test-id="cover-wrapper">
      <div class="page-numbers__container">
        <div class="page__wrapper">
          <div class="page">
            <div class="text-caption page-number">{{ bp_pageNumbers.cover }}</div>
          </div>
        </div>
      </div>
      <v-sheet class="page__wrapper" tile>
        <Page :pageId="coverPageId" />
      </v-sheet>
      <div v-if="!showTypeStats && !showCategoryStats && !showTagStats" style="clear: both"></div>
    </div>
    <div v-if="showTypeStats" class="ml-4 mr-4">
      <TypeStats />
      <div style="clear: both"></div>
    </div>
    <div v-if="showCategoryStats" class="ml-4 mr-4">
      <CategoryStats />
      <div style="clear: both"></div>
    </div>
    <div v-if="showTagStats" class="ml-4 mr-4">
      <TagStats />
      <div style="clear: both"></div>
    </div>
  </div>
  <div v-else>
    <div v-if="showTypeStats" class="mb-4">
      <TypeStats />
      <div style="clear: both"></div>
    </div>
    <div v-if="showCategoryStats" class="mb-4">
      <CategoryStats />
      <div style="clear: both"></div>
    </div>
    <div v-if="showTagStats" class="mb-4">
      <TagStats />
      <div style="clear: both"></div>
    </div>
  </div>

  <div id="flatplan-for-resize-sensor__wrapper" class="flatplan__wrapper">
    <div data-test-id="pages-container" class="pages__container" :class="layoutClasses">
      <!-- group page and pagenumbers together as a block to improve PDF printing -->
      <v-sheet v-for="(pageNumber, index) in bp_pageNumbers.inner" :key="pageIds[index] + '-' + index" class="page__wrapper" tile>
        <Page :pageId="pageIds[index]" :stitchInId="stitchInIdForPageIndex(index)" />
        <div class="text-caption page-number">{{ bp_pageNumbers.inner[index] }}<sup v-if="hasNotes(pageIds[index])">*</sup></div>
      </v-sheet>
    </div>
  </div>
  <QuickLook />
  <SlideShow />
</template>

<script>
import { reactive } from "vue"

import { usePage } from "@/modules/page/use"
import { useStitchIn } from "@/modules/stitchIn/use"
import { useViewSetting } from "@/modules/viewSetting/use"
import { useFlatplanRowBreaks } from "@/modules/flatplan/useFlatplanRowBreaks"
import { usePageNumbers } from "@/modules/flatplan/usePageNumbers"

import Page from "./Page"
import QuickLook from "@/modules/page/components/QuickLook"
import SlideShow from "@/modules/flatplan/components/SlideShow"
import TypeStats from "@/modules/type/components/TypeStats"
import CategoryStats from "@/modules/category/components/CategoryStats"
import TagStats from "@/modules/tag/components/TagStats"

export default {
  name: "Flatplan",
  components: {
    Page,
    QuickLook,
    SlideShow,
    TypeStats,
    CategoryStats,
    TagStats,
  },
  setup() {
    let bp_page = usePage()
    let bp_viewSetting = useViewSetting()

    let bp_pageNumbers = usePageNumbers()
    let bp_stitchIn = useStitchIn()

    let { layoutClasses } = useFlatplanRowBreaks({
      domContainerId: "flatplan-for-resize-sensor__wrapper",
    })

    let hasNotes = (pageId) => {
      if (bp_viewSetting.showNotes.value) {
        let page = bp_page.getById(pageId)
        return !!page && !!page.notes
      } else {
        return false
      }
    }

    return {
      layoutClasses,
      bp_pageNumbers: reactive(bp_pageNumbers),
      coverPageId: bp_page.coverPageId,
      pageIds: bp_page.orderForInnerPageIds,
      showTypeStats: bp_viewSetting.showTypeStats,
      showCategoryStats: bp_viewSetting.showCategoryStats,
      showTagStats: bp_viewSetting.showTagStats,
      stitchInIdForPageIndex: bp_stitchIn.idForPageIndex,
      hasNotes,
    }
  },
}
</script>

<style lang="scss" scoped>
@import "@/styles/flatplan.scss";
</style>
