<template>
  <v-container fluid>
    <div class="d-flex mb-4">
      <h4 class="text-h4">
      Upload Ads/Content from File
    </h4>
    <a class="text-decoration-none text-primary" href="//www.blinkplan.com/docs/adscontent" target="_blank"
        ><v-icon>{{ mdiHelpCircleOutline }}</v-icon></a
      >
    </div>
    <v-alert type="info"
      >
      <div class="my-4">Upload a single CSV (comma separated) or TSV (tab separated) file.</div>
      <div class="my-4">The first row must be the <strong>header row</strong>.</div>
      <h6 class="text-subtitle-1"><strong>Columns</strong> (the names don't matter, but the order does):</h6>
      <ol class="mt-4">
        <li>1. <span class="text-uppercase">unique identifier</span> <small>(optional; for uploading repeatedly)</small></li>
        <li>2. <span class="text-uppercase">text</span></li>
        <li>
          3. <span class="text-uppercase">suggested page numbers</span> <small>(optional; separated with a semicolon <v-chip variant="outlined" label size="x-small">;</v-chip>)</small>
        </li>
        <li>
          4. <span class="text-uppercase">suggested size</span> <small>(optional)</small>
        </li>
        <li>
          5. <span class="text-uppercase">suggested shape</span> <small>(optional)</small>
        </li>
      </ol></v-alert
    >
    <v-container data-test-id="upload-button" fluid>
      <ContentUploadButton @uploaded="uploaded" />
    </v-container>
    <v-container v-if="uploadResults.length > 0" data-test-id="upload-confirm" fluid>
      <h5 class="text-h5">Confirm rows to import</h5>
      <ContentUploadConfirmResults :rows="uploadResults" />
    </v-container>
  </v-container>
</template>

<script>
import { ref } from "vue"
import { mdiHelpCircleOutline } from "@mdi/js"

import ContentUploadButton from "./ContentUploadButton"
import ContentUploadConfirmResults from "./ContentUploadConfirmResults"

export default {
  name: "ContentUploadFromFileView",
  components: {
    ContentUploadButton,
    ContentUploadConfirmResults,
  },
  setup() {
    let uploadResults = ref([])

    const uploaded = (data) => {
      data.shift() // drop header row
      uploadResults.value = data
    }

    return {
      uploaded,
      uploadResults,
      mdiHelpCircleOutline,
    }
  },
}
</script>

<style lang="scss" scoped></style>
