<template>
  <base-toolbar>
    <BackToFlatplan />
    <SummaryOptions />
  </base-toolbar>
</template>

<script>
import { inject } from "vue"

import BackToFlatplan from "@/components/BackToFlatplan"
import SummaryOptions from "./SummaryOptions"

export default {
  name: "SummaryToolBar",
  components: { BackToFlatplan, SummaryOptions },
  setup() {
    let mobile = inject("mobile")

    return {
      mobile,
    }
  },
}
</script>

<style lang="scss" scoped></style>
