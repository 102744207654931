import { ref, computed } from "vue"
import { defineStore } from "pinia"

import AccountService from "@/services/AccountService.js"
import DataService from "@/services/DataService.js"

import { snakeCase } from "@/utils/general"

export const useBillingStore = defineStore("billing", () => {
  const account = ref({})
  const accountPlans = ref([])
  const accountStats = ref({})

  const customAccountPlans = computed(() => {
    return account.value.custom_plans
  })

  const isDemo = computed(() => {
    return account.value.plan ? account.value.plan.code === "demo" : false
  })

  const invoices = computed(() => {
    return account.value.invoices ? account.value.invoices : []
  })

  function bootstrap(data) {
    account.value = data.account
    accountPlans.value = data.accountPlans
  }

  function setupStripeSession() {
    return AccountService.billingSetupStripeSession(account.value.secret_id)
  }

  function upgrade({ code }) {
    return AccountService.billingUpgrade(account.value.secret_id, { code })
  }

  function update({ billingName, billingDetails }) {
    let payload = snakeCase({ billingName, billingDetails })
    return AccountService.billingUpdate(account.value.secret_id, payload).then((response) => {
      if (billingName !== undefined) {
        account.value.billing_name = billingName
      }
      if (billingDetails !== undefined) {
        account.value.billing_details = billingDetails
      }
      return response
    })
  }

  function fetchAccountStats() {
    return DataService.accountStats({ accountId: account.value.secret_id }).then((response) => {
      accountStats.value = response.data
    })
  }

  function fetchInvoice({ code }) {
    return AccountService.invoice(account.value.secret_id, code)
  }

  function deleteAccount() {
    return AccountService.deleteAccount({ accountId: account.value.secret_id })
  }

  return {
    account,
    accountPlans,
    customAccountPlans,
    accountStats,
    isDemo,
    invoices,
    bootstrap,
    setupStripeSession,
    upgrade,
    update,
    fetchAccountStats,
    fetchInvoice,
    deleteAccount,
  }
})
