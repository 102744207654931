import { SVG } from "@svgdotjs/svg.js"
import fragments from "@/modules/page/render/fragments"
import { getNonOverlappingPaths, textWrap, scaleFontIfNeeded } from "@/modules/page/render/utils"

export default ({}) => {
  let drawnFragments = []

  const drawText = ({ draw, drawnFragment, fullPhrase, fragmentItem }) => {
    const margin = 4

    // for L-shape we might want to lose some space where it will be too difficult to
    // render in the narrow sections - so we compress/reduce the available space.
    let textCompressFractionX = fragmentItem.textCompressFractionX || 1
    let textCompressFractionY = fragmentItem.textCompressFractionY || 1

    let isTextHorizontal = true

    if (!fragmentItem.forceHorizontal) {
      // figure out whether the text should be horizontal by the aspect ratio of the shape
      isTextHorizontal = fragmentItem.fractionX * textCompressFractionX >= fragmentItem.fractionY * textCompressFractionY
    }

    // let's get the dimensions of our drawn fragment
    // we will need to fit the text inside here.
    // Calc line breaks, font size etc.
    let fragBBox = drawnFragment.bbox()

    let shapeWidth = fragBBox.width * textCompressFractionX
    let shapeHeight = fragBBox.height * textCompressFractionY

    let wrappedPhrase = isTextHorizontal ? textWrap(fullPhrase, shapeWidth - 2 * margin) : textWrap(fullPhrase, shapeHeight - 2 * margin)

    let textOffsetFractionX = parseFloat(fragmentItem.textOffsetFractionX)
    let textOffsetFractionY = parseFloat(fragmentItem.textOffsetFractionY)

    // let's place the text inside the bounding box of the drawn shape
    let x = textOffsetFractionX ? fragBBox.x + textOffsetFractionX * fragBBox.width : fragBBox.x
    let y = textOffsetFractionY ? fragBBox.y + textOffsetFractionY * fragBBox.height : fragBBox.y

    let text = draw
      .text(wrappedPhrase)
      .font({
        family: "Roboto",
        anchor: "middle",
        leading: "1.1em",
      })
      .fill("black")

    let xText = x
    let yText = y

    if (isTextHorizontal) {
      text = scaleFontIfNeeded(text, shapeWidth, shapeHeight)

      // center the text block inside the shape
      let bbox = text.bbox()
      xText += (shapeWidth - bbox.width) / 2
      yText += (shapeHeight - bbox.height) / 2

      text.move(xText, yText)
    } else {
      text = scaleFontIfNeeded(text, shapeHeight, shapeWidth) // note width, height swapped around

      // center the text block inside the shape
      let bbox = text.bbox()
      xText += (shapeWidth - bbox.width) / 2
      yText += (shapeHeight - bbox.height) / 2

      text.move(xText, yText).rotate(270)
    }
  }

  const drawFragment = ({ draw, fragment, fragmentIndex, width, height, highlightFragmentIndex }) => {
    let paths = []
    let fragmentItem = fragments.find((s) => s.name === fragment.shape)

    if (fragmentItem !== undefined) {
      let fragmentGroup = draw.group()

      let fragmentYSize = fragmentItem.shapeDrawPlan.length
      let fragmentXSize = fragmentItem.shapeDrawPlan[0].length

      let fragTopLeftX = fragment.topLeftX * width
      let fragTopLeftY = fragment.topLeftY * height

      let sqWidth = (fragmentItem.fractionX / fragmentXSize) * width
      let sqHeight = (fragmentItem.fractionY / fragmentYSize) * height

      // a shape might look like:
      // 01
      // 01
      // 11
      for (let row = 0; row < fragmentYSize; row++) {
        for (let col = 0; col < fragmentXSize; col++) {
          let sq = fragmentItem.shapeDrawPlan[row][col]
          if (sq === 1) {
            let sqTopLeftX = fragTopLeftX + col * sqWidth
            let sqTopLeftY = fragTopLeftY + row * sqHeight
            fragmentGroup.rect(sqWidth, sqHeight).move(sqTopLeftX, sqTopLeftY)

            // collect paths that make up the shape, to give it an outline
            let needsPathTop = row === 0 || fragmentItem.shapeDrawPlan[row - 1][col] === 0
            let needsPathBottom = row === fragmentYSize - 1 || fragmentItem.shapeDrawPlan[row + 1][col] === 0
            let needsPathLeft = col === 0 || fragmentItem.shapeDrawPlan[row][col - 1] === 0
            let needsPathRight = col === fragmentXSize - 1 || fragmentItem.shapeDrawPlan[row][col + 1] === 0

            if (needsPathTop) {
              paths.push([sqTopLeftX, sqTopLeftY, sqTopLeftX + sqWidth, sqTopLeftY])
            }
            if (needsPathBottom) {
              paths.push([sqTopLeftX, sqTopLeftY + sqHeight, sqTopLeftX + sqWidth, sqTopLeftY + sqHeight])
            }
            if (needsPathLeft) {
              paths.push([sqTopLeftX, sqTopLeftY, sqTopLeftX, sqTopLeftY + sqHeight])
            }
            if (needsPathRight) {
              paths.push([sqTopLeftX + sqWidth, sqTopLeftY, sqTopLeftX + sqWidth, sqTopLeftY + sqHeight])
            }
          }
        }
      }

      let fillColour = "white"

      fragmentGroup.fill(fillColour)
      fragmentGroup.data("fragmentIndex", fragmentIndex)

      drawText({
        draw: fragmentGroup,
        drawnFragment: fragmentGroup,
        fullPhrase: fragment.text,
        fragmentItem: { ...fragmentItem, forceHorizontal: true }, //
      })

      drawnFragments.push(fragmentGroup)
    }

    return paths
  }

  let drawFragments = function ({ draw, fragments, width, height, highlightFragmentIndex }) {
    let paths = []

    fragments.forEach(function (fragment, fragmentIndex) {
      let fragmentPaths = drawFragment({ draw, fragment, fragmentIndex, width, height, highlightFragmentIndex })
      paths.push(...fragmentPaths)
    })

    let nonOverlappingPaths = getNonOverlappingPaths(paths)

    let pathColor = "#444444"

    nonOverlappingPaths.forEach(function (p) {
      let path = draw.line(...p)
      path.attr({ "stroke": pathColor, "stroke-width": 0.5 })
    })
  }

  let drawArc = function ({ draw, pageNumber, pageWidth, pageHeight }) {
    let fillColour = "white"
    let pathColor = "#444444"
    let radius = 15
    let path = draw.path([
      ["M", pageWidth / 2 - radius, pageHeight],
      ["A", radius, -radius, 0, 0, 1, pageWidth / 2 + radius, pageHeight],
    ])
    path.attr({ "stroke": pathColor, "stroke-width": 0.5 })

    path.fill(fillColour)

    let text = pageNumber ? pageNumber.toString() : ""

    let textBlock = draw
      .text(text)
      .font({
        family: "Roboto",
        size: 8,
        anchor: "middle",
        leading: "1.1em",
      })
      .fill("black")

    let bbox = textBlock.bbox()
    let xText = pageWidth / 2 - (radius * 2 - bbox.width) / 4 + 1
    let yText = pageHeight - bbox.height

    textBlock.move(xText, yText)
  }

  return ({ el, pageNumber, frontContent, backContent, width, height }) => {
    let draw = SVG()
      .addTo(el)
      .size("100%", "100%")
      .viewbox(-0.5, -0.5, width + 1, height + 1)

    drawFragments({
      draw,
      fragments: [
        {
          shape: "vertical_1/2",
          topLeftX: 0,
          topLeftY: 0,
          text: frontContent,
        },
        {
          shape: "vertical_1/2",
          topLeftX: 0.5,
          topLeftY: 0,
          text: backContent,
        },
      ],
      width,
      height,
    })

    drawArc({
      draw,
      pageNumber,
      pageHeight: height,
      pageWidth: width,
    })
  }
}
