<template>
  <v-overlay :model-value="overlay" @click="hideSlideShow" class="overlay">
    <v-sheet class="d-flex align-center flex-column" :height="height" :width="width" :key="previews.map((p) => p.id).join('')">
      <v-sheet class="d-flex mt-auto justify-space-around slideshow">
        <div v-for="preview in previews" :key="preview.id">
          <v-img v-if="!!preview.url" :position="'top ' + (preview.left ? 'right ' : ' ') + (preview.right ? 'left ' : ' ')" :width="pageWidth" :height="pageHeight" :src="preview.url">
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular indeterminate bg-color="transparent" color="muted"></v-progress-circular>
              </v-row>
            </template>
          </v-img>
          <div v-else>
            <div :style="{ width: pageWidth + 'px' }">
              <PageSvg :fragments="preview.fragments" pathColor="black" />
            </div>
          </div>
        </div>
      </v-sheet>
      <div class="mt-auto mb-2 d-flex justify-center" style="width: 100%">
        <div>
          <v-btn variant="text" color="white" class="mr-2" icon v-show="hasLeft" @click.stop="left">
            <v-icon size="x-large">{{ mdiArrowLeft }}</v-icon>
          </v-btn>
          <v-btn variant="text" color="white" class="ml-2" icon v-show="hasRight" @click.stop="right">
            <v-icon size="x-large">{{ mdiArrowRight }}</v-icon>
          </v-btn>
        </div>
        <div v-if="!singlePages" class="toggle-display">
          <v-btn-toggle v-model="displayOption" background-color="transparent" mandatory>
            <v-btn @click.stop="dummy" :icon="mdiFile" size="small" :color="displayOption === 0 ? 'primary' : ''"></v-btn>
            <v-btn @click.stop="dummy" :icon="mdiBookOpenBlankVariant" size="small" :color="displayOption === 1 ? 'primary' : ''"></v-btn>
          </v-btn-toggle>
        </div>
      </div>
    </v-sheet>
  </v-overlay>
</template>

<script>
import { ref, computed, watch, onMounted, onUnmounted } from "vue"
import { mdiArrowLeft, mdiArrowRight, mdiFile, mdiBookOpenBlankVariant } from "@mdi/js"

import PageSvg from "@/modules/page/components/PageSvg"

import { usePage } from "@/modules/page/use"
import { usePageSetting } from "@/modules/pageSetting/use"
import { useViewSetting } from "@/modules/viewSetting/use"
import { cdn } from "@/lib/cdn"

import { Bus } from "@/Bus"

export default {
  name: "SlideShow",
  components: {
    PageSvg,
  },
  setup() {
    let bp_page = usePage()
    let bp_pageSetting = usePageSetting()
    let bp_viewSetting = useViewSetting()

    let overlay = ref(false)

    let hideSlideShow = () => {
      overlay.value = false
    }

    let previews = ref([])

    let displayOption = ref(bp_viewSetting.showSlideshowAsDoublePageSpreads.value ? 1 : 0) // 0 == single, 1 == spread

    let width = window.innerWidth
    let height = window.innerHeight
    let pageWidth = ref(width)
    let pageHeight = ref(height)
    let pageIdx = ref(null)

    let displayDoublePageSpreads = computed(() => {
      return !bp_pageSetting.singlePages.value && displayOption.value == 1
    })

    let setPageDimensions = () => {
      pageWidth.value = width
      pageHeight.value = height

      pageHeight.value = pageHeight.value - 50 // to make room for controls

      pageWidth.value = Math.floor(pageHeight.value * bp_pageSetting.aspectRatio.value) // aspect ratio

      if (pageWidth.value > width) {
        pageWidth.value = width
        pageHeight.value = Math.floor(pageWidth.value / bp_pageSetting.aspectRatio.value) // aspect ratio
      }

      if (displayDoublePageSpreads.value) {
        if (pageWidth.value * 2 > width) {
          pageWidth.value = Math.floor(width / 2)
          pageHeight.value = Math.floor(pageWidth.value / bp_pageSetting.aspectRatio.value) // aspect ratio
        }
      }
    }

    let pagePair = (idx) => {
      let pair = []

      if (!displayDoublePageSpreads.value) {
        if (bp_page.onFlatplan.value[idx]) {
          pair.push(bp_page.onFlatplan.value[idx])
        }
      } else {
        if (idx % 2 == 1) {
          // odd => left page
          if (bp_page.onFlatplan.value[idx]) {
            pair.push(bp_page.onFlatplan.value[idx])
          }

          if (hasNextPage(idx)) {
            if (bp_page.onFlatplan.value[idx + 1]) {
              pair.push(bp_page.onFlatplan.value[idx + 1])
            }
          }
        } else {
          // even => right page
          if (hasPreviousPage(idx)) {
            if (bp_page.onFlatplan.value[idx - 1]) {
              pair.push(bp_page.onFlatplan.value[idx - 1])
            }
          }

          if (bp_page.onFlatplan.value[idx]) {
            pair.push(bp_page.onFlatplan.value[idx])
          }
        }
      }
      return pair
    }

    let showPages = () => {
      let possiblePreviews = []

      let pages = pagePair(pageIdx.value)

      pages.forEach((page, index) => {
        let left = pages.length > 1 && index === 0
        let right = pages.length > 1 && index === 1

        if (!!page.thumbnail && !!page.thumbnail.key) {
          possiblePreviews.push({ id: page._id, url: thumbnailUrl(page.thumbnail), left, right })
        } else {
          possiblePreviews.push({ id: page._id, fragments: page.fragments, left, right })
        }
      })
      previews.value = possiblePreviews
    }

    let thumbnailUrl = (thumbnail) => {
      return cdn.url(thumbnail, { width: pageWidth.value, height: pageHeight.value })
    }

    let dummy = () => {
      // just used to soak up the click so that it doesn't go to the overlay
    }

    let hasNextPage = (idx) => {
      return idx < bp_page.onFlatplan.value.length - 1
    }

    let hasPreviousPage = (idx) => {
      return idx > 0
    }

    let left = () => {
      if (hasLeft.value) {
        pageIdx.value = displayDoublePageSpreads.value ? Math.max(0, pageIdx.value - 2) : pageIdx.value - 1
        showPages()
      }
    }

    let right = () => {
      if (hasRight.value) {
        pageIdx.value = displayDoublePageSpreads.value ? Math.min(bp_page.onFlatplan.value.length - 1, pageIdx.value + 2) : pageIdx.value + 1
        showPages()
      }
    }

    let hasLeft = computed(() => {
      return hasPreviousPage(pageIdx.value)
    })

    let hasRight = computed(() => {
      return hasNextPage(pageIdx.value)
    })

    let keyUpListener = (e) => {
      if (e.key === "Escape") {
        hideSlideShow()
      }
      if (e.key === "ArrowLeft") {
        if (hasLeft.value) {
          left()
        }
      }
      if (e.key === "ArrowRight") {
        if (hasRight.value) {
          right()
        }
      }
    }

    watch(displayOption, (displayOption) => {
      bp_viewSetting.setShowSlideshowAsDoublePageSpreads(displayOption === 1)

      setPageDimensions()
      showPages()
    })

    onMounted(() => {
      Bus.$on("slide-show", ({ pageId }) => {
        overlay.value = true
        pageIdx.value = bp_page.orderForFlatplan.value.indexOf(pageId)

        setPageDimensions()
        showPages()
      })

      document.addEventListener("keyup", keyUpListener)
    })

    onUnmounted(() => {
      Bus.$off("slide-show")
      document.removeEventListener("keyup", keyUpListener)
    })

    return {
      overlay,
      hideSlideShow,
      previews,
      pageWidth,
      pageHeight,
      height,
      width,
      displayOption,
      dummy,
      hasLeft,
      hasRight,
      left,
      right,
      singlePages: bp_pageSetting.singlePages,
      mdiArrowLeft,
      mdiArrowRight,
      mdiFile,
      mdiBookOpenBlankVariant,
    }
  },
}
</script>

<style lang="scss">
.slideshow {
  cursor: pointer;
}
.toggle-display {
  position: absolute;
  right: 0;
}

// HACK: should use :deep(.v-overlay__scrim) in a scoped style block ... But bug.
.overlay .v-overlay__scrim {
  opacity: 0.8;
}
</style>
