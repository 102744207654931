<template>
  <base-dialog :model-value="open" @keydown.esc="closeDialog" @click:outside="closeDialog" size="small">
    <v-card-item>
      <v-card-title>Bulk Edit Category</v-card-title>
      <v-card-subtitle>For pages {{ pageNos ? pageNos.join(", ") : "unknown" }}</v-card-subtitle>
    </v-card-item>
    <v-card-text>
      <v-select v-if="canBulkEditCategories" label="Category" v-model="categoryValue" data-label="bulk assign category" :items="categories" item-title="name" item-value="_id" />
      <span v-else>Not all your selected pages are using the <strong>Full</strong> template. Therefore you can't bulk edit categories.</span>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <cancel-btn @click="closeDialog">Cancel</cancel-btn>
      <submit-btn v-if="canBulkEditCategories" type="submit" @click="saveDialog">Apply</submit-btn>
    </v-card-actions>
  </base-dialog>
</template>

<script>
import { ref, computed } from "vue"

import { usePage } from "../use"
import { useCategory } from "@/modules/category/use"
import { usePageNumbers } from "@/modules/flatplan/usePageNumbers"

export default {
  name: "BulkEditCategory",
  props: {
    open: {
      type: Boolean,
      required: true,
      default: false,
    },
    pageIds: {
      type: Array,
      required: true,
      default: () => {
        return []
      },
    },
  },
  emits: ["update:open"],
  setup(props, context) {
    let bp_page = usePage()
    let bp_category = useCategory()
    let bp_pageNumbers = usePageNumbers()

    let pages = JSON.parse(JSON.stringify(bp_page.getByIds(props.pageIds)))
    
    let getCurrentCategoryId = () => {
      let categoryId = pages.length > 0 && pages[0].fragments.length > 0 ? pages[0].fragments[0].categoryId || bp_category.noneId : bp_category.noneId

      // if not all ("full") pages have the same fragment category, then blank the category rather.
      if (
        pages.some((p) => {
          return categoryId !== (p.fragments[0].categoryId || bp_category.noneId)
        })
      ) {
        categoryId = bp_category.noneId
      }

      return categoryId
    }

    let categoryValue = ref(getCurrentCategoryId())

    let pageNos = pages.map((p) => {
      return bp_pageNumbers.pageNoFor(p._id)
    })

    // when we reach this dialog - it should always return True anyway ... this is just in case.
    let canBulkEditCategories = computed(() => {
      return pages.every((page) => page.template.toLowerCase() === "full")
    })

    let closeDialog = () => {
      context.emit("update:open", false)
    }

    let saveDialog = () => {
      if (canBulkEditCategories.value) {
        pages.forEach((p) => {
          p.fragments[0].categoryId = categoryValue.value
        })

        bp_page.batchUpdate({ pages })
      }

      closeDialog()
    }

    return {
      categories: bp_category.categories.value.concat(bp_category.noneCategory),
      categoryValue,
      pageNos,
      canBulkEditCategories,
      saveDialog,
      closeDialog,
    }
  },
}
</script>

<style lang="scss" scoped></style>
