import { computed } from "vue"
import { usePersonStore } from "./store"

export const usePerson = function () {
  const personStore = usePersonStore()
  const { getByNiceId, del, updatePermission } = personStore

  // store's state is reactively passed on (computed for readonly) - all state changes should occur within store
  return {
    people: computed(() => personStore.people),
    getByNiceId,
    del,
    updatePermission,
  }
}
