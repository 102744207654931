import { useBootstrapFlatplanStore } from "./store"

export const useBootstrapFlatplan = function () {
  const bootstrapFlatplanStore = useBootstrapFlatplanStore()

  const { lastFlatplanId, init, forgetLastFlatplan } = bootstrapFlatplanStore

  return {
    lastFlatplanId,
    init,
    forgetLastFlatplan,
  }
}
