import { useRootStore } from "@/stores/root"
import { NetworkMetrics } from '../metrics/index'

// Constants
const DEFAULT_REQUEST_TIMEOUT = 9000
const requestTimeout = Number(import.meta.env.VITE_REQUEST_TIMEOUT) || DEFAULT_REQUEST_TIMEOUT

if (isNaN(requestTimeout)) {
  console.warn(`Invalid VITE_REQUEST_TIMEOUT value, using default: ${DEFAULT_REQUEST_TIMEOUT}ms`)
}

console.log(`Request timeout: ${requestTimeout}ms`)

// Setup abort controller with fallback
const setupAbortSignal = (timeout) => {
  if (typeof AbortSignal?.timeout === 'function') {
    try {
      const signal = AbortSignal.timeout(timeout)
      // Add a custom property to identify timeout signals
      signal.isTimeoutSignal = true
      return signal
    } catch (error) {
      console.warn('Failed to create AbortSignal:', error)
      return null
    }
  }
  return null
}

export function setupRequestInterceptors(axiosInstance) {
  const metrics = NetworkMetrics.getInstance()

  // Add interceptor to set X-Token dynamically
  axiosInstance.interceptors.request.use(config => {
    const sessionId = sessionStorage.getItem("sessionId")

    if (sessionId) {
      config.headers['X-Token'] = sessionId
    }

    return config
  })

  // Add interceptor to set ability to abort
  axiosInstance.interceptors.request.use(config => {
    const signal = setupAbortSignal(requestTimeout)
    if (signal) {
      config.signal = signal
    }
    return config
  })

  axiosInstance.interceptors.request.use(
    async (config) => {
      try {

        // Store timing info in config for later use
        config.timing = metrics.logRequest(config, Date.now())

        const rootStore = useRootStore()
        rootStore.startLoading()

        // UNCOMMENT if we need to debug DNS issues
        // const connectionDetails = await metrics.measureConnectionDetails()
        // config.timing.connectionDetails = connectionDetails

        return config
      } catch (error) {
        console.error('Request interceptor error:', error)
        const rootStore = useRootStore()
        rootStore.stopLoading()        
        throw error
      }
    },
    (error) => {
      const rootStore = useRootStore()
      rootStore.stopLoading()
      return Promise.reject(error)
    }
  )

  return axiosInstance
}