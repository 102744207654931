<template>
  <v-chip v-for="category in categories" :key="category._id" size="x-small" variant="flat" :color="category.color" class="chip">
    <strong>{{ category.name }}</strong>
    <span class="pl-2"><small>x</small>{{ Math.round((detailsFor(category).fraction + Number.EPSILON) * 100) / 100 }}&nbsp;&nbsp;<small>=</small>{{ ((detailsFor(category).percentage + Number.EPSILON) * 100).toFixed(1) }}%</span>
  </v-chip>
</template>

<script>
import { useCategory } from "../use"
import { useStats } from "../useStats"

export default {
  name: "CategoryStats",
  setup() {
    let bp_category = useCategory()
    let bp_stats = useStats()

    return {
      categories: bp_category.categories,
      detailsFor: bp_stats.detailsFor,
    }
  },
}
</script>

<style lang="scss" scoped>
  .chip {
    color: black;
  }
  // :deep(.v-chip--variant-flat) {
  //   color: black;
  // }
</style>
