import { setupRequestInterceptors } from './request'
import { setupResponseInterceptors } from './response'
import { setupErrorInterceptors } from './error'
import { setupAuthInterceptors } from './auth'
import { setupRetryInterceptors } from './retry'
import { setupMetricsInterceptors } from './metrics'

export function setupInterceptors(axiosInstance) {
  setupRetryInterceptors(axiosInstance)
  setupRequestInterceptors(axiosInstance)
  setupAuthInterceptors(axiosInstance)
  setupResponseInterceptors(axiosInstance)
  setupErrorInterceptors(axiosInstance)
  setupMetricsInterceptors(axiosInstance)
  
  return axiosInstance
}