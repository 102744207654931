<template>
  <base-dialog :model-value="open" @keydown.esc="closeDialog" @click:outside="closeDialog" size="small">
    <v-card-item>
      <v-card-title>Bulk Shift Categories</v-card-title>
      <v-card-subtitle>For pages {{ pageNos ? pageNos.join(", ") : "unknown" }}</v-card-subtitle>
    </v-card-item>
    <v-card-text>
      <v-alert type="info">
        Shift all fragments of that particular category to another category.
      </v-alert>
      <div v-for="category in orderedShiftCategories" class="mt-4">
        <v-row dense>
          <v-col cols="12" sm="4">
            <div class="pt-3">{{ category.name }}</div>
          </v-col>
          <v-col cols="12" sm="8">
            <v-select label="Category" :model-value="shiftCategoryValue(category._id)" @update:modelValue="updateShiftCategoryValue(category._id, $event)" data-label="bulk assign category" :prepend-icon="mdiArrowRight" :items="categories" item-title="name" item-value="_id" />
          </v-col>
        </v-row>
      </div>
      
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <cancel-btn @click="closeDialog">Cancel</cancel-btn>
      <submit-btn type="submit" @click="saveDialog">Apply</submit-btn>
    </v-card-actions>
  </base-dialog>
</template>

<script>
import { ref, computed } from "vue"
import { mdiArrowRight } from "@mdi/js"

import { usePage } from "../use"
import { useCategory } from "@/modules/category/use"
import { usePageNumbers } from "@/modules/flatplan/usePageNumbers"

export default {
  name: "BulkShiftCategories",
  props: {
    open: {
      type: Boolean,
      required: true,
      default: false,
    },
    pageIds: {
      type: Array,
      required: true,
      default: () => {
        return []
      },
    },
  },
  emits: ["update:open"],
  setup(props, context) {
    let bp_page = usePage()
    let bp_category = useCategory()
    let bp_pageNumbers = usePageNumbers()

    let pages = JSON.parse(JSON.stringify(bp_page.getByIds(props.pageIds)))

    let shiftCategoryIds = ref({})
    
    let currentCategories = () => {
      let categoryIds = new Set()

      pages.forEach((p) => {
        p.fragments.forEach((f) => {
          categoryIds.add(f.categoryId || bp_category.noneId)
        })
      })

      return Array.from(categoryIds)
    }

    let initShiftCategories = () => {
      for (const item of currentCategories()) {
        shiftCategoryIds.value[item] = item
      }
    }

    let orderedShiftCategories = computed(() => {
      let categories = bp_category.getByIds(Object.keys(shiftCategoryIds.value))

      categories.sort(bp_category.orderFn)

      return categories
    })

    let updateShiftCategoryValue = (categoryId, value) => {
      shiftCategoryIds.value[categoryId] = value
    }

    let shiftCategoryValue = (categoryId) => {
      return shiftCategoryIds.value[categoryId]
    }

    let pageNos = pages.map((p) => {
      return bp_pageNumbers.pageNoFor(p._id)
    })

    let closeDialog = () => {
      context.emit("update:open", false)
    }

    let saveDialog = () => {
      pages.forEach((p) => {
        p.fragments.forEach((f) => {
          f.categoryId = shiftCategoryIds.value[f.categoryId]
        })
      })

      bp_page.batchUpdate({ pages })

      closeDialog()
    }

    initShiftCategories()

    return {
      categories: bp_category.categories.value.concat(bp_category.noneCategory),
      shiftCategoryIds,
      orderedShiftCategories,
      shiftCategoryValue,
      updateShiftCategoryValue,
      pageNos,
      saveDialog,
      closeDialog,
      mdiArrowRight
    }
  },
}
</script>

<style lang="scss" scoped></style>
