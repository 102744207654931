import { computed, onMounted, onUnmounted, nextTick } from "vue"
import { usePage } from "@/modules/page/use"
import { usePageSetting } from "@/modules/pageSetting/use"
import { useBulkPageSelect } from "@/modules/flatplan/useBulkPageSelect"
import Sortable from "@/lib/sortable/Sortable"

export const usePageSortable = ({ sortableContainer, draggableFor }) => {
  const isHoldingArea = draggableFor.toLowerCase() === "holdingarea"
  const isFlatplan = draggableFor.toLowerCase() === "flatplan"

  let bp_page = usePage()
  let bp_pageSetting = usePageSetting()
  let bp_bulkPageSelect = useBulkPageSelect()

  const coverPageId = computed(() => {
    return bp_page.coverPageId.value
  })

  const pageIds = computed({
    get: () => {
      if (isHoldingArea) {
        return bp_page.orderForHoldingArea.value
      } else if (isFlatplan) {
        return bp_page.orderForInnerPageIds.value
      } else {
        return []
      }
    },
    set: (value) => {
      if (isHoldingArea) {
        bp_page.reorderHoldingArea(value)
      } else if (isFlatplan) {
        if (!bp_pageSetting.singlePages.value) {
          value.splice(0, 0, coverPageId.value)
        }
        bp_page.reorderFlatplan(value)
      } else {
        // noop
      }
    },
  })

  const isLocked = (pageId) => {
    if (!pageId) {
      console.log("We have an undefined pageId for usePageSortable/isLocked")
      return false
    }
    if (pageId.includes("special")) {
      return false
    } else {
      return bp_page.getById(pageId).isLocked
    }
  }

  // the keyup event does not reliably fire to deactivate swap mode
  // so lets force it here when the drag ends.
  const onEnd = (e) => {
    forceDeselectMultiDrag(e)
  }

  const onUpdate = (e) => {
    console.log("Reorder with swapMode: " + sortableContainer.value._sortable.option("swap"))
    bp_page.reorder({ fromHoldingArea: isHoldingArea })
  }

  const onAdd = (e) => {
    nextTick(() => {
      // important this runs in a nextTick. We need to give onRemove a chance to fire too if
      // we need to add tmp spaces, before firing off the reorder to the data service.
      bp_page.reorder({ fromHoldingArea: isHoldingArea })
    })
  }

  const onRemove = (e) => {
    if (!isHoldingArea && !e.swapItem) {
      // we have dragged a page from the flatplan to the holding area
      // we will need to add a space so that we can leave a gap behind
      // on the flatplan
      let indexes = Array.from(new Set([e.oldIndex, ...e.oldIndicies.map((i) => i.index)])) // set ensures uniqueness
      if (!bp_pageSetting.singlePages.value) {
        // remember the cover page isn't part of the sortable - but it is part of the list of order pages
        // so let's add + 1 to each index to account for this.
        indexes = indexes.map((i) => i + 1)
      }
      bp_page.addTmpSpacesToFlatplan({ indexes })
    }
  }

  let forceDeselectMultiDrag = (e) => {
    Sortable.utils.forceDeselectAll("selected")
    bp_bulkPageSelect.clear()

    // not sure if the below is actually still needed ...
    if (sortableContainer.value._sortable.multiDrag) {
      let _deselectMultiDrag = sortableContainer.value._sortable.multiDrag._deselectMultiDrag
      _deselectMultiDrag(e)
    }
  }

  const setSwapMode = (active) => {
    sortableContainer.value._sortable.option("swap", active)

    // let's have this here for debugging
    if (draggableFor === "flatplan") {
      console.log(`swapMode: ${sortableContainer.value._sortable.option("swap")}`)
    }
  }

  let deselectMultiDrag = (e) => {
    if (e.target.closest(".affordance") === null) {
      // This is really so that when we click the cover page - we don't deselect everything
      // The cover isn't part of the sortable ...

      forceDeselectMultiDrag(e)
      setSwapMode(false) // just to be sure
    }
  }

  const keyDownListener = (e) => {
    if (e.key === "Alt") {
      // prevent multiple pages from being swapped by deslecting them when trying to swap
      forceDeselectMultiDrag(e)
      setSwapMode(true)
    }
  }

  const keyUpListener = (e) => {
    // note: e.altKey will be false here
    if (e.key === "Alt") {
      //alt
      forceDeselectMultiDrag(e)
      setSwapMode(false)
    }
  }

  onMounted(() => {
    setSwapMode(false)

    document.addEventListener("keydown", keyDownListener)
    document.addEventListener("keyup", keyUpListener)

    // Prevent deselection on external click.
    // HACK
    // The if statement is just there for tests - not sure how to mock out this stuff

    if (sortableContainer.value._sortable.multiDrag) {
      let _deselectMultiDrag = sortableContainer.value._sortable.multiDrag._deselectMultiDrag

      document.removeEventListener("pointerup", _deselectMultiDrag, false)
      document.removeEventListener("mouseup", _deselectMultiDrag, false)
      document.removeEventListener("touchend", _deselectMultiDrag, false)

      if (document.getElementById("editor-workspace")) {
        document.getElementById("editor-workspace").addEventListener("pointerup", deselectMultiDrag)
        document.getElementById("editor-workspace").addEventListener("mouseup", deselectMultiDrag)
        document.getElementById("editor-workspace").addEventListener("touchend", deselectMultiDrag)
      }
    }
  })

  onUnmounted(() => {
    document.removeEventListener("keydown", keyDownListener)
    document.removeEventListener("keyup", keyUpListener)

    if (document.getElementById("editor-workspace")) {
      document.getElementById("editor-workspace").removeEventListener("pointerup", deselectMultiDrag)
      document.getElementById("editor-workspace").removeEventListener("mouseup", deselectMultiDrag)
      document.getElementById("editor-workspace").removeEventListener("touchend", deselectMultiDrag)
    }
  })

  return {
    pageIds,
    coverPageId,
    onEnd,
    onAdd,
    onUpdate,
    onRemove,
    isLocked,
  }
}
