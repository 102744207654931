import { ref, computed } from "vue"
import { defineStore } from "pinia"

export const useCommandStore = defineStore("command", () => {
  const commands = ref([])

  const count = computed(() => {
    return commands.value.length
  })

  function add(command) {
    commands.value.push(command)

    return command.execute().catch((err) => {
      commands.value.pop()
    })
  }

  function undo() {
    let command = commands.value[count.value - 1] // mutations don't return values - so we can't pop from there
    return command.undo().then(() => {
      commands.value.pop()
      return Promise.resolve()
    })
  }

  return {
    commands,
    count,
    add,
    undo,
  }
})
