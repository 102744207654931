import { computed } from "vue"
import { useBillingStore } from "./store"
import { useAccount } from "@/modules/account/use"

export const useBilling = function () {
  const billingStore = useBillingStore()
  const bp_account = useAccount()
  const { setupStripeSession, deleteAccount, upgrade, update, fetchInvoice, fetchAccountStats } = billingStore

  let hasNextPayment = computed(() => {
    let account = billingStore.account
    return !!account.stripe_current_period_end && account.stripe_canceled_at === null && !!account.stripe_subscription_id && account.active && account.plan.price > 0
  })

  let needsPayment = computed(() => {
    let account = billingStore.account
    return !account.active
  })

  let withinLimitsForPlan = (accountPlan) => {
    let stats = billingStore.accountStats
    return stats.maxPagesForFlatplanCount <= accountPlan.no_of_pages && stats.flatplanCount <= accountPlan.no_of_flatplans
  }

  // store's state is reactively passed on (computed for readonly) - all state changes should occur within store
  return {
    accountName: computed(() => bp_account.currentAccount.value.name),
    account: computed(() => billingStore.account),
    accountPlans: computed(() => billingStore.accountPlans),
    customAccountPlans: computed(() => billingStore.customAccountPlans),
    accountStats: computed(() => billingStore.accountStats),
    invoices: computed(() => billingStore.invoices),
    isDemo: computed(() => billingStore.isDemo),
    fetchAccountStats,
    setupStripeSession,
    upgrade,
    update,
    deleteAccount,
    fetchInvoice,
    hasNextPayment,
    needsPayment,
    withinLimitsForPlan,
  }
}
