<template>
  <v-overlay :model-value="overlay" @click="hideQuickLook" class="overlay">
    <v-sheet class="d-flex align-center flex-column" :height="height" :width="width">
      <v-sheet class="d-flex mt-auto justify-space-around">
        <v-img v-if="!!url" class="quicklook" position="top" :width="pageWidth" :height="pageHeight" :src="url">
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular indeterminate bg-color="transparent" color="muted"></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-sheet>
    </v-sheet>
  </v-overlay>
</template>

<script>
import { ref, computed, onMounted, onUnmounted } from "vue"

import { usePageSetting } from "@/modules/pageSetting/use"
import { cdn } from "@/lib/cdn"
import { Bus } from "@/Bus"

export default {
  name: "QuickLook",
  setup() {
    let bp_pageSetting = usePageSetting()
    let overlay = ref(false)

    let hideQuickLook = () => {
      overlay.value = false
    }

    let thumbnailKey = ref(null)

    let width = window.innerWidth
    let height = window.innerHeight
    let pageWidth = ref(width)
    let pageHeight = ref(height)

    let url = computed(() => {
      return cdn.url(thumbnailKey.value, { width: pageWidth.value, height: pageHeight.value })
    })


    let setPageDimensions = () => {
      pageWidth.value = width
      pageHeight.value = height

      pageWidth.value = Math.floor(pageHeight.value * bp_pageSetting.aspectRatio.value) // aspect ratio

      if (pageWidth.value > width) {
        pageWidth.value = width
        pageHeight.value = Math.floor(pageWidth.value / bp_pageSetting.aspectRatio.value) // aspect ratio
      }

    }

    onMounted(() => {
      Bus.$on("quick-look", ({ thumbnail }) => {
        thumbnailKey.value = thumbnail

        setPageDimensions()

        overlay.value = true
      })
    })

    onUnmounted(() => {
      Bus.$off("quick-look")
    })

    return {
      overlay,
      hideQuickLook,
      url,
      pageWidth,
      pageHeight,
      height,
      width,
    }
  },
}
</script>

<style lang="scss" scoped>
.quicklook {
  cursor: pointer;
}

// HACK: should use :deep(.v-overlay__scrim) in a scoped style block ... But bug.
.overlay .v-overlay__scrim {
  opacity: 0.8;
}
</style>
