<template>
  <v-card color="background" width="350">
    <v-form @submit.prevent="changePosition">
      <v-card-text>
        <div class="d-flex">
          <v-select v-model="action" :items="actions" data-label="move action"></v-select>
          <v-autocomplete
            class="ml-2"
            v-model="comboPageNo"
            :items="pageNumbers"
            placeholder="page"
            @keyup.enter="setSelectedPageNoAndSubmit"
            @blur="setSelectedPageNo"
            data-label="move pageNo textbox"
            autofocus
            auto-select-first
            no-data-text="That page doesn't exist"
          >
            <template v-slot:item="{ props, item }">
              <v-list-item
                v-if="item.raw === pageNo" 
                v-bind="props"
                :title="item.raw + ' ' + '(current)'"
              ></v-list-item>              
              <v-list-item
                v-else
                v-bind="props"
                :title="item.raw"
              ></v-list-item> 
            </template>
          </v-autocomplete>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <cancel-btn @click="closeDialog">Cancel</cancel-btn>
        <submit-btn type="submit">{{ actionButtonLabel }}</submit-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import { ref, computed } from "vue"

import { usePageNumbers } from "@/modules/flatplan/usePageNumbers"
import { usePageSetting } from "@/modules/pageSetting/use"
import { useNotification } from "@/modules/notification/use"
import { usePage } from "../use"

export default {
  name: "PageMove",
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    pageId: {
      type: String,
      required: true,
    },
  },
  setup(props, context) {
    let bp_page = usePage()
    let bp_pageNumbers = usePageNumbers()
    let bp_pageSetting = usePageSetting()

    let pageNo = bp_pageNumbers.pageNoFor(props.pageId)

    const swapAction = "Swap with"
    const moveBeforeAction = "Move before"
    const moveAfterAction = "Move after"
    const actions = [swapAction, moveBeforeAction, moveAfterAction]

    let closeDialog = () => {
      context.emit("update:open", false)
    }

    let pageNumbers = bp_pageNumbers.all.value.slice(bp_pageSetting.singlePages.value ? 0 : 1)

    let selectedPageNo = ref("")
    let comboPageNo = ref("")
    let action = ref(actions[0])

    let actionButtonLabel = computed(() => {
      return action.value.split(" ")[0]
    })

    let getCurrentAndAffectedIndex = () => {
      let order = [...bp_page.orderForFlatplan.value]
      let currentPageNoOrder = order.map((o) => bp_pageNumbers.pageNoFor(o))

      let currentIndex = order.indexOf(props.pageId)
      let targetIndex = currentPageNoOrder.indexOf(selectedPageNo.value) // find first occurence of page number

      return {
        currentIndex,
        targetIndex,
      }
    }

    let calcSwap = () => {
      let order = [...bp_page.orderForFlatplan.value]
      let { currentIndex, targetIndex } = getCurrentAndAffectedIndex()

      let affectedPageIds = [props.pageId, order[targetIndex]]
      // swap pages
      order.splice(currentIndex, 1, order.splice(targetIndex, 1, order[currentIndex])[0])

      return {
        order,
        affectedPageIds,
      }
    }

    let calcMoveBefore = () => {
      let order = [...bp_page.orderForFlatplan.value]
      let { currentIndex, targetIndex } = getCurrentAndAffectedIndex()

      let affectedPageIds = [props.pageId]

      // insert before
      if (currentIndex < targetIndex) {
        order.splice(targetIndex - 1, 0, order.splice(currentIndex, 1)[0])
      } else {
        order.splice(targetIndex, 0, order.splice(currentIndex, 1)[0])
      }

      return {
        order,
        affectedPageIds,
      }
    }

    let calcMoveAfter = () => {
      let order = [...bp_page.orderForFlatplan.value]
      let { currentIndex, targetIndex } = getCurrentAndAffectedIndex()

      let affectedPageIds = [props.pageId]

      // insert after
      if (currentIndex < targetIndex) {
        order.splice(targetIndex, 0, order.splice(currentIndex, 1)[0])
      } else {
        order.splice(targetIndex + 1, 0, order.splice(currentIndex, 1)[0])
      }

      return {
        order,
        affectedPageIds,
      }
    }

    let changePosition = () => {
      let order
      let affectedPageIds

      if (selectedPageNo.value.length > 0) {
        if (pageNumbers.indexOf(selectedPageNo.value) === -1) {
          let bp_notification = useNotification()
          bp_notification.show({
            type: "error",
            message: "That page doesn't exist ...",
          })
          return
        } else {
          switch (action.value) {
            case swapAction:
              ;({ order, affectedPageIds } = calcSwap())
              break
            case moveBeforeAction:
              ;({ order, affectedPageIds } = calcMoveBefore())
              break
            case moveAfterAction:
              ;({ order, affectedPageIds } = calcMoveAfter())
              break
          }
          // we don't actually save any move here - just emit the new order
          context.emit("moved", { order, affectedPageIds, description: `${action.value} ${selectedPageNo.value}` })
        }
      }

      closeDialog()
    }

    // we go through this whole complicated dance (as opposed to just using the comboPageNo model)
    // so that somebody could type in a page number, and then without clicking on the autocomplete suggestion, hits
    // move button - this way we still get the value of the autocomplete via the blur event (else the comboPageNo would not be set)
    let setSelectedPageNo = (e) => {
      selectedPageNo.value = e.target.value
    }

    let setSelectedPageNoAndSubmit = (e) => {
      selectedPageNo.value = e.target.value
      changePosition()
    }

    return {
      setSelectedPageNo,
      setSelectedPageNoAndSubmit,
      pageNo,
      closeDialog,
      changePosition,
      pageNumbers,
      comboPageNo,
      actions,
      action,
      actionButtonLabel,
      includeFirstPageNumber: bp_pageSetting.singlePages,
    }
  },
}
</script>

<style lang="scss" scoped></style>
