<template>
  <base-dialog :model-value="open" @keydown.esc="closeDialog" @click:outside="closeDialog" size="small">
    <v-card-item>
      <v-card-title>Bulk Edit Tags</v-card-title>
      <v-card-subtitle>For pages {{ pageNos.join(", ") }}</v-card-subtitle>
    </v-card-item>
    <v-card-text>
      <AssignTags v-model:tags="tags" data-label="bulk assign tags" />
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <cancel-btn @click="closeDialog"> Cancel </cancel-btn>
      <submit-btn type="submit" @click="saveDialog">Apply</submit-btn>
    </v-card-actions>
  </base-dialog>
</template>

<script>
import { ref } from "vue"

import { usePage } from "../use"
import { useTag } from "@/modules/tag/use"
import { usePageNumbers } from "@/modules/flatplan/usePageNumbers"

import AssignTags from "@/modules/tag/components/AssignTags"

export default {
  name: "BulkEditTags",
  props: {
    open: {
      type: Boolean,
      required: true,
      default: false,
    },
    pageIds: {
      type: Array,
      required: true,
      default: () => {
        return []
      },
    },
  },
  emits: ["update:open"],
  components: {
    AssignTags,
  },
  setup(props, context) {
    let bp_page = usePage()
    let bp_tag = useTag()
    let bp_pageNumbers = usePageNumbers()

    let pages = JSON.parse(JSON.stringify(bp_page.getByIds(props.pageIds)))

    let getCurrentTags = () => {
      let tagRefs = pages.length > 0 ? pages[0].tagRefs || [] : []

      // if not all pages have the same tagRefs, then blank the tagRefs rather.
      if (
        pages.some((p) => {
          return tagRefs.sort().toString() !== (p.tagRefs || []).sort().toString()
        })
      ) {
        tagRefs = []
      }

      return bp_tag.getByIds(tagRefs) || [] // add the || [] for tests 
    }

    let tags = ref(getCurrentTags())

    let pageNos = pages.map((p) => {
      return bp_pageNumbers.pageNoFor(p._id)
    })

    let closeDialog = () => {
      context.emit("update:open", false)
    }

    let saveDialog = () => {
      pages.forEach((p) => {
        p.tagRefs = tags.value.filter((t) => !t.create).map((t) => t._id)
      })

      bp_page.batchUpdate({ pages, createTags: tags.value.filter((t) => t.create).map((t) => t.name) })

      closeDialog()
    }

    return {
      tags,
      pageNos,
      saveDialog,
      closeDialog,
    }
  },
}
</script>

<style lang="scss" scoped></style>
