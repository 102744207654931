import { useBootstrapShareStore } from "./store"

export const useBootstrapShare = function () {
  const bootstrapShareStore = useBootstrapShareStore()

  const { fetch } = bootstrapShareStore

  return {
    fetch,
  }
}
