<template>
  <v-container fluid>
    <h4 class="text-h4">
      People for <span class="font-weight-black" v-if="!!account">{{ account.name }}</span>
    </h4>
    <br />
    <v-alert type="info"
      >You can have up to <strong>{{ account.plan.no_of_users }}</strong> people including you.</v-alert
    >
    <v-list bg-color="transparent" class="mt-4">
      <v-list-item v-for="person in people" :key="person.nice_id" @click="showPerson(person.nice_id)">
        <template v-slot:prepend>
          <v-icon>{{ mdiAccount }}</v-icon>
        </template>
        <v-list-item-title>{{ person.name }}<span v-if="person.email === you" class="text-caption">&nbsp;(you)</span></v-list-item-title>
      </v-list-item>
    </v-list>
  </v-container>
</template>

<script>
import { useRouter } from "vue-router"
import { mdiAccount } from "@mdi/js"

import { usePerson } from "../use"
import { useBilling } from "@/modules/billing/use"
import { useAuth } from "@/modules/auth/use"

export default {
  name: "PeopleView",
  setup() {
    let router = useRouter()
    let bp_person = usePerson()
    let bp_billing = useBilling()
    let bp_auth = useAuth()

    let account = bp_billing.account
    let people = bp_person.people

    let showPerson = (niceId) => {
      router.push({ name: "person-show", params: { niceId } })
    }

    return {
      account,
      people,
      showPerson,
      you: bp_auth.email,
      mdiAccount,
    }
  },
}
</script>

<style scoped>
.person {
  cursor: pointer;
}
</style>
