<template>
  <v-item-group selected-class="border" @update:model-value="selected" :model-value="modelValue" mandatory data-test-id="selection">
    <div class="d-flex flex-wrap">
      <div v-for="color in colors" :key="color" class="mr-1 mb-1">
        <v-item v-slot:default="{ selectedClass, toggle }" :value="color">
          <v-sheet height="25" width="25" @click="toggle" :color="color" :rounded="rounded" :class="[selectedClass]"> </v-sheet>
        </v-item>
      </div>
    </div>
  </v-item-group>
</template>

<script>
export default {
  name: "ColorSelector",
  props: {
    colors: {
      type: Array,
      required: true,
    },
    modelValue: {
      type: String,
    },
    rounded: {
      type: [Boolean, String],
    },
  },
  emits: ['update:modelValue'],
  setup(props, context) {
    let selected = (color) => {
      context.emit("update:modelValue", color)
    }

    return {
      selected,
    }
  },
}
</script>

<style lang="scss" scoped>
.border {
  border: 2px solid black !important;
}
</style>
