<template>
  <v-container fluid>
    <Flatplan v-if="!isArchived" />
    <ArchivedFlatplan v-else />
    <router-view name="dialog" />
  </v-container>
</template>

<script>
import Flatplan from "./Flatplan"
import ArchivedFlatplan from "./ArchivedFlatplan"

import { useFlatplan } from "../use"

export default {
  name: "FlatplanShowView",
  components: {
    Flatplan,
    ArchivedFlatplan,
  },
  setup() {
    let bp_flatplan = useFlatplan()

    return {
      isArchived: bp_flatplan.isArchived,
    }
  },
}
</script>
