import { useFlatplan } from "@/modules/flatplan/use"
import { usePage } from "@/modules/page/use"
import { useCategory } from "@/modules/category/use"
import { useTag } from "@/modules/tag/use"
import { useStitchIn } from "@/modules/stitchIn/use"
import { useSection } from "@/modules/section/use"
import { useContent } from "@/modules/content/use"

export const useLiveupdate = function () {
  let bp_flatplan = useFlatplan()
  let bp_page = usePage()
  let bp_category = useCategory()
  let bp_tag = useTag()
  let bp_stitchIn = useStitchIn()
  let bp_section = useSection()
  let bp_content = useContent()

  let connectAttempts = 1
  let applyNextMessage = false

  let heartbeatMsg = "--heartbeat--"
  let heartbeatInterval = null
  let missedHeartbeats = 0

  let handleWebsockets = (sessionId) => {
    let liveupdateUrl = import.meta.env.VITE_LIVEUPDATE_ENDPOINT + "/ws/" + bp_flatplan.id.value + "/" + sessionId
    let conn = new WebSocket(liveupdateUrl)

    conn.onopen = (evt) => {
      console.log(`Connected to ${liveupdateUrl}`)
      connectAttempts = 1
      if (heartbeatInterval === null) {
        missedHeartbeats = 0
        heartbeatInterval = setInterval(() => {
          try {
            missedHeartbeats += 1
            if (missedHeartbeats >= 3) {
              throw new Error("Too many missed heartbeats.")
            }
            if (conn.readyState === WebSocket.OPEN) {
              conn.send(heartbeatMsg)
            }
          } catch (e) {
            clearInterval(heartbeatInterval)
            heartbeatInterval = null
            if (conn.readyState === WebSocket.OPEN) {
              conn.close()
            }
          }
        }, 5000)
      }
    }

    conn.onclose = (evt) => {
      console.log("Lost connection to Liveupdate!")
      let t = generateInterval(connectAttempts)
      setTimeout(() => {
        connectAttempts += 1
        handleWebsockets(sessionId)
      }, t)
    }

    conn.onmessage = (evt) => {
      if (evt.data === heartbeatMsg) {
        missedHeartbeats = 0
        return
      }

      let message = JSON.parse(evt.data)
      // console.log("Message JSON", message, evt)

      //this is for the following scenario
      //1) liveupdate is disconnected
      //2) another makes an update - and the message is in the queue
      //3) we make an update to the same object; message added to queue, and interface updated
      //4) liveupdate connects to find 2 messages
      //5) another's message is applied
      //6) our queued message needs to be reapplied
      //We need to ensure that if liveupdate goes down - that our own older messages in the queue get reapplied in case there are newer messages in the queue (from others) overwriting our changes already visible in the interface.
      applyNextMessage = message.token !== sessionId

      if (applyNextMessage) {
        // we don't want to update when the liveupdate message was in response
        // to one of our own API calls.

        switch (message.controller) {
          // these are system messages - so they bypass the check whether we generated the message or not
          case "triggers":
            switch (message.msgType) {
              case "updatePageCommentCount":
                // currently used to update commentCount
                bp_page.setCommentCount({ pageId: message.data._id, commentCount: message.data.commentCount })
                break
            }
            break
          case "pages":
            switch (message.msgType) {
              case "update":
                let page = bp_page.getById(message.data._id)
                if (page) {
                  bp_page.updatePage({ pageId: page._id, fragments: message.data.fragments, thumbnail: message.data.thumbnail, tags: { tagRefs: message.data.tagRefs }, notes: message.data.notes, fromLiveUpdate: true })
                  bp_page.changePageLock({ pageId: page._id, isLocked: message.data.isLocked, fromLiveUpdate: true })
                } else {
                  // NOTE: I think we're only doing batchDestroy's ... so don't think we'll ever make it here.
                  // this scenario occurs when we undo a destroy
                  // model = new Page.Model(message.data, { collection: collection })
                  // model.set("_fadeIn", true)
                  // collection.add(model)
                }
                break
              case "batchCreate":
                bp_page.addPagesFromLiveupdate({ addedPages: message.data.addedPages, shiftedPageData: message.data.shiftedPageData })
                break
              case "batchDestroy":
                bp_page.deletePages({ pageIds: message.data.pages.map((p) => p._id), fromLiveUpdate: true })
                break
              case "batchStretch":
                bp_page.batchUpdate({ pages: message.data.pages, fromLiveUpdate: true })
                break
              case "batchUpdate":
                message.data.createTags.forEach((tag) => {
                  bp_tag.addFromLiveupdate(tag)
                })
                bp_page.batchUpdate({ pages: message.data.pages, fromLiveUpdate: true })
                break
              case "reorder":
                bp_page.reorder({ orderData: message.data.order, fromLiveUpdate: true })
                break
              case "createTags":
                message.data.tags.forEach((tag) => {
                  bp_tag.addFromLiveupdate(tag)
                })
                break
            }
            break
          case "categories":
            let category
            switch (message.msgType) {
              case "create":
                bp_category.addFromLiveupdate(message.data)
                break
              case "update":
                category = bp_category.getById(message.data._id)
                if (category) {
                  bp_category.update({ categoryId: category._id, ...message.data, fromLiveUpdate: true })
                } else {
                  // must be an undelete
                  bp_category.addFromLiveupdate(message.data)
                }
                break
              case "destroy":
                category = bp_category.getById(message.data._id)
                if (category) {
                  bp_category.del({ categoryId: category._id, fromLiveUpdate: true })
                }
                break
              case "reorder":
                bp_category.reorder({ orderData: message.data.order, fromLiveUpdate: true })
                break
            }
            break
          case "tags":
            let tag
            switch (message.msgType) {
              case "create":
                bp_tag.addFromLiveupdate(message.data)
                break
              case "update":
                tag = bp_tag.getById(message.data._id)
                if (tag) {
                  bp_tag.update({ tagId: tag._id, ...message.data, fromLiveUpdate: true })
                } else {
                  // must be an undelete
                  bp_tag.addFromLiveupdate(message.data)
                }
                break
              case "destroy":
                tag = bp_tag.getById(message.data._id)
                if (tag) {
                  bp_tag.del({ tagId: tag._id, fromLiveUpdate: true })
                }
                break
              case "reorder":
                bp_tag.reorder({ orderData: message.data.order, fromLiveUpdate: true })
                break
            }
            break
          case "stitchIns":
            let stitchIn
            switch (message.msgType) {
              case "create":
                bp_stitchIn.addFromLiveupdate(message.data)
                break
              case "update":
                stitchIn = bp_stitchIn.getById(message.data._id)
                if (stitchIn) {
                  bp_stitchIn.update({ stitchInId: stitchIn._id, ...message.data, fromLiveUpdate: true })
                } else {
                  // must be an undelete
                  bp_stitchIn.addFromLiveupdate(message.data)
                }
                break
              case "destroy":
                stitchIn = bp_stitchIn.getById(message.data._id)
                if (stitchIn) {
                  bp_stitchIn.del({ stitchInId: stitchIn._id, fromLiveUpdate: true })
                }
                break
            }
            break
          case "sections":
            let section
            switch (message.msgType) {
              case "create":
                bp_section.addFromLiveupdate(message.data)
                break
              case "update":
                section = bp_section.getById(message.data._id)
                if (section) {
                  bp_section.update({ sectionId: section._id, ...message.data, fromLiveUpdate: true })
                } else {
                  // must be an undelete
                  bp_section.addFromLiveupdate(message.data)
                }
                break
              case "destroy":
                section = bp_section.getById(message.data._id)
                if (section) {
                  bp_section.del({ sectionId: section._id, fromLiveUpdate: true })
                }
                break
            }
            break
          case "contents":
            let content
            switch (message.msgType) {
              case "update":
                content = bp_content.getById(message.data._id)
                if (content) {
                  bp_content.update({ contentId: content._id, ...message.data, fromLiveUpdate: true })
                } else {
                  // must be an undelete
                  bp_content.addContentsFromLiveupdate({ updatedContents: [message.data] })
                }
                break
              case "batchCreate":
                bp_content.addContentsFromLiveupdate({ addedContents: message.data.addedContents, updatedContents: message.data.updatedContents })
                break
              case "batchDestroy":
                bp_content.batchDel({ contentIds: message.data.contents.map((c) => c._id), fromLiveUpdate: true })
                break
            }
            break
          case "flatplans":
            switch (message.msgType) {
              case "create":
                // we won't be getting the creates
                // as we are listening on the current Flatplan's channel.
                break
              case "update":
                // we can only update the current one.
                if (message.data._id === bp_flatplan.id.value) {
                  bp_flatplan.update({ ...message.data, fromLiveUpdate: true })
                  bp_flatplan.updatePageNumberings({ ...message.data, fromLiveUpdate: true })
                }
                break
            }
            break
        }
      }
    }
  }

  let generateInterval = (k) => {
    // Exponential Backoff algorithm
    let maxInterval = (Math.pow(2, k) - 1) * 1000

    if (maxInterval > 30 * 1000) {
      // If the generated interval is more than 30 seconds, truncate it down to 30 seconds.
      maxInterval = 30 * 1000
    }

    // generate the interval to a random number between 0 and the maxInterval determined from above
    return Math.random() * maxInterval
  }

  if (window["WebSocket"]) {
    // vent.on "sync", (method, model, opts) =>
    //   if method isnt 'read'
    //     @applyNextMessage = false # reset
    handleWebsockets(sessionStorage.getItem("sessionId"))
  }

  return {}
}
