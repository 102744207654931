<template>
  <div>
    <hr class="mb-4" />
    <v-container>
      <v-row>
        <v-col cols="12" sm="4">
          <span class="text-subtitle-1">Name on card</span>: <span class="text-caption">{{ account.stripe_card_name }}</span
          ><br />
          <span class="text-subtitle-1">Type of card</span>: <span class="text-caption">{{ account.stripe_cardbp_type }}</span
          ><br />
          <span class="text-subtitle-1">Credit card number</span>: <span class="text-caption">xxxx xxxx xxxx {{ account.stripe_card_last4 }}</span
          ><br />
          <span class="text-subtitle-1">Expires</span>: <span class="text-caption">{{ account.stripe_card_exp_month }}/{{ account.stripe_card_exp_year }}</span
          ><br />
        </v-col>
        <v-col cols="12" sm="2">
          <v-btn @click="redirectToCheckout" color="primary" variant="text" :disabled="!sessionId"
            ><v-icon start>{{ mdiCreditCardOutline }}</v-icon
            >Update payment details</v-btn
          >
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { ref, onMounted } from "vue"
import { mdiCreditCardOutline } from "@mdi/js"

import { useBilling } from "../use"
import { useNotification } from "@/modules/notification/use"

export default {
  name: "PaymentDetails",
  props: {
    account: {
      type: Object,
      required: true,
    },
  },
  setup() {
    let bp_billing = useBilling()
    let bp_notification = useNotification()
    let sessionId = ref(null)
    let checkoutUrl = ref(null)

    let setupStripeSession = () => {
      bp_billing
        .setupStripeSession()
        .then(({ data }) => {
          sessionId.value = data.session_id
          checkoutUrl.value = data.checkout_url
        })
        .catch((err) => {
          bp_notification.show({
            type: "error",
            message: "Unable to connect to the payment gateway.",
          })
        })
    }

    let redirectToCheckout = () => {
      location.href = checkoutUrl.value
    }

    onMounted(() => {
      setupStripeSession()
    })

    return {
      sessionId,
      redirectToCheckout,
      mdiCreditCardOutline    }
  },
}
</script>

<style lang="scss" scoped></style>
