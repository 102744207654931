export default [
  {
    name: "full",
    shapeDrawPlan: [[1]],
    fractionX: 1,
    fractionY: 1,
    size: "1",
    shape: "full"
  },
  {
    name: "horizontal_1/2",
    shapeDrawPlan: [[1]],
    fractionX: 1,
    fractionY: 0.5,
    size: "1/2",
    shape: "horizontal"
  },
  {
    name: "horizontal_1/3",
    shapeDrawPlan: [[1]],
    fractionX: 1,
    fractionY: 1 / 3,
    size: "1/3",
    shape: "horizontal"
  },
  {
    name: "horizontal_2/3",
    shapeDrawPlan: [[1]],
    fractionX: 1,
    fractionY: 2 / 3,
    size: "2/3",
    shape: "horizontal"
  },
  {
    name: "horizontal_1/4",
    shapeDrawPlan: [[1]],
    fractionX: 1,
    fractionY: 0.25,
    size: "1/4",
    shape: "horizontal"
  },
  {
    name: "horizontal_3/4",
    shapeDrawPlan: [[1]],
    fractionX: 1,
    fractionY: 0.75,
    size: "3/4",
    shape: "horizontal"
  },
  {
    name: "horizontal_1/6",
    shapeDrawPlan: [[1]],
    fractionX: 1,
    fractionY: 1 / 6,
    size: "1/6",
    shape: "horizontal"
  },
  {
    name: "horizontal_1/7",
    shapeDrawPlan: [[1]],
    fractionX: 1,
    fractionY: 1 / 7,
    size: "1/7",
    shape: "horizontal"
  },
  {
    name: "horizontal_1/10",
    shapeDrawPlan: [[1]],
    fractionX: 1,
    fractionY: 1 / 10,
    size: "1/10",
    shape: "horizontal"
  },
  {
    name: "horizontal_9/10",
    shapeDrawPlan: [[1]],
    fractionX: 1,
    fractionY: 9 / 10,
    size: "9/10",
    shape: "horizontal"
  },
  {
    name: "horizontal_1/5",
    shapeDrawPlan: [[1]],
    fractionX: 1,
    fractionY: 1 / 5,
    size: "1/5",
    shape: "horizontal"
  },
  {
    name: "vertical_1/2",
    shapeDrawPlan: [[1]],
    fractionX: 1 / 2,
    fractionY: 1,
    size: "1/2",
    shape: "vertical"
  },
  {
    name: "vertical_1/3",
    shapeDrawPlan: [[1]],
    fractionX: 1 / 3,
    fractionY: 1,
    size: "1/3",
    shape: "vertical"
  },
  {
    name: "vertical_2/3",
    shapeDrawPlan: [[1]],
    fractionX: 2 / 3,
    fractionY: 1,
    forceHorizontal: true,
    size: "2/3",
    shape: "vertical"
  },
  {
    name: "square_1/4",
    shapeDrawPlan: [[1]],
    fractionX: 0.5,
    fractionY: 0.5,
    size: "1/4",
    shape: "square"
  },
  {
    name: "bottomRightCorner_3/4",
    shapeDrawPlan: [
      [0, 1],
      [1, 1],
    ],
    fractionX: 1,
    fractionY: 1,
    textOffsetFractionY: 0.25,
    size: "3/4",
    shape: "L-shape"
  },
  {
    name: "bottomLeftCorner_3/4",
    shapeDrawPlan: [
      [1, 0],
      [1, 1],
    ],
    fractionX: 1,
    fractionY: 1,
    textOffsetFractionY: 0.25,
    size: "3/4",
    shape: "L-shape"
  },
  {
    name: "topRightCorner_3/4",
    shapeDrawPlan: [
      [1, 1],
      [0, 1],
    ],
    fractionX: 1,
    fractionY: 1,
    textOffsetFractionY: -0.25,
    size: "3/4",
    shape: "L-shape"
  },
  {
    name: "topLeftCorner_3/4",
    shapeDrawPlan: [
      [1, 1],
      [1, 0],
    ],
    fractionX: 1,
    fractionY: 1,
    textOffsetFractionY: -0.25,
    size: "3/4",
    shape: "L-shape"
  },
  {
    name: "topRightCorner_3/4_3cols",
    shapeDrawPlan: [
      [1, 1, 1],
      [1, 1, 1],
      [1, 1, 1],
      [1, 1, 1],
      [1, 1, 1],
      [0, 0, 1],
      [0, 0, 1],
      [0, 0, 1],
    ],
    fractionX: 1,
    fractionY: 1,
    textCompressFractionY: 2 / 3,
    size: "3/4",
    shape: "L-shape"
  },
  {
    name: "topLeftCorner_3/4_3cols",
    shapeDrawPlan: [
      [1, 1, 1],
      [1, 1, 1],
      [1, 1, 1],
      [1, 1, 1],
      [1, 1, 1],
      [1, 0, 0],
      [1, 0, 0],
      [1, 0, 0],
    ],
    fractionX: 1,
    fractionY: 1,
    textCompressFractionY: 2 / 3,
    size: "3/4",
    shape: "L-shape"
  },
  {
    name: "topLeftCorner_10/12_3cols",
    shapeDrawPlan: [
      [1, 1, 1],
      [1, 1, 1],
      [1, 1, 1],
      [1, 0, 0],
    ],
    fractionX: 1,
    fractionY: 1,
    textCompressFractionY: 2 / 3,
    size: "5/6",
    shape: "L-shape"
  },
  {
    name: "blockHorizontal_1/4_3cols",
    shapeDrawPlan: [[1]],
    fractionX: 2 / 3,
    fractionY: 3 / 8,
    size: "1/4",
    shape: "horizontal"
  },
  {
    name: "blockHorizontal_1/8",
    shapeDrawPlan: [[1]],
    fractionX: 0.5,
    fractionY: 0.25,
    size: "1/8",
    shape: "horizontal"
  },
  {
    name: "blockVertical_3/8",
    shapeDrawPlan: [[1]],
    fractionX: 0.5,
    fractionY: 0.75,
    size: "3/8",
    shape: "vertical"
  },
  {
    name: "blockHorizontal_3/16_fat",
    shapeDrawPlan: [[1]],
    fractionX: 0.5,
    fractionY: 3 / 8,
    size: "3/16",
    shape: "horizontal"
  },
  {
    name: "blockHorizontal_3/16_thin",
    shapeDrawPlan: [[1]],
    fractionX: 0.75,
    fractionY: 0.25,
    size: "3/16",
    shape: "horizontal"
  },
  {
    name: "blockHorizontal_3/4",
    shapeDrawPlan: [[1]],
    fractionX: 1,
    fractionY: 0.75,
    size: "3/4",
    shape: "horizontal"
  },
  {
    name: "topRightCorner_5/8",
    shapeDrawPlan: [
      [1, 1],
      [1, 1],
      [0, 1],
    ],
    fractionX: 1,
    fractionY: 0.75,
    textCompressFractionY: 2 / 3,
    size: "5/8",
    shape: "L-shape"
  },
  {
    name: "topLeftCorner_5/8",
    shapeDrawPlan: [
      [1, 1],
      [1, 1],
      [1, 0],
    ],
    fractionX: 1,
    fractionY: 0.75,
    textCompressFractionY: 2 / 3,
    size: "5/8",
    shape: "L-shape"
  },
  {
    name: "blockHorizontal_1/12_2cols",
    shapeDrawPlan: [[1]],
    fractionX: 0.5,
    fractionY: 1 / 6,
    size: "1/12",
    shape: "horizontal"
  },
  {
    name: "blockHorizontal_1/12_3cols",
    shapeDrawPlan: [[1]],
    fractionX: 1 / 3,
    fractionY: 1 / 4,
    size: "1/12",
    shape: "horizontal"
  },
  {
    name: "blockHorizontal_1/6_2col",
    shapeDrawPlan: [[1]],
    fractionX: 0.5,
    fractionY: 1 / 3,
    size: "1/6",
    shape: "horizontal"
  },
  {
    name: "blockVertical_1/6_3cols",
    shapeDrawPlan: [[1]],
    fractionX: 1 / 3,
    fractionY: 0.5,
    size: "1/6",
    shape: "vertical"
  },
  {
    name: "blockHorizontal_1/6_3cols",
    shapeDrawPlan: [[1]],
    fractionX: 2 / 3,
    fractionY: 0.25,
    size: "1/6",
    shape: "horizontal"
  },
  {
    name: "blockHorizontal_2/3",
    shapeDrawPlan: [[1]],
    fractionX: 1,
    fractionY: 2 / 3,
    size: "2/3",
    shape: "horizontal"
  },
  {
    name: "topRightCorner_5/12_thin",
    shapeDrawPlan: [
      [1, 1],
      [0, 1],
      [0, 1],
      [0, 1],
    ],
    fractionX: 1,
    fractionY: 2 / 3,
    textOffsetFractionX: 0.5,
    textCompressFractionX: 0.5,
    size: "5/12",
    shape: "L-shape"
  },
  {
    name: "topLeftCorner_5/12_thin",
    shapeDrawPlan: [
      [1, 1],
      [1, 0],
      [1, 0],
      [1, 0],
    ],
    fractionX: 1,
    fractionY: 2 / 3,
    textCompressFractionX: 0.5,
    size: "5/12",
    shape: "L-shape"
  },
  {
    name: "topLeftCorner_5/12_fat",
    shapeDrawPlan: [
      [1, 1, 1],
      [1, 1, 0],
    ],
    fractionX: 1,
    fractionY: 0.5,
    textCompressFractionX: 2 / 3,
    size: "5/12",
    shape: "L-shape"
  },
  {
    name: "topRightCorner_5/6",
    shapeDrawPlan: [
      [1, 1, 1],
      [0, 1, 1],
    ],
    fractionX: 1,
    fractionY: 1,
    textCompressFractionY: 0.5,
    size: "5/6",
    shape: "L-shape"
  },
  {
    name: "topLeftCorner_5/6",
    shapeDrawPlan: [
      [1, 1, 1],
      [1, 1, 0],
    ],
    fractionX: 1,
    fractionY: 1,
    textCompressFractionY: 0.5,
    size: "5/6",
    shape: "L-shape"
  },
  {
    name: "blockVertical_1/3",
    shapeDrawPlan: [[1]],
    fractionX: 0.5,
    fractionY: 2 / 3,
    size: "1/3",
    shape: "vertical"
  },
  {
    name: "blockHorizontal_1/10",
    shapeDrawPlan: [[1]],
    fractionX: 0.5,
    fractionY: 1 / 5,
    size: "1/10",
    shape: "horizontal"
  },
  {
    name: "topRightCorner_3/8",
    shapeDrawPlan: [
      [1, 1],
      [0, 1],
    ],
    fractionX: 1,
    fractionY: 0.5,
    textCompressFractionY: 0.5,
    size: "3/8",
    shape: "L-shape"
  },
  {
    name: "topLeftCorner_3/8",
    shapeDrawPlan: [
      [1, 1],
      [1, 0],
    ],
    fractionX: 1,
    fractionY: 0.5,
    textCompressFractionY: 0.5,
    size: "3/8",
    shape: "L-shape"
  },
  {
    name: "topRightCorner_1/2",
    shapeDrawPlan: [
      [1, 1],
      [0, 1],
      [0, 1],
    ],
    fractionX: 1,
    fractionY: 0.75,
    textCompressFractionY: 1 / 3,
    size: "1/2",
    shape: "L-shape"
  },
  {
    name: "topLeftCorner_1/2",
    shapeDrawPlan: [
      [1, 1],
      [1, 0],
      [1, 0],
    ],
    fractionX: 1,
    fractionY: 0.75,
    textCompressFractionY: 1 / 3,
    size: "1/2",
    shape: "L-shape"
  },
  {
    name: "topRightCorner_1/2_3cols",
    shapeDrawPlan: [
      [1, 1, 1],
      [0, 0, 1],
      [0, 0, 1],
      [0, 0, 1],
    ],
    fractionX: 1,
    fractionY: 1,
    textCompressFractionY: 0.25,
    size: "1/2",
    shape: "L-shape"
  },
  {
    name: "topLeftCorner_1/2_3cols",
    shapeDrawPlan: [
      [1, 1, 1],
      [1, 0, 0],
      [1, 0, 0],
      [1, 0, 0],
    ],
    fractionX: 1,
    fractionY: 1,
    textCompressFractionY: 0.25,
    size: "1/2",
    shape: "L-shape"
  },
  {
    name: "bottomLeftCorner_1/2_3cols",
    shapeDrawPlan: [
      [1, 0, 0],
      [1, 0, 0],
      [1, 0, 0],
      [1, 1, 1],
    ],
    fractionX: 1,
    fractionY: 1,
    textCompressFractionY: 0.25,
    textOffsetFractionY: 0.75,
    size: "1/2",
    shape: "L-shape"
  },
  {
    name: "bottomRightCorner_1/2_3cols",
    shapeDrawPlan: [
      [0, 0, 1],
      [0, 0, 1],
      [0, 0, 1],
      [1, 1, 1],
    ],
    fractionX: 1,
    fractionY: 1,
    textCompressFractionY: 0.25,
    textOffsetFractionY: 0.75,
    size: "1/2",
    shape: "L-shape"
  },
  {
    name: "topRightCorner_7/8",
    shapeDrawPlan: [
      [1, 1],
      [1, 1],
      [1, 1],
      [0, 1],
    ],
    fractionX: 1,
    fractionY: 1,
    textCompressFractionY: 0.75,
    size: "7/8",
    shape: "L-shape"
  },
  {
    name: "topLeftCorner_7/8",
    shapeDrawPlan: [
      [1, 1],
      [1, 1],
      [1, 1],
      [1, 0],
    ],
    fractionX: 1,
    fractionY: 1,
    textCompressFractionY: 0.75,
    size: "7/8",
    shape: "L-shape"
  },
  {
    name: "blockHorizontal_1/5",
    shapeDrawPlan: [[1]],
    fractionX: 0.5,
    fractionY: 2 / 5,
    size: "1/5",
    shape: "horizontal"
  },
  {
    name: "topLeftCorner_3/5",
    shapeDrawPlan: [
      [1, 1],
      [1, 0],
    ],
    fractionX: 1,
    fractionY: 4 / 5,
    textCompressFractionY: 0.5,
    size: "3/5",
    shape: "L-shape"
  },
  {
    name: "topRightCorner_2/3",
    shapeDrawPlan: [
      [1, 1, 1],
      [0, 0, 1],
    ],
    fractionX: 1,
    fractionY: 1,
    textCompressFractionY: 0.5,
    size: "2/3",
    shape: "L-shape"
  },
  {
    name: "topLeftCorner_2/3",
    shapeDrawPlan: [
      [1, 1, 1],
      [1, 0, 0],
    ],
    fractionX: 1,
    fractionY: 1,
    textCompressFractionY: 0.5,
    size: "2/3",
    shape: "L-shape"
  },
  {
    name: "blockVertical_1/6",
    shapeDrawPlan: [[1]],
    fractionX: 1 / 3,
    fractionY: 0.5,
    size: "1/6",
    shape: "vertical"
  },
  {
    name: "square_1/9",
    shapeDrawPlan: [[1]],
    fractionX: 1 / 3,
    fractionY: 1 / 3,
    size: "1/9",
    shape: "square"
  },
  {
    name: "blockVertical_2/9",
    shapeDrawPlan: [[1]],
    fractionX: 1 / 3,
    fractionY: 2 / 3,
    size: "2/9",
    shape: "vertical"
  },
  {
    name: "blockHorizontal_1/3",
    shapeDrawPlan: [[1]],
    fractionX: 2 / 3,
    fractionY: 0.5,
    size: "1/3",
    shape: "horizontal"
  },
  {
    name: "blockHorizontal_2/9",
    shapeDrawPlan: [[1]],
    fractionX: 2 / 3,
    fractionY: 1 / 3,
    size: "2/9",
    shape: "horizontal"
  },
  {
    name: "blockVertical_4/9",
    shapeDrawPlan: [[1]],
    fractionX: 2 / 3,
    fractionY: 2 / 3,
    size: "4/9",
    shape: "vertical"
  },
  {
    name: "blockVertical_1/2_3cols",
    shapeDrawPlan: [[1]],
    fractionX: 2 / 3,
    fractionY: 0.75,
    forceHorizontal: true,
    size: "1/2",
    shape: "vertical"
  },
  {
    name: "blockHorizontal_1/18",
    shapeDrawPlan: [[1]],
    fractionX: 1 / 3,
    fractionY: 1 / 6,
    size: "1/18",
    shape: "horizontal"
  },
  {
    name: "blockHorizontal_1/15",
    shapeDrawPlan: [[1]],
    fractionX: 1 / 3,
    fractionY: 1 / 5,
    size: "1/15",
    shape: "horizontal"
  },
  {
    name: "blockVertical_5/9",
    shapeDrawPlan: [[1]],
    fractionX: 2 / 3,
    fractionY: 5 / 6,
    size: "5/9",
    shape: "vertical"
  },
  {
    name: "blockHorizontal_1/9",
    shapeDrawPlan: [[1]],
    fractionX: 2 / 3,
    fractionY: 1 / 6,
    size: "1/9",
    shape: "horizontal"
  },
  {
    name: "blockVertical_5/18",
    shapeDrawPlan: [[1]],
    fractionX: 1 / 3,
    fractionY: 5 / 6,
    size: "5/18",
    shape: "vertical"
  },
  {
    name: "blockVertical_1/2",
    shapeDrawPlan: [[1]],
    fractionX: 2 / 3,
    fractionY: 0.75,
    forceHorizontal: true,
    size: "1/2",
    shape: "vertical"
  },
  {
    name: "topRightCorner_1/4_3cols",
    shapeDrawPlan: [
      [1, 1, 1],
      [0, 0, 1],
      [0, 0, 1],
      [0, 0, 1],
    ],
    fractionX: 1,
    fractionY: 0.5,
    textCompressFractionY: 0.25,
    size: "1/4",
    shape: "L-shape"
  },
  {
    name: "topLeftCorner_1/4_3cols",
    shapeDrawPlan: [
      [1, 1, 1],
      [1, 0, 0],
      [1, 0, 0],
      [1, 0, 0],
    ],
    fractionX: 1,
    fractionY: 0.5,
    textCompressFractionY: 0.25,
    size: "1/4",
    shape: "L-shape"
  },
  {
    name: "bottomLeftCorner_7/12",
    shapeDrawPlan: [
      [1, 1, 0],
      [1, 1, 0],
      [1, 1, 1],
    ],
    fractionX: 1,
    fractionY: 0.75,
    textCompressFractionX: 2 / 3,
    textOffsetFractionY: -1 / 12,
    size: "7/12",
    shape: "L-shape"
  },
  {
    name: "bottomRightCorner_7/12",
    shapeDrawPlan: [
      [0, 1, 1],
      [0, 1, 1],
      [1, 1, 1],
    ],
    fractionX: 1,
    fractionY: 0.75,
    textCompressFractionX: 2 / 3,
    textOffsetFractionY: -1 / 12,
    textOffsetFractionX: 1 / 3,
    size: "7/12",
    shape: "L-shape"
  },
  {
    name: "vertical_1/4",
    shapeDrawPlan: [[1]],
    fractionX: 0.25,
    fractionY: 1,
    size: "1/4",
    shape: "vertical"
  },
  {
    name: "vertical_3/4",
    shapeDrawPlan: [[1]],
    fractionX: 0.75,
    fractionY: 1,
    forceHorizontal: true,
    size: "3/4",
    shape: "vertical"
  },
  {
    name: "blockVertical_1/8",
    shapeDrawPlan: [[1]],
    fractionX: 0.25,
    fractionY: 0.5,
    size: "1/8",
    shape: "vertical"
  },
  {
    name: "blockHorizontal_3/8",
    shapeDrawPlan: [[1]],
    fractionX: 0.75,
    fractionY: 0.5,
    size: "3/8",
    shape: "horizontal"
  },
  {
    name: "blockHorizontal_1/16",
    shapeDrawPlan: [[1]],
    fractionX: 0.25,
    fractionY: 0.25,
    size: "1/16",
    shape: "horizontal"
  },
  {
    name: "blockVertical_3/16",
    shapeDrawPlan: [[1]],
    fractionX: 0.25,
    fractionY: 0.75,
    size: "3/16",
    shape: "vertical"
  },
  {
    name: "square_9/16",
    shapeDrawPlan: [[1]],
    fractionX: 0.75,
    fractionY: 0.75,
    size: "9/16",
    shape: "square"
  },
  {
    name: "blockHorizontal_1/2",
    shapeDrawPlan: [[1]],
    fractionX: 0.75,
    fractionY: 2 / 3,
    size: "1/2",
    shape: "horizontal"
  },
  {
    name: "topRightCorner_11/24",
    shapeDrawPlan: [
      [1, 1, 1, 1],
      [1, 1, 1, 1],
      [0, 0, 0, 1],
      [0, 0, 0, 1],
      [0, 0, 0, 1],
    ],
    fractionX: 1,
    fractionY: 5 / 6,
    textCompressFractionY: 2 / 5,
    size: "11/24",
    shape: "L-shape"
  },
  {
    name: "topLeftCorner_11/24",
    shapeDrawPlan: [
      [1, 1, 1, 1],
      [1, 1, 1, 1],
      [1, 0, 0, 0],
      [1, 0, 0, 0],
      [1, 0, 0, 0],
    ],
    fractionX: 1,
    fractionY: 5 / 6,
    textCompressFractionY: 2 / 5,
    size: "11/24",
    shape: "L-shape"
  },
  {
    name: "blockHorizontal_1/24",
    shapeDrawPlan: [[1]],
    fractionX: 0.25,
    fractionY: 1 / 6,
    size: "1/24",
    shape: "horizontal"
  },
  {
    name: "blockVertical_1/6_4cols",
    shapeDrawPlan: [[1]],
    fractionX: 0.25,
    fractionY: 2 / 3,
    size: "1/6",
    shape: "vertical"
  },
  {
    name: "blockVertical_1/12_4cols",
    shapeDrawPlan: [[1]],
    fractionX: 0.25,
    fractionY: 1 / 3,
    size: "1/12",
    shape: "vertical"
  },
]
