<template>
  <base-dialog v-if="contentExists" :model-value="true" @keydown.esc="closeDialog" size="small">
    <v-card-item>
      <v-card-title>
        <span class="text-h5" data-test-id="title">{{ mode === "add" ? "Add" : "Edit" }} Ad/Content to be Placed</span>
      </v-card-title>
    </v-card-item>
    <v-card-text>
      <v-form ref="form" v-model="formValid" @submit.prevent="saveDialog">
        <v-row>
          <v-col cols="12">
            <v-text-field label="text" v-model="text" data-test-id="text" autofocus required :rules="textRules" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-combobox
              v-model="pageNos"
              :items="allPageNos"
              :delimiters="[',', ';']"
              label="Suggested page numbers"
              multiple
              chips
              closable-chips
              :menu-props="{ closeOnClick: true, closeOnContentClick: true }"
              v-model:search-input="searchInput"
              hint="Can also be something descriptive like 'middle'"
              persistent-hint
              hide-selected
            ></v-combobox>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-select
              v-model="size"
              label="Size"
              :items="fragmentSizes"
            ></v-select>            
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-select
              v-model="shape"
              label="Shape"
              :items="fragmentShapes"
            ></v-select>            
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <cancel-btn @click="closeDialog">Close</cancel-btn>
      <submit-btn type="submit" @click="saveDialog">Save</submit-btn>
    </v-card-actions>
  </base-dialog>
</template>

<script>
import { ref } from "vue"
import { useRouter } from "vue-router"

import { useContent } from "../use"
import { useNotification } from "@/modules/notification/use"
import { usePageNumbers } from "@/modules/flatplan/usePageNumbers"
import { fragmentSizes, fragmentShapes } from "@/modules/page/render/fragmentUtils"

export default {
  name: "EditContentView",
  props: {
    contentId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    let router = useRouter()

    let bp_content = useContent()
    let bp_pageNumbers = usePageNumbers()

    let mode = props.contentId === "__new__" ? "add" : "edit"

    const form = ref(null)
    let formValid = ref(false)
    let textRules = [(v) => !!v || "Text is required."]

    let text = ref(null)
    let pageNos = ref([])
    let size = ref("")
    let shape = ref("")
    let searchInput = ref(null)

    if (mode === "edit") {
      let content = bp_content.getById(props.contentId)

      if (content === undefined || content === null) {
        // we may have lost it via LiveUpdate, and we do a browser refresh?
        let bp_notification = useNotification()
        bp_notification.show({
          type: "error",
          message: "That content no longer exists",
        })
        router.push({ name: "contents-show" })
        return {
          contentExists: false,
        }
      }

      text.value = content.text
      size.value = content.size
      shape.value = content.shape
      pageNos.value = [...(content.pageNos || [])] // because it's an array ... else we change the values in the store (undoing updates will become confusing)
    }

    const closeDialog = () => {
      router.push({ name: "contents-show" })
    }

    const saveDialog = () => {
      form.value.validate()

      if (formValid.value || formValid.value === null) { // HACK: first time the value might be null? Bug?) {
        if (mode === "edit") {
          bp_content.update({ contentId: props.contentId, text: text.value, pageNos: pageNos.value, size: size.value, shape: shape.value })
        } else {
          bp_content.batchAdd({ contents: [{ text: text.value, pageNos: pageNos.value, size: size.value, shape: shape.value, imported: false }] })
        }
        closeDialog()
      }
    }

    return {
      contentExists: true,
      form,
      formValid,
      textRules,
      closeDialog,
      saveDialog,
      text,
      pageNos,
      size,
      shape,
      allPageNos: bp_pageNumbers.all,
      mode,
      searchInput,
      fragmentSizes: [""].concat(fragmentSizes),
      fragmentShapes: [""].concat(fragmentShapes),
    }
  },
}
</script>

<style lang="scss" scoped></style>
