import { ref, computed } from "vue"
import { defineStore } from "pinia"

import AccountService from "@/services/AccountService.js"

const compareName = (a, b) => {
  return a.name.localeCompare(b.name)
}

export const useAccountStore = defineStore("account", () => {
  const accounts = ref([])
  const people = ref([])

  const currentAccountId = computed(() => {
    let accountId = sessionStorage.getItem("currentAccountId")

    let foundAccountId = null

    if (accounts.value) {
      let account = accounts.value.find((a) => a.secret_id === accountId)
      if (!account) {
        // then let's find the first active account
        account = accounts.value.filter((a) => a.active)[0]
      }
      if (!account) {
        // then let's return the first account we can find
        account = accounts.value[0]
      }

      foundAccountId = account ? account.secret_id : null

      // for Intercom via Google Tag Manager
      // not sure whether this is the best location for this - feels a bit weird to set this when we try to get the currentAccountId
      if (foundAccountId && !!window.dataLayer) {
        // check if we have already added a company layer
        if (
          window.dataLayer.findIndex((d) => {
            return d.company
          }) === -1
        ) {
          window.dataLayer.push({
            company: {
              name: account.name,
              id: account.id,
              plan: account.plan ? account.plan.name : null, // the share screen may not have the account plan info. It's not going to get sent to Intercom anyway
            },
          })
        }
      }
    }
    sessionStorage.setItem("currentAccountId", foundAccountId)
    return sessionStorage.getItem("currentAccountId")
  })

  const anyAccounts = computed(() => {
    return accounts.value ? accounts.value.length > 0 : false
  })

  const getCurrentAccount = computed(() => {
    return getAccountById(currentAccountId.value)
  })

  const orderedAccounts = computed(() => {
    let tmpAccounts = []

    const currentAccount = getCurrentAccount.value

    // we want accounts in the following order:
    // current account, active accounts, inactive accounts
    if (currentAccount) {
      tmpAccounts.push(currentAccount)

      // only run the code below if we have a currentAccount - it may not have been bootstrapped yet ...
      tmpAccounts.push(...accounts.value.sort(compareName).filter((a) => a.secret_id !== currentAccount.secret_id && a.active))
      tmpAccounts.push(...accounts.value.sort(compareName).filter((a) => a.secret_id !== currentAccount.secret_id && !a.active))
    }

    return tmpAccounts
  })

  const isActive = computed(() => {
    let account = getAccountById(currentAccountId.value)
    return account ? account.active : null
  })

  const id = computed(() => {
    let account = getAccountById(currentAccountId.value)
    return account ? account.id : null
  })

  function getAccountById(id) {
    return accounts.value ? accounts.value.find((a) => a.secret_id === id) : null
  }

  function bootstrap(data) {
    accounts.value = data.accounts
  }

  function changeCurrentAccount({ account, route }) {
    sessionStorage.setItem("currentAccountId", account.secret_id)
    // we could also remove the lastFlatplanId localStorage item here?
    // it will just avoid the 404's that happen silently.
    // Or store lastFlatplanId as an object rather, keyed to an accountId
    location.href = route
  }

  function fetchPeople({ account }) {
    if (people.value.length === 0) {
      return AccountService.fetchPeople({ accountId: account.secret_id }).then((response) => {
        people.value = response.data.people || []
        return Promise.resolve()
      })
    } else {
      return Promise.resolve()
    }
  }

  function setAccount({ name }) {
    let account = getAccountById(currentAccountId.value)
    
    if (name !== undefined) {
      account.name = name
    }
  }

  function update({ name, storeOnly }) {
    let account = getAccountById(currentAccountId.value)
    let prevAttributes = JSON.parse(JSON.stringify(account) || {})

    setAccount({ name })

    if (!storeOnly) {
      return AccountService.updateAccount({ accountId: currentAccountId.value, payload: { name } }).catch((err) => {
        setAccount(prevAttributes)
      })
    }
  }

  return {
    people,
    accounts,
    currentAccountId,
    getAccountById,
    getCurrentAccount,
    anyAccounts,
    orderedAccounts,
    isActive,
    id,
    bootstrap,
    changeCurrentAccount,
    fetchPeople,
    update
  }
})
