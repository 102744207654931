<template>
  <v-menu>
    <template v-slot:activator="{ props }">
      <v-btn v-if="!mobile" v-bind="props" variant="text" color="primary" :prepend-icon="mdiViewGrid">View</v-btn>
      <v-btn v-else v-bind="props" variant="text" color="primary" :prepend-icon="mdiViewGrid"></v-btn>
    </template>
    <v-list>
      <v-list-item @click="setShowThumbnails">
        <template v-slot:prepend>
          <v-list-item-action>
            <v-checkbox-btn :model-value="showThumbnails" color="primary"></v-checkbox-btn>
          </v-list-item-action>
        </template>
        <v-list-item-title>Show thumbnails</v-list-item-title>
      </v-list-item>
      <v-list-item @click="setShowTags">
        <template v-slot:prepend>
          <v-list-item-action>
            <v-checkbox-btn :model-value="showTags" color="primary"></v-checkbox-btn>
          </v-list-item-action>
        </template>
        <v-list-item-title>Show tags</v-list-item-title>
      </v-list-item>
      <v-list-item @click="setShowNotes">
        <template v-slot:prepend>
          <v-list-item-action>
            <v-checkbox-btn :model-value="showNotes" color="primary"></v-checkbox-btn>
          </v-list-item-action>
        </template>
        <v-list-item-title>Show notes</v-list-item-title>
      </v-list-item>
      <v-list-subheader>Stats</v-list-subheader>
      <v-list-item @click="setShowTypeStats">
        <template v-slot:prepend>
          <v-list-item-action>
            <v-checkbox-btn :model-value="showTypeStats" color="primary"></v-checkbox-btn>
          </v-list-item-action>
        </template>
        <v-list-item-title>Ads</v-list-item-title>
      </v-list-item>
      <v-list-item @click="setShowCategoryStats">
        <template v-slot:prepend>
          <v-list-item-action>
            <v-checkbox-btn :model-value="showCategoryStats" color="primary"></v-checkbox-btn>
          </v-list-item-action>
        </template>
        <v-list-item-title>Categories</v-list-item-title>
      </v-list-item>
      <v-list-item @click="setShowTagStats">
        <template v-slot:prepend>
          <v-list-item-action>
            <v-checkbox-btn :model-value="showTagStats" color="primary"></v-checkbox-btn>
          </v-list-item-action>
        </template>
        <v-list-item-title>Tags</v-list-item-title>
      </v-list-item>
      <v-list-subheader>Page sizes</v-list-subheader>
      <v-list-item v-for="size in sizes" :key="size.value" :model-value="size.value" @click="setPageSize(size.value)">
        <template v-slot:prepend>
          <v-list-item-action start>
            <v-checkbox-btn :model-value="size.value === pageSize" color="primary"></v-checkbox-btn>
          </v-list-item-action>
        </template>
        <v-list-item-title>{{ size.text }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { inject } from "vue"
import { mdiViewGrid, mdiCheck } from "@mdi/js"

import { usePageSetting } from "@/modules/pageSetting/use"
import { useViewSetting } from "@/modules/viewSetting/use"

export default {
  name: "ViewOptions",
  setup() {
    let bp_pageSetting = usePageSetting()
    let bp_viewSetting = useViewSetting()

    let mobile = inject("mobile")

    let setShowThumbnails = () => {
      bp_pageSetting.setShowThumbnails(!bp_pageSetting.showThumbnails.value)
    }

    let setShowTypeStats = () => {
      bp_viewSetting.setShowTypeStats(!bp_viewSetting.showTypeStats.value)
    }

    let setShowCategoryStats = () => {
      bp_viewSetting.setShowCategoryStats(!bp_viewSetting.showCategoryStats.value)
    }

    let setShowTagStats = () => {
      bp_viewSetting.setShowTagStats(!bp_viewSetting.showTagStats.value)
    }

    let setShowTags = () => {
      bp_viewSetting.setShowTags(!bp_viewSetting.showTags.value)
    }

    let setShowNotes = () => {
      bp_viewSetting.setShowNotes(!bp_viewSetting.showNotes.value)
    }

    let setPageSize = (size) => {
      bp_pageSetting.setPageSize(size)
    }

    return {
      mobile,
      sizes: bp_pageSetting.pageSizes,
      setShowTypeStats,
      setShowCategoryStats,
      setShowTagStats,
      setShowTags,
      setShowNotes,
      setShowThumbnails,
      setPageSize,
      showTypeStats: bp_viewSetting.showTypeStats,
      showCategoryStats: bp_viewSetting.showCategoryStats,
      showTagStats: bp_viewSetting.showTagStats,
      showTags: bp_viewSetting.showTags,
      showNotes: bp_viewSetting.showNotes,
      showThumbnails: bp_pageSetting.showThumbnails,
      pageSize: bp_pageSetting.pageSize,
      mdiViewGrid,
      mdiCheck,
    }
  },
}
</script>

<style lang="scss" scoped></style>
