import { ref } from "vue"
import { defineStore } from "pinia"

export const usePresenceStore = defineStore("presence", () => {
  const users = ref([])
  const currentStatus = ref("active")

  function getByUserId(userId) {
    return users.value.find((u) => u.userId.toString() === userId.toString())
  }

  function setCurrentStatus(status) {
    currentStatus.value = status
  }

  function add({ user }) {
    let userIndex = users.value.findIndex((c) => c.userId === user.userId)
    if (userIndex === -1) {
      // insert if new
      users.value.splice(0, 0, user)
    } else {
      // replace if exists
      users.value.splice(userIndex, 1, user)
    }
  }

  function remove({ userId }) {
    let userIndex = users.value.findIndex((c) => c.userId === userId)
    if (userIndex !== -1) {
      users.value.splice(userIndex, 1)
    }
  }

  function update({ userId, user }) {
    let userIndex = users.value.findIndex((c) => c.userId === userId)
    if (userIndex > -1) {
      // replace if exists
      users.value.splice(userIndex, 1, user)
    }
  }

  return {
    users,
    currentStatus,
    getByUserId,
    setCurrentStatus,
    add,
    remove,
    update,
  }
})
