import { ref, computed } from "vue"
import { defineStore } from "pinia"

import AccountService from "@/services/AccountService.js"
import DataService from "@/services/DataService.js"

import { usePageSettingStore } from "@/modules/pageSetting/store"

const compareIssue = (a, b) => {
  return a.issue.localeCompare(b.issue)
}

export const useFlatplanStore = defineStore("flatplan", () => {
  const current = ref({})
  const others = ref([])
  const archived = ref([])
  const defaults = ref({})

  const id = computed(() => {
    return current.value._id
  })

  const issue = computed(() => {
    return current.value.issue
  })

  const isArchived = computed(() => {
    return current.value.archivedAt
  })

  const singlePages = computed(() => {
    // using defaultVal aids in setting up specs mostly
    let defaultVal = false

    if (!!current.value && !!current.value.singlePages) {
      defaultVal = current.value.singlePages
    }
    return defaultVal
  })

  const pageOrientation = computed(() => {
    // using defaultVal aids in setting up specs mostly
    let defaultVal = "portrait"

    if (!!current.value && !!current.value.pageOrientation) {
      defaultVal = current.value.pageOrientation
    }
    return defaultVal
  })

  const pageNumberings = computed(() => {
    // using defaultVal aids in setting up specs mostly
    let defaultVal = [{ pagePos: 0, startWith: 1 }]

    if (!!current.value && !!current.value.pageNumberings) {
      defaultVal = current.value.pageNumberings
    }
    return defaultVal
  })

  const displayCoverPageNumbering = computed(() => {
    // using defaultVal aids in setting up specs mostly
    let defaultVal = true

    if (current.value) {
      defaultVal = !!current.value.displayCoverPageNumbering
    }
    return defaultVal
  })

  const notes = computed(() => {
    return current.value.notes
  })

  const ordered = computed(() => {
    let flatplans = []

    // we want flatplans in the following order:
    // current flatplan, others ordered by issue
    flatplans.push(current.value)

    flatplans.push(...others.value.sort(compareIssue))

    return flatplans
  })

  function setFlatplan({ issue, singlePages, pageOrientation, notes }) {
    if (issue !== undefined) {
      current.value.issue = issue
    }

    if (singlePages !== undefined) {
      current.value.singlePages = singlePages
    }

    if (pageOrientation !== undefined) {
      current.value.pageOrientation = pageOrientation
    }

    if (notes !== undefined) {
      current.value.notes = notes
    }
  }

  function setPageNumbering({ pageNumberings, displayCoverPageNumbering }) {
    if (pageNumberings !== undefined) {
      current.value.pageNumberings = JSON.parse(JSON.stringify(pageNumberings))
    }
    if (displayCoverPageNumbering !== undefined) {
      current.value.displayCoverPageNumbering = displayCoverPageNumbering
    }
  }

  function bootstrap({ currentData, othersData, archivedData }) {
    current.value = currentData
    others.value = othersData
    archived.value = archivedData

    const pageSettingStore = usePageSettingStore()
    pageSettingStore.setSinglePages(current.value.singlePages)
    pageSettingStore.setPageOrientation(current.value.pageOrientation)
  }

  function bootstrapDefaults({ defaultsData }) {
    defaults.value = defaultsData
  }

  function update({ flatplanId, issue, singlePages, pageOrientation, notes, storeOnly }) {
    let prevAttributes = JSON.parse(JSON.stringify(current.value) || {})

    let changeSinglePages = singlePages !== undefined && prevAttributes.singlePages !== singlePages
    let changePageOrientation = pageOrientation !== undefined && prevAttributes.pageOrientation !== pageOrientation

    // only pass in position for undeleted - else use reorder

    setFlatplan({ issue, singlePages, pageOrientation, notes })

    const pageSettingStore = usePageSettingStore()

    if (changeSinglePages) {
      pageSettingStore.setSinglePages(singlePages)
    }
    if (changePageOrientation) {
      pageSettingStore.setPageOrientation(pageOrientation)
    }

    if (!storeOnly) {
      return DataService.updateFlatplan({ accountId: sessionStorage.getItem("currentAccountId"), flatplanId, payload: { issue, singlePages, pageOrientation, notes } }).catch((err) => {
        setFlatplan(prevAttributes)

        if (changeSinglePages) {
          pageSettingStore.setSinglePages(prevAttributes.singlePages)
        }
        if (changePageOrientation) {
          pageSettingStore.setPageOrientation(prevAttributes.pageOrientation)
        }
      })
    }
  }

  function updatePageNumberings({ flatplanId, pageNumberings, displayCoverPageNumbering, storeOnly }) {
    const prevAttributes = { displayCoverPageNumbering: current.value.displayCoverPageNumbering, pageNumberings: JSON.parse(JSON.stringify(current.value.pageNumberings)) }

    setPageNumbering({ pageNumberings, displayCoverPageNumbering })

    if (!storeOnly) {
      return DataService.updateFlatplan({ accountId: sessionStorage.getItem("currentAccountId"), flatplanId, payload: { pageNumberings, displayCoverPageNumbering } }).catch((err) => {
        setPageNumbering({ ...prevAttributes })
      })
    }
  }

  function create({ issue, singlePages, pageOrientation, displayCoverPageNumbering, pageNumberings, cloneFromFlatplanId, clone }) {
    return DataService.createFlatplan({ accountId: sessionStorage.getItem("currentAccountId"), payload: { issue, singlePages, pageOrientation, displayCoverPageNumbering, pageNumberings, cloneFromFlatplanId, clone } })
  }

  function del({ flatplanId }) {
    return DataService.deleteFlatplan({ accountId: sessionStorage.getItem("currentAccountId"), flatplanId })
  }

  function archive({ flatplanId }) {
    return DataService.archiveFlatplan({ accountId: sessionStorage.getItem("currentAccountId"), flatplanId })
  }

  function sendShareEmail({ to, message, issue }) {
    return AccountService.sendShareEmail({ accountId: sessionStorage.getItem("currentAccountId"), payload: { to, message, issue } })
  }

  return {
    current,
    others,
    archived,
    defaults,
    id,
    issue,
    isArchived,
    singlePages,
    pageOrientation,
    pageNumberings,
    displayCoverPageNumbering,
    notes,
    ordered,
    bootstrap,
    bootstrapDefaults,
    update,
    updatePageNumberings,
    create,
    del,
    archive,
    sendShareEmail,
  }
})
