<template>
  <v-row v-if="!editMode">
    <v-col cols="3" sm="2">
      <v-chip>{{ comment.initials }}</v-chip>
    </v-col>
    <v-col cols="12" sm="10">
      <div class="text-caption text-disabled">{{ comment.commenter }}</div>
      <div class="text-body-1 decoded-markup" v-html="decodedComment"></div>
      <div class="text-caption">
        {{ fmtDateTime(comment.meta.createdAt) }}
        <div class="d-inline ml-2" v-if="canEdit">
          &mdash;&nbsp;
          <v-btn size="x-small" variant="text" @click="editMode = true">Edit</v-btn>
          <div class="d-inline" v-if="!confirm"><v-btn size="x-small" variant="text" @click="confirm = true">Delete</v-btn></div>
          <div class="d-inline" v-else><span class="text-error">Delete (there's no undo)?</span>&nbsp;<v-btn v-if="confirm" size="x-small" variant="text" @click="del" color="error">Yes, Delete</v-btn></div>
        </div>
      </div>
    </v-col>
  </v-row>
  <v-row v-else>
    <v-col cols="3" sm="2">
      <v-chip>{{ comment.initials }}</v-chip>
    </v-col>
    <v-col cols="12" sm="10">
      <div class="text-caption text-disabled">{{ comment.commenter }}</div>
      <v-textarea v-model="text" rows="2" auto-grow hide-details data-label="edit comment area"></v-textarea>
      <div class="d-flex">
        <v-spacer></v-spacer>
        <v-btn variant="text" color="primary" @click="save" data-label="edit comment save">Save</v-btn>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { ref, computed } from "vue"
import { DateTime } from "luxon"

import { useAuth } from "@/modules/auth/use"
import { useComment } from "@/modules/comment/use"
import { decode, htmlDecode } from "@/utils/general"

export default {
  name: "Comment",
  props: {
    pageId: {
      type: String,
      required: true,
    },
    comment: {
      type: Object,
      required: true,
      default: () => {
        return {}
      },
    },
  },
  setup(props, context) {
    let bp_auth = useAuth()
    let bp_comment = useComment()
    let editMode = ref(false)
    let confirm = ref(false)

    let text = ref(htmlDecode(props.comment.text))

    let fmtDateTime = (date) => {
      return DateTime.fromISO(date).toFormat("DD t")
    }

    let del = () => {
      bp_comment.del({ pageId: props.pageId, commentId: props.comment._id }).then(({ data }) => {
        context.emit("comment-deleted", props.comment._id)
      })
    }

    let save = () => {
      bp_comment.update({ pageId: props.pageId, commentId: props.comment._id, text: text.value }).then(({ data }) => {
        editMode.value = false
        context.emit("comment-updated", data)
      })
    }

    return {
      fmtDateTime,
      canEdit: props.comment.commenterId === bp_auth.niceId.value,
      del,
      save,
      editMode,
      text,
      confirm,
      decodedComment: computed(() => {
        return decode(props.comment.text)
      }),
    }
  },
}
</script>

<style lang="scss" scoped></style>
