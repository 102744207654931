import * as Sentry from "@sentry/vue"
import { MetricTypes } from './types'

export class MetricsLogger {
  static logToSentry(error, context = {}) {
    Sentry.withScope(scope => {
      // Add tags
      Object.entries(context.tags || {}).forEach(([key, value]) => {
        scope.setTag(key, value)
      })

      // Add contexts
      Object.entries(context.contexts || {}).forEach(([key, value]) => {
        scope.setContext(key, value)
      })

      // Better error handling
      if (error instanceof Error) {
        Sentry.captureException(error)
      } else if (error.breadcrumb) {
        Sentry.addBreadcrumb(error.breadcrumb)
      } else if (!error || error.message === undefined) {
        // Handle undefined or empty errors
        const wrappedError = new Error('Unknown error occurred');
        wrappedError.originalError = error;
        scope.setContext("original_error", { error });
        Sentry.captureException(wrappedError);
      } else {
        Sentry.captureEvent({
          message: error.message || 'No error message provided',
          level: 'error',
          extra: { error }
        })
      }
    })
  }

  static logToConsole(type, data) {
    if (type === MetricTypes.ERROR) {
      console.error("Axios error", JSON.stringify(data, null, 2))
    } else {
      console.log(`${type}:`, JSON.stringify(data, null, 2))
    }
  }
}