<template>
  <v-container fluid>
      <h4 class="text-h4">Account Settings for <span class="font-weight-black">{{ accountName }}</span></h4>
    <br/>
    <v-form ref="form" v-model="formValid" @submit.prevent="saveDialog">
      <v-text-field label="Name" v-model="name" data-test-id="name" autofocus required :rules="nameRules" />
      <submit-btn type="submit" class="float-right" @click="saveDialog">Save</submit-btn>
    </v-form>
  </v-container>
</template>

<script>
import { ref } from "vue"

import { useAccount } from "../use"
import { useContext } from "@/use/context"
import { useNotification } from "@/modules/notification/use"

export default {
  name: "AccountEditView",
  setup() {
    let bp_account = useAccount()
    let bp_notification = useNotification()
    let bp_context = useContext()

    const form = ref(null)
    let formValid = ref(false)

    let nameRules = [(v) => !!v || "Name is required."]

    let name = ref(bp_account.currentAccount.value.name)

    const saveDialog = () => {
      form.value.validate()

      if (formValid.value || formValid.value === null) { // JD: Hack: it shouldn't be null ...
        bp_account.update({ name: name.value }).then(() => {
          bp_notification.show({
            type: "success",
            message: "Settings saved :)",
          })
        })
      }
    }

    return {
      saveDialog,
      form,
      formValid,
      name,
      nameRules,
      accountName: bp_context.accountName,
    }
  },
}
</script>

<style lang="scss" scoped></style>
