import { computed } from "vue"
import router from "@/router"
import { useAccount } from "@/modules/account/use"
import { useFlatplan } from "@/modules/flatplan/use"

export const useShare = function () {
  let bp_account = useAccount()
  let bp_flatplan = useFlatplan()

  let shareLink = computed(() => {
    return router.resolve({ name: "share", params: { accountId: bp_account.currentAccountId.value, flatplanId: bp_flatplan.id.value } }).href
  })

  let shareUrl = computed(() => {
    return window.location.protocol + "//" + window.location.host + router.resolve({ name: "share", params: { accountId: bp_account.currentAccountId.value, flatplanId: bp_flatplan.id.value } }).href
  })

  let pdfLink = (opts) => {
    opts.accountName = bp_account.currentAccount.value.name
    opts.flatplanName = bp_flatplan.issue.value
    return `//${import.meta.env.VITE_PDF_ENDPOINT}/g/${bp_account.currentAccountId.value}/${bp_flatplan.id.value}?${new URLSearchParams(opts)}`
  }

  return {
    shareLink,
    shareUrl,
    pdfLink,
  }
}
