import { ref } from "vue"
import { defineStore } from "pinia"
import axios from "axios"
import AccountService from "@/services/AccountService.js"

import { useAccountStore } from "@/modules/account/store"

export const useBootstrapShareStore = defineStore("bootstrapShare", () => {
  const state = ref("ready")
  const token = ref(null)

  function fetch(accountId) {
    if (state.value === "done") {
      return Promise.resolve()
    } else {
      state.value = "fetching"
      return AccountService.bootstrapShare(accountId)
        .then(({ data }) => {
          state.value = "done"

          token.value = data.token

          axios.defaults.headers.common["Authorization"] = `Bearer ${token.value}` // overwriting what main.js will have already set with the token from localStorage

          const accounts = [data.account]
          const accountStore = useAccountStore()
          accountStore.bootstrap({ accounts })

          return data
        })
        .catch((err) => {
          console.error(err)
          state.value = "ready"
          throw(err)
        })
    }
  }

  return {
    state,
    token,
    fetch,
  }
})
