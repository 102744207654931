import { computed } from "vue"
import { useContext } from "@/use/context"
import { useCommand } from "@/modules/command/use"
import { useSectionStore } from "./store"

export const useSection = function () {
  let bp_command = useCommand()
  let bp_context = useContext()

  const sectionStore = useSectionStore()
  const { getById } = sectionStore

  let flatplanId = bp_context.flatplanId.value

  const update = ({ sectionId, type, count, size, fromLiveUpdate }) => {
    if (fromLiveUpdate) {
      return sectionStore.update({ flatplanId, sectionId, type, count, size, storeOnly: true })
    }

    const section = sectionStore.getById(sectionId)

    if (section) {
      const prevAttributes = { type: section.type, count: section.count, size: section.size }

      return bp_command.add({
        execute: () => {
          return sectionStore.update({ flatplanId, sectionId, type, count, size })
        },
        undo: () => {
          return sectionStore.update({ flatplanId, sectionId, ...prevAttributes })
        },
      })
    } else {
      return Promise.resolve()
    }
  }

  const add = ({ type, count, size }) => {
    let sectionId

    return bp_command.add({
      execute: () => {
        return sectionStore.add({ flatplanId, type, count, size }).then((response) => {
          sectionId = response.data._id
        })
      },
      undo: () => {
        return sectionStore.del({ flatplanId, sectionId })
      },
    })
  }

  // the idea is to add section on the server first
  // but when we know what section to add (an undo on a delete via Liveupdate, or a new one coming through
  // from another session via Liveupdate) - that's when we call this method
  // This method won't have an undo as this is in response to a liveupdate - so the data has already changed on the server
  const addFromLiveupdate = (section) => {
    return sectionStore.addFromLiveupdate(section)
  }

  const del = ({ sectionId, fromLiveUpdate }) => {
    if (fromLiveUpdate) {
      return sectionStore.del({ flatplanId, sectionId, storeOnly: true })
    }

    const prevSection = sectionStore.getById(sectionId)

    if (prevSection) {
      return bp_command.add({
        execute: () => {
          return sectionStore.del({ flatplanId, sectionId })
        },
        undo: () => {
          return sectionStore.update({ flatplanId, sectionId, ...prevSection })
        },
      })
    } else {
      return Promise.resolve()
    }
  }

  return {
    sections: computed(() => sectionStore.sections),
    coverSections: computed(() => sectionStore.coverSections),
    contentSections: computed(() => sectionStore.contentSections),
    getById,
    update,
    del,
    add,
    addFromLiveupdate,
  }
}
