import { computed } from "vue"
import { useApiKeyStore } from "./store"

export const useApiKey = function () {
  const apiKeyStore = useApiKeyStore()
  const { add, del, getByKeyHash } = apiKeyStore

  // store's state is reactively passed on (computed for readonly) - all state changes should occur within store
  return {
    apiKeys: computed(() => apiKeyStore.apiKeys),
    add,
    del,
    getByKeyHash
  }
}
