import { computed } from "vue"

import { useViewSettingStore } from "./store"

export const useViewSetting = function () {
  const viewSettingStore = useViewSettingStore()
  const { setShowLeftDrawer, setShowRightDrawer, setShowTypeStats, setShowCategoryStats, setShowTagStats, setShowTags, setShowNotes, setShowSlideshowAsDoublePageSpreads } = viewSettingStore

  // store's state is reactively passed on (computed for readonly) - all state changes should occur within store
  return {
    showLeftDrawer: computed(() => viewSettingStore.showLeftDrawer),
    showRightDrawer: computed(() => viewSettingStore.showRightDrawer),
    showTypeStats: computed(() => viewSettingStore.showTypeStats),
    showCategoryStats: computed(() => viewSettingStore.showCategoryStats),
    showTagStats: computed(() => viewSettingStore.showTagStats),
    showTags: computed(() => viewSettingStore.showTags),
    showNotes: computed(() => viewSettingStore.showNotes),
    showSlideshowAsDoublePageSpreads: computed(() => viewSettingStore.showSlideshowAsDoublePageSpreads),
    setShowLeftDrawer,
    setShowRightDrawer,
    setShowTypeStats,
    setShowCategoryStats,
    setShowTagStats,
    setShowTags,
    setShowNotes,
    setShowSlideshowAsDoublePageSpreads,
  }
}
