<template>
  <v-menu>
    <template v-slot:activator="{ props }">
      <v-btn v-if="!mobile" v-bind="props" variant="text" color="primary" :prepend-icon="mdiViewList">Summary</v-btn>
      <v-btn v-else v-bind="props" variant="text" color="primary" :prepend-icon="mdiViewList"></v-btn>
    </template>
    <v-list>
      <v-list-item :to="{ name: 'summary-notes' }" exact >
        <v-list-item-title>Notes</v-list-item-title>
      </v-list-item>
      <v-list-item :to="{ name: 'summary-comments' }" exact >
        <v-list-item-title>Comments</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { inject } from "vue"
import { mdiViewList } from "@mdi/js"

export default {
  name: "SummaryOptions",
  setup() {
    let mobile = inject("mobile")

    return {
      mobile,
      mdiViewList,
    }
  },
}
</script>

<style lang="scss" scoped></style>
