import { ref } from "vue"

let pageIds = null

export const useBulkPageSelect = () => {
  pageIds ??= ref([])

  let toggle = (pageId) => {
    let pageIndex = pageIds.value.findIndex((id) => id === pageId)
    if (pageIndex !== -1) {
      pageIds.value.splice(pageIndex, 1)
    } else {
      pageIds.value.splice(0, 0, pageId)
    }
  }

  let isSelected = (pageId) => {
    let pageIndex = pageIds.value.findIndex((id) => id === pageId)
    return pageIndex !== -1
  }

  let clear = () => {
    pageIds.value.splice(0, pageIds.value.length)
  }

  return {
    pageIds,
    toggle,
    isSelected,
    clear,
  }
}
