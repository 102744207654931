<template>
  <base-dialog v-if="tagExists" :model-value="true" @keydown.esc="closeDialog" size="small">
    <v-card-item>
      <v-card-title>
        <span class="text-h5" data-test-id="title">{{ mode === "add" ? "Add" : "Edit" }} Tag</span>
      </v-card-title>
    </v-card-item>
    <v-card-text>
      <v-form ref="form" v-model="formValid" @submit.prevent="saveDialog">
        <v-row>
          <v-col cols="12">
            <v-text-field label="Name" v-model="name" data-test-id="name" autofocus required :rules="nameRules" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <ColorSelector :colors="colors" v-model="color" rounded="circle" />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <cancel-btn @click="closeDialog">Close</cancel-btn>
      <submit-btn type="submit" @click="saveDialog">Save</submit-btn>
    </v-card-actions>
  </base-dialog>
</template>

<script>
import { ref } from "vue"
import { useRouter } from "vue-router"

import { useTag } from "../use"
import { useNotification } from "@/modules/notification/use"

import ColorSelector from "@/components/ColorSelector"
import colors from "../colors"

export default {
  name: "EditTagView",
  props: {
    tagId: {
      type: String,
      required: true,
    },
  },
  components: {
    ColorSelector,
  },
  setup(props) {
    let router = useRouter()
    
    let bp_tag = useTag()

    let mode = props.tagId === "new" ? "add" : "edit"

    const form = ref(null)
    let formValid = ref(false)
    let nameRules = [(v) => !!v || "Name is required."]

    let name = ref(null)
    let color = ref(colors[0])

    if (mode === "edit") {
      let tag = bp_tag.getById(props.tagId)

      if (tag === undefined || tag === null) {
        // we may have lost it via LiveUpdate, and we do a browser refresh?
        let bp_notification = useNotification()
        bp_notification.show({
          type: "error",
          message: "That tag no longer exists",
        })
        router.push({ name: "tags-show" })
        return {
          tagExists: false,
        }
      }

      name.value = tag.name
      color.value = tag.color
    }

    const closeDialog = () => {
      router.push({ name: "tags-show" })
    }

    const saveDialog = () => {
      form.value.validate()

      if (formValid.value) {
        if (mode === "edit") {
          bp_tag
            .update({ tagId: props.tagId, name: name.value, color: color.value })
            .then(() => {
              closeDialog()
            })
            .catch((err) => {
              // noop
              throw err
            })
        } else {
          bp_tag
            .add({ name: name.value, color: color.value })
            .then(() => {
              closeDialog()
            })
            .catch((err) => {
              // noop
              throw err
            })
        }
      }
    }

    return {
      tagExists: true,
      form,
      formValid,
      nameRules,
      closeDialog,
      saveDialog,
      name,
      color,
      colors,
      mode,
    }
  },
}
</script>

<style lang="scss" scoped></style>
