import { default as ResizeSensor } from "@/utils/resizeSensor"
import { usePageSetting } from "@/modules/pageSetting/use"
import { ref, watchEffect, computed, onMounted } from "vue"

export const useFlatplanRowBreaks = ({ domContainerId }) => {
  let pagesContainer = null

  let bp_pageSetting = usePageSetting()
  let pagesPerRow = ref(8) // choose a default

  const adjustRowBreaks = ({ pageSize, pageOrientation, singlePages }) => {
    if (pagesContainer) {
      if (singlePages) {
        return Infinity
      } else {
        const totalPageWidth = bp_pageSetting.pageWidth.value + bp_pageSetting.pageGap.value + bp_pageSetting.pageSpreadGap.value / 2
        const fractionOfPagesPerRow = pagesContainer.clientWidth / totalPageWidth
        return 2 * Math.floor(fractionOfPagesPerRow / 2) // round down to nearest even integer
      }
    }
  }

  const layoutClasses = computed(() => {
    return {
      "spreads": !bp_pageSetting.singlePages.value,
      "page-per-row-26": pagesPerRow.value == 26,
      "page-per-row-24": pagesPerRow.value == 24,
      "page-per-row-22": pagesPerRow.value == 22,
      "page-per-row-20": pagesPerRow.value == 20,
      "page-per-row-18": pagesPerRow.value == 18,
      "page-per-row-16": pagesPerRow.value == 16,
      "page-per-row-14": pagesPerRow.value == 14,
      "page-per-row-12": pagesPerRow.value == 12,
      "page-per-row-10": pagesPerRow.value == 10,
      "page-per-row-8": pagesPerRow.value == 8,
      "page-per-row-6": pagesPerRow.value == 6,
      "page-per-row-4": pagesPerRow.value == 4,
      "page-per-row-2": pagesPerRow.value == 2,
    }
  })

  watchEffect(() => {
    let pageSize = bp_pageSetting.pageSize.value
    let pageOrientation = bp_pageSetting.pageOrientation.value
    let singlePages = bp_pageSetting.singlePages.value

    pagesPerRow.value = adjustRowBreaks({
      pageSize,
      pageOrientation,
      singlePages,
    })
  })

  onMounted(() => {
    pagesContainer = document.getElementById(domContainerId)

    new ResizeSensor(pagesContainer, function () {
      let pageSize = bp_pageSetting.pageSize.value
      let pageOrientation = bp_pageSetting.pageOrientation.value
      let singlePages = bp_pageSetting.singlePages.value
      pagesPerRow.value = adjustRowBreaks({
        pageSize,
        pageOrientation,
        singlePages,
      })
    })
  })

  return {
    layoutClasses,
  }
}
