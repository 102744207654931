<template>
  <base-toolbar>
    <BackToFlatplan />
  </base-toolbar>
</template>

<script>
import { inject } from "vue"

import BackToFlatplan from "@/components/BackToFlatplan"

export default {
  name: "NotesToolBar",
  components: { BackToFlatplan },
  setup() {
    let mobile = inject("mobile")

    return {
      mobile,
    }
  },
}
</script>

<style lang="scss" scoped></style>
