import { NetworkMetrics } from '../metrics/index'

export function setupMetricsInterceptors(axiosInstance) {
  const metrics = NetworkMetrics.getInstance()

  axiosInstance.interceptors.response.use(
    (response) => {
      if (response.config.timing) {
        metrics.logResponse(
          response.config.timing.endpoint,
          response.config.timing.startTime
        )
      }
 
      return response
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  return axiosInstance
}