<template>
  <v-row justify="center">
    <v-col v-if="!mobile || userExists" cols="12" sm="6" lg="3" order-sm="last" align-self="center">
      <div v-if="!userExists" class="text-medium-emphasis text-h4 ml-8">Our users are in over 6000 cities around the world.<br /><br />Join them.</div>
      <v-alert v-else class="mt-12" type="error">
        <span class="text-h6 text-error">You already have a login.</span><br />
        <p>Please use the same password so that we can log you in.</p>
        <router-link :to="{ name: 'forgot-password', query: { useEmail: email } }" class="text-primary text-decoration-none">Did you forget your password?</router-link>
      </v-alert>
    </v-col>
    <v-col cols="12" sm="6" lg="3">
      <h4 class="text-h4">Sign up for a free Trial</h4>
      <h6 class="text-subtitle-1 text-medium-emphasis">no credit card required, valid for 15 days</h6>

      <v-form class="mt-6" ref="form" v-model="formValid" @submit.prevent="signup">
        <v-text-field label="Organisation (or magazine)" type="text" v-model="accountName" :rules="accountNameRules" data-test-id="accountName" required />
        <v-text-field label="Your name" type="text" v-model="name" :rules="nameRules" data-test-id="name" required />
        <v-text-field label="Email" type="email" v-model="email" data-test-id="email" :rules="emailRules" required />
        <v-text-field
          label="Password"
          :type="showPassword ? 'text' : 'password'"
          :append-inner-icon="showPassword ? mdiEye : mdiEyeOff"
          @click:append-inner="showPassword = !showPassword"
          v-model="password"
          :rules="passwordRules"
          data-test-id="password"
          counter="6"
          required
        />
        <v-alert :model-value="bp_auth.httpStatus === 400 || bp_auth.httpStatus === 401" type="error">
          <ul>
            <li v-for="(error, index) in bp_auth.errors" :key="`error-${index}`">
              {{ error }}
            </li>
          </ul>
        </v-alert>
        <submit-btn type="submit" data-test-id="submit" block :loading="bp_auth.loading"> Create account </submit-btn>
      </v-form>
      <div class="mt-12">
        Already have an account?
        <router-link :to="{ name: 'login' }" class="text-primary text-decoration-none"> Login. </router-link>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { ref, reactive, toRefs, inject } from "vue"
import { mdiEye, mdiEyeOff } from "@mdi/js"

import { useAuth } from "@/modules/auth/use"

export default {
  name: "SignupView",
  setup() {
    const bp_auth = useAuth()

    const mobile = inject("mobile")

    const form = ref(null)
    const userExists = ref(false)

    const state = reactive({
      email: "",
      emailRules: [
        (v) => !!v || "Email is required.",
        (v) => v.indexOf("@") !== 0 || "Email should have a username.",
        (v) => v.includes("@") || "Email should include an @ symbol.",
        (v) => v.includes(".") || "Email should include a period symbol.",
        (v) => v.indexOf(".") <= v.length - 3 || "Email should contain a valid domain extension.",
      ],
      password: "",
      passwordRules: [(v) => !!v || "Password is required.", (v) => v.length >= 6 || "Password should be at least six long."],
      accountName: "",
      accountNameRules: [(v) => !!v || "Organisation name is required."],
      name: "",
      nameRules: [(v) => !!v || "Name is required."],
      formValid: false,
      showPassword: false,
    })

    const signup = () => {
      userExists.value = false
      form.value.validate()
      if (state.formValid || state.formValid === null) { // JD: HACK/BUG
        bp_auth
          .signup({
            accountName: state.accountName.trim(),
            name: state.name.trim(),
            email: state.email.trim(),
            password: state.password,
          })
          .catch((err) => {
            if (err.response && err.response.data.error.code === "USER_EXISTS") {
              userExists.value = true
            }
          })
      } else {
        return false
      }
    }

    return {
      mobile,
      ...toRefs(state),
      bp_auth: reactive(bp_auth),
      form,
      signup,
      userExists,
      mdiEye,
      mdiEyeOff,
    }
  },
}
</script>
