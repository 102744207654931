<template>
  <v-container>
    <div class="d-flex justify-center align-center" style="min-height: 100vh">
      <div class="text-h4 font-weight-black">
        <p><LogoFull /></p>
        <p>We are busy upgrading the service.</p>
        <p>Sorry for this inconvenience 😔</p>
        <p>We will be back in an hour or so.</p>
        <a class="text-body-1" href="/">Try again later.</a>
      </div>
    </div>
  </v-container>
</template>

<script>
import LogoFull from "@/components/Logos/LogoFull.vue"

export default {
  components: {
    LogoFull,
  },
}
</script>
