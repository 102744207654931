const pageNumberingCompare = (a, b) => {
  if (+a.pagePos < +b.pagePos) {
    return -1
  } else if (+a.pagePos > +b.pagePos) {
    return 1
  } else {
    return 0
  }
}

function numberRange({ start, end, inclusive }) {
  if (inclusive) {
    end += 1
  }
  return new Array(end - start).fill().map((d, i) => i + start)
}

export default ({ pageNumberings, noOfPages, displayCoverPageNumbering }) => {
  if (noOfPages > 0) {
    let numberTransforms = [...pageNumberings].sort(pageNumberingCompare)
    let pages = []
    for (var i = 1; i <= noOfPages; i++) {
      pages.push(i)
    }

    for (let transform of numberTransforms) {
      // we may have dirty data where pagePos is -1 ....
      if (+transform.pagePos >= 0 && +transform.pagePos < noOfPages) {
        let noOfPagesTillTheEnd = noOfPages - +transform.pagePos
        pages.splice(
          +transform.pagePos,
          noOfPagesTillTheEnd,
          ...numberRange({
            start: +transform.startWith,
            end: +transform.startWith + noOfPagesTillTheEnd - 1,
            inclusive: true,
          })
        )
      }
    }

    if (displayCoverPageNumbering) {
      if (noOfPages > 0) {
        pages[0] = "Cover"
      }
      if (noOfPages > 1) {
        pages[1] = "IFC"
      }
      if (noOfPages > 2) {
        pages[pages.length - 1] = "Back"
      }
      if (noOfPages > 3) {
        pages[pages.length - 2] = "IBC"
      }
    }

    return pages.map((p) => `${p}`)
  } else {
    return []
  }
}
