import { computed } from "vue"

import { useContext } from "@/use/context"
import { useCommand } from "@/modules/command/use"
import { usePageSetting } from "@/modules/pageSetting/use"
import { usePageNumbers } from "@/modules/flatplan/usePageNumbers"
import { useStitchInStore } from "./store"

export const useStitchIn = function () {
  let bp_command = useCommand()
  let bp_context = useContext()
  let bp_pageSetting = usePageSetting()
  let bp_pageNumbers = usePageNumbers()

  const stitchInStore = useStitchInStore()
  const { getByPageNumber, getById } = stitchInStore

  let pageNumbers = bp_pageNumbers.all.value

  let flatplanId = bp_context.flatplanId.value

  const update = ({ stitchInId, pageNumber, frontContent, backContent, fromLiveUpdate }) => {
    if (fromLiveUpdate) {
      return stitchInStore.update({ flatplanId, stitchInId, pageNumber, frontContent, backContent, storeOnly: true })
    }

    const stitchIn = stitchInStore.getById(stitchInId)

    if (stitchIn) {
      const prevAttributes = { pageNumber: stitchIn.pageNumber, frontContent: stitchIn.frontContent, backContent: stitchIn.backContent }

      return bp_command.add({
        execute: () => {
          return stitchInStore.update({ flatplanId, stitchInId, pageNumber, frontContent, backContent })
        },
        undo: () => {
          return stitchInStore.update({ flatplanId, stitchInId, ...prevAttributes })
        },
      })
    } else {
      return Promise.resolve()
    }
  }

  const add = ({ pageNumber, frontContent, backContent }) => {
    let stitchInId

    return bp_command.add({
      execute: () => {
        return stitchInStore.add({ flatplanId, pageNumber, frontContent, backContent }).then((response) => {
          stitchInId = response.data._id
        })
      },
      undo: () => {
        return stitchInStore.del({ flatplanId, stitchInId })
      },
    })
  }

  // the idea is to add stitchIn on the server first
  // but when we know what stitchIn to add (an undo on a delete via Liveupdate, or a new one coming through
  // from another session via Liveupdate) - that's when we call this method
  // This method won't have an undo as this is in response to a liveupdate - so the data has already changed on the server
  const addFromLiveupdate = (stitchIn) => {
    return stitchInStore.addFromLiveupdate(stitchIn)
  }

  const del = ({ stitchInId, fromLiveUpdate }) => {
    if (fromLiveUpdate) {
      return stitchInStore.del({ flatplanId, stitchInId, storeOnly: true })
    }

    const prevStitchIn = stitchInStore.getById(stitchInId)

    if (prevStitchIn) {
      return bp_command.add({
        execute: () => {
          return stitchInStore.del({ flatplanId, stitchInId })
        },
        undo: () => {
          return stitchInStore.update({ flatplanId, stitchInId, ...prevStitchIn })
        },
      })
    }
  }

  let idForPageIndex = (idx) => {
    let stitchIn
    if (bp_pageSetting.singlePages.value) {
      stitchIn = stitchInStore.getByPageNumber(idx)
    } else {
      stitchIn = stitchInStore.getByPageNumber(idx + 1)
    }
    return stitchIn ? stitchIn._id : null
  }

  let translatePageNumber = (pageNumber) => {
    return pageNumbers[pageNumber]
  } // stitchIn.pageNumber here is really a page number index

  // store's state is reactively passed on (computed for readonly) - all state changes should occur within store
  return {
    stitchIns: computed(() => stitchInStore.ordered),
    getById,
    getByPageNumber,
    idForPageIndex,
    translatePageNumber,
    update,
    del,
    add,
    addFromLiveupdate,
  }
}
