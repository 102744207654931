import { ref, computed } from "vue"
import { defineStore } from "pinia"

import { Bus } from "@/Bus"
import DataService from "@/services/DataService.js"
import { setTag } from "@sentry/browser"

import { useTagStore } from "@/modules/tag/store"

const comparePosition = (a, b) => {
  return a.position - b.position
}

export const usePageStore = defineStore("page", () => {
  const size = ref("md")
  const pages = ref([])
  const orderForFlatplan = ref([])
  const orderForHoldingArea = ref([])

  const ordered = computed(() => {
    return pages.value.sort(comparePosition)
  })

  const onFlatplan = computed(() => {
    return ordered.value.filter((p) => !p.onHoldingArea)
  })

  const onHoldingArea = computed(() => {
    return ordered.value.filter((p) => p.onHoldingArea)
  })

  const order = computed(() => {
    return ordered.value.map((p) => {
      let { _id, position, isSpace, onHoldingArea } = p
      return {
        _id,
        position,
        isSpace: isSpace || false,
        onHoldingArea: onHoldingArea || false,
      }
    })
  })

  const withNotes = computed(() => {
    return onFlatplan.value.filter((p) => !!p.notes)
  })

  function setPageOrder(orderData) {
    let pagesData = []
    orderData.forEach((item) => {
      let pageIndex = pages.value.findIndex((p) => p._id === item._id)
      if (pageIndex !== -1) {
        if (item.specialOp === "delete") {
          // noop
          // therefore "removing" it - by not adding it - to the new pages collection
        } else {
          let page = pages.value[pageIndex]
          page.position = item.position
          page.onHoldingArea = item.onHoldingArea

          pagesData.push(page)
        }
      } else {
        // we have a specialOp space: add to our pages.value
        // when we uodate on data service, we'd need to return this list and
        // update the id (it might be a tmp id generated on the frontend) with the real mongodb id.
        pagesData.push({
          _id: item._id,
          position: item.position,
          onHoldingArea: item.onHoldingArea,
          isSpace: item.isSpace,
        })
      }
    })
    pages.value = pagesData
  }

  function setPageLock({ pageId, isLocked }) {
    let pageIndex = pages.value.findIndex((p) => p._id === pageId)
    if (pageIndex !== -1) {
      let page = pages.value[pageIndex]
      page.isLocked = isLocked
      pages.value.splice(pageIndex, 1, page)
    }
  }

  function changePageLock({ pageId, flatplanId, isLocked, storeOnly }) {
    let prevIsLocked = getById(pageId).isLocked

    let pageIndex = pages.value.findIndex((p) => p._id === pageId)
    if (pageIndex !== -1) {
      let page = pages.value[pageIndex]
      page.isLocked = isLocked
      pages.value.splice(pageIndex, 1, page)
    }

    if (!storeOnly) {
      return DataService.updatePage({ accountId: sessionStorage.getItem("currentAccountId"), flatplanId, pageId, payload: { isLocked } }).catch((err) => {
        setPageLock({ pageId, isLocked: prevIsLocked })
        return Promise.reject(err)
      })
    } else {
      return Promise.resolve()
    }
  }

  function setFragments({ pageId, fragments, template }) {
    let pageIndex = pages.value.findIndex((p) => p._id === pageId)
    if (pageIndex !== -1 && !!fragments) {
      let page = pages.value[pageIndex]
      let pageFragmentsCount = (page.fragments || []).length
      if (fragments) {
        ;(page.fragments || []).splice(0, pageFragmentsCount, ...fragments)
      }
      page.template = template
      pages.value.splice(pageIndex, 1, page)
    }
  }

  function setThumbnail({ pageId, thumbnail }) {
    let pageIndex = pages.value.findIndex((p) => p._id === pageId)
    if (pageIndex !== -1) {
      let page = pages.value[pageIndex]
      page.thumbnail = thumbnail
      pages.value.splice(pageIndex, 1, page)
    }
  }

  function setNotes({ pageId, notes }) {
    let pageIndex = pages.value.findIndex((p) => p._id === pageId)
    if (pageIndex !== -1) {
      let page = pages.value[pageIndex]
      page.notes = notes
      pages.value.splice(pageIndex, 1, page)
    }
  }

  function setTagRefs({ pageId, tagRefs }) {
    let pageIndex = pages.value.findIndex((p) => p._id === pageId)
    if (pageIndex !== -1) {
      let page = pages.value[pageIndex]
      let pageTagRefsCount = (page.tagRefs || []).length
      if (tagRefs) {
        ;(page.tagRefs || []).splice(0, pageTagRefsCount, ...tagRefs)
      }
      pages.value.splice(pageIndex, 1, page)
    }
  }

  function setPage({ pageId, template, fragments, thumbnail, tagRefs }) {
    let pageIndex = pages.value.findIndex((p) => p._id === pageId)
    if (pageIndex !== -1) {
      let page = pages.value[pageIndex]
      let pageFragmentsCount = (page.fragments || []).length
      if (fragments) {
        ;(page.fragments || []).splice(0, pageFragmentsCount, ...fragments)
      }
      page.thumbnail = thumbnail
      page.template = template
      let pageTagRefsCount = (page.tagRefs || []).length
      if (tagRefs) {
        ;(page.tagRefs || []).splice(0, pageTagRefsCount, ...tagRefs)
      }
      pages.value.splice(pageIndex, 1, page)
    } else {
      // we have undeleted - so we end up here
      pages.value.splice(0, 0, { _id: pageId, template, fragments, thumbnail, tagRefs })
    }
  }

  function removePages({ pageIds }) {
    pageIds.forEach((id) => {
      let pageIndex = pages.value.findIndex((p) => p._id === id)
      if (pageIndex !== -1) {
        pages.value.splice(pageIndex, 1)
      }
    })
  }

  function replaceTmpSpacesWithRealOnes(createdSpaces) {
    createdSpaces.forEach((space) => {
      // replace in the state.pages
      let pageIndex = pages.value.findIndex((p) => p._id === space.tmpId)
      if (pageIndex !== -1) {
        let page = pages.value[pageIndex]
        page._id = space._id
        pages.value.splice(pageIndex, 1, page)
      }

      // replace in the state.orderForFlatplan
      let orderIndex = orderForFlatplan.value.indexOf(space.tmpId)
      if (orderIndex !== -1) {
        orderForFlatplan.value.splice(orderIndex, 1, space._id)
      }
    })
  }

  function adjustPositions({ positions }) {
    positions.forEach((data) => {
      let pageIndex = pages.value.findIndex((p) => p._id === data._id)
      if (pageIndex !== -1) {
        // update order data
        let page = pages.value[pageIndex]
        page.position = data.position

        pages.value.splice(pageIndex, 1, page)
      }
    })
  }

  function getById(id) {
    return pages.value.find((p) => p._id === id)
  }

  function getByIds(ids) {
    return pages.value.filter((p) => ids.includes(p._id))
  }

  function getByPosition(position) {
    return onFlatplan[position]
  }

  function collectOrder() {
    let orderData = []

    orderForFlatplan.value.forEach((_id, position) => {
      if (!_id) {
        console.log("We have an undefined _id for page/actions/collectOrder orderForFlatplan")
        return // jump out of this iteration
      }
      if (_id.includes("specialOp-create")) {
        // this happens when we drag a page from the flatplan to the holding area.
        // We want to leave a space behind.
        // So we need to create a space on MongoDB too, and apply the returned MongoDB _id
        // to this tmp space.
        orderData.push({
          _id,
          position,
          isSpace: true,
          onHoldingArea: false,
          specialOp: "create",
        })
      } else {
        let page = getById(_id)

        orderData.push({
          _id,
          position,
          isSpace: page.isSpace,
          onHoldingArea: false,
        })
      }
    })
    orderForHoldingArea.value.forEach((_id, position) => {
      if (!_id) {
        console.log("We have an undefined _id for page/actions/collectOrder orderForHoldingArea")
        return // jump out of this iteration
      }

      orderData.push({
        _id,
        position,
        isSpace: false,
        onHoldingArea: true,
      })
    })

    return orderData
  }

  function spacesToDelete({ orderData }) {
    let currentSpaces = pages.value.filter((p) => p.isSpace).map((p) => p._id)
    let spacesInEditor = orderData.filter((p) => p.isSpace).map((p) => p._id)

    return currentSpaces.filter((s) => !spacesInEditor.includes(s))
  }

  function setSize(data) {
    size.value = data
  }

  function bootstrap(data) {
    pages.value = data
    orderForFlatplan.value = order.value.filter((p) => !p.onHoldingArea).map((p) => p._id)
    orderForHoldingArea.value = order.value.filter((p) => p.onHoldingArea).map((p) => p._id)
  }

  function reorderFlatplan(orderData) {
    orderForFlatplan.value = orderData
    return Promise.resolve() // we need this as we promise change a whole bunch of commands when we save a page using asyncSeries
  }

  function reorderHoldingArea(orderData) {
    orderForHoldingArea.value = orderData
    return Promise.resolve() // we need this as we promise change a whole bunch of commands when we save a page using asyncSeries
  }

  function reorder({ flatplanId, orderData, fromHoldingArea, storeOnly }) {
    let prevOrderData = order.value

    orderData = orderData || []

    if (orderData.length === 0) {
      // we didn't pass in order
      // let's build it up from state
      // if the reorder is due to an undo - then we're passing in an orderData
      orderData = collectOrder()

      if (!fromHoldingArea) {
        // this action was not called from the HoldingArea, but from the flatplan ...
        // so, find missing spaces (dragging from holding area to the flatplan)
        // as we want to delete those
        spacesToDelete({ orderData }).forEach((spaceId) => {
          let data = orderData.find((d) => d._id === spaceId)
          if (data) {
            data.specialOp = "delete"
          }
        })
      }
    }

    // let's update the state.pages
    // in cases of specialOp="delete", it will remove
    // that from the pages
    // But it remains in orderData - as we need to send that to the data service
    setPageOrder(orderData)

    // the next two commits are very important if we called this action to undo.
    // They're superflous if we called this function has a result of the drag&drop
    // But by calling them anyway, we're consistent with both reasons for calling this
    // action, and they won't result in any DOM changes, as the arrays themselves that
    // hold the flatplan and holding area order wouldn't have changed.
    orderForFlatplan.value = order.value.filter((p) => !p.onHoldingArea).map((p) => p._id)
    orderForHoldingArea.value = order.value.filter((p) => p.onHoldingArea).map((p) => p._id)

    if (!storeOnly) {
      return DataService.reorderPages({ accountId: sessionStorage.getItem("currentAccountId"), flatplanId, orderData })
        .then((response) => {
          // let's fix up any temporary spaces we added with the true mongodb ids.
          // any deletedPages are used to amend the prevOrderData for the undo. Look in the comp func where this action is called
          if (response.data.createdSpaces.length > 0) {
            replaceTmpSpacesWithRealOnes(response.data.createdSpaces)
          }
          return Promise.resolve(response)
        })
        .catch((err) => {
          setPageOrder(prevOrderData)
          orderForFlatplan.value = order.value.filter((p) => !p.onHoldingArea).map((p) => p._id)
          orderForHoldingArea.value = order.value.filter((p) => p.onHoldingArea).map((p) => p._id)
          return Promise.reject(err)
        })
    } else {
      return Promise.resolve()
    }
  }

  function addTmpSpacesToFlatplan({ indexes }) {
    // start with biggest index first - else
    // indexes should shift if you insert before
    indexes
      .sort((a, b) => a - b)
      .reverse()
      .forEach((i) => {
        // we are putting in a space temporarily - but it will eventually
        // need to be added to mongo too - and will get a mongo ID.
        // After we save on Mongo, it will return a list of created spaces
        // with these temporary ids. We will then need to match up the new
        // DB ids' with these temporary ids.
        // When we try to save the new order, we will create tmp data for these
        // tmp spaces.
        let tmpId = `specialOp-create-${i}-${Date.now()}`
        orderForFlatplan.value.splice(i, 0, tmpId)
      })
  }

  function addPages({ data }) {
    data.forEach((page) => {
      let pageIndex = pages.value.findIndex((p) => p._id === page._id)
      if (pageIndex === -1) {
        // insert if new
        pages.value.splice(0, 0, page)
      } else {
        // replace if exists
        pages.value.splice(pageIndex, 1, page)
      }
    })
  }

  function updateFragments({ pageId, flatplanId, template, fragments, storeOnly }) {
    let prevFragments = JSON.parse(JSON.stringify(getById(pageId))).fragments
    let prevTemplate = getById(pageId).template

    setFragments({ pageId, fragments, template })
    Bus.$emit("page-changed", { pageId })
    if (!storeOnly) {
      return DataService.updatePage({ accountId: sessionStorage.getItem("currentAccountId"), flatplanId, pageId, payload: { fragments, template } }).catch((err) => {
        setFragments({ pageId, fragments: prevFragments, template: prevTemplate })
        return Promise.reject(err)
      })
    } else {
      return Promise.resolve()
    }
  }

  function setCommentCount({ pageId, commentCount }) {
    let pageIndex = pages.value.findIndex((p) => p._id === pageId)
    if (pageIndex !== -1) {
      let page = pages.value[pageIndex]
      page.commentCount = commentCount
      pages.value.splice(pageIndex, 1, page)
    }
  }

  function updateThumbnail({ pageId, flatplanId, thumbnail, storeOnly }) {
    let prevThumbnail = getById(pageId).thumbnail

    setThumbnail({ pageId, thumbnail })

    Bus.$emit("page-changed", { pageId })

    if (!storeOnly) {
      if (thumbnail === undefined) {
        thumbnail = {}
      }
      return DataService.updatePage({ accountId: sessionStorage.getItem("currentAccountId"), flatplanId, pageId, payload: { thumbnail } }).catch((err) => {
        setThumbnail({ pageId, thumbnail: prevThumbnail })
        return Promise.reject(err)
      })
    } else {
      return Promise.resolve()
    }
  }

  function updateNotes({ pageId, flatplanId, notes, storeOnly }) {
    let prevNotes = getById(pageId).notes

    setNotes({ pageId, notes })

    Bus.$emit("page-changed", { pageId })

    if (!storeOnly) {
      if (notes === undefined) {
        notes = ""
      }
      return DataService.updatePage({ accountId: sessionStorage.getItem("currentAccountId"), flatplanId, pageId, payload: { notes } }).catch((err) => {
        setNotes({ pageId, notes: prevNotes })
        return Promise.reject(err)
      })
    } else {
      return Promise.resolve()
    }
  }

  // tags: { tagRefs, createTags }
  function updateTags({ pageId, flatplanId, tags, storeOnly }) {
    let prevTagRefs = JSON.parse(JSON.stringify(getById(pageId))).tagRefs

    setTagRefs({ pageId, tagRefs: tags.tagRefs }) // first set tags that already exist

    if (!storeOnly) {
      return DataService.updatePage({ accountId: sessionStorage.getItem("currentAccountId"), flatplanId, pageId, payload: tags })
        .then((response) => {
          if (response.data.createTags.length > 0) {
            // let's fix up tagRefs - we may have passed in createTags to the API to create a new tag
            // So let's just  take all tagRefs we get back and set it to the store.
            setTagRefs({ pageId, tagRefs: response.data.tagRefs })

            // we will also need to add the created Tags to our store
            response.data.createTags.forEach((tag) => {
              const tagStore = useTagStore()
              tagStore.addFromLiveupdate(tag)
            })
          }
          return Promise.resolve(response)
        })
        .catch((err) => {
          setTagRefs({ pageId, tagRefs: prevTagRefs })
          return Promise.reject(err)
        })
    } else {
      return Promise.resolve()
    }
  }

  function deletePages({ pageIds, flatplanId, storeOnly }) {
    let prevPages = JSON.parse(JSON.stringify(getByIds(pageIds)))

    removePages({ pageIds })

    // update the collection of order ids for flatplan and holding area
    orderForFlatplan.value = order.value.filter((p) => !p.onHoldingArea).map((p) => p._id)
    orderForHoldingArea.value = order.value.filter((p) => p.onHoldingArea).map((p) => p._id)

    if (!storeOnly) {
      return DataService.deletePages({ accountId: sessionStorage.getItem("currentAccountId"), flatplanId, pageIds }).catch((err) => {
        addPages({ data: prevPages })
        orderForFlatplan.value = order.value.filter((p) => !p.onHoldingArea).map((p) => p._id)
        orderForHoldingArea.value = order.value.filter((p) => p.onHoldingArea).map((p) => p._id)
        return Promise.reject(err)
      })
    } else {
      return Promise.resolve()
    }
  }

  // addNoOfPages - we generate on server first, then mutate store
  function addNoOfPages({ flatplanId, pages, insertAtIndex, shiftRemainingPages }) {
    return DataService.addPages({ accountId: sessionStorage.getItem("currentAccountId"), flatplanId, payload: { newPages: pages, insertAtIndex, shiftRemainingPages } }).then((response) => {
      addPages({ data: response.data.addedPages })
      adjustPositions({ positions: response.data.shiftedPageData })

      // // update the collection of order ids for flatplan and holding area
      orderForFlatplan.value = order.value.filter((p) => !p.onHoldingArea).map((p) => p._id)
      orderForHoldingArea.value = order.value.filter((p) => p.onHoldingArea).map((p) => p._id)

      return Promise.resolve(response)
    })
  }

  // this method is used when pages were added via Liveupdate (so they already exist on the server)
  function addPagesFromLiveupdate({ addedPages, shiftedPageData }) {
    addPages({ data: addedPages })
    adjustPositions({ positions: shiftedPageData })

    // // update the collection of order ids for flatplan and holding area
    orderForFlatplan.value = order.value.filter((p) => !p.onHoldingArea).map((p) => p._id)
    orderForHoldingArea.value = order.value.filter((p) => p.onHoldingArea).map((p) => p._id)
  }

  function stretch({ flatplanId, pageId, pagesData }) {
    let page = getById(pageId)

    pagesData
      .map((p) => p._id)
      .forEach((pageId) => {
        setPage({ pageId, ...page })
      })

    return DataService.stretchPage({ accountId: sessionStorage.getItem("currentAccountId"), flatplanId, pageId, payload: { pageIds: pagesData.map((p) => p._id) } }).catch((err) => {
      pagesData.forEach((page) => {
        setPage({ pageId: page._id, ...page })
      })
      return Promise.reject(err)
    })
  }

  function batchUpdate({ flatplanId, pagesData, createTags, storeOnly }) {
    let prevPages = JSON.parse(JSON.stringify(getByIds(pages.value.map((p) => p._id))))

    pagesData.forEach((page) => {
      setPage({ pageId: page._id, ...page })
    })

    if (!storeOnly) {
      return DataService.updatePages({ accountId: sessionStorage.getItem("currentAccountId"), flatplanId, payload: { updatePages: pagesData, createTags } })
        .then((response) => {
          if (response.data.createTags && response.data.createTags.length > 0) {
            // let's fix up tagRefs - we may have passed in createTags to the API to create a new tag
            // So let's just  take all tagRefs we get back and set it to the store.
            response.data.pages.forEach((page) => {
              setTagRefs({ pageId: page._id, tagRefs: page.tagRefs })
            })

            response.data.createTags.forEach((tag) => {
              const tagStore = useTagStore()
              tagStore.addFromLiveupdate(tag)
            })
          }
          return Promise.resolve(response)
        })
        .catch((err) => {
          prevPages.forEach((page) => {
            setPage({ pageId: page._id, ...page })
          })
          return Promise.reject(err)
        })
    } else {
      return Promise.resolve()
    }
  }

  return {
    size,
    orderForFlatplan,
    orderForHoldingArea,
    ordered,
    withNotes,
    pages,
    onFlatplan,
    onHoldingArea,
    order,
    getById,
    getByIds,
    getByPosition,
    setSize,
    bootstrap,
    reorderFlatplan,
    reorderHoldingArea,
    reorder,
    addTmpSpacesToFlatplan,
    changePageLock,
    updateFragments,
    setCommentCount,
    updateThumbnail,
    updateNotes,
    updateTags,
    deletePages,
    addNoOfPages,
    addPagesFromLiveupdate,
    stretch,
    batchUpdate,
  }
})
