<template>
  <div v-if="page && !page.isSpace" class="page-container" :class="{ 'file-over': fileDragOver }" data-label="edit page">
    <PageAffordance :page="page" @page-selected="$emit('page-selected', pageId)" />
    <div class="page" ref="pageEl" :data-page-id="pageId" data-element-type="page" data-test-id="page" @click="openEditDialog({ pageId })">
      <div v-if="showTags && tags.length > 0" class="page_bp_tag-container">
        <TagDot v-for="tag in tags" :key="tag._id" :color="tag.color" size="8" />
      </div>
      <PageThumbnail v-if="showThumbnails && thumbnail && !!thumbnail.key" :pageId="pageId" :thumbnail="thumbnail" :permitSlideShow="true" data-test-id="thumbnailImg" :key="thumbnail.key" />
      <div v-else class="page__canvas-container">
        <PageSvg :fragments="page.fragments" @fragment-click="fragmentClicked" notifyOnClick />
      </div>
      <div v-if="page.commentCount > 0 || !!page.notes">
        <div class="messages-background"></div>
        <v-icon size="x-small" class="messages-icon icon">{{ mdiMessageReply }}</v-icon>
      </div>
      <div v-if="page.isLocked" class="page__lock-container">
        <v-icon size="x-small" class="icon">{{ mdiLockOutline }}</v-icon>
      </div>
      <StitchInLine v-if="stitchInId" :stitchInId="stitchInId" />
    </div>
    <v-sheet v-if="fileUploading" class="file-dropped d-flex flex-column justify-center align-center" @drop.prevent @dragover.prevent @dragenter.prevent>
      <v-progress-circular indeterminate color="muted"></v-progress-circular>
      <v-btn class="mt-2" variant="text" size="x-small" color="primary" :disabled="!fileUploadCanCancel" @click="cancelFileUpload">cancel</v-btn>
    </v-sheet>
  </div>
  <div v-else class="page space" :data-page-id="pageId" data-element-type="page,space" data-test-id="space">
    <v-btn size="x-small" icon @click="delSpace" color="transparent" data-label="delete space">
      <v-icon>{{ mdiDeleteOutline }}</v-icon>
    </v-btn>
    <StitchInLine v-if="stitchInId" :stitchInId="stitchInId" />
  </div>
</template>

<script>
import { ref, reactive, computed } from "vue"
import { useRouter } from "vue-router"
import { mdiLockOutline, mdiDeleteOutline, mdiMessageReply } from "@mdi/js"

import PageSvg from "./PageSvg"
import PageThumbnail from "./PageThumbnail"
import PageAffordance from "./PageAffordance"

import TagDot from "@/modules/tag/components/TagDot"
import StitchInLine from "@/modules/stitchIn/components/StitchInLine"

import { usePage } from "../use"
import { useFileDragDrop } from "../useFileDragDrop"
import { usePageSetting } from "@/modules/pageSetting/use"
import { useViewSetting } from "@/modules/viewSetting/use"
import { useTag } from "@/modules/tag/use"

export default {
  name: "Page",
  props: {
    pageId: {
      type: String,
      required: true,
    },
    stitchInId: {
      type: String,
      required: false,
    },
  },
  emits: ["page-selected"],
  components: {
    PageSvg,
    PageThumbnail,
    PageAffordance,
    TagDot,
    StitchInLine,
  },
  setup(props) {
    let router = useRouter()

    let bp_page = usePage()
    let bp_pageSetting = usePageSetting()
    let bp_viewSetting = useViewSetting()
    let bp_tag = useTag()

    let pageEl = ref(null)
    let page = reactive(bp_page.getById(props.pageId))

    let _fileDragDrop = useFileDragDrop(pageEl, (thumbnailKey) => {
      bp_page.updatePage({ pageId: props.pageId, thumbnail: { key: thumbnailKey } })
    })

    let fragmentIndexClicked = null

    const fragmentClicked = ({ fragmentIndex }) => {
      fragmentIndexClicked = fragmentIndex
    }

    const openEditDialog = ({ pageId }) => {
      let query = {}
      if (fragmentIndexClicked !== null) {
        query = { highlightFragmentIndex: fragmentIndexClicked }
      }
      router.push({ name: "page-edit-content", params: { pageId }, query })
    }

    let showThumbnails = bp_pageSetting.showThumbnails
    let showTags = bp_viewSetting.showTags

    let thumbnail = computed(() => {
      return bp_page.getById(props.pageId).thumbnail || {}
    })

    let delSpace = () => {
      bp_page.deletePages({ pageIds: [page._id] })
    }

    let tags = computed(() => {
      return page.tagRefs && page.tagRefs.length > 0 ? bp_tag.getByIds(page.tagRefs) : []
    })

    return {
      pageEl,
      page,
      openEditDialog,
      fragmentClicked,
      showThumbnails,
      showTags,
      thumbnail,
      delSpace,
      tags,
      fileDragOver: _fileDragDrop.fileDragOver,
      fileUploading: _fileDragDrop.fileUploading,
      fileUploadCanCancel: _fileDragDrop.canCancel,
      cancelFileUpload: _fileDragDrop.cancel,
      mdiLockOutline,
      mdiDeleteOutline,
      mdiMessageReply,
    }
  },
}
</script>

<style lang="scss" scoped>
@use "@/styles/page.scss";

.space {
  height: calc(var(--bp-page-height) + #{$bp-page-affordance-height}); // add spacing for affordance
}

.page__lock-container {
  display: block;
  position: absolute;
  bottom: -6px;
  left: 0px;
  width: 101%;
  height: 12px;
  text-align: center;
}

.messages-background {
  position: absolute;
  height: 20px;
  width: 20px;
  bottom: 0;
  left: 0;
  background-color: $bp-img-overlay-color;
  opacity: 0.46;
}

.icon {
  opacity: 0.67;
  font-size: 0.75rem;
}

.messages-icon {
  position: absolute;
  bottom: 4px;
  left: 4px;
}

.file-over .page {
  opacity: 0.5;
}

.file-over .affordance {
  background-color: rgb(var(--v-theme-quaternary));
  color: rgb(var(--v-theme-on-quaternary));
}

.file-dropped {
  position: absolute;
  top: calc(#{$bp-page-affordance-height}); // add spacing for affordance
  bottom: 0;
  left: 0;
  right: 0;
}
</style>
