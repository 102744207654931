export default {
  screen: {
    sm: {
      portrait: {
        width: 64,
        height: 84,
        gap: 3,
        spreadGap: 12,
        singleGap: 7,
        rowGutter: 3,
      },
      landscape: {
        width: 84,
        height: 64,
        gap: 3,
        spreadGap: 14,
        singleGap: 6,
        rowGutter: 3,
      },
      square: {
        width: 84,
        height: 84,
        gap: 3,
        spreadGap: 12,
        singleGap: 7,
        rowGutter: 3,
      },
    },
    md: {
      portrait: {
        width: 88,
        height: 114,
        gap: 3,
        spreadGap: 24,
        singleGap: 14,
        rowGutter: 13,
      },
      landscape: {
        width: 114,
        height: 88,
        gap: 3,
        spreadGap: 24,
        singleGap: 6,
        rowGutter: 13,
      },
      square: {
        width: 114,
        height: 114,
        gap: 3,
        spreadGap: 24,
        singleGap: 14,
        rowGutter: 13,
      },
    },
    lg: {
      portrait: {
        width: 120,
        height: 156,
        gap: 3,
        spreadGap: 24,
        singleGap: 16,
        rowGutter: 15,
      },
      landscape: {
        width: 156,
        height: 120,
        gap: 3,
        spreadGap: 24,
        singleGap: 10,
        rowGutter: 19,
      },
      square: {
        width: 156,
        height: 156,
        gap: 3,
        spreadGap: 24,
        singleGap: 10,
        rowGutter: 19,
      },
    },
  },
  pdf: {
    sm: {
      portrait: {
        width: 64,
        height: 84,
        gap: 0,
        spreadGap: 12,
        singleGap: 7,
        rowGutter: -3,
      },
      landscape: {
        width: 84,
        height: 64,
        gap: 0,
        spreadGap: 14,
        singleGap: 6,
        rowGutter: 0,
      },
      square: {
        width: 84,
        height: 84,
        gap: 0,
        spreadGap: 12,
        singleGap: 6,
        rowGutter: 0,
      },
    },
    md: {
      portrait: {
        width: 86,
        height: 108,
        gap: 0,
        spreadGap: 10,
        singleGap: 7,
        rowGutter: 17,
      },
      landscape: {
        width: 106,
        height: 82,
        gap: 0,
        spreadGap: 14,
        singleGap: 6,
        rowGutter: 13,
      },
      square: {
        width: 108,
        height: 108,
        gap: 0,
        spreadGap: 14,
        singleGap: 6,
        rowGutter: 13,
      },
    },
    lg: {
      portrait: {
        width: 110,
        height: 142,
        gap: 0,
        spreadGap: 16,
        singleGap: 8,
        rowGutter: 15,
      },
      landscape: {
        width: 156,
        height: 120,
        gap: 0,
        spreadGap: 24,
        singleGap: 10,
        rowGutter: 19,
      },
      square: {
        width: 142,
        height: 142,
        gap: 0,
        spreadGap: 16,
        singleGap: 8,
        rowGutter: 15,
      },
    },
  },
}
