export class MetricsStore {
  constructor() {
    this.data = {
      responseTimes: [],    // newest first
      errorLog: [],   // newest first
      retryLog: []    // newest first
    }
  }

  addResponseTime(timing) {
    this.data.responseTimes = [timing, ...this.data.responseTimes.slice(0, 99)]
  }

  addError(error) {
    this.data.errorLog = [error, ...this.data.errorLog.slice(0, 99)]
  }

  addRetry(retry) {
    this.data.retryLog = [retry, ...this.data.retryLog.slice(0, 99)]
  }

  getRecentMetrics() {
    return {
      recentResponseTimes: this.data.responseTimes.slice(0, 5),
      recentErrors: this.data.errorLog.slice(0, 5),
      recentRetries: this.data.retryLog.slice(0, 5)
    }
  }
}