import { computed } from "vue"
import { useCommand } from "@/modules/command/use"
import { useAccountStore } from "./store"

export const useAccount = function () {
  let bp_command = useCommand()
  const accountStore = useAccountStore()
  const { getAccountById, changeCurrentAccount } = accountStore

  const fetchPeople = () => {
    return accountStore.fetchPeople({ account: accountStore.getCurrentAccount })
  }

  const update = ({ name, fromLiveUpdate }) => {
    if (fromLiveUpdate) {
      return accountStore.update({ name, storeOnly: true })
    }

    // const flatplan = accountStore.current

    const prevAttributes = { name: accountStore.getCurrentAccount.value }

    return bp_command.add({
      execute: () => {
        return accountStore.update({ name })
      },
      undo: () => {
        return accountStore.update({ ...prevAttributes })
      },
    })
  }


  // store's state is reactively passed on (computed for readonly) - all state changes should occur within store
  return {
    accounts: computed(() => accountStore.orderedAccounts),
    currentAccountId: computed(() => accountStore.currentAccountId),
    currentAccount: computed(() => accountStore.getCurrentAccount),
    anyAccounts: computed(() => accountStore.anyAccounts),
    isActive: computed(() => accountStore.isActive),
    id: computed(() => accountStore.id),
    people: computed(() => accountStore.people),
    getAccountById,
    changeCurrentAccount,
    fetchPeople,
    update
  }
}
