import { computed } from "vue"
import { useCommand } from "@/modules/command/use"
import { useFlatplanStore } from "./store"

export const useFlatplan = () => {
  let bp_command = useCommand()
  const flatplanStore = useFlatplanStore()

  let flatplanId = flatplanStore.id

  const update = ({ issue, singlePages, pageOrientation, notes, fromLiveUpdate }) => {
    if (fromLiveUpdate) {
      return flatplanStore.update({ flatplanId, issue, singlePages, pageOrientation, notes, storeOnly: true })
    }

    const flatplan = flatplanStore.current

    const prevAttributes = { issue: flatplan.issue, singlePages: flatplan.singlePages, pageOrientation: flatplan.pageOrientation, notes: flatplan.notes }

    return bp_command.add({
      execute: () => {
        return flatplanStore.update({ flatplanId, issue, singlePages, pageOrientation, notes })
      },
      undo: () => {
        return flatplanStore.update({ flatplanId, ...prevAttributes })
      },
    })
  }

  const updatePageNumberings = ({ pageNumberings, displayCoverPageNumbering, fromLiveUpdate }) => {
    if (fromLiveUpdate) {
      return flatplanStore.updatePageNumberings({ flatplanId, pageNumberings, displayCoverPageNumbering, storeOnly: true })
    }

    const flatplan = flatplanStore.current

    const prevAttributes = { displayCoverPageNumbering: flatplan.displayCoverPageNumbering.value, pageNumberings: JSON.parse(JSON.stringify(flatplan.pageNumberings)) }

    return bp_command.add({
      execute: () => {
        return flatplanStore.updatePageNumberings({ flatplanId, pageNumberings, displayCoverPageNumbering })
      },
      undo: () => {
        return flatplanStore.updatePageNumberings({ flatplanId, ...prevAttributes })
      },
    })
  }

  // will never come from liveupdate - liveupdate only deals with current flatplan
  // It also cannot be undone.
  const create = ({ issue, singlePages, pageOrientation, clone }) => {
    return flatplanStore.create({
      issue,
      singlePages,
      pageOrientation,
      displayCoverPageNumbering: flatplanStore.displayCoverPageNumbering,
      pageNumberings: flatplanStore.pageNumberings,
      cloneFromFlatplanId: flatplanId,
      clone,
    })
  }

  // will never come from liveupdate - liveupdate only deals with current flatplan
  // It also cannot be undone.
  const del = () => {
    return flatplanStore.del({ flatplanId })
  }

  // will never come from liveupdate - liveupdate only deals with current flatplan
  // It also cannot be undone.
  const archive = () => {
    return flatplanStore.archive({ flatplanId })
  }

  const sendShareEmail = ({ to, message, issue }) => {
    return flatplanStore.sendShareEmail({
      to,
      message,
      issue,
    })
  }

  // store's state is reactively passed on (computed for readonly) - all state changes should occur within store
  return {
    id: computed(() => flatplanStore.id),
    issue: computed(() => flatplanStore.issue),
    isArchived: computed(() => flatplanStore.isArchived),
    singlePages: computed(() => flatplanStore.singlePages),
    pageOrientation: computed(() => flatplanStore.pageOrientation),
    pageNumberings: computed(() => flatplanStore.pageNumberings),
    displayCoverPageNumbering: computed(() => flatplanStore.displayCoverPageNumbering),
    notes: computed(() => flatplanStore.notes),
    ordered: computed(() => flatplanStore.ordered),
    archived: computed(() => flatplanStore.archived),
    orderData: computed(() => flatplanStore.orderData),
    defaults: computed(() => flatplanStore.defaults),
    update,
    create,
    del,
    archive,
    sendShareEmail,
    updatePageNumberings,
  }
}
