<template>
  <v-main v-if="layout === 'plain'">
    <slot></slot>
  </v-main>
</template>

<script>
import { computed } from "vue"
import { useRouter } from "vue-router"
import TheFooter from "@/components/TheFooter.vue"

export default {
  name: "PlainLayout",
  components: {
    TheFooter,
  },
  setup() {
    let router = useRouter()
    let currentRoute = router.currentRoute

    return {
      layout: computed(() => currentRoute.value.meta.layout),
    }
  }
}
</script>
