<template>
  <div v-if="layout === 'auth'">
    <v-app-bar flat>
      <v-app-bar-title>
        <a :href="isImpersonateRoute ? '/' : '//www.blinkplan.com'" class="ml-1"><LogoFull class="mt-3" /></a>
      </v-app-bar-title>
      <TheProgressBar />
    </v-app-bar>
    <v-main>
      <v-container class="fill-height">
        <v-row>
          <v-col justify="center" align="center">
            <v-sheet>
              <v-row>
                <v-col>
                  <v-sheet class="d-flex justify-center mb-4">
                    <v-avatar color="info" size="125">
                      <img src="@/assets/bonnie.jpg" />
                    </v-avatar>
                  </v-sheet>
                </v-col>
              </v-row>
              <v-row>
                <v-col :style="{ textAlign: 'left' }">
                  <slot></slot>
                </v-col>
              </v-row>
            </v-sheet>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <TheFooter />
  </div>
</template>

<script>
import { computed } from "vue"
import { useRouter } from "vue-router"

import LogoFull from "@/components/Logos/LogoFull.vue"
import TheProgressBar from "@/components/TheProgressBar.vue"
import TheFooter from "@/components/TheFooter.vue"


export default {
  name: "AuthLayout",
  components: {
    LogoFull,
    TheProgressBar,
    TheFooter,
  },
  setup() {
    let router = useRouter()
    let currentRoute = router.currentRoute

    return {
      isImpersonateRoute: currentRoute.value.name === "impersonate",
      layout: computed(() => currentRoute.value.meta.layout),
    }
  },
}
</script>
