<template>
  <v-card class="rounded-t-lg" :color="isCurrentPlan ? 'secondary' : ''">
    <v-card-item>
      <v-card-title>{{ accountPlan.name }}</v-card-title>
    </v-card-item>
    <v-card-text>
      <div class="text-subtitle-2 mb-3 font-weight-black" :class="{ 'on-secondary': isCurrentPlan, 'text-secondary': !isCurrentPlan }">
        <span v-if="accountPlan.price === 0">Free</span><span v-else>${{ accountPlan.price }}</span> {{ term }}
      </div>
      
      <v-row dense>
        <v-col
          ><span class="font-weight-black">{{ accountPlan.no_of_users }}</span> people</v-col
        >
      </v-row>
      <v-row dense>
        <v-col
          ><span class="font-weight-black">{{ accountPlan.no_of_flatplans }}</span> editable flatplans</v-col
        >
      </v-row>
      <v-row dense>
        <v-col
          ><span class="font-weight-black">{{ accountPlan.no_of_pages }}</span> pages per flatplan</v-col
        >
      </v-row>
      <v-row v-if="!withinLimits">
        <v-col>
          <span class="text-error">Your current plan exceeds the limits of this plan.</span
          ><span class="ml-2 font-weight-black">You currently have {{ accountStats.flatplanCount }} flatplans, and a page count of {{ accountStats.maxPagesForFlatplanCount }}. You'll need to delete some data.</span>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions v-if="withinLimits && accountPlan.price > 0 && !isCurrentPlan">
      <v-dialog v-model="dialog" @keydown.esc="closeDialog" persistent max-width="290">
        <template v-slot:activator="{ props }">
          <v-btn v-bind="props" variant="text" color="primary">Upgrade</v-btn>
        </template>
        <v-card>
          <v-card-item>
            <v-card-title class="text-h5">Confirm</v-card-title>
          </v-card-item>          
          <v-card-text>
            You are about to subscribe to the <span class="font-weight-black">{{ accountPlan.name }}</span> Plan. You will be charged <span class="font-weight-black">${{ accountPlan.price }} {{ term }}</span>.
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <cancel-btn @click="closeDialog">Cancel</cancel-btn>
            <submit-btn type="submit" class="ml-2" @click="saveDialog">Accept</submit-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-actions>
  </v-card>
</template>

<script>
import { ref, computed } from "vue"

import { useBilling } from "../use"

export default {
  name: "AccountPlan",
  props: {
    accountPlan: {
      type: Object,
      required: true,
    },
    currentAccountPlanId: {
      type: Number,
    },
    accountStats: {
      type: Object,
      required: true,
      default: () => {
        return {}
      },
    },
  },
  setup(props) {
    let bp_billing = useBilling()
    let dialog = ref(false)
    let sessionId = ref(null)
    let checkoutUrl = ref(null)

    let isCurrentPlan = computed(() => {
      return props.currentAccountPlanId === props.accountPlan.id
    })

    let term = computed(() => {
      if (props.accountPlan.trial) {
        return "for 15 days"
      }
      if (props.accountPlan.price === 0) {
        return ""
      }
      if (props.accountPlan.cycle === 1) {
        return "per month"
      }
      if (props.accountPlan.cycle === 12) {
        return "per year"
      }
      return `every ${props.accountPlan.cycle} months`
    })

    let closeDialog = () => {
      dialog.value = false
    }

    let saveDialog = () => {
      bp_billing.upgrade({ code: props.accountPlan.code }).then(({ data }) => {
        if (data.session_id) {
          sessionId.value = data.session_id
          checkoutUrl.value = data.checkout_url
          redirectToCheckout()
        } else if (data.location) {
          location.href = data.location
        }
      })
    }

    let redirectToCheckout = () => {
      location.href = checkoutUrl.value
    }

    return {
      dialog,
      sessionId,
      isCurrentPlan,
      term,
      withinLimits: computed(() => {
        return bp_billing.withinLimitsForPlan(props.accountPlan)
      }),
      closeDialog,
      saveDialog,
    }
  },
}
</script>

<style lang="scss" scoped></style>
