<template>
  <v-row justify="center">
    <v-col cols="12" sm="6" lg="3">
      <p>Enter a new password to reset the password on your account.</p>
      <v-form class="mt-6" ref="form" v-model="formValid" @submit.prevent="resetPassword">
        <v-text-field
          label="Password"
          :type="showPassword ? 'text' : 'password'"
          :append-icon="showPassword ? mdiEye : mdiEyeOff"
          @click:append="showPassword = !showPassword"
          data-test-id="password"
          v-model="password"
          :rules="passwordRules"
          required
        />
        <v-alert :model-value="bp_auth.httpStatus === 406" type="error"> We couldn't find that email. </v-alert>

        <submit-btn type="submit" class="mr-4" data-test-id="submit" :loading="bp_auth.loading" block> Set new password </submit-btn>
      </v-form>
      <div class="mt-12">
        You remembered your password?
        <router-link :to="{ name: 'login' }" class="text-primary text-decoration-none"> Login here. </router-link>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { reactive, toRefs, ref } from "vue"
import { mdiEye, mdiEyeOff } from "@mdi/js"

import { useNotification } from "@/modules/notification/use"
import { useAuth } from "@/modules/auth/use"

export default {
  name: "ResetPasswordView",
  props: {
    reset_password_token: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const bp_auth = useAuth()
    const bp_notification = useNotification()

    const form = ref(null)

    const state = reactive({
      password: "",
      passwordRules: [(v) => !!v || "Password is required.", (v) => v.length >= 6 || "Password should be at least six long."],
      formValid: false,
      showPassword: false,
    })

    const resetPassword = () => {
      form.value.validate()
      if (state.formValid || state.formValid === null) { // JD: HACK/BUG
        bp_auth
          .resetPassword({
            password: state.password,
            reset_password_token: props.reset_password_token,
          })
          .then(() => {
            bp_notification.show({
              type: "success",
              message: "Password reset successfully.",
            })
          })
      }
    }

    return {
      ...toRefs(state),
      resetPassword,
      form,
      bp_auth: reactive(bp_auth),
      mdiEye,
      mdiEyeOff,
    }
  },
}
</script>
