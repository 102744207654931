import { createApp } from "vue"
import router from "../router"
import * as Sentry from "@sentry/vue"
import LogRocket from "logrocket"
import { useMonitoring } from "@/use/useMonitoring"

let bp_monitoring = useMonitoring()

if (bp_monitoring.captureErrors()) {

  let app = createApp()

  const attachmentUrlFromDsn = (dsn, eventId) => {
    const { host, path, projectId, port, protocol, user } = dsn
    return `${protocol}://${host}${port !== "" ? `:${port}` : ""}${path !== "" ? `/${path}` : ""}/api/${projectId}/events/${eventId}/attachments/?sentry_key=${user}&sentry_version=7&sentry_client=custom-javascript`
  }

  // Sentry.getGlobalScope().addEventProcessor((event) => {
  //   try {
  //     const client = Sentry.getClient()
  //     const endpoint = attachmentUrlFromDsn(client.getDsn(), event.event_id)
  //     const formData = new FormData()
  //     formData.append(
  //       "my-attachment",
  //       new Blob([JSON.stringify({ sessionStorage, localStorage })], {
  //         type: "application/json",
  //       }),
  //       "storage.json"
  //     )
  //     fetch(endpoint, {
  //       method: "POST",
  //       body: formData,
  //     }).catch((ex) => {
  //       // we have to catch this otherwise it throws an infinite loop in Sentry
  //       console.error(ex)
  //     })
  //     return event
  //   } catch (ex) {
  //     console.error(ex)
  //   }
  // })

  // TryCatch: https://github.com/getsentry/sentry-javascript/issues/2957
  Sentry.init({
    app,
    dsn: "https://81cd98d21e17454b8a15a0732cdbb029@o8276.ingest.sentry.io/5431075",
    release: import.meta.env.VITE_SENTRY_RELEASE || "",
    environment: import.meta.env.MODE,
    integrations: [
      Sentry.browserSessionIntegration(),
      Sentry.vueIntegration({
        tracingOptions: {
          trackComponents: true,
          timeout: 1000,
          hooks: ["mount", "update", "unmount"],
        },
      }),
      Sentry.browserTracingIntegration({ router })
    ],
    ignoreErrors: ["SecurityError", "InvalidStateError", "InternalError"], //InternalError === Sentry Error?
    tracesSampleRate: 0.2,
    tracingOptions: {
      // Track all HTTP requests
      trackHttpRequestTime: true
    },
    logErrors: true,
    beforeSend(event, hint) {
      if (hint.originalException) {
        const { message } = hint.originalException
        // "ServiceWorker", private browsing error can occur in Firefox when the ServiceWorker fails to register due to cookies being deleted after each sessin
        if (message && (message.match(/status code/i) || message.match(/navigation/i) || message.match(/ServiceWorker/i) || message.match(/Service Worker/i) || message.match(/messageHandlers/i) || message.match(/service-worker/i) || message.match(/ResizeObserver/i))) {
          return null
        }
      }
      return event
    },
    beforeBreadcrumb(breadcrumb, hint) {
      if (breadcrumb.message.includes("sckts.blinkplan.com")) return null
      if (breadcrumb.message.includes("Lost connection")) return null
      if (breadcrumb.message.includes("Service worker looking for update")) return null
      if (breadcrumb.message.includes("Honey.safariextension")) return null

      // If we have a `ui.click` type of breadcrumb, eg. clicking on a button
      // We will extract a `data-label` attribute from it and use it as a part of the message
      if (breadcrumb.category === "ui.click") {
        let elWithLabel = hint.event.target.closest("[data-label]")
        if (elWithLabel) {
          breadcrumb.message = `User clicked on an elm with label "${elWithLabel.dataset.label}"`
        }
      }
      if (breadcrumb.category === "ui.input") {
        let elWithLabel = hint.event.target.closest("[data-label]")
        if (elWithLabel) {
          breadcrumb.message = `User typing into an input with label "${elWithLabel.dataset.label}"`
        }
      }
      return breadcrumb
    },
  })

  LogRocket.getSessionURL((sessionURL) => {
    Sentry.getCurrentScope().setContext("logrocketURL", sessionURL)
  })
}