import { ref } from "vue"
import { defineStore } from "pinia"

import DataService from "@/services/DataService.js"

export const useEmailGroupStore = defineStore("emailGroup", () => {
  const emailGroups = ref([])

  function getById(id) {
    return emailGroups.value.find((s) => s._id === id)
  }

  function bootstrap(data) {
    emailGroups.value = data
  }

  function setEmailGroup({ emailGroupId, name, emails }) {
    let emailGroupIndex = emailGroups.value.findIndex((c) => c._id === emailGroupId)
    if (emailGroupIndex !== -1) {
      let emailGroup = emailGroups.value[emailGroupIndex]
      emailGroup.name = name
      emailGroup.emails = JSON.parse(JSON.stringify(emails))
      emailGroups.value.splice(emailGroupIndex, 1, emailGroup)
    } else {
      // we have undeleted - so we end up here
      emailGroups.value.splice(0, 0, { _id: emailGroupId, name, emails })
    }
  }

  function removeEmailGroup({ emailGroupId }) {
    let emailGroupIndex = emailGroups.value.findIndex((c) => c._id === emailGroupId)
    if (emailGroupIndex !== -1) {
      emailGroups.value.splice(emailGroupIndex, 1)
    }
  }

  function addEmailGroup(emailGroup) {
    let emailGroupIndex = emailGroups.value.findIndex((c) => c._id === emailGroup._id)
    if (emailGroupIndex === -1) {
      // insert if new
      emailGroups.value.splice(0, 0, emailGroup)
    } else {
      // replace if exists
      emailGroups.value.splice(emailGroupIndex, 1, emailGroup)
    }
  }

  function update({ emailGroupId, name, emails, storeOnly }) {
    let prevAttributes = JSON.parse(JSON.stringify(getById(emailGroupId) || {}))

    setEmailGroup({ emailGroupId, name, emails })

    if (!storeOnly) {
      return DataService.updateEmailGroup({ accountId: sessionStorage.getItem("currentAccountId"), emailGroupId, payload: { name, emails } }).catch((err) => {
        setEmailGroup({ emailGroupId, ...prevAttributes })
        return Promise.reject(err)
      })
    } else {
      return Promise.resolve()
    }
  }

  function del({ emailGroupId, storeOnly }) {
    let prevEmailGroup = JSON.parse(JSON.stringify(getById(emailGroupId)))

    removeEmailGroup({ emailGroupId })

    if (!storeOnly) {
      return DataService.deleteEmailGroup({ accountId: sessionStorage.getItem("currentAccountId"), emailGroupId }).catch((err) => {
        addEmailGroup(prevEmailGroup)
        return Promise.reject(err)
      })
    } else {
      return Promise.resolve()
    }
  }

  // add - we generate on server first, then mutate store
  function add({ name, emails }) {
    return DataService.addEmailGroup({ accountId: sessionStorage.getItem("currentAccountId"), payload: { name, emails } }).then((response) => {
      addEmailGroup(response.data)

      return Promise.resolve(response)
    })
  }

  // this method is used when emailGroup was added via Liveupdate (so it already exists on the server)
  function addFromLiveupdate(emailGroup) {
    addEmailGroup(emailGroup)
  }

  return {
    emailGroups,
    getById,
    bootstrap,
    update,
    del,
    add,
    addFromLiveupdate,
  }
})
