<template>
  <base-toolbar>
    <BackToFlatplan />
    <v-btn variant="text" :to="{ name: 'content-edit', params: { contentId: '__new__' } }" :prepend-icon="mdiPlus" color="primary">
      <span v-if="!mobile">Add</span>
    </v-btn>
    <v-btn variant="text" :to="{ name: 'content-upload-from-file' }" :prepend-icon="mdiUpload" exact color="primary">
      <span v-if="!mobile">Upload from file</span>
    </v-btn>
  </base-toolbar>
</template>

<script>
import { inject } from "vue"
import { mdiPlus, mdiUpload } from "@mdi/js"

import BackToFlatplan from "@/components/BackToFlatplan"

export default {
  name: "ContentToolBar",
  components: { BackToFlatplan },
  setup() {
    let mobile = inject("mobile")

    return {
      mobile,
      mdiPlus,
      mdiUpload,
    }
  },
}
</script>

<style lang="scss" scoped></style>
