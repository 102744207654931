import { computed } from "vue"
import { useContext } from "@/use/context"
import { useCommand } from "@/modules/command/use"
import { useCategoryStore } from "./store"

export const useCategory = function () {
  let bp_command = useCommand()
  let bp_context = useContext()
  const categoryStore = useCategoryStore()
  const { getById, getByIds, orderFn, noneId, noneCategory } = categoryStore

  let flatplanId = bp_context.flatplanId.value

  const update = ({ categoryId, name, color, fromLiveUpdate }) => {
    if (fromLiveUpdate) {
      return categoryStore.update({ flatplanId, categoryId, name, color, storeOnly: true })
    }

    const category = categoryStore.getById(categoryId)

    if (category) {
      const prevAttributes = { name: category.name, color: category.color }

      return bp_command.add({
        execute: () => {
          return categoryStore.update({ flatplanId, categoryId, name, color })
        },
        undo: () => {
          return categoryStore.update({ flatplanId, categoryId, ...prevAttributes })
        },
      })
    }
  }

  const add = ({ name, color }) => {
    let categoryId

    return bp_command.add({
      execute: () => {
        return categoryStore.add({ flatplanId, name, color }).then((response) => {
          categoryId = response.data._id
        })
      },
      undo: () => {
        return categoryStore.del({ flatplanId, categoryId })
      },
    })
  }

  // the idea is to add category on the server first
  // but when we know what category to add (an undo on a delete via Liveupdate, or a new one coming through
  // from another session via Liveupdate) - that's when we call this method
  // This method won't have an undo as this is in response to a liveupdate - so the data has already changed on the server
  const addFromLiveupdate = (category) => {
    return categoryStore.addFromLiveupdate(category)
  }

  // pass in orderData if from LiveUpdate.
  const reorder = ({ orderData, fromLiveUpdate }) => {
    if (fromLiveUpdate) {
      return categoryStore.reorder({ flatplanId, orderData, storeOnly: true })
    }

    const prevOrderData = categoryStore.orderData

    return bp_command.add({
      execute: () => {
        return categoryStore.reorder({ flatplanId })
      },
      undo: () => {
        return categoryStore.reorder({ flatplanId, orderData: prevOrderData })
      },
    })
  }

  const reorderDraggable = (orderData) => {
    categoryStore.reorderDraggable(orderData)
  }

  const del = ({ categoryId, fromLiveUpdate }) => {
    if (fromLiveUpdate) {
      return categoryStore.del({ flatplanId, categoryId, storeOnly: true })
    }

    const prevCategory = categoryStore.getById(categoryId)

    if (prevCategory) {
      return bp_command.add({
        execute: () => {
          return categoryStore.del({ flatplanId, categoryId })
        },
        undo: () => {
          return categoryStore.update({ flatplanId, categoryId, ...prevCategory })
        },
      })
    }
  }

  // store's state is reactively passed on (computed for readonly) - all state changes should occur within store
  return {
    categories: computed(() => categoryStore.categories),
    draggableOrder: computed(() => categoryStore.draggableOrder),
    getById,
    getByIds,
    reorder,
    reorderDraggable,
    update,
    del,
    add,
    addFromLiveupdate,
    colorHash: computed(() => categoryStore.categories.map((c) => c.color || "").join("")), // we use this for keys in templates to figure out if categories have changed
    orderFn,
    noneId,
    noneCategory,
  }
}
