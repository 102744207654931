import axios from "axios"

const baseURL = "//" + import.meta.env.VITE_DATA_ENDPOINT

export default {
  bootstrap({ accountId, flatplanId }) {
    return axios.get(`${baseURL}/${accountId}/bootstrap/${flatplanId ? flatplanId : ""}`, { _ignore404: true })
  },
  initBootstrap({ accountId }) {
    return axios.post(`${baseURL}/${accountId}/bootstrap/init`)
  },
  accountStats({ accountId }) {
    return axios.get(`${baseURL}/${accountId}/stats`)
  },
  updateFlatplan({ accountId, flatplanId, payload }) {
    return axios.put(`${baseURL}/${accountId}/flatplans/${flatplanId}`, payload)
  },
  createFlatplan({ accountId, payload }) {
    return axios.post(`${baseURL}/${accountId}/flatplans`, payload)
  },
  deleteFlatplan({ accountId, flatplanId }) {
    return axios.delete(`${baseURL}/${accountId}/flatplans/${flatplanId}`)
  },
  archiveFlatplan({ accountId, flatplanId }) {
    return axios.post(`${baseURL}/${accountId}/flatplans/${flatplanId}/archive`)
  },
  reorderPages({ accountId, flatplanId, orderData }) {
    return axios.post(`${baseURL}/${accountId}/flatplans/${flatplanId}/pages/reorder`, { orderData })
  },
  updatePage({ accountId, flatplanId, pageId, payload }) {
    return axios.put(`${baseURL}/${accountId}/flatplans/${flatplanId}/pages/${pageId}`, payload)
  },
  updatePages({ accountId, flatplanId, payload }) {
    return axios.post(`${baseURL}/${accountId}/flatplans/${flatplanId}/pages/batch/update`, payload)
  },
  deletePages({ accountId, flatplanId, pageIds }) {
    return axios.post(`${baseURL}/${accountId}/flatplans/${flatplanId}/pages/batch/destroy`, { pageIds })
  },
  addPages({ accountId, flatplanId, payload }) {
    return axios.post(`${baseURL}/${accountId}/flatplans/${flatplanId}/pages/batch/create`, payload)
  },
  stretchPage({ accountId, flatplanId, pageId, payload }) {
    return axios.post(`${baseURL}/${accountId}/flatplans/${flatplanId}/pages/batch/${pageId}/stretch`, payload)
  },
  reorderCategories({ accountId, flatplanId, orderData }) {
    return axios.post(`${baseURL}/${accountId}/flatplans/${flatplanId}/categories/reorder`, { orderData })
  },
  updateCategory({ accountId, flatplanId, categoryId, payload }) {
    return axios.put(`${baseURL}/${accountId}/flatplans/${flatplanId}/categories/${categoryId}`, payload)
  },
  deleteCategory({ accountId, flatplanId, categoryId }) {
    return axios.delete(`${baseURL}/${accountId}/flatplans/${flatplanId}/categories/${categoryId}`)
  },
  addCategory({ accountId, flatplanId, payload }) {
    return axios.post(`${baseURL}/${accountId}/flatplans/${flatplanId}/categories`, payload)
  },
  reorderTags({ accountId, flatplanId, orderData }) {
    return axios.post(`${baseURL}/${accountId}/flatplans/${flatplanId}/tags/reorder`, { orderData })
  },
  updateTag({ accountId, flatplanId, tagId, payload }) {
    return axios.put(`${baseURL}/${accountId}/flatplans/${flatplanId}/tags/${tagId}`, payload)
  },
  deleteTag({ accountId, flatplanId, tagId }) {
    return axios.delete(`${baseURL}/${accountId}/flatplans/${flatplanId}/tags/${tagId}`)
  },
  addTag({ accountId, flatplanId, payload }) {
    return axios.post(`${baseURL}/${accountId}/flatplans/${flatplanId}/tags`, payload)
  },
  updateStitchIn({ accountId, flatplanId, stitchInId, payload }) {
    return axios.put(`${baseURL}/${accountId}/flatplans/${flatplanId}/stitch_ins/${stitchInId}`, payload)
  },
  deleteStitchIn({ accountId, flatplanId, stitchInId }) {
    return axios.delete(`${baseURL}/${accountId}/flatplans/${flatplanId}/stitch_ins/${stitchInId}`)
  },
  addStitchIn({ accountId, flatplanId, payload }) {
    return axios.post(`${baseURL}/${accountId}/flatplans/${flatplanId}/stitch_ins`, payload)
  },
  updateSection({ accountId, flatplanId, sectionId, payload }) {
    return axios.put(`${baseURL}/${accountId}/flatplans/${flatplanId}/sections/${sectionId}`, payload)
  },
  deleteSection({ accountId, flatplanId, sectionId }) {
    return axios.delete(`${baseURL}/${accountId}/flatplans/${flatplanId}/sections/${sectionId}`)
  },
  addSection({ accountId, flatplanId, payload }) {
    return axios.post(`${baseURL}/${accountId}/flatplans/${flatplanId}/sections`, payload)
  },
  updateEmailGroup({ accountId, emailGroupId, payload }) {
    return axios.put(`${baseURL}/${accountId}/email_groups/${emailGroupId}`, payload)
  },
  deleteEmailGroup({ accountId, emailGroupId }) {
    return axios.delete(`${baseURL}/${accountId}/email_groups/${emailGroupId}`)
  },
  addEmailGroup({ accountId, payload }) {
    return axios.post(`${baseURL}/${accountId}/email_groups`, payload)
  },
  updateContent({ accountId, flatplanId, contentId, payload }) {
    return axios.put(`${baseURL}/${accountId}/flatplans/${flatplanId}/contents/${contentId}`, payload)
  },
  addContents({ accountId, flatplanId, payload }) {
    return axios.post(`${baseURL}/${accountId}/flatplans/${flatplanId}/contents/batch/upsert`, payload)
  },
  deleteContents({ accountId, flatplanId, identifiers }) {
    return axios.post(`${baseURL}/${accountId}/flatplans/${flatplanId}/contents/batch/destroy`, { identifiers })
  },
  fetchComments({ accountId, flatplanId, pageId }) {
    return axios.get(`${baseURL}/${accountId}/flatplans/${flatplanId}/pages/${pageId}/comments`)
  },
  addComment({ accountId, flatplanId, pageId, payload }) {
    return axios.post(`${baseURL}/${accountId}/flatplans/${flatplanId}/pages/${pageId}/comments`, payload)
  },
  deleteComment({ accountId, flatplanId, pageId, commentId }) {
    return axios.delete(`${baseURL}/${accountId}/flatplans/${flatplanId}/pages/${pageId}/comments/${commentId}`)
  },
  updateComment({ accountId, flatplanId, pageId, commentId, payload }) {
    return axios.put(`${baseURL}/${accountId}/flatplans/${flatplanId}/pages/${pageId}/comments/${commentId}`, payload)
  },
}
