<template>
  <base-toolbar>
    <BackToFlatplan />
    <v-btn variant="text" :to="{ name: 'tag-edit', params: { tagId: 'new' } }" :prepend-icon="mdiPlus" color="primary">
      <span v-if="!mobile">Add</span>
    </v-btn>
  </base-toolbar>
</template>

<script>
import { inject } from "vue"
import { mdiPlus } from "@mdi/js"

import BackToFlatplan from "@/components/BackToFlatplan"

export default {
  name: "TagToolBar",
  components: { BackToFlatplan },
  setup() {
    let mobile = inject("mobile")

    return {
      mobile,
      mdiPlus
    }
  },
}
</script>

<style lang="scss" scoped></style>
