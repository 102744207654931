import { useBootstrapAppStore } from "./store"

export const useBootstrapApp = function () {
  const bootstrapAppStore = useBootstrapAppStore()

  const { fetch } = bootstrapAppStore

  return {
    fetch,
  }
}
