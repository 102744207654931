<template>
  <div v-if="layout === 'account'">
    <v-navigation-drawer @update:model-value="leftDrawerStateChange" :model-value="showLeftDrawer">
      <AccountPicker />
      <v-divider></v-divider>

      <v-list density="compact">
        <v-list-item v-if="permitBilling" :to="{ name: 'billing' }" exact>
          <v-list-item-title>Billing details</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="permitBilling" :to="{ name: 'invoices' }" exact>
          <v-list-item-title>Past invoices</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="isActive" :to="{ name: 'people' }" exact>
          <v-list-item-title>People</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="isActive" :to="{ name: 'invites' }" exact>
          <v-list-item-title>Past invites</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="isActive && (permitBilling || permitAdmin)" :to="{ name: 'settings' }" exact data-label="settings nav">
          <v-list-item-title>Settings</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="isActive && permitAdmin" :to="{ name: 'api-keys' }" exact>
          <v-list-item-title>API keys</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="permitBilling || permitAdmin" :to="{ name: 'account-cancel' }" exact>
          <v-list-item-title class="text-error">Cancel account &hellip;</v-list-item-title>
        </v-list-item>
      </v-list>

      <template v-slot:prepend>
        <div class="pa-3">
          <a href="/"><LogoFull class="ml-3" /></a>
        </div>
      </template>
    </v-navigation-drawer>

    <v-app-bar flat>
      <template v-slot:prepend>
        <v-app-bar-nav-icon @click.stop="setShowLeftDrawer">
          <v-icon color="primary">{{ showLeftDrawer ? mdiBackburger : mdiMenu }}</v-icon>
        </v-app-bar-nav-icon>
      </template>

      <v-app-bar-title>
        <a v-show="!showLeftDrawer" href="/"><LogoBlinkplan class="mt-3" /></a>
      </v-app-bar-title>

      <TheProgressBar />

      <template v-slot:append>
        <v-app-bar-nav-icon>
          <TheUserNav />
        </v-app-bar-nav-icon>
      </template>

      <template v-slot:extension v-if="toolbar !== null">
        <BillingToolBar v-if="toolbar === 'billing'" />
        <PersonToolBar v-if="toolbar === 'person'" />
        <PeopleToolBar v-if="toolbar === 'people'" />
        <InvitesToolBar v-if="toolbar === 'invites'" />
        <InviteToolBar v-if="toolbar === 'invite'" />
        <ApiKeysToolBar v-if="toolbar === 'apiKeys'" />
        <ApiKeyToolBar v-if="toolbar === 'apiKey'" />
      </template>
    </v-app-bar>

    <v-main>
      <slot></slot>
      <router-view name="dialog" />
    </v-main>
  </div>
</template>

<script>
import { computed } from "vue"
import { useRouter } from "vue-router"
import { mdiMenu, mdiBackburger } from "@mdi/js"

import TheUserNav from "@/components/TheUserNav.vue"
import TheProgressBar from "@/components/TheProgressBar.vue"
import LogoFull from "@/components/Logos/LogoFull.vue"
import LogoBlinkplan from "@/components/Logos/LogoBlinkplan.vue"
import AccountPicker from "@/modules/account/components/AccountPicker.vue"
import BillingToolBar from "@/modules/billing/components/BillingToolBar"
import PersonToolBar from "@/modules/person/components/PersonToolBar"
import PeopleToolBar from "@/modules/person/components/PeopleToolBar"
import InvitesToolBar from "@/modules/invite/components/InvitesToolBar"
import InviteToolBar from "@/modules/invite/components/InviteToolBar"
import ApiKeysToolBar from "@/modules/apiKey/components/ApiKeysToolBar"
import ApiKeyToolBar from "@/modules/apiKey/components/ApiKeyToolBar"

import { useAccount } from "@/modules/account/use"
import { useViewSetting } from "@/modules/viewSetting/use"
import { useToolbar } from "@/use/toolbar"
import { useContext } from "@/use/context"

export default {
  name: "AccountLayout",
  components: {
    AccountPicker,
    TheUserNav,
    TheProgressBar,
    LogoFull,
    LogoBlinkplan,
    BillingToolBar,
    PersonToolBar,
    PeopleToolBar,
    InviteToolBar,
    InvitesToolBar,
    ApiKeysToolBar,
    ApiKeyToolBar
  },
  setup() {
    let router = useRouter()
    let currentRoute = router.currentRoute

    let bp_account = useAccount()

    let bp_toolbar = useToolbar()
    let bp_viewSetting = useViewSetting()
    let bp_context = useContext()

    let showLeftDrawer = bp_viewSetting.showLeftDrawer

    let setShowLeftDrawer = () => {
      bp_viewSetting.setShowLeftDrawer(!bp_viewSetting.showLeftDrawer.value)
    }

    // we need this in case the drawer becomes a temporary drawer, and we click on the overlay to close.
    // in that case we don't get that click event, but we can listen for a state change on the drawer,
    // and can set the setting here too.
    let leftDrawerStateChange = (state) => {
      bp_viewSetting.setShowLeftDrawer(state)
    }

    return {
      toolbar: bp_toolbar.toolbar,
      showLeftDrawer,
      setShowLeftDrawer,
      leftDrawerStateChange,
      layout: computed(() => currentRoute.value.meta.layout),
      isActive: bp_account.isActive,
      permitBilling: bp_context.permitBilling,
      permitAdmin: bp_context.permitAdmin,
      mdiMenu,
      mdiBackburger,
    }
  },
}
</script>
