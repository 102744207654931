<template>
  <div class="d-flex flex-column justify-center align-center" style="min-height: 100vh">
    <a href="/"><LogoOnly :height="100" /></a>
    <div class="text-h3 text-center mt-8">Oops</div>
    <div class="text-h5 mt-8">The {{ resource }} you are looking for is not here.</div>
  </div>
</template>

<script>
import LogoOnly from "@/components/Logos/LogoOnly.vue"

export default {
  name: "NotFoundView",
  props: {
    resource: {
      type: String,
      default: "page",
    },
  },
  components: {
    LogoOnly,
  },
}
</script>

<style lang="scss" scoped></style>
