// export these functions for testing

export const compareMagnitude = (a, b) => {
  return calcMagnitude(b) - calcMagnitude(a)
}

export const compareNumbers = (a, b) => {
  return a - b
}

export const isHorizontal = (path) => {
  const horizontalAngles = new Set([0, 180])
  return horizontalAngles.has(calcAngle(path))
}

export const calcAngle = (path) => {
  let angle = Math.atan2(path[3] - path[1], path[2] - [path[0]])

  while (angle < 0) {
    angle += 2 * Math.PI
  }

  return (angle * 180) / Math.PI // return degrees
}

export const calcMagnitude = (path) => {
  return Math.sqrt(Math.pow(Math.abs(path[0] - path[2]), 2) + Math.pow(Math.abs(path[1] - path[3]), 2))
}

export const orderPathsByMagnitude = (paths) => {
  return paths.sort(compareMagnitude)
}

export const pathsOverlap = (pathA, pathB) => {
  const isHorizontalA = isHorizontal(pathA)
  const isHorizontalB = isHorizontal(pathB)

  if (isHorizontalA === isHorizontalB) {
    let orderedPaths = orderPathsByMagnitude([pathA, pathB])

    if (isHorizontalA) {
      if (pathA[1] === pathB[1] && pathA[3] === pathB[3]) {
        // on same horizontal line

        // get ordered x's for both the longer and shorter path
        let xLonger = [orderedPaths[0][0], orderedPaths[0][2]].sort(compareNumbers)
        let xShorter = [orderedPaths[1][0], orderedPaths[1][2]].sort(compareNumbers)
        return xShorter[0] >= xLonger[0] && xShorter[1] <= xLonger[1]
      }
    } else {
      if (pathA[0] === pathB[0] && pathA[2] === pathB[2]) {
        // on same vertical line

        // get ordered y's for both the longer and shorter path
        let yLonger = [orderedPaths[0][1], orderedPaths[0][3]].sort(compareNumbers)
        let yShorter = [orderedPaths[1][1], orderedPaths[1][3]].sort(compareNumbers)
        return yShorter[0] >= yLonger[0] && yShorter[1] <= yLonger[1]
      }
    }
  }

  return false
}

export const getNonOverlappingPaths = (paths) => {
  let nonOverlapping = []
  orderPathsByMagnitude(paths).forEach(function (p) {
    if (nonOverlapping.length === 0) {
      nonOverlapping.push(p)
    } else {
      if (!nonOverlapping.some((a) => pathsOverlap(a, p))) {
        nonOverlapping.push(p)
      }
    }
  })
  return nonOverlapping
}

export const textWrap = (fullPhrase, width) => {
  const letterWidth = 7 // just a thumbsuck

  fullPhrase = unescapeText(fullPhrase || "")

  let words = fullPhrase.split(" ")
  let x = 0
  let s = []
  for (let i = 0; i < words.length; i++) {
    let l = words[i].length
    if (x + l * letterWidth > width) {
      if (s.length > 0) {
        // can't start off with a newline
        s.push("\n")
      }
      x = 0
    }
    x += l * letterWidth
    s.push(words[i] + " ")
  }
  return s.join("")
}

export const scaleFontIfNeeded = (text, width, height) => {
  let fontSize = 0.75 // 0.75 is caption size
  text.font("font-size", `${fontSize}rem`)
  while (fontSize >= 0.42) {
    if (text.bbox().width > width || text.bbox().height > height) {
      text.font("font-size", `${fontSize}rem`)
      fontSize -= 0.02
    } else {
      break
    }
  }
  return text
}

export const unescapeText = (string) => {
  const htmlUnescapes = {
    "&amp;": "&",
    "&lt;": "<",
    "&gt;": ">",
    "&quot;": '"',
    "&#39;": "'",
  }

  const htmlEscapes = {
    "&": "&amp;",
    "<": "&lt;",
    ">": "&gt;",
    '"': "&quot;",
    "'": "&#39;",
  }

  const reEscapedHtml = /&(?:amp|lt|gt|quot|#39);/g
  const reHasEscapedHtml = RegExp(reEscapedHtml.source)

  const reUnescapedHtml = /[&<>"']/g

  const basePropertyOf = (object) => {
    return function (key) {
      return object == null ? undefined : object[key]
    }
  }

  const unescapeHtmlChar = basePropertyOf(htmlUnescapes)

  return string && reHasEscapedHtml.test(string) ? string.replace(reEscapedHtml, unescapeHtmlChar) : string
}
