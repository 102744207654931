<template>
  <v-sheet class="thumbnail" :width="width" :height="height" :style="{ cursor: clickable ? 'pointer' : 'default' }">
    <v-img v-if="!!url" contain position="top center" :width="width" :height="height" :min-width="width" :min-height="height" :src="url" data-test-id="img">
      <template v-slot:placeholder>
        <v-row class="fill-height ma-0" align="center" justify="center">
          <v-progress-circular indeterminate bg-color="transparent" color="muted"></v-progress-circular>
        </v-row>
      </template>
      <div v-if="permitSlideShow" @click.stop="slideShow">
        <div class="slide-show-background"></div>
        <v-icon size="x-small" class="slide-show-icon" data-test-id="slideShow">{{ mdiEye }}</v-icon>
      </div>
      <div v-if="permitQuickLook" @click.stop="quickLook">
        <div class="slide-show-background"></div>
        <v-icon size="x-small" class="slide-show-icon" data-test-id="slideShow">{{ mdiEye }}</v-icon>
      </div>
    </v-img>
  </v-sheet>
</template>

<script>
import { computed } from "vue"
import { mdiEye } from "@mdi/js"

// we need the 88, 114 to give it a min height so that we can see the loader
import { Bus } from "@/Bus"

import { usePageSetting } from "@/modules/pageSetting/use"
import { cdn } from "@/lib/cdn"

export default {
  name: "PageThumbnail",
  props: {
    pageId: {
      type: String,
      required: false,
    },
    thumbnail: {
      type: Object,
    },
    permitSlideShow: {
      type: Boolean,
      required: false,
      default: false,
    },
    permitQuickLook: {
      type: Boolean,
      required: false,
      default: false,
    },
    clickable: {
      type: Boolean,
      required: false,
      default: true,
    },
    size: {
      type: String,
      required: false,
    },
  },
  data: () => ({
    mdiEye,
  }),
  setup(props) {
    let bp_pageSetting = usePageSetting()

    let slideShow = () => {
      Bus.$emit("slide-show", { pageId: props.pageId })
    }

    let quickLook = () => {
      Bus.$emit("quick-look", { thumbnail: props.thumbnail })
    }

    let width = props.size
      ? computed(() => {
          return bp_pageSetting.pageWidthForSize(props.size)
        })
      : bp_pageSetting.pageWidth

    let height = props.size
      ? computed(() => {
          return bp_pageSetting.pageHeightForSize(props.size)
        })
      : bp_pageSetting.pageHeight

    let url = computed(() => {
      return cdn.url(props.thumbnail, { width: width.value, height: height.value })
    })

    // Preload image. This might improve load times for browserless and prevent a Heroku H13 error?
    new Image().src = url.value


    return {
      slideShow,
      quickLook,
      url,
      width,
      height,
    }
  },
}
</script>

<style lang="scss" scoped>
.thumbnail {
  box-shadow: -0.5px -0.5px 0 #444444, 0.5px 0.5px 0 #444444;
}

.slide-show-background {
  position: absolute;
  height: 20px;
  width: 20px;
  bottom: 0;
  right: 0;
  background-color: $bp-img-overlay-color;
  opacity: 0.46;
}

.slide-show-icon {
  position: absolute;
  bottom: 4px;
  right: 4px;
  opacity: 0.67;
  font-size: 0.75rem;
  cursor: pointer;
}
</style>
