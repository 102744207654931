<template>
  <v-container fluid>
    <ContentsList />
    <router-view name="dialog" />
  </v-container>
</template>

<script>
import ContentsList from "./ContentsList"

export default {
  name: "ContentsShowView",
  components: {
    ContentsList,
  },
}
</script>
