<template>
  <v-container fluid>
    <h4 class="text-h4 mb-4">Invoice</h4>
    <div v-html="invoiceHtml"></div>
  </v-container>
</template>

<script>
import { DateTime } from "luxon"

import { useBilling } from "../use"

import { ref, onMounted } from "vue"

export default {
  name: "InvoiceView",
  props: {
    code: {
      type: [String, Number],
      required: true,
    },
  },
  setup(props) {
    let bp_billing = useBilling()
    let invoiceHtml = ref(null)

    let fmtDate = (date) => {
      return DateTime.fromISO(date).toFormat("DD")
    }

    onMounted(() => {
      bp_billing.fetchInvoice({ code: props.code }).then(({ data }) => {
        invoiceHtml.value = data.invoice_html
      })
    })

    return {
      fmtDate,
      invoiceHtml,
    }
  },
}
</script>

<style scoped>
.invoice {
  cursor: pointer;
}
</style>
