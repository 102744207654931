import axios from 'axios'

const baseURL = "//" + import.meta.env.VITE_ACCOUNT_ENDPOINT

export default {
  bootstrap() {
    return axios.get(`${baseURL}/bootstrap`)
  },
  // used mainly for billing
  bootstrapAccount(accountId) {
    return axios.get(`${baseURL}/${accountId}/bootstrap`)
  },
  bootstrapShare(accountId) {
    return axios.get(`${baseURL}/${accountId}/bootstrap/share`)
  },
  fetchPeople({ accountId }) {
    return axios.get(`${baseURL}/${accountId}/people/`)
  },
  createDemoUser() {
    return axios.post(`${baseURL}/demo/create_user`)
  },
  setupDemo() {
    return axios.post(`${baseURL}/demo/setup`)
  },
  sendShareEmail({ accountId, payload }) {
    return axios.post(`${baseURL}/${accountId}/email`, payload)
  },
  billingSetupStripeSession(accountId) {
    return axios.get(`${baseURL}/${accountId}/billing/stripe_setup_session`)
  },
  billingUpgrade(accountId, payload) {
    return axios.post(`${baseURL}/${accountId}/billing/upgrade`, payload)
  },
  billingUpdate(accountId, payload) {
    return axios.post(`${baseURL}/${accountId}/billing`, { account: payload })
  },
  invoice(accountId, code) {
    return axios.get(`${baseURL}/${accountId}/invoices/${code}`)
  },
  deletePerson({ accountId, niceId }) {
    return axios.delete(`${baseURL}/${accountId}/people/${niceId}`)
  },
  updatePermission({ accountId, niceId, payload }) {
    return axios.post(`${baseURL}/${accountId}/people/${niceId}/update_permission`, payload)
  },
  deleteInvite({ accountId, token }) {
    return axios.delete(`${baseURL}/${accountId}/invites/${token}`)
  },
  addInvite({ accountId, payload }) {
    return axios.post(`${baseURL}/${accountId}/invites`, payload)
  },
  fetchInvite({ accountId, token }) {
    return axios.get(`${baseURL}/${accountId}/invites/${token}`)
  },
  acceptInvite({ token, payload }) {
    return axios.post(`${baseURL}/invites/${token}`, payload)
  },
  updateAccount({ accountId, payload }) {
    return axios.put(`${baseURL}/${accountId}`, { account: payload })
  },
  deleteAccount({ accountId }) {
    return axios.delete(`${baseURL}/${accountId}`)
  },
  addApiKey({ accountId, payload }) {
    return axios.post(`${baseURL}/${accountId}/api_keys`, payload)
  },
  deleteApiKey({ accountId, key_hash }) {
    return axios.delete(`${baseURL}/${accountId}/api_keys/${key_hash}`)
  },
}
