<template>
  <v-card color="background" width="205">
    <v-form @submit.prevent="addPages">
      <v-card-text>
        <v-text-field ref="noOfPagesCtrl" label="How many pages?" type="number" v-model="noOfPages" data-test-id="noOfPages" autofocus required @focus="$event.target.select()" />
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <cancel-btn :disabled="loading" @click="closeDialog"> Cancel </cancel-btn>
        <submit-btn type="submit" :loading="loading" :disabled="loading"> Add </submit-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import { ref, onMounted } from "vue"
import { usePage } from "../use"

export default {
  name: "AddPages",
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    insertAtIndex: {
      type: Number,
      required: false,
    },
  },
  emits: ["update:open"],
  setup(props, context) {
    let noOfPagesCtrl = ref(null)
    let noOfPages = ref(1)
    let loading = ref(false)

    let bp_page = usePage()

    let addPages = () => {
      loading.value = true
      bp_page
        .addNoOfPages({ noOfPages: noOfPages.value, insertAtIndex: props.insertAtIndex })
        .then(() => {
          noOfPages.value = ""
          closeDialog()
        })
        .finally(() => {
          loading.value = false
        })
    }

    let closeDialog = () => {
      context.emit("update:open", false)
    }

    let focusNoOfPagesText = () => {
      noOfPagesCtrl.value.$el.getElementsByTagName("input")[0].focus()
    }

    onMounted(() => {
      setTimeout(() => {
        focusNoOfPagesText()
      }, 50)
    })

    return {
      loading,
      addPages,
      noOfPages,
      closeDialog,
      noOfPagesCtrl
    }
  },
}
</script>

<style lang="scss" scoped></style>
