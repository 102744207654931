<template>
  <v-container fluid>
    <h4 class="text-h4">Section Calculator</h4>
    <br />

    <v-row>
      <v-col cols="12" sm="6">
        <v-alert type="info"> Set the different paper sections your magazine will be printed on. </v-alert>
        <v-container data-test-id="covers">
          <span class="text-h6">Cover sections</span>
          <v-btn variant="text" color="primary" @click="add('Cover')"
            ><v-icon>{{ mdiPlus }}</v-icon
            >&nbsp;Add</v-btn
          >
          <SectionItem v-for="section in coverSections" :key="section._id" type="Cover" :sectionId="section._id" />
          <v-row dense>
            <v-col cols="2" offset="6">
              <div class="text-body-1" data-test-id="calc">= {{ totalCoverSections }} <small class="text-caption">pages</small></div>
            </v-col>
          </v-row>
        </v-container>
        <v-container data-test-id="contents">
          <span class="text-h6">Content sections</span>
          <v-btn variant="text" color="primary" @click="add('Content')"
            ><v-icon>{{ mdiPlus }}</v-icon
            >&nbsp;Add</v-btn
          >
          <SectionItem v-for="section in contentSections" :key="section._id" type="Content" :sectionId="section._id" />
          <v-row dense>
            <v-col cols="2" offset="6">
              <div class="text-body-1" data-test-id="calc">= {{ totalContentSections }} <small class="text-caption">pages</small></div>
            </v-col>
          </v-row>
        </v-container>
        <hr />
        <v-container data-test-id="calcs">
          <v-row dense>
            <v-col cols="6"> <span class="text-subtitle-1">Total pages</span> <span class="text-caption">(via sections)</span> </v-col>
            <v-col cols="2">{{ totalViaSections }}</v-col>
          </v-row>
          <v-row dense>
            <v-col cols="6"> <span class="text-subtitle-1">Current pages</span> <span class="text-caption">(on flatplan)</span> </v-col>
            <v-col cols="2">{{ totalViaFlatplan }}</v-col>
          </v-row>
          <v-row dense>
            <v-col cols="6"> <span class="text-subtitle-1" :class="{ 'text-error': difference !== 0, 'text-secondary': difference === 0 }">Difference</span></v-col>
            <v-col cols="2" :class="{ 'text-error': difference !== 0, 'text-secondary': difference === 0 }"
              >{{ difference }} <v-icon v-if="difference > 0">{{ mdiArrowDown }}</v-icon
              ><v-icon v-if="difference < 0">{{ mdiArrowUp }}</v-icon></v-col
            >
          </v-row>
          <p class="text-caption mt-8" v-if="difference > 0">
            <v-icon>{{ mdiInformationOutline }}</v-icon
            >You will need to <strong class="text-error">remove {{ difference }} pages</strong> from the flatplan, or add more sections so that your current pages match your sections.
          </p>
          <p class="text-caption mt-8" v-if="difference < 0">
            <v-icon>{{ mdiInformationOutline }}</v-icon
            >You will need to <strong class="text-error">add {{ Math.abs(difference) }} pages</strong> to the flatplan, or remove some sections so that your current pages match your sections.
          </p>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SectionItem from "./SectionItem"

import { useSection } from "../use"
import { usePage } from "@/modules/page/use"

import { mdiArrowDown, mdiArrowUp, mdiInformationOutline, mdiPlus } from "@mdi/js"

import { computed } from "vue"

export default {
  name: "SectionsShowView",
  components: {
    SectionItem,
  },
  data: () => ({
    mdiPlus,
    mdiArrowUp,
    mdiArrowDown,
    mdiInformationOutline,
  }),
  setup() {
    let bp_section = useSection()
    let bp_page = usePage()

    let add = (type) => {
      bp_section.add({ type, count: 0, size: 0 })
    }

    let totalCoverSections = computed(() => {
      return bp_section.coverSections.value.reduce((arr, section) => arr + section.count * section.size, 0)
    })

    let totalContentSections = computed(() => {
      return bp_section.contentSections.value.reduce((arr, section) => arr + section.count * section.size, 0)
    })

    let totalViaSections = computed(() => {
      return bp_section.sections.value.reduce((arr, section) => arr + section.count * section.size, 0)
    })

    let totalViaFlatplan = computed(() => {
      return bp_page.pages.value.filter((p) => !p.isSpace).length
    })

    let difference = computed(() => {
      return totalViaFlatplan.value - totalViaSections.value
    })

    return {
      coverSections: bp_section.coverSections,
      contentSections: bp_section.contentSections,
      add,
      totalViaSections,
      totalViaFlatplan,
      totalCoverSections,
      totalContentSections,
      difference,
    }
  },
}
</script>

<style lang="scss" scoped></style>
