import { ref } from "vue"
import { defineStore } from "pinia"

import AccountService from "@/services/AccountService.js"

import { useBillingStore } from "@/modules/billing/store"
import { usePersonStore } from "@/modules/person/store"
import { useInviteStore } from "@/modules/invite/store"
import { useApiKeyStore } from "@/modules/apiKey/store"

export const useBootstrapAccountStore = defineStore("bootstrapAccount", () => {
  const state = ref("ready")

  function fetch(accountId) {
    if (state.value === "done") {
      return Promise.resolve()
    } else {
      state.value = "fetching"
      return AccountService.bootstrapAccount(accountId)
        .then((response) => {
          state.value = "done"

          const billingStore = useBillingStore()
          const personStore = usePersonStore()
          const inviteStore = useInviteStore()
          const apiKeyStore = useApiKeyStore()

          billingStore.bootstrap(response.data)
          personStore.bootstrap(response.data.account.users)
          inviteStore.bootstrap(response.data.invites)
          apiKeyStore.bootstrap(response.data.apiKeys)

          return response
        })
        .catch((err) => {
          console.error(err)
          state.value = "ready"
          throw(err)
        })
    }
  }

  return {
    state,
    fetch,
  }
})
