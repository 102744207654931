<template>
  <v-row justify="center">
    <v-col cols="12" sm="6" lg="3">
      <v-alert v-if="loggedOutReason.length > 0" type="warning" class="mb-4">
        {{ loggedOutReason }}
      </v-alert>

      <h4 class="text-h4">Sign in to your account</h4>
      
      <v-form v-model="formValid" @submit.prevent="login" class="mt-6" ref="form" >
        <v-text-field label="Email" type="email" v-model="email" data-test-id="email" :rules="emailRules" required />
        <v-text-field
          label="Password"
          :type="showPassword ? 'text' : 'password'"
          :append-inner-icon="showPassword ? mdiEye : mdiEyeOff"
          @click:append-inner="showPassword = !showPassword"
          data-test-id="password"
          v-model="password"
          :rules="passwordRules"
          required
        />
        <router-link :to="{ name: 'forgot-password', query: { useEmail: email } }" class="float-right mb-4 pt-n4 text-primary text-decoration-none"> Forgot your password? </router-link>
        <br />
        

        <submit-btn type="submit" class="mr-4" data-test-id="submit" :loading="bp_auth.loading.value" block> Continue </submit-btn>
      </v-form>
      <v-alert :model-value="bp_auth.httpStatus.value === 401" type="error" class="mt-4"> Incorrect email or password. :( </v-alert>
      <div class="mt-12">
        Don't have an account?
        <router-link :to="{ name: 'signup' }" class="text-primary text-decoration-none"> Create one. </router-link>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { reactive, toRefs, ref } from "vue"
import { mdiEye, mdiEyeOff } from "@mdi/js"

import { useAuth } from "@/modules/auth/use"

export default {
  name: "LoginView",
  props: {
    useEmail: {
      type: String,
      required: false,
      default: "",
    },
    loggedOutReason: {
      type: String,
      default: "",
    },
    redirect: {
      type: String,
    },
  },
  setup(props) {
    const bp_auth = useAuth()

    const form = ref(null)

    const state = reactive({
      email: props.useEmail,
      emailRules: [
        (v) => !!v || "Email is required.",
        (v) => v.indexOf("@") !== 0 || "Email should have a username.",
        (v) => v.includes("@") || "Email should include an @ symbol.",
        (v) => v.includes(".") || "Email should include a period symbol.",
        (v) => v.indexOf(".") <= v.length - 3 || "Email should contain a valid domain extension.",
      ],
      password: "",
      passwordRules: [(v) => !!v || "Password is required.", (v) => v.length >= 6 || "Password should be at least six long."],
      formValid: false,
      showPassword: false,
    })

    const login = () => {
      form.value.validate()
      
      if (state.formValid || state.formValid === null) { // JD: HACK: formValid shouldn't actually ever be null
        bp_auth.login({ email: state.email.trim(), password: state.password, redirect: props.redirect })
        .catch(() => {
          // noop
        })
      } else {
        return false
      }
    }

    return {
      ...toRefs(state),
      login,
      form,
      bp_auth,
      mdiEye,
      mdiEyeOff,
    }
  },
}
</script>
