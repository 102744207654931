<template>
  <v-container fluid>
    <v-table density="compact">
      <thead>
        <tr>
          <th class="text-left">
            <v-checkbox-btn @click="toggleAllSelect($event)" :model-value="true" density="compact"></v-checkbox-btn>
          </th>
          <th v-for="column in columns" class="text-left" :key="column.identifier">
            {{ column.text }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in processedRows" :key="index" :class="item.isSelectable ? '' : 'text-grey'">
          <td>
            <v-checkbox-btn :model-value="item.selected" @click="(item.selected.value = !item.selected)" :readonly="!item.isSelectable" density="compact"></v-checkbox-btn>
          </td>
          <td v-for="column in columns" class="text-left" :key="'' + index + column.identifier">
            <span v-if="Array.isArray(item[column.value])">{{ item[column.value].join() }}</span>
            <span v-else>{{ item[column.value] }}</span>
          </td>
        </tr>
      </tbody>
    </v-table>

    <div v-if="messages" class="mt-2">
      <v-icon start>{{ mdiAlertCircleOutline }}</v-icon>
      {{ messages }}
    </div>

    <div class="mt-4">
      <cancel-btn @click="cancel">Cancel</cancel-btn>
      <submit-btn type="submit" @click="importContents" :disabled="selectedCount === 0" class="ml-2">Import {{ selectedCount }} rows</submit-btn>
    </div>
  </v-container>
</template>

<script>
import { ref, computed } from "vue"
import { useRouter } from "vue-router"
import { mdiAlertCircleOutline } from "@mdi/js"

import { useContent } from "../use"
import { useNotification } from "@/modules/notification/use"

export default {
  name: "ContentUploadConfirmResults",
  props: {
    rows: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    let router = useRouter()
    let bp_content = useContent()
    let bp_notification = useNotification()

    let columns = [
      {
        text: "Identifier",
        align: "start",
        value: "displayedIdentifier",
      },
      { text: "Text", value: "text" },
      { text: "Page nos", value: "pageNos" },
      { text: "Size", value: "size" },
      { text: "Shape", value: "shape" }
    ]

    const IDENTIFIER_COL = 0
    const TEXT_COL = 1
    const PAGENOS_COL = 2
    const SIZEDESCRIPTION_COL = 3
    const SHAPEDESCRIPTION_COL = 4

    const { cleanIdentifier, cleanText, cleanPageNos, cleanSize, cleanShape, isEqualText, isEqualShape, isEqualSize, isDuplicateExistingItem } = bp_content

    // detects duplicates in the upload file only (not in our existing data)
    // a duplicate identifier in our existing data is fine - it means
    // that this upload is updating that content.
    const isDuplicateIdentifier = ({ identifier, identifierIdx }) => {
      if (identifier) {
        const firstOccurrenceIdx = props.rows.findIndex((row) => cleanIdentifier(row[IDENTIFIER_COL]) === identifier)
        return firstOccurrenceIdx > -1 && firstOccurrenceIdx !== identifierIdx
      }
    }

    // detects duplicates in the upload file only (not in our existing data)
    const isDuplicateItem = ({ text, size, shape, identifierIdx }) => {
      const firstOccurrenceIdx = props.rows.findIndex((row) => isEqualText(row[TEXT_COL], text) && isEqualShape(row[SHAPEDESCRIPTION_COL], shape) && isEqualSize(row[SIZEDESCRIPTION_COL], size))
      return firstOccurrenceIdx > -1 && firstOccurrenceIdx !== identifierIdx
    }

    // detects duplicates in existing data that have a different identifier
    // text needs to be unique
    const isDuplicateData = ({ identifier, text, size, shape, identifierIdx }) => {
      return isDuplicateIdentifier({ identifier, identifierIdx }) || isDuplicateItem({ text, size, shape, identifierIdx }) || isDuplicateExistingItem({ identifier, text, size, shape })
    }

    const processedRows = computed(() => {
      const rows = []

      props.rows.forEach((row, idx) => {
        const identifier = cleanIdentifier(row[IDENTIFIER_COL])
        const text = cleanText(row[TEXT_COL])
        const pageNos = cleanPageNos(row[PAGENOS_COL])
        const size = cleanSize(row[SIZEDESCRIPTION_COL])
        const shape = cleanShape(row[SHAPEDESCRIPTION_COL])
        const isDuplicate = isDuplicateData({ identifier, text, size, shape, identifierIdx: idx })
        const isSelectable = !isDuplicate
        const ownIdentifier = !!identifier
        const displayedIdentifier = ownIdentifier ? identifier : ""

        const item = {
          id: idx,
          identifier,
          ownIdentifier,
          displayedIdentifier,
          text,
          pageNos,
          size,
          shape,
          isDuplicate,
          isSelectable,
          selected: ref(isSelectable)
        }
        rows.push(item)
      })
      return rows
    })

    const messages = computed(() => {
      if (processedRows.value.some((row) => row.isDuplicate)) {
        return "Some rows are greyed out as they have either a duplicate identifier or duplicate text, or you already have content with that text. Identifiers need to be unique for each row, so that subsequent imports can update your existing content."
      }
    })

    const cancel = () => {
      router.push({ name: "contents-show" })
    }

    const backToContents = () => {
      router.push({ name: "contents-show" })
    }

    const toggleAllSelect = (evt) => {
      processedRows.value.filter((row) => row.isSelectable).forEach((row) => {
        if (evt.target.checked) {
          row.selected.value = true
        } else {
          row.selected.value = false
        }
      })
    }

    const selectedCount = computed(() => {
      return processedRows.value.filter((row) => row.selected.value).length
    })

    const importContents = () => {
      let contents = []
      processedRows.value.filter((row) => row.selected.value).forEach((s) => {
        contents.push({
          identifier: s.identifier,
          ownIdentifier: s.ownIdentifier,
          text: s.text,
          pageNos: s.pageNos,
          size: s.size,
          shape: s.shape,
          imported: true,
        })
      })
      bp_content
        .batchAdd({ contents })
        .then(() => {
          backToContents()
          bp_notification.show({
            type: "success",
            message: "Content imported successfully :)",
          })
        })
        .catch((err) => {
          // noop
          throw err
        })
    }

    return {
      selectedCount,
      toggleAllSelect,
      columns,
      processedRows,
      messages,
      importContents,
      cancel,
      mdiAlertCircleOutline,
    }
  },
}
</script>

<style lang="scss" scoped></style>
