<template>
  <div class="text-center">
    <v-snackbar :model-value="true" :timeout="-1" :color="type" location="top" data-test-id="snackbar">
      {{ message }}
      <template v-slot:actions>
        <v-btn variant="text" @click="hide"> Close </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { onMounted, onBeforeUnmount } from "vue"
import { useNotification } from "@/modules/notification/use"

export default {
  name: "NotificationBar",
  props: {
    type: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
  },
  setup() {
    const bp_notification = useNotification()

    let timeout = null

    onMounted(() => {
      timeout = setTimeout(() => bp_notification.hide(), 4000)
    })

    onBeforeUnmount(() => {
      clearTimeout(timeout)
    })

    return {
      hide: bp_notification.hide,
    }
  },
}
</script>
