<template>
  <v-row>
    <v-col cols="1">
      <span v-if="content.ownIdentifier" class="text-caption text-grey" data-test-id="identifier">{{ content.identifier }}</span>
    </v-col>
    <v-col cols="4" class="content" @click="openEditDialog">
      <span data-test-id="text">{{ content.text }}</span>
    </v-col>
    <v-col cols="4" data-test-id="pagenos">
      <v-chip
        v-for="(pageNo, idx) in content.pageNos"
        :key="'pageNo' + pageNo + content._id"
        size="x-small"
        variant="outlined"
        :to="pageByPageNo(pageNo) ? { name: 'page-edit-content', params: { pageId: pageByPageNo(pageNo)._id }, query: { openPickContent: true, returnTo: 'contents-show' } } : null"
        :data-test-id="'pageNo-' + idx"
        :color="pageByPageNo(pageNo) ? '' : 'disabled'"
      >
        {{ pageNo }}
      </v-chip>
      <span data-test-id="size" class="ml-1 text-caption">{{ fragmentFullDescription(content.size, content.shape) }}</span>
    </v-col>
    <v-col cols="2" data-test-id="placed">
      <v-chip
        v-for="(pageData, idx) in placed"
        :key="idx + pageData.pageId"
        :data-test-id="'placed-' + idx"
        size="x-small"
        :to="{ name: 'page-edit-content', params: { pageId: pageData.pageId }, query: { highlightFragmentIndex: pageData.fragmentIndex, returnTo: 'contents-show' } }"
        :color="color(pageData.pageNo, pageData.fragmentName)"
        variant="flat"
      >
        {{ pageData.pageNo }}
      </v-chip>
      <v-chip v-if="placed.length === 0" size="x-small" variant="outlined" disabled>&nbsp;&nbsp;</v-chip>
    </v-col>
    <v-col cols="1">
      <v-btn :icon="mdiDeleteOutline" @click.stop="del" size="small" variant="text"></v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { computed } from "vue"
import { useRouter } from "vue-router"
import { mdiDeleteOutline } from "@mdi/js"

import { useContent } from "../use"
import { usePageNumbers } from "@/modules/flatplan/usePageNumbers"
import { fragmentFullDescription } from "@/modules/page/render/fragmentUtils"

export default {
  name: "ContentItem",
  props: {
    contentId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    let router = useRouter()
    let bp_content = useContent()
    let bp_pageNumbers = usePageNumbers()

    let content = computed(() => bp_content.getById(props.contentId)) // the content might change via import+liveupdate - without computed we wouldn't pick it up

    let del = () => {
      bp_content.batchDel({ contentIds: [props.contentId] })
    }

    const openEditDialog = () => {
      router.push({ name: "content-edit", params: { contentId: props.contentId } })
    }

    const placed = computed(() => bp_content.placed(props.contentId))

    let color = (pageNo, fragmentName) => {
      let fit = bp_content.contentFit({ contentId: props.contentId, pageNo, fragmentName })      
      return bp_content.fitColor(fit)
    }

    return {
      content,
      del,
      openEditDialog,
      placed,
      pageByPageNo: bp_pageNumbers.pageByPageNo,
      color,
      fragmentFullDescription,
      mdiDeleteOutline
    }
  },
}
</script>

<style lang="scss" scoped>
.content {
  cursor: pointer;
}
</style>
