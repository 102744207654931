<template>
  <div v-if="mobile">
    <v-btn v-if="!reload" variant="flat" :icon="mdiArrowLeft" size="small" color="secondary" :loading="flatplanLoading" @click="back" data-label="back to flatplan"></v-btn>
    <v-btn v-else variant="flat" :icon="mdiArrowLeft" size="small" color="secondary" href="/" :disabled="!isAccountActive" data-label="back to editor"></v-btn>
  </div>
  <div v-else>
    <v-btn v-if="!reload" variant="flat" :prepend-icon="mdiArrowLeft" size="small" color="secondary" :loading="flatplanLoading" @click="back" data-label="back to flatplan">
      Back to flatplan
    </v-btn>
    <v-btn v-else variant="flat" :prepend-icon="mdiArrowLeft" size="small" color="secondary" href="/" :disabled="!isAccountActive" data-label="back to editor">
      Back to editor
    </v-btn>
  </div>
</template>

<script>
import { ref, inject } from "vue"
import { useRouter } from "vue-router"
import { mdiArrowLeft } from "@mdi/js"

import { useAccount } from "@/modules/account/use"

export default {
  name: "BackToFlatplan",
  props: {
    reload: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    let router = useRouter()
    
    let bp_account = useAccount()
    const mobile = inject("mobile")

    let flatplanLoading = ref(false)

    // there can be a bit of lag between pressing the button and rendering - esp for large flatplans
    // so let's indicate it is busy
    let back = () => {
      flatplanLoading.value = true
      setTimeout(() => {
        router.push({ name: "flatplan-show" })
      }, 350)
    }

    return {
      flatplanLoading,
      back,
      mobile,
      isAccountActive: bp_account.isActive,
      mdiArrowLeft
    }
  },
}
</script>

<style lang="scss" scoped></style>
