<template>
  <v-combobox
    :model-value="value"
    :items="contents"
    item-title="text"
    v-model:search="searchInput"
    :hide-no-data="!searchInput"
    autofocus
    label="Pick content"
    ref="pickContentCtrl"
  >
    <template v-slot:item="{ item }">
      <v-list-item v-if="item.raw.type === 'item'" @click="pick(item.raw)">
        <v-list-item-title>
          <v-icon size="x-small" class="mr-1" :color="item.raw.color">{{ mdiSquareRounded }}</v-icon>
          <span :class="item.raw.isSuggestedPage || item.raw.isSuggestedSizeOrShape ? 'font-weight-bold' : (item.raw.isAnyPageNo ? 'text-medium-emphasis' : 'text-disabled')" data-test-id="item">{{ item.raw.text }}</span>
          <small v-if="item.raw.placedPageNos.length > 0" class="ml-2 font-weight-thin">(pg&nbsp;{{ item.raw.placedPageNos.map(p => p.pageNo).join(", ") }})</small>
          <v-tooltip activator="parent" location="start">
            <span class="font-weight-bold">{{ item.raw.suggestedPageNos.length > 0 || item.raw.sizeDescription.length > 0 ? 'Suggested:' : 'Place anywhere' }}</span><br/>
            <div v-if="item.raw.suggestedPageNos.length > 0">Page No: {{ item.raw.suggestedPageNos.join() }}</div>
            <div v-if="item.raw.sizeDescription.length > 0">Size: {{ item.raw.sizeDescription }}</div>
          </v-tooltip>
        </v-list-item-title>
      </v-list-item>
      <v-divider v-if="item.raw.type === 'divider'" />
    </template>

    <template v-slot:no-data>
      <v-list-item @click="pick(searchInput)">
        <v-list-item-title>
          <span class="subheading">Create:&nbsp;</span>
          <strong>
            {{ searchInput }}
          </strong>
        </v-list-item-title>
      </v-list-item>
    </template>
  </v-combobox>
</template>

<script>
import { ref, computed, onMounted } from "vue"
import { mdiSquareRounded, mdiSquareRoundedOutline } from '@mdi/js'

import { usePageNumbers } from "@/modules/flatplan/usePageNumbers"
import { useContent } from "@/modules/content/use"
import { getFragment, fragmentFullDescription } from "@/modules/page/render/fragmentUtils"

export default {
  name: "PickContent",
  props: {
    pageId: {
      type: String,
      required: true,
    },
    pageNo: {
      type: String,
      required: true,
    },
    pickedContent: {
      required: true,
      default: null,
    },
    fragmentName: {
      type: String,
      required: true,
    },
  },
  emits: ["picked"],
  setup(props, context) {
    let bp_content = useContent()
    let bp_pageNumbers = usePageNumbers()
    let pickContentCtrl = ref(null)

    let searchInput = ref(null)

    const fragment = getFragment(props.fragmentName)

    let pick = (data) => {
      if (data !== Object(data)) {
        data = { text: data, _createContent: true }
      }
      context.emit("picked", data)
    }

    const pickedContentId = computed(() => {
      if (props.pickedContent) {
        return props.pickedContent._id
      }
    })

    const value = computed(() => (bp_content.getById(pickedContentId) || {}).text)

    let contents = computed(() => {
      let modified = []

      bp_content.contents.value.sort(bp_content.compareText).forEach((content) => {
        const existingPageNos = (content.pageNos || []).filter((no) => bp_pageNumbers.pageByPageNo(no)) // exclude things like "near front"
        const missingPageNos = (content.pageNos || []).filter((no) => !bp_pageNumbers.pageByPageNo(no)) // only pages like "near front", or 299 (when 299 doesn't exist yet)

        let text = content.text
        let size = content.size
        let shape = content.shape
        let placed = bp_content.placed(content._id)
        let fit = bp_content.contentFit({ contentId: content._id, pageNo: props.pageNo, fragmentName: props.fragmentName })
        let color = bp_content.fitColor(fit)
        let isSuggestedPage = existingPageNos.includes(props.pageNo) // display this content "above the fold" then
        let isSuggestedSizeOrShape = bp_content.isEqualSize(size, fragment.size) && bp_content.isEqualShape(shape, fragment.shape) // display this content bold then (but below the fold)
        let isAnyPageNo = missingPageNos.length > 0 || existingPageNos.length === 0
        let placedPageNos = new Set()

        placed.forEach((p) => {
          // exclude if content on current page
          if (p.pageId !== props.pageId) {
            placedPageNos.add({ pageNo: p.pageNo, incorrect: !existingPageNos.includes(p.pageNo) })
          }
        })
        modified.push({ type: "item", _id: content._id, text, placedPageNos: [...placedPageNos], suggestedPageNos: content.pageNos, isSuggestedPage, isSuggestedSizeOrShape, isAnyPageNo, sizeDescription: fragmentFullDescription(size, shape), fit, color })
      })

      // let's sort by relevancy: matching page number first, then content with any page no, and then 'incorrect' page numbers, then by fit
      modified.sort((a, b) => {
        return ((a.isSuggestedPage === b.isSuggestedPage) ? 0 : (a.isSuggestedPage ? -1 : 1)) || ((a.isAnyPageNo === b.isAnyPageNo) ? 0 : (a.isAnyPageNo ? -1 : 1)) || (b.fit - a.fit)
      })

      // let's add a divider between the suggested ones, and the rest
      const startOfNotSuggested = modified.findIndex((item) => !item.isSuggestedPage)
      if (startOfNotSuggested > 0) {
        modified.splice(startOfNotSuggested, 0, { type: "divider" })
      }

      // let's add a divider between the suggested ones, and the rest
      const startOfNotAny = modified.findIndex((item) => item.type === 'item' && !item.isSuggestedPage && !item.isAnyPageNo)
      if (startOfNotAny > 0) {
        modified.splice(startOfNotAny, 0, { type: "divider" })
      }

      return modified
    })

    onMounted(() => {
      if (pickContentCtrl.value !== null) {
        let autocompleteInput = pickContentCtrl.value.$el.getElementsByTagName("input")[0]

        autocompleteInput.addEventListener(
          "focus",
          () => {
            setTimeout(() => {
              pickContentCtrl.value.menu = true
            }, 375) // give it a delay - else it positions the dropdown menu items a little wonky. Also easier for user to see that it opened
          },
          true
        )
      }
    })

    return {
      pickContentCtrl,
      pick,
      contents,
      value,
      searchInput,
      mdiSquareRounded,
      mdiSquareRoundedOutline
    }
  },
}
</script>

<style lang="scss" scoped></style>
