<template>
  <v-dialog v-bind="$attrs" persistent :fullscreen="mobile" :transition="mobile ? 'dialog-bottom-transition' : 'dialog-transition'" :max-width="maxWidth">
    <v-card>
      <slot></slot>
    </v-card>
  </v-dialog>
</template>

<script>
import { inject } from "vue"

export default {
  name: "BaseDialog",
  props: {
    size: {
      type: String,
    },
  },
  setup(props) {
    const mobile = inject("mobile")

    let maxWidth

    switch (props.size) {
      case "small":
        maxWidth = 400
        break
      case "medium":
        maxWidth = 600
        break
      case "large":
        maxWidth = 800
        break
    }

    return {
      mobile,
      maxWidth,
    }
  },
}
</script>

<style lang="scss" scoped></style>
