<template>
  <base-toolbar>
    <BackToFlatplan :reload="true" />
  </base-toolbar>
</template>

<script>
import BackToFlatplan from "@/components/BackToFlatplan"

export default {
  name: "BillingToolBar",
  components: {
    BackToFlatplan,
  },
}
</script>

<style lang="scss" scoped></style>
