<template>
  <div>
    <h4 class="text-h4">Categories</h4>
    <v-container>
      <draggable
        v-model="categoryIds"
        :item-key="(i) => i"
        draggable=">*:not([data-locked='true'])"
        swapThreshold="0.6"
        fallbackTolerance="0"
        touchStartThreshold="3"
        :delay="isTouchDevice ? 150 : 0"
        :forceFallback="false"
        :delayOnTouchOnly="true"
        @add="bp_categorySortable.onAdd"
        @update="bp_categorySortable.onUpdate"
        data-test-id="draggable"
      >
        <template v-slot:item="{ element }">
          <CategoryItem :categoryId="element" />
        </template>
      </draggable>
    </v-container>
  </div>
</template>

<script>
import CategoryItem from "./CategoryItem"

import draggable from "@/lib/draggable"
import { useCategorySortable } from "../useCategorySortable"

export default {
  name: "CategoriesList",
  components: {
    draggable,
    CategoryItem,
  },
  setup() {
    let bp_categorySortable = useCategorySortable()

    return {
      isTouchDevice: window.__blinkplan_is_touch_device,
      categoryIds: bp_categorySortable.categoryIds,
      bp_categorySortable,
    }
  },
}
</script>

<style lang="scss" scoped></style>
