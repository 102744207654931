import { onMounted, onUnmounted, computed } from "vue"

import { useFlatplan } from "@/modules/flatplan/use"
import { useAuth } from "@/modules/auth/use"
import { usePresenceStore } from "./store"

export const usePresence = function () {
  let bp_flatplan = useFlatplan()
  let bp_auth = useAuth()

  let presenceStore = usePresenceStore()

  let conn

  let connectAttempts = 1
  let applyNextMessage = false

  let heartbeatMsg = "--heartbeat--"
  let sendStatusInterval = null
  let missedHeartbeats = 0

  let idleTimerId = null

  let handleWebsockets = () => {
    let presenceUrl = import.meta.env.VITE_PRESENCE_ENDPOINT + "/ws/" + bp_flatplan.id.value
    conn = new WebSocket(presenceUrl)

    conn.onopen = (evt) => {
      console.log(`Connected to ${presenceUrl}`)
      connectAttempts = 1

      sendStatus()
      if (sendStatusInterval === null) {
        missedHeartbeats = 0
        sendStatusInterval = setInterval(() => {
          try {
            missedHeartbeats += 1
            if (missedHeartbeats >= 3) {
              throw new Error("Too many missed heartbeats.")
            }
            sendStatus()
          } catch (e) {
            clearInterval(sendStatusInterval)
            sendStatusInterval = null
            if (conn?.readyState === WebSocket.OPEN) {
              conn.close()
            }
          }
        }, 45000) //keep this interval below 60s else adjust nginx proxy timeouts
      }
    }

    conn.onclose = (evt) => {
      console.log("Lost connection to Presence!")
      if (sendStatusInterval !== null) {
        clearInterval(sendStatusInterval)
        sendStatusInterval = null
      }

      conn = null

      let t = generateInterval(connectAttempts)
      setTimeout(() => {
        connectAttempts += 1
        handleWebsockets()
      }, t)
    }

    conn.onmessage = (evt) => {
      missedHeartbeats = 0
      if (evt.data === heartbeatMsg) {
        return
      }

      let statuses = JSON.parse(evt.data)

      statuses.forEach((status) => {
        if (status.userId !== bp_auth.niceId.value) {
          // don't change/overwrite status for ourselves via the service -
          // only use our immediate interactions with the browser; else
          // we may set ourselves as active via a focus, but the message from the service still has us as idle
          let user = presenceStore.getByUserId(status.userId)
          if (user) {
            presenceStore.update({ userId: status.userId, user: status })
          } else if (status.userId !== "") {
            presenceStore.add({ user: status })
          }
        }
      })

      presenceStore.users
        .filter((u) => !statuses.map((s) => s.userId).includes(u.userId))
        .forEach((user) => {
          if (user.userId !== bp_auth.niceId.value) {
            presenceStore.remove({ userId: user.userId })
          }
        })
    }
  }

  let resetIdleTimer = () => {
    if (idleTimerId !== null) {
      clearTimeout(idleTimerId)
    }

    idleTimerId = setTimeout(() => {
      presenceStore.setCurrentStatus("idle")
    }, 180000)
  }

  let handleStatusChange = () => {
    sendStatus() // update server immediately - so everybody has a live view of this
  }

  let statusJson = () => {
    return {
      userId: bp_auth.niceId.value,
      name: bp_auth.name.value,
      initials: bp_auth.initials.value,
      email: bp_auth.email.value,
      status: presenceStore.currentStatus,
    }
  }

  let sendStatus = () => {
    if (conn?.readyState === WebSocket.OPEN) {
      conn.send(JSON.stringify(statusJson()))
    }
  }

  let iAmActive = () => {
    presenceStore.setCurrentStatus("active")
    resetIdleTimer()
  }

  let generateInterval = (k) => {
    // Exponential Backoff algorithm
    let maxInterval = (Math.pow(2, k) - 1) * 1000

    if (maxInterval > 30 * 1000) {
      // If the generated interval is more than 30 seconds, truncate it down to 30 seconds.
      maxInterval = 30 * 1000
    }

    // generate the interval to a random number between 0 and the maxInterval determined from above
    return Math.random() * maxInterval
  }

  let status = (user) => {
    if (user.userId === bp_auth.niceId.value) {
      return presenceStore.currentStatus
    } else {
      return user.status
    }
  }

  onMounted(() => {
    document.addEventListener("keydown", iAmActive)
    document.addEventListener("mousedown", iAmActive)
    window.addEventListener("focus", iAmActive)
  })

  onUnmounted(() => {
    document.removeEventListener("keydown", iAmActive)
    document.removeEventListener("mousedown", iAmActive)
    window.removeEventListener("focus", iAmActive)
  })

  if (window["WebSocket"]) {
    presenceStore.add({ user: statusJson() })
    resetIdleTimer()
    handleWebsockets()
  }

  // store's state is reactively passed on (computed for readonly) - all state changes should occur within store
  return {
    users: computed(() => presenceStore.users),
    status,
  }
}
