<template>
  <v-footer padless inset app>
    <v-layout justify-center wrap>
      <v-responsive class="d-flex py-4 align-center text-center fill-height">
        <small class="text-medium-emphasis">
          Since 2008 — <strong><a class="text-decoration-none text-grey-darken-2" href="//www.blinkplan.com">blinkplan</a></strong></small
        >
      </v-responsive>
    </v-layout>
  </v-footer>
</template>

<script>
export default {
  name: "TheFooter",
}
</script>
