<template>
  <v-container fluid>
    <h4 class="text-h4">
      Invites for <span class="font-weight-black" v-if="!!account">{{ account.name }}</span>
    </h4>
    <br />
    <v-alert type="info">To add people to your account, you will need to invite them. They will then receive an invitation link via email.</v-alert>

    <v-list v-if="invites.length > 0" bg-color="transparent" class="mt-4">
      <v-list-item v-for="invite in invites" :key="invite.token" @click="showInvite(invite.token)">
        <template v-slot:prepend>
          <v-icon>{{ mdiAccountPlus }}</v-icon>
        </template>
        <v-list-item-title>{{ invite.email }}</v-list-item-title>
      </v-list-item>
    </v-list>
    <router-view name="dialog" />
  </v-container>
</template>

<script>
import { useRouter } from "vue-router"
import { mdiAccountPlus } from "@mdi/js"

import { useInvite } from "../use"
import { useAccount } from "@/modules/account/use"

export default {
  name: "InvitesView",
  setup() {
    let router = useRouter()
    let bp_invite = useInvite()
    let bp_account = useAccount()

    let account = bp_account.currentAccount
    let invites = bp_invite.invites

    let showInvite = (token) => {
      router.push({ name: "invite-show", params: { token } })
    }

    return {
      account,
      invites,
      showInvite,
      mdiAccountPlus,
    }
  },
}
</script>

<style scoped></style>
