/* eslint-disable no-console */

import { register } from "register-service-worker"

if (import.meta.env.PROD) {
  register(`${import.meta.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log("App is being served from cache by a service worker.\n" + "For more details, visit https://goo.gl/AFskqB")
    },
    registered(registration) {
      setInterval(() => {
        console.log("Service worker looking for update.")
        registration.update()
      }, 1000 * 60 * 1) // e.g. every 1 minutes

      console.log("Service worker has been registered.")
    },
    cached() {
      console.log("Content has been cached for offline use.")
    },
    updatefound() {
      console.log("New content is downloading.")
    },
    updated(registration) {
      // JD: I added these two lines
      document.dispatchEvent(new CustomEvent("newAppAvailable", { detail: registration }))

      console.log("New content is available; please refresh.")
    },
    offline() {
      console.log("No internet connection found. App is running in offline mode.")
    },
    error(error) {
      console.error("Error during service worker registration:", error)
    },
  })
}
