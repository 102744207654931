export class MetricsVisualizer {
  static logConnectionDetailsChart(dns) {
    console.group('\n🔍 DNS Connection Analysis')
    
    // Service Timings with status and comparison
    console.group('Service Response Times')
    this.visualizeService('Account', dns.services.account)
    this.visualizeService('Data', dns.services.data)
    console.groupEnd()

    // Network Quality Indicators
    console.group('\nNetwork Quality')
    this.visualizeNetworkConditions(dns.networkInfo)
    console.groupEnd()

    // Device/Browser Info
    console.group('\nEnvironment')
    this.visualizeEnvironment(dns.networkInfo)
    console.groupEnd()

    // Summary
    console.group('\nSummary')
    this.visualizeSummary(dns)
    console.groupEnd()

    console.groupEnd()
  }

  static visualizeService(name, service) {
    const BAR_LENGTH = 50
    const MAX_EXPECTED_DURATION = 500 // ms
    const barLength = Math.floor((service.duration / MAX_EXPECTED_DURATION) * BAR_LENGTH)
    
    const bar = '█'.repeat(Math.min(barLength, BAR_LENGTH))
    const statusIcon = service.status === 'success' ? '✅' : '❌'
    const durationColor = service.duration < 100 ? '🟢' : 
                         service.duration < 300 ? '🟡' : '🔴'

    console.log(`${name.padEnd(8)} ${bar.padEnd(BAR_LENGTH)} ${service.duration.toFixed(0)}ms ${statusIcon} ${durationColor}`)
    
    if (service.status === 'error') {
      console.log(`          ⚠️  Error: ${service.error}`)
    }
  }


  static visualizeNetworkConditions(networkInfo) {
    // Connection Type
    const connectionQuality = {
      '4g': '🟢 Excellent',
      '3g': '🟡 Good',
      '2g': '🔴 Poor',
      'slow-2g': '🔴 Very Poor',
      'unknown': '❓ Unknown'
    }
    console.log(`Connection: ${connectionQuality[networkInfo.effectiveType] || networkInfo.effectiveType}`)

    // RTT (Round Trip Time)
    const rttQuality = networkInfo.rtt < 50 ? '🟢' : 
                      networkInfo.rtt < 100 ? '🟡' : '🔴'
    console.log(`RTT:        ${rttQuality} ${networkInfo.rtt} ms`)

    // Bandwidth
    const bandwidthQuality = networkInfo.downlink > 5 ? '🟢' : 
                            networkInfo.downlink > 2 ? '🟡' : '🔴'
    console.log(`Bandwidth:  ${bandwidthQuality} ${networkInfo.downlink} Mbps`)

    // Online Status
    console.log(`Status:     ${networkInfo.online ? '🟢 Online' : '🔴 Offline'}`)
  }

  static visualizeEnvironment(networkInfo) {
    // Device Resources
    const cores = networkInfo.hardwareConcurrency
    const memory = networkInfo.deviceMemory
    console.log(`CPU Cores:  ${cores ? `${cores} cores` : 'Unknown'}`)
    console.log(`Memory:     ${memory ? `${memory}GB` : 'Unknown'}`)

    // Service Worker
    console.log(`SW Status:  ${networkInfo.serviceWorkerStatus === 'active' ? '🟢 Active' : '⚪ Inactive'}`)
    
    // Data Saver
    console.log(`Data Saver: ${networkInfo.saveData ? '🟡 Enabled' : '⚪ Disabled'}`)

    // Page Visibility
    console.log(`Tab Focus:  ${networkInfo.wasHidden ? '⚪ Background' : '🟢 Focused'}`)
  }

  static visualizeSummary(dns) {
    const successCount = Object.values(dns.services)
      .filter(s => s.status === 'success').length

    const allSuccess = successCount === Object.keys(dns.services).length
    const partialSuccess = successCount > 0
    const allFailed = successCount === 0

    const status = allSuccess ? '🟢 All Services Healthy' :
                  partialSuccess ? '🟡 Partial Service Disruption' :
                  '🔴 Complete Service Disruption'

    console.log(`Status:     ${status}`)
    console.log(`Total Time: ${dns.totalTime.toFixed(0)}ms`)
    
    // Performance Rating
    const avgDuration = Object.values(dns.services)
      .reduce((acc, s) => acc + s.duration, 0) / Object.keys(dns.services).length
    
    const rating = avgDuration < 100 ? '⭐⭐⭐⭐⭐ Excellent' :
                  avgDuration < 200 ? '⭐⭐⭐⭐ Very Good' :
                  avgDuration < 300 ? '⭐⭐⭐ Good' :
                  avgDuration < 400 ? '⭐⭐ Fair' :
                  '⭐ Poor'
    
    console.log(`Performance: ${rating} (${avgDuration.toFixed(0)}ms avg)`)
  }
}
