<template>
  <v-menu :close-on-content-click="false" :model-value="modelValue" @update:model-value="$emit('update:modelValue', $event)">
    <template v-slot:activator="{ props }">
      <v-icon v-bind="props" :color="color" data-test-id="choose-content">
        {{ isContent ? mdiTextBoxCheck : mdiText }}
      </v-icon>
    </template>
    <v-card min-width="350">
      <v-card-text>
        <PickContent :pageId="pageId" :pageNo="pageNo" :pickedContent="pickedContent" :fragmentName="fragmentName" @picked="$emit('picked', $event)" />
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import { computed } from "vue"
import { mdiTextBoxCheck, mdiText } from "@mdi/js"

import { useContent } from "../use"
import PickContent from "@/modules/content/components/PickContent"

export default {
  name: "PickContentActivator",
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    pageId: {
      type: String,
      required: true,
    },
    pageNo: {
      type: String,
      required: true,
    },
    fragmentName: {
      type: String,
      required: true,
    },
    pickedContent: {
      default: null,
      required: true,
    },
    isContent: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  emits: ["update:modelValue", "picked"],
  components: {
    PickContent,
  },
  setup(props) {
    let bp_content = useContent()

    let color = computed(() => {
      if (props.pickedContent) {
        let fit = bp_content.contentFit({ contentId: props.pickedContent._id, pageNo: props.pageNo, fragmentName: props.fragmentName })      
        return bp_content.fitColor(fit)
      }
    })

    return {
      color,
      mdiTextBoxCheck,
      mdiText,
    }
  },
}
</script>

<style lang="scss" scoped></style>
