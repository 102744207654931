import { ref } from "vue"
import { defineStore } from "pinia"

import AccountService from "@/services/AccountService.js"

export const useApiKeyStore = defineStore("apiKey", () => {
  const apiKeys = ref([])

  function bootstrap(data) {
    apiKeys.value = data
  }

  function getByKeyHash(key_hash) {
    return apiKeys.value.find((k) => k.key_hash === key_hash)
  }


  function del({ key_hash }) {
    return AccountService.deleteApiKey({ accountId: sessionStorage.getItem("currentAccountId"), key_hash }).then(() => {
      let apiKeyIndex = apiKeys.value.findIndex((c) => c.key_hash === key_hash)
      if (apiKeyIndex !== -1) {
        apiKeys.value.splice(apiKeyIndex, 1)
      }
      return Promise.resolve()
    })
  }

  function add({ name }) {
    return AccountService.addApiKey({ accountId: sessionStorage.getItem("currentAccountId"), payload: { name } }).then((response) => {
      let apiKey = response.data.api_key
      let apiRawKey = response.data.raw_key
      let apiKeyIndex = apiKeys.value.findIndex((c) => c.key_hash === apiKey.key_hash)
      if (apiKeyIndex === -1) {
        // insert if new
        apiKeys.value.splice(0, 0, apiKey)
      } else {
        // replace if exists
        apiKeys.value.splice(apiKeyIndex, 1, apiKey)
      }
      return Promise.resolve(apiRawKey)
    })
  }

  return {
    apiKeys,
    getByKeyHash,
    bootstrap,
    del,
    add,
  }
})
