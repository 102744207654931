export const useMonitoring = () => {
  // unfortuantely we can't get it off the auth store
  // as we need access to these functions before the Pinia store exists
  let authData = {}
  const authString = localStorage.getItem("auth")

  if (authString) {
    authData = JSON.parse(authString)
  }

  let isDemo = () => {
    return authData.isDemo
  }

  let enableDiagnostics = () => {
    return authData.enableDiagnostics
  }

  let isPdf = () => {
    return window.location.pathname.startsWith("/pdf/")
  }

  let isShare = () => {
    return window.location.pathname.startsWith("/share/")
  }

  let captureErrors = () => {
    return import.meta.env.PROD && !isPdf() && !isDemo()
  }

  let captureDiagnostics = () => {
    return import.meta.env.PROD && !isPdf() && !isDemo()
  }

  return {
    isPdf,
    isShare,
    captureErrors,
    captureDiagnostics,
    enableDiagnostics,
  }
}
