<template>
  <v-container fluid>
    <div class="mb-4 d-flex align-baseline">
      <div class="text-secondary text-heading-6 font-weight-medium">{{ flatplanIssue }}</div>
      <div class="text-caption ml-4 text-medium-emphasis">{{ generatedAt }}</div>
    </div>
    <SectionStats />
    <Flatplan />
    <div style="clear: both" v-if="hasStitchIns"></div>
    <div class="mt-4" v-if="hasStitchIns"><StitchIns /></div>
    <div style="clear: both" v-if="showFlatplanNotes"></div>
    <div class="mt-4" v-if="showFlatplanNotes"><Notes /></div>
    <div style="clear: both" v-if="showPageNotes"></div>
    <div class="mt-4" v-if="showPageNotes"><PageNotes :viewOnly="true" /></div>
  </v-container>
</template>

<script>
import { useRouter } from "vue-router"

import Flatplan from "@/modules/viewOnly/components/Flatplan"
import Notes from "@/modules/flatplan/components/Notes"
import PageNotes from "@/modules/flatplan/components/PageNotes"
import SectionStats from "@/modules/section/components/SectionStats"
import StitchIns from "@/modules/viewOnly/components/StitchIns"

import { useFlatplan } from "@/modules/flatplan/use"
import { usePage } from "@/modules/page/use"
import { useStitchIn } from "@/modules/stitchIn/use"
import { usePageSetting } from "@/modules/pageSetting/use"
import { useViewSetting } from "@/modules/viewSetting/use"

export default {
  name: "PdfView",
  components: {
    Flatplan,
    SectionStats,
    Notes,
    PageNotes,
    StitchIns,
  },
  setup() {
    let router = useRouter()
    let currentRoute = router.currentRoute

    let bp_flatplan = useFlatplan()
    let bp_page = usePage()
    let bp_stitchIn = useStitchIn()
    let bp_pageSetting = usePageSetting()
    let bp_viewSetting = useViewSetting()

    bp_pageSetting.setDisplay("pdf")
    bp_pageSetting.setPageSize(currentRoute.value.query.pageSize || "sm")
    bp_pageSetting.setShowThumbnails(currentRoute.value.query.showThumbnails === "true")
    bp_viewSetting.setShowTags(currentRoute.value.query.showTags === "true")
    bp_viewSetting.setShowNotes(currentRoute.value.query.showNotes === "true")
    bp_viewSetting.setShowTypeStats(currentRoute.value.query.showTypeStats === "true")
    bp_viewSetting.setShowCategoryStats(currentRoute.value.query.showCategoryStats === "true")
    bp_viewSetting.setShowTagStats(currentRoute.value.query.showTagStats === "true")

    return {
      flatplanIssue: bp_flatplan.issue,
      generatedAt: new Date().toLocaleString(),
      hasStitchIns: bp_stitchIn.stitchIns.value.length > 0,
      showFlatplanNotes: bp_viewSetting.showNotes.value && bp_flatplan.notes !== "",
      showPageNotes: bp_viewSetting.showNotes.value && bp_page.withNotes.value.length > 0,
    }
  },
}
</script>

<style lang="scss"></style>
