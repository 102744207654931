<template>
  <div class="d-flex flex-column justify-center align-center" style="min-height: 100vh">
    <a href="/"><LogoOnly :height="100" /></a>
    <div class="text-h3 text-center mt-8">{{ version }}</div>
    <div class="text-h5 mt-8">{{ ago }}</div>
    <div class="text-h5 mt-8">
      Commit: <a :href="'https://github.com/joergd/blinkplan-3-app/commit/' + commit">{{ commit }}</a>
    </div>
  </div>
</template>

<script>
import LogoOnly from "@/components/Logos/LogoOnly.vue"
import { DateTime } from "luxon"
import { computed } from "vue"
import * as gitVersion from "@/git-version.json"

export default {
  name: "VersionView",
  components: {
    LogoOnly,
  },
  setup() {
    return {
      version: DateTime.fromFormat(gitVersion.lastCommitTime, "EEE LLL d TT yyyy ZZZ").toFormat("yyyy-MM-dd T"),
      ago: computed(() => {
        return DateTime.fromFormat(gitVersion.lastCommitTime, "EEE LLL d TT yyyy ZZZ").toRelative()
      }),
      commit: import.meta.env.VITE_SENTRY_RELEASE,
    }
  },
}
</script>

<style lang="scss" scoped></style>
