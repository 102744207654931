<template>
  <v-container fluid>
    <h4 class="text-h4 mb-4">Summary: Comments</h4>
    <template v-if="anyComments()" v-for="pageId in pageIds" :key="'page-' + pageId">
      <v-sheet v-if="hasComments(pageId)" class="d-flex mb-2">
        <div>
          <Page :pageId="pageId" class="mr-4 clickable" @click="visitPage(pageId)" />
          <div class="text-caption page-number">{{ pageNoFor(pageId) }}</div>
        </div>
        <PageComments :pageId="pageId" />
      </v-sheet>
    </template>
    <template v-else>Once you have pages with <strong>comments</strong>, they will be listed here.</template>
  </v-container>
</template>

<script>
import { ref, onMounted, computed } from "vue"

import router from "@/router"
import { decode } from "@/utils/general"
import { usePageNumbers } from "@/modules/flatplan/usePageNumbers"
import { usePage } from "@/modules/page/use"
import { useComment } from "@/modules/comment/use"
import { useAccount } from "@/modules/account/use"

import Page from "./Page"
import PageComments from "./PageComments"

export default {
  name: "SummaryCommentsView",
  components: {
    Page,
    PageComments
  },
  setup() {

    let bp_pageNumbers = usePageNumbers()
    let bp_page = usePage()
    let bp_account = useAccount()
    let bp_comment = useComment()
    
    let comments = ref([])

    let pageIdsWithComments = computed(() => {
      return bp_page.pages.value.filter((page) => !!page.commentCount).map((page) => page._id)
    })

    let hasComments = (pageId) => {
      return pageIdsWithComments.value.indexOf(pageId) > -1
    }

    let anyComments = () => {
      return pageIdsWithComments.value.length > 0
    }

    let visitPage = (pageId) => {
      router.push({
        name: "page-edit-comments",
        params: { pageId },
        query: { returnTo: "summary-comments" }
      })
    }

    let decodedComments = (pageId) => {
      let page = bp_page.getById(pageId)

      return !!page ? decode(page.notes) : ""
    }

    onMounted(() => {
      // bp_comment.fetchComments({ pageId: props.pageId }).then((response) => {
      //   comments.value = response.data
      // })
      bp_account.fetchPeople()
    })

    return {
      pageIds: bp_page.orderForFlatplan,
      hasComments,
      anyComments,
      visitPage,
      pageNoFor: bp_pageNumbers.pageNoFor,
      decodedComments
    }
  },
}
</script>

<style lang="scss" scoped>
.clickable {
  cursor: pointer;
}

</style>
