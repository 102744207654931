<template>
  <base-toolbar>
    <v-scale-transition>
      <div v-if="!multiplePagesSelected">
        <AddOptions v-if="!isArchived" />
        <ShareOptions />
        <SummaryOptions />
        <ViewOptions />
      </div>
    </v-scale-transition>
    <v-scale-transition>
      <BulkOptions v-if="multiplePagesSelected && !isArchived" />
    </v-scale-transition>
  </base-toolbar>
</template>

<script>
import { computed } from "vue"

import ShareOptions from "./ShareOptions"
import AddOptions from "./AddOptions"
import ViewOptions from "./ViewOptions"
import BulkOptions from "./BulkOptions"
import SummaryOptions from "@/modules/summary/components/SummaryOptions"

import { useFlatplan } from "../use"
import { useBulkPageSelect } from "../useBulkPageSelect"

export default {
  name: "FlatplanToolBar",
  components: {
    ShareOptions,
    ViewOptions,
    BulkOptions,
    AddOptions,
    SummaryOptions
  },
  setup() {
    let bp_flatplan = useFlatplan()
    let bp_bulkPageSelect = useBulkPageSelect()

    return {
      isArchived: bp_flatplan.isArchived,
      multiplePagesSelected: computed(() => {
        return bp_bulkPageSelect.pageIds.value.length > 1
      }),
    }
  },
}
</script>

<style lang="scss" scoped></style>
