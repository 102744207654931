<template>
  <div v-for="column in columns" :key="column">
    <v-list-subheader>{{ column }} column</v-list-subheader>
    <div class="d-flex flex-wrap">
      <div class="mr-2" v-for="template in templates.filter((t) => t.columns === column).slice(0, columnLimit[column])" @click="$emit('pick', template)" :key="template.name">
        <PageSvg class="template" :fragments="template.fragments" :withFill="true" :withAdCorners="false" forceFillColor="#FBFBFB" :pathColor="usedTemplateNames.includes(template.name) ? 'blue' : 'black'" />
      </div>
      <v-btn v-if="columnLimit[column] !== Infinity" variant="text" size="x-small" color="primary" @click="more(column)">More&hellip;</v-btn>
    </div>
  </div>
</template>

<script>
import { ref } from "vue"

import templates from "../render/templates"
import PageSvg from "./PageSvg"

import { usePage } from "@/modules/page/use"

export default {
  name: "PageTemplates",
  emits: ["pick"],
  components: {
    PageSvg,
  },
  setup() {
    let bp_page = usePage()

    let columns = Array.from(new Set(templates.map((t) => t.columns))).sort((a, b) => {
      return a - b
    })

    let defaultColumnLimit = () => {
      let limit = []

      columns.forEach((column) => {
        limit[column] = 3
      })

      return limit
    }

    let columnLimit = ref(defaultColumnLimit())

    let more = (column) => {
      columnLimit.value.splice(column, 1, Infinity)
    }

    return {
      templates,
      usedTemplateNames: bp_page.pages.value.map((p) => p.template),
      columns,
      columnLimit,
      more,
    }
  },
}
</script>

<style lang="scss" scoped>
.template {
  height: 72px;
  width: 52px;
  cursor: pointer;
}
</style>
