import { ref, computed } from "vue"

let item = null

export const useClipboard = () => {
  item ??= ref(null)

  let isEmpty = () => {
    return item.value === null
  }

  let write = (val) => {
    item.value = val
  }

  let read = () => {
    return item.value
  }

  let clear = () => {
    item.value = null
  }

  return {
    item,
    isEmpty,
    write,
    read,
    clear,
  }
}
