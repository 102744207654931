import { computed } from "vue"
import { useCommandStore } from "./store"

export const useCommand = function () {
  const commandStore = useCommandStore()

  const { add, undo } = commandStore

  // store's state is reactively passed on (computed for readonly) - all state changes should occur within store
  return {
    count: computed(() => commandStore.count),
    add,
    undo,
  }
}
