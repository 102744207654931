import { useAuthStore } from "@/modules/auth/store"
import { NetworkMetrics } from '../metrics/index'
import router from "@/router"

// Constants
const TOKENLESS_ROUTES = {
  LOGIN: 'login',
  SIGNUP: 'signup',
  INVITE_ACCEPT: 'invite-accept'
}

const REFRESH_TOKEN_PATH = '/token'

const MAX_PENDING_QUEUE_SIZE = 5

let isRefreshing = false
let pendingQueue = []

const resetRefreshState = () => {
  if (isRefreshing) {
    console.log("Resetting token refresh state. Queue length:", pendingQueue.length)
  }
  isRefreshing = false
  pendingQueue = []
}

const addToPendingQueue = () => {
  if (pendingQueue.length >= MAX_PENDING_QUEUE_SIZE) {
    throw new Error('Maximum request queue size exceeded during token refresh')
  }

  return new Promise((resolve, reject) => {
    pendingQueue.push({ resolve, reject })
  })
}

const processPendingQueue = (error, token = null) => {
  pendingQueue.forEach((prom) => {
    if (error) {
      prom.reject(error)
    } else {
      prom.resolve(token)
    }
  })
  pendingQueue = []
}

const isTokenlessRoute = (routeName) => {
  return !Object.values(TOKENLESS_ROUTES).includes(routeName)
}

const validateTokenResponse = (response) => {
  return (
    response &&
    typeof response === 'object' &&
    typeof response.token === 'string' &&
    (response.expiresIn === undefined || typeof response.expiresIn === 'number')
  )
}

const isRefreshTokenRequest = (url) => {
  try {
    const pathname = new URL(url, window.location.origin).pathname
    return pathname.endsWith(REFRESH_TOKEN_PATH)
  } catch (e) {
    console.error('Invalid URL in isRefreshTokenRequest:', url)
    return false
  }
}

const handleQueuedRequest = async (axiosInstance, originalRequest) => {
  try {
    const token = await addToPendingQueue()
    console.log("Queued request proceeding with new token", {
      url: originalRequest.url
    })
    
    // we have a new refresh token - let's fix up the original request and
    // execute it (for the first time)
    // Create a new request with the new token
    const newRequest = {
      ...originalRequest,
      headers: {
        ...originalRequest.headers,
        Authorization: `Bearer ${token}`
      },
      _retry: true, // Mark as retried to prevent infinite loops
      _fromAuthRefresh: true
    }

    // Make a completely new axios request with the updated config
    return axiosInstance(newRequest)
  } catch (err) {
    console.error('Queued request failed:', err)
    return Promise.reject(err)
  }
}

const performTokenRefresh = async (axiosInstance, originalRequest, authStore) => {
  const metrics = NetworkMetrics.getInstance()
  const requestContext = {
    triggeredBy: originalRequest.url,
    queueLength: pendingQueue.length
  }

  console.log("Starting token refresh", requestContext)

  originalRequest._retry = true // we are going to retry this request
  isRefreshing = true // we are now going to try and refresh the access token

  try {
    const response = await authStore.refreshToken()
    
    if (!validateTokenResponse(response)) {
      throw new Error('Invalid token response format')
    }

    const { token } = response
    console.log("Token refresh successful")
    
    // Create a new request with the new token
    const newRequest = {
      ...originalRequest,
      headers: {
        ...originalRequest.headers,
        Authorization: `Bearer ${token}`
      },
      _retry: true,
      _fromAuthRefresh: true
    }

    // now that we have a new token again,
    // lets's process any of the other calls that may have come in in the mean time
    processPendingQueue(null, token)
    
    // Make a completely new axios request with the updated config
    return await axiosInstance(newRequest)
  } catch (err) {
    console.error("Token refresh failed")
    metrics.logError(err, 'token_refresh', Date.now(), requestContext)

    // we weren't able to get a new token
    processPendingQueue(err, null)  // cancel all the other requests that have come in
    return Promise.reject(err)  // and reject the promise
  } finally {
    resetRefreshState()
  }

}

export function setupAuthInterceptors(axiosInstance) {
  axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error.response?.status === 401) {
        const authStore = useAuthStore()
        const originalRequest = error.config
        const currentRoute = router.currentRoute.value.name

        if (isTokenlessRoute(currentRoute)) {
          
          if (originalRequest._retry || isRefreshTokenRequest(error.config.url)) {
            console.log("Token refresh failed - logging out", {
              isRetry: originalRequest._retry,
              url: error.config.url
            })
            resetRefreshState()
            await authStore.logout({ loggedOutReason: "You have been logged out." })

            // Make sure to clear the pending queue with the error
            processPendingQueue(error)
            return Promise.reject(error)
          } else {
            return isRefreshing
              ? handleQueuedRequest(axiosInstance, originalRequest)
              : performTokenRefresh(axiosInstance, originalRequest, authStore)
          }
        }
      }
      return Promise.reject(error)
    }
  )

  return axiosInstance
}