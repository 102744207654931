<template>
  <div v-if="page && !page.isSpace" class="page-container">
    <div class="page" :data-page-id="pageId" data-element-type="page" data-test-id="page">
      <div v-if="showTags && tags.length > 0" class="page_bp_tag-container">
        <TagDot v-for="tag in tags" :key="tag._id" :color="tag.color" size="8" />
      </div>
      <PageThumbnail v-if="showThumbnails && !!thumbnail && !!thumbnail.key" class="thumbnail" :pageId="pageId" :thumbnail="thumbnail" :clickable="false" :permitSlideShow="!pdf" data-test-id="thumbnailImg" :key="thumbnail.key" />
      <div v-else class="page__canvas-container">
        <PageSvg :fragments="page.fragments" />
      </div>
      <StitchInLine v-if="stitchInId" :stitchInId="stitchInId" :viewOnly="true" />
    </div>
  </div>
  <div v-else class="page space" :data-page-id="pageId" data-element-type="page,space" data-test-id="space">
    <StitchInLine v-if="stitchInId" :stitchInId="stitchInId" :viewOnly="true" />
  </div>
</template>

<script>
import { reactive, computed } from "vue"

import PageSvg from "@/modules/page/components/PageSvg"
import PageThumbnail from "@/modules/page/components/PageThumbnail"

import TagDot from "@/modules/tag/components/TagDot"
import StitchInLine from "@/modules/stitchIn/components/StitchInLine"

import { usePage } from "@/modules/page/use"
import { usePageSetting } from "@/modules/pageSetting/use"
import { useViewSetting } from "@/modules/viewSetting/use"
import { useTag } from "@/modules/tag/use"

export default {
  name: "Page",
  props: {
    pageId: {
      type: String,
      required: true,
    },
    stitchInId: {
      type: String,
      required: false,
    },
  },
  components: {
    PageSvg,
    PageThumbnail,
    TagDot,
    StitchInLine,
  },
  setup(props) {
    let bp_page = usePage()
    let bp_pageSetting = usePageSetting()
    let bp_viewSetting = useViewSetting()
    let bp_tag = useTag()

    let page = reactive(bp_page.getById(props.pageId))

    let showThumbnails = bp_pageSetting.showThumbnails
    let showTags = bp_viewSetting.showTags

    let thumbnail = computed(() => {
      return bp_page.getById(props.pageId).thumbnail || {}
    })

    let tags = computed(() => {
      return page.tagRefs && page.tagRefs.length > 0 ? bp_tag.getByIds(page.tagRefs) : []
    })

    return {
      page,
      showThumbnails,
      thumbnail,
      showTags,
      tags,
      pdf: bp_pageSetting.display.value === "pdf",
    }
  },
}
</script>

<style lang="scss" scoped>
@import "@/styles/page.scss";
.page__canvas-container {
  cursor: default;
}
</style>
