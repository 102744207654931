import { computed } from "vue"
import DataService from "@/services/DataService.js"

import router from "@/router"

import { useContext } from "@/use/context"

export const useComment = function () {
  let bp_context = useContext()

  let accountId = bp_context.accountId.value
  let flatplanId = bp_context.flatplanId.value

  const fetchComments = ({ pageId }) => {
    return DataService.fetchComments({ accountId, flatplanId, pageId })
  }

  const add = ({ pageId, commenter, initials, text, notifications }) => {
    let pageUrl = router.resolve({ name: "page-edit-comments", params: { flatplanId, pageId } }).href
    return DataService.addComment({ accountId, flatplanId, pageId, payload: { commenter, initials, text, notifications, pageUrl } })
  }

  const del = ({ pageId, commentId }) => {
    return DataService.deleteComment({ accountId, flatplanId, pageId, commentId })
  }

  const update = ({ pageId, commentId, text }) => {
    let pageUrl = router.resolve({ name: "page-edit-comments", params: { flatplanId, pageId } }).href
    return DataService.updateComment({ accountId, flatplanId, pageId, commentId, payload: { text, pageUrl } })
  }

  return {
    fetchComments,
    add,
    del,
    update,
  }
}
