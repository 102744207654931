<template>
  <v-menu v-model="menu">
    <template v-slot:activator="{ props }">
      <v-btn v-if="!mobile" v-bind="props" variant="text" :prepend-icon="mdiExportVariant" color="primary">
        Share
      </v-btn>
      <v-btn v-else v-bind="props" variant="text" :prepend-icon="mdiExportVariant" color="primary"></v-btn>
    </template>
    <v-list density="compact">
      <v-list-item :to="{ name: 'flatplan-email' }">
        <template v-slot:prepend>
          <v-icon>{{ mdiEmailOutline }}</v-icon>
        </template>
        <v-list-item-title>Email</v-list-item-title>
      </v-list-item>
      <v-menu v-model="showPdfOptions" :close-on-content-click="false" max-width="300">
        <template v-slot:activator="{ props }">
          <v-list-item v-bind="props">
            <template v-slot:prepend>
              <v-icon>{{ mdiDownloadOutline }}</v-icon>
            </template>
            <v-list-item-title>Export as Pdf &hellip;</v-list-item-title>
          </v-list-item>
        </template>
        <PdfOptions v-model:open="showPdfOptions" />
      </v-menu>
      <v-list-item :href="shareLink" target="_blank">
        <template v-slot:prepend>
          <v-icon>{{ mdiLink }}</v-icon>
        </template>
        <v-list-item-title>View-Only</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { ref, watch, inject } from "vue"
import { mdiExportVariant, mdiEmailOutline, mdiDownloadOutline, mdiLink } from "@mdi/js"

import { useShare } from "@/modules/share/use"
import PdfOptions from "./PdfOptions"

export default {
  name: "ShareOptions",
  components: {
    PdfOptions,
  },
  setup() {
    let mobile = inject("mobile")
    let menu = ref(false)

    let bp_share = useShare()

    let showPdfOptions = ref(false)

    // when we open a popup from the menu,
    // then close the menu when we close the popup
    watch(showPdfOptions, (showPdfOptions) => {
      if (!showPdfOptions) {
        menu.value = false
      }
    })

    return {
      menu,
      mobile,
      shareLink: bp_share.shareLink,
      showPdfOptions,
      mdiExportVariant,
      mdiEmailOutline,
      mdiDownloadOutline,
      mdiLink,
    }
  },
}
</script>

<style lang="scss" scoped></style>
