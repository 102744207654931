<template>
  <div>
    <h5 class="text-h5">Invoice Details</h5>
    <div class="mt-4 mb-4">These are the details we can add onto your invoices we send you.</div>
    <v-text-field label="Organisation name (or you)" type="text" v-model="name" :hint="`If left blank we will use ${accountName}.`" persistent-hint></v-text-field>
    <v-textarea label="Additional details" v-model="details" hint="Your address - and if you like your tax or VAT number." persistent-hint></v-textarea>
    <submit-btn type="submit" @click="save" :loading="loading" class="mt-2 float-right">Save</submit-btn>
  </div>
</template>

<script>
import { ref } from "vue"

import { useBilling } from "../use"
import { useNotification } from "@/modules/notification/use"

export default {
  name: "InvoiceDetails",
  setup() {
    let bp_billing = useBilling()
    let bp_notification = useNotification()

    let name = ref(bp_billing.account.billing_name)
    let details = ref(bp_billing.account.billing_details)

    let loading = ref(false)

    let save = () => {
      loading.value = true
      bp_billing
        .update({ billingName: name.value, billingDetails: details.value })
        .then(() => {
          bp_notification.show({
            type: "success",
            message: "Invoice details saved",
          })
        })
        .finally(() => {
          loading.value = false
        })
    }

    return {
      accountName: bp_billing.accountName,
      name,
      details,
      save,
      loading,
    }
  },
}
</script>

<style lang="scss" scoped></style>
