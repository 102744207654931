<template>
  <div v-for="page in pages" :key="'page-notes-' + page._id" class="text-caption" :id="'note-' + pageNo(page._id)">
    <router-link v-if="!viewOnly" :to="{ name: 'page-edit-notes', params: { flatplanId, pageId: page._id }, query: { edit: true } }" class="float-left mr-2 font-weight-black text-decoration-none text-primary"
      >{{ pageNo(page._id) }}:</router-link
    >
    <div v-else class="float-left mr-2 font-weight-black">{{ pageNo(page._id) }}:</div>
    <div v-html="notesHtml(page.notes)"></div>
  </div>
</template>

<script>
import { usePage } from "@/modules/page/use"
import { usePageNumbers } from "@/modules/flatplan/usePageNumbers"
import { useContext } from "@/use/context"
import { decode } from "@/utils/general"

export default {
  name: "PageNotes",
  props: {
    viewOnly: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    let bp_page = usePage()
    let bp_pageNumbers = usePageNumbers()
    let bp_context = useContext()

    let accountId = bp_context.accountId.value
    let flatplanId = bp_context.flatplanId.value

    let notesHtml = (notes) => {
      return decode(notes)
    }

    return {
      accountId,
      flatplanId,
      pages: bp_page.withNotes,
      pageNo: bp_pageNumbers.pageNoFor,
      notesHtml,
    }
  },
}
</script>

<style lang="scss" scoped></style>
