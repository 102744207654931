import { useContent } from "@/modules/content/use"

export const useFragment = function () {
  const bp_content = useContent()

  const text = (fragment) => {
    let t = ""

    if (fragment) {
      t = fragment.text

      if (!!fragment.contentId && !fragment._createContent) {
        t = bp_content.contentText(fragment.contentId)
      }
    }

    return t
  }

  return {
    text,
  }
}
