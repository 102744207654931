import { ref } from "vue"
import { defineStore } from "pinia"

export const useRoleStore = defineStore("role", () => {
  const roles = ref([])

  function hasRole({ role, accountId }) {
    return roles.value.findIndex((r) => r.name === role && r.resource_id.toString() === accountId.toString()) >= 0
  }

  function bootstrap(data) {
    roles.value = data.roles
  }

  return {
    roles,
    hasRole,
    bootstrap,
  }
})
