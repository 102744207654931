<template>
  <base-dialog :model-value="true" @keydown.esc="closeDialog" size="small">
    <v-card-item>
      <v-card-title>
        <span class="text-h5 text-error" data-test-id="title">There is <strong>no</strong> undo...</span>
      </v-card-title>
    </v-card-item>
    <v-card-text>
      <strong>Archive flatplan</strong> if you want to refer back to it or clone new flatplans from it — it’ll be kept safely for your records, but <strong>no longer editable</strong>, or shown in the Flatplan picker.
      <strong>Delete it</strong> if you want it gone for good. <br />
      <v-btn-toggle class="mt-4 mb-4" v-model="mode" color="error" rounded mandatory>
        <v-btn variant="flat" value="archive" size="small" :disabled="isArchived">Archive</v-btn>
        <v-btn variant="flat" value="delete" size="small">Delete</v-btn>
      </v-btn-toggle>
      <p class="mt-4">
        To <strong>{{ mode }}</strong
        >, please enter the name of your flatplan <i>exactly</i> as it is shown below the textfield.
      </p>
      <v-form ref="form" @submit.prevent="saveDialog" class="mt-4">
        <v-text-field label="Name" v-model="confirm" data-test-id="confirm" :messages="[currentFlatplanIssue]" autofocus required />
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <cancel-btn text @click="closeDialog">Cancel</cancel-btn>
      <submit-btn type="submit" :disabled="!confirmed" @click="saveDialog" color="error" :loading="loading">{{ mode }}</submit-btn>
    </v-card-actions>
  </base-dialog>
</template>

<script>
import { ref, computed, inject } from "vue"
import { useRouter } from "vue-router"

import { useFlatplan } from "../use"
import { useAccount } from "@/modules/account/use"
import { useBootstrapFlatplan } from "@/modules/bootstrapFlatplan/use"

export default {
  name: "FlatplanEditView",
  setup() {
    let router = useRouter()

    let bp_flatplan = useFlatplan()
    let mobile = inject("mobile")
    let loading = ref(false)

    let mode = ref(bp_flatplan.isArchived.value ? "delete" : "archive")
    let confirm = ref("")

    const closeDialog = () => {
      router.push({ name: "flatplan-show" })
    }

    const saveDialog = () => {
      if (confirmed.value) {
        loading.value = true
        if (mode.value === "delete") {
          bp_flatplan
            .del()
            .then(() => {
              closeDialog()
              let bp_bootstrapFlatplan = useBootstrapFlatplan()
              let bp_account = useAccount()
              bp_bootstrapFlatplan.forgetLastFlatplan({ accountId: bp_account.currentAccountId.value })
              location.href = "/"
            })
            .catch((err) => {
              // noop
              throw err
            })
            .finally(() => {
              loading.value = false
            })
        } else {
          bp_flatplan
            .archive()
            .then(() => {
              closeDialog()
              router.push({
                name: "flatplan-show",
              }).then(() => {
                location.reload()
              })
            })
            .catch((err) => {
              // noop
              throw err
            })
            .finally(() => {
              loading.value = false
            })
        }
      }
    }

    let currentFlatplanIssue = bp_flatplan.issue.value || ""

    let confirmed = computed(() => {
      return currentFlatplanIssue.replace(/\s/g, "").toLowerCase() === confirm.value.replace(/\s/g, "").toLowerCase()
    })

    return {
      closeDialog,
      saveDialog,
      mobile,
      currentFlatplanIssue,
      mode,
      loading,
      confirm,
      confirmed,
      isArchived: bp_flatplan.isArchived,
    }
  },
}
</script>

<style lang="scss" scoped></style>
