import { ref, computed } from "vue"
import { defineStore } from "pinia"

import DataService from "@/services/DataService.js"

const comparePosition = (a, b) => {
  return a.position - b.position
}

export const useTagStore = defineStore("tag", () => {
  const tags = ref([])
  const draggableOrder = ref([])

  const ordered = computed(() => {
    return tags.value.sort(comparePosition)
  })

  function getById(id) {
    return tags.value.find((c) => c._id === id)
  }

  function getByIds(ids) {
    if (ids !== undefined) {
      return tags.value.filter((t) => ids.includes(t._id))
    } else {
      return []
    }
  }

  const currentOrderData = computed(() => {
    return ordered.value.map((c) => {
      let { _id, position } = c
      return {
        _id,
        position,
      }
    })
  })

  function collectOrder() {
    let orderData = []

    draggableOrder.value.forEach((_id, position) => {
      let category = getById(_id)

      orderData.push({
        _id,
        position,
      })
    })
    return orderData
  }

  // we just pass in position for undeletes ...
  function setTag({ tagId, name, color, position }) {
    let tagIndex = tags.value.findIndex((c) => c._id === tagId)
    if (tagIndex !== -1) {
      let tag = tags.value[tagIndex]
      if (name !== undefined) {
        tag.name = name
      }
      if (color !== undefined) {
        tag.color = color
      }
      tags.value.splice(tagIndex, 1, tag)
    } else {
      // we have undeleted - so we end up here
      tags.value.splice(0, 0, { _id: tagId, name, color, position })
    }
  }

  function removeTag({ tagId }) {
    let tagIndex = tags.value.findIndex((c) => c._id === tagId)
    if (tagIndex !== -1) {
      tags.value.splice(tagIndex, 1)
    }
  }

  function addTag(tag) {
    let tagIndex = tags.value.findIndex((c) => c._id === tag._id)
    if (tagIndex === -1) {
      // insert if new
      tags.value.splice(0, 0, tag)
    } else {
      // replace if exists
      tags.value.splice(tagIndex, 1, tag)
    }
  }

  function setOrder(orderData) {
    let tagsData = []
    orderData.forEach((item) => {
      let tagIndex = tags.value.findIndex((c) => c._id === item._id)
      if (tagIndex !== -1) {
        let tag = tags.value[tagIndex]
        tag.position = item.position

        tagsData.push(tag)
      }
    })
    tags.value = tagsData
  }

  function bootstrap(data) {
    tags.value = data
    draggableOrder.value = currentOrderData.value.map((c) => c._id)
  }

  function reorderDraggable(orderData) {
    draggableOrder.value = orderData
  }

  function update({ flatplanId, tagId, name, color, position, storeOnly }) {
    let prevAttributes = JSON.parse(JSON.stringify(getById(tagId) || {}))

    // only pass in position for undeleted - else use reorder
    setTag({ tagId, name, color, position })

    // we may be doing this in response to an undelete - so let's update the draggable order too
    // as we may have inserted a tag
    draggableOrder.value = currentOrderData.value.map((c) => c._id)

    if (!storeOnly) {
      return DataService.updateTag({ accountId: sessionStorage.getItem("currentAccountId"), flatplanId, tagId, payload: { name, color, position } }).catch((err) => {
        setTag({ tagId, ...prevAttributes })
        return Promise.reject(err)
      })
    } else {
      return Promise.resolve()
    }
  }

  function del({ tagId, flatplanId, storeOnly }) {
    let prevTag = JSON.parse(JSON.stringify(getById(tagId)))

    removeTag({ tagId })

    // update the collection of order ids
    draggableOrder.value = currentOrderData.value.map((c) => c._id)

    if (!storeOnly) {
      return DataService.deleteTag({ accountId: sessionStorage.getItem("currentAccountId"), flatplanId, tagId }).catch((err) => {
        addTag(prevTag)
        draggableOrder.value = currentOrderData.value.map((c) => c._id)
        return Promise.reject(err)
      })
    } else {
      return Promise.resolve()
    }
  }

  // add - we generate on server first, then mutate store
  function add({ flatplanId, name, color }) {
    return DataService.addTag({ accountId: sessionStorage.getItem("currentAccountId"), flatplanId, payload: { name, color } }).then((response) => {
      addTag(response.data)

      // // update the collection of order ids
      draggableOrder.value = currentOrderData.value.map((c) => c._id)

      return Promise.resolve(response)
    })
  }

  // this method is used when tag was added via Liveupdate (so it already exists on the server)
  function addFromLiveupdate(tag) {
    addTag(tag)

    // // update the collection of order ids
    draggableOrder.value = currentOrderData.value.map((c) => c._id)
  }

  function reorder({ flatplanId, orderData, storeOnly }) {
    let prevOrderData = currentOrderData.value

    orderData = orderData || []

    if (orderData.length === 0) {
      // we didn't pass in order
      // let's build it up from state
      // if the reorder is due to an undo - then we're passing in an orderData
      orderData = collectOrder()
    }
    setOrder(orderData)
    draggableOrder.value = currentOrderData.value.map((c) => c._id)

    if (!storeOnly) {
      return DataService.reorderTags({ accountId: sessionStorage.getItem("currentAccountId"), flatplanId, orderData }).catch((err) => {
        setOrder(prevOrderData)
        draggableOrder.value = currentOrderData.value.map((c) => c._id)
        return Promise.reject(err)
      })
    } else {
      return Promise.resolve()
    }
  }

  return {
    tags,
    ordered,
    draggableOrder,
    getById,
    getByIds,
    currentOrderData,
    collectOrder,
    bootstrap,
    reorderDraggable,
    update,
    del,
    add,
    addFromLiveupdate,
    reorder,
  }
})
