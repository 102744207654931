<template>
  <v-menu v-if="bp_flatplan.ordered.length > 0">
    <!-- we need max-height to get a scrollbar when we have too many flatplans -->
    <template v-slot:activator="{ props }">
      <v-btn v-bind="props" :color="bp_flatplan.isArchived ? 'error' : 'secondary'" variant="text" data-test-id="menuButton">
        {{ bp_flatplan.ordered[0].issue }}<v-icon end>{{ mdiChevronDown }}</v-icon>
      </v-btn>
    </template>
    <v-list density="compact">
      <v-list-item v-for="flatplan in bp_flatplan.ordered" :key="flatplan.id" @click="changeFlatplan(flatplan)">
        <v-list-item-title>
          <span>{{ flatplan.issue }}</span>
        </v-list-item-title>
      </v-list-item>
      <v-divider v-if="bp_flatplan.archived.length > 0" />
      <v-list-item v-if="bp_flatplan.archived.length > 0" @click="openPicker = true">
        <v-list-item-title>
          <span class="font-weight-black">Archived plans&hellip;</span>
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
  <FlatplanArchivedPicker v-model:openPicker="openPicker" />
</template>

<script>
import { ref, reactive } from "vue"
import { useRouter } from "vue-router"
import { mdiChevronDown } from "@mdi/js"

import { useFlatplan } from "../use"
import FlatplanArchivedPicker from "./FlatplanArchivedPicker"

export default {
  name: "FlatplanPicker",
  components: {
    FlatplanArchivedPicker,
  },
  setup() {
    let bp_flatplan = useFlatplan()
    let openPicker = ref(false)

    const router = useRouter()

    let changeFlatplan = (flatplan) => {
      router.push({
        params: { flatplanId: flatplan._id },
      }).then(() => {
        location.reload()
      })
    }

    return {
      bp_flatplan: reactive(bp_flatplan),
      changeFlatplan,
      openPicker,
      mdiChevronDown
    }
  },
}
</script>

<style lang="scss" scoped></style>
