<template>
  <div class="d-flex flex-wrap">
    <div class="mr-2" v-for="template in templates" @click="$emit('pick', template)" :key="template.name">
      <PageSvg class="template" :fragments="template.fragments" :withFill="true" :withAdCorners="false" forceFillColor="#FBFBFB" pathColor="black" />
    </div>
  </div>
</template>

<script>
import templates from "../render/templates"
import PageSvg from "./PageSvg"

import { useFlatplan } from "@/modules/flatplan/use"
import { usePage } from "@/modules/page/use"

export default {
  name: "DefaultPageTemplates",
  emits: ["pick"],
  components: {
    PageSvg,
  },
  setup() {
    let bp_flatplan = useFlatplan()
    let bp_page = usePage()

    let templateNames = new Set(bp_flatplan.defaults.value.templates)
    bp_page.pages.value
      .map((p) => p.template)
      .forEach((template) => {
        templateNames.add(template)
      })

    return {
      templates: templates.filter((t) => Array.from(templateNames).includes(t.name)),
    }
  },
}
</script>

<style lang="scss" scoped>
.template {
  height: 72px;
  width: 52px;
  cursor: pointer;
}
</style>
