<template>
  <v-card color="background" max-height="100vh">
    <v-card-item>
      <v-card-title><h6 class="text-h6">PDF Options</h6></v-card-title>
    </v-card-item>
    <v-card-text>
      <v-select label="Format" v-model="format" :items="formats"/>
      <p class="text-subtitle-1">Orientation</p>
      <v-radio-group v-model="orientation">
        <v-radio key="portrait" label="Portrait" value="portrait"></v-radio>
        <v-radio key="landscape" label="Landscape" value="landscape"></v-radio>
      </v-radio-group>
      <v-select label="Page sizes" v-model="pageSize" :items="pageSizes" item-title="text" />
      <p class="text-subtitle-1">Include</p>
      <v-checkbox v-model="showThumbnails" label="Thumbnails" hide-details="false"></v-checkbox>
      <v-checkbox v-model="showTags" label="Tags" hide-details="false"></v-checkbox>
      <v-checkbox v-model="showNotes" label="Notes" hide-details="false"></v-checkbox>
      <p class="text-subtitle-1 mt-6">Stats</p>
      <v-checkbox v-model="showTypeStats" label="Ads" hide-details="false"></v-checkbox>
      <v-checkbox v-model="showCategoryStats" label="Categories" hide-details="false"></v-checkbox>
      <v-checkbox v-model="showTagStats" label="Tags" hide-details="false"></v-checkbox>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <cancel-btn @click="closeDialog">Cancel</cancel-btn>
      <submit-btn type="submit" :href="pdfLink" target="_blank" @click="closeDialog">Generate PDF</submit-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { ref, computed, watch } from "vue"

import { useShare } from "@/modules/share/use"
import { usePageSetting } from "@/modules/pageSetting/use"
import { useViewSetting } from "@/modules/viewSetting/use"
import { usePdfSetting } from "@/modules/pdfSetting/use"

export default {
  name: "PdfOptions",
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:open"],
  setup(props, context) {
    let bp_share = useShare()
    let bp_pageSetting = usePageSetting()
    let bp_viewSetting = useViewSetting()
    let bp_pdfSetting = usePdfSetting()

    let formats = ["A4", "A3", "Letter"]

    let pageSizes = bp_pageSetting.pageSizes
    let pageSize = ref(bp_pageSetting.pageSize.value)

    let orientation = ref(bp_pdfSetting.orientation.value)
    let format = ref(bp_pdfSetting.format.value)
    let showThumbnails = ref(bp_pageSetting.showThumbnails.value)
    let showTags = ref(bp_viewSetting.showTags.value)
    let showNotes = ref(bp_viewSetting.showNotes.value)
    let showTypeStats = ref(bp_viewSetting.showTypeStats.value)
    let showTagStats = ref(bp_viewSetting.showTagStats.value)
    let showCategoryStats = ref(bp_viewSetting.showCategoryStats.value)

    let pdfLink = computed(() => {
      return bp_share.pdfLink({
        format: format.value,
        orientation: orientation.value,
        showThumbnails: showThumbnails.value,
        showTags: showTags.value,
        showNotes: showNotes.value,
        showTypeStats: showTypeStats.value,
        showTagStats: showTagStats.value,
        showCategoryStats: showCategoryStats.value,
        pageSize: pageSize.value,
      })
    })

    watch(format, (format) => {
      bp_pdfSetting.setFormat(format)
    })

    watch(orientation, (orientation) => {
      bp_pdfSetting.setOrientation(orientation)
    })

    let closeDialog = () => {
      context.emit("update:open", false)
    }

    return {
      format,
      formats,
      orientation,
      showThumbnails,
      showTags,
      showNotes,
      showTypeStats,
      showTagStats,
      showCategoryStats,
      closeDialog,
      pdfLink,
      pageSize,
      pageSizes,
    }
  },
}
</script>

<style lang="scss" scoped></style>
