<template>
  <base-toolbar>
    <BackToFlatplan :reload="true" />

    <v-btn v-if="!isDemo" variant="text" :to="{ name: 'invite-add' }" :prepend-icon="mdiAccountPlus" color="primary" exact>
      <span v-if="!mobile">Add</span>
    </v-btn>
  </base-toolbar>
</template>

<script>
import { inject } from "vue"
import { mdiAccountPlus } from "@mdi/js"

import { useBilling } from "@/modules/billing/use"
import BackToFlatplan from "@/components/BackToFlatplan"

export default {
  name: "PeopleToolBar",
  components: {
    BackToFlatplan,
  },
  setup() {
    let bp_billing = useBilling()

    let mobile = inject("mobile")

    return {
      mobile,
      isDemo: bp_billing.isDemo,
      mdiAccountPlus
    }
  },
}
</script>

<style lang="scss" scoped></style>
