import { computed } from "vue"
import { useContext } from "@/use/context"
import { useCommand } from "@/modules/command/use"
import { usePage } from "@/modules/page/use"
import { usePageNumbers } from "@/modules/flatplan/usePageNumbers"
import { getFragment } from "@/modules/page/render/fragmentUtils"
import { useContentStore } from "./store"
import { asyncSeries } from "@/utils/general"

export const useContent = function () {
  let bp_command = useCommand()
  let bp_context = useContext()
  let bp_page = usePage()
  let bp_pageNumbers = usePageNumbers()

  const contentStore = useContentStore()
  const { getById, getByText, compareText, cleanIdentifier, cleanPageNos, cleanText, cleanSize, cleanShape, isDuplicateExistingItem, isEqualText, isEqualShape, isEqualSize, isAcceptableSize } = contentStore

  let flatplanId = bp_context.flatplanId.value

  const update = ({ contentId, text, pageNos, size, shape, fromLiveUpdate }) => {
    if (fromLiveUpdate) {
      return contentStore.update({ flatplanId, contentId, text, pageNos, size, shape, storeOnly: true })
    }

    const content = contentStore.getById(contentId)

    if (content) {
      const prevAttributes = { text: content.text, pageNos: content.pageNos, size: content.size, shape: content.shape }

      return bp_command.add({
        execute: () => {
          return contentStore.update({ flatplanId, contentId, text, pageNos, size, shape })
        },
        undo: () => {
          return contentStore.update({ flatplanId, contentId, ...prevAttributes })
        },
      })
    }
  }

  const batchAdd = ({ contents }) => {
    let addedContents = []
    let updatedContents = []

    const prevContents = contentStore.getByIdentifiers(contents.map((c) => c.identifier))

    return bp_command.add({
      execute: () => {
        return contentStore.batchAdd({ flatplanId, contents }).then((response) => {
          addedContents = response.data.addedContents
          updatedContents = response.data.updatedContents
          return Promise.resolve(response)
        })
      },
      undo: () => {
        let undos = []
        let prevUpdatedContents = prevContents.filter((pc) => updatedContents.map((uc) => uc._id).includes(pc._id))

        undos.push(() => contentStore.batchDel({ flatplanId, contentIds: addedContents.map((c) => c._id) }))
        undos.push(() => contentStore.batchAdd({ flatplanId, contents: prevUpdatedContents })) // think of it as a batch update
        return asyncSeries(undos)
      },
    })
  }

  const batchDel = ({ contentIds, fromLiveUpdate }) => {
    if (fromLiveUpdate) {
      return contentStore.batchDel({ flatplanId, contentIds, storeOnly: true })
    }

    const prevContents = contentStore.getByIds(contentIds)

    if (prevContents.length > 0) {
      return bp_command.add({
        execute: () => {
          return contentStore.batchDel({ flatplanId, contentIds })
        },
        undo: () => {
          return contentStore.batchAdd({ flatplanId, contents: prevContents })
        },
      })
    } else {
      return Promise.resolve()
    }
  }

  // the idea is to add pages on the server first - so we pass on how many using noOfPages
  // but when we know what pages to add (an undo on a delete via Liveupdate, or a new set coming through
  // from another session via Liveupdate) - that's when we call this method
  // This method won't have an undo as this is in response to a liveupdate - so the data has already changed on the server
  const addContentsFromLiveupdate = ({ addedContents, updatedContents }) => {
    return contentStore.addContentsFromLiveupdate({ addedContents, updatedContents })
  }

  // useful for when there might no longer be the content referenced
  const contentText = (contentId) => {
    let text = ""

    if (contentId) {
      let content = getById(contentId)
      if (content) {
        text = content.text
      }
    }

    return text
  }

  const placed = (contentId) => {
    let pages = []
    let content = getById(contentId)

    if (content) {
      bp_page.onFlatplan.value.forEach((p, pageIdx) => {
        if (p.fragments) {
          p.fragments.forEach((f, fragmentIndex) => {
            if (f.contentId === contentId) {
              pages.push({
                pageId: p._id,
                pageNo: bp_pageNumbers.pageNoByIdx(pageIdx),
                fragmentName: f.shape,
                fragmentIndex,
              })
            }
          })
        }
      })
    }

    return pages
  }

  const isPageNoFit = (pageNo, pageNos) => {
    if (pageNos.length === 0) {
      return true
    } else {
      return pageNos.includes(pageNo)
    }
  }

  const isSizeFit = (contentSize, fragmentSize) => {
    if (cleanSize(contentSize) === "") {
      // we have no suggested size - so it's a fit
      return true
    } else {
      return isAcceptableSize(contentSize, fragmentSize) // use isAcceptableSize instead of isEqualSize to accommodate DPS content on full page templates
    }
  }

  const isShapeFit = (contentShape, fragmentShape) => {
    if (cleanShape(contentShape) === "") {
      // we have no suggested shape - so it's a fit
      return true
    } else {
      return isEqualShape(contentShape, fragmentShape)
    }
  }

  // '0' (n/a) - if content has no pageNos, nor size and shape
  // '1' (mismatch) - if we have suggestions and none of them fit
  // '2' (partial) - if pageNo matches, but optionally size and shape don't, or no pageNos suggested, and optionally either size and shape don't
  // '3' (match) - if pageNo, and optionally size and shape match, or no PageNos suggested, but both size and shape match
  const contentFit = ({ contentId, pageNo, fragmentName }) => {
    let content = getById(contentId)

    if (content) {
      const missingPageNos = (content.pageNos || []).filter((no) => !bp_pageNumbers.pageByPageNo(no))
      const existingPageNos = (content.pageNos || []).filter((no) => bp_pageNumbers.pageByPageNo(no))
      const fragment = getFragment(fragmentName)

      if (existingPageNos.length === 0 && cleanSize(content.size) === "" && cleanShape(content.shape) === "") { // use the cleanSize and cleanShape - in case on the DB those values are undefined/null
        return 0
      }

      let matchCount = 0
      let maxMatchCount = 0

      if (existingPageNos.length > 0) {
        maxMatchCount += 1
        if (isPageNoFit(pageNo, existingPageNos)) {
          matchCount += 1
        }  
      }

      if (cleanSize(content.size).length > 0) {
        maxMatchCount += 1
        if (isSizeFit(content.size, fragment.size)) {
          matchCount += 1
        }
      }

      if (cleanShape(content.shape).length > 0) {
        maxMatchCount += 1
        if (isShapeFit(content.shape, fragment.shape)) {
          matchCount += 1
        }  
      }

      if (matchCount === 0) {
        return 1
      }

      if (matchCount < maxMatchCount) {
        return 2
      }

      if (matchCount === maxMatchCount) {
        return 3
      }
    }
  }

  const fitColor = (fit) => {
    switch (fit) {
      case 0: // n/a
        return "grey"
        break;
      case 1: // mismatch
        return "red"
        break;
      case 2: // partial
        return "amber"
        break;
      case 3: // match
        return "green"
        break;
      default:
        return "grey"
    }
  }

  return {
    contents: computed(() => contentStore.contents),
    getById,
    getByText,
    compareText,
    update,
    batchAdd,
    batchDel,
    addContentsFromLiveupdate,
    contentText,
    placed,
    isDuplicateExistingItem,
    cleanIdentifier,
    cleanText,
    cleanPageNos,
    cleanSize,
    cleanShape,
    isEqualText,
    isEqualShape,
    isEqualSize,
    isAcceptableSize,
    contentFit,
    fitColor
  }
}
