<template>
  <base-dialog :model-value="true" @keydown.esc="closeDialog" size="small">
    <v-card-item>
      <v-card-title>
        <span class="text-h5" data-test-id="title">Create New Flatplan</span>
      </v-card-title>
    </v-card-item>
    <v-card-text>
      <v-form ref="form" v-model="formValid" @submit.prevent="saveDialog">
        <v-text-field label="Issue" v-model="issue" data-test-id="issue" autofocus required :rules="issueRules" />
        <v-checkbox v-model="singlePages" label="Single pages" hide-details="false"></v-checkbox>
        <v-radio-group v-model="pageOrientation">
          <v-radio key="portrait" label="Portrait" value="portrait"></v-radio>
          <v-radio key="landscape" label="Landscape" value="landscape"></v-radio>
          <v-radio key="square" label="Square" value="square"></v-radio>
        </v-radio-group>
        <h6 class="text-subtitle-1 font-weight-black">
          Would you like to clone <strong>{{ currentFlatplanIssue }}</strong
          >?
        </h6>
        <v-radio-group v-model="clone">
          <v-radio key="categories" label="No, just create an empty flatplan" value="categories"></v-radio>
          <v-radio key="layout" label="Yes, only the layout" value="layout"></v-radio>
          <v-radio key="all" label="Yes, the layout and all the content" value="all"></v-radio>
        </v-radio-group>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <cancel-btn @click="closeDialog">Close</cancel-btn>
      <submit-btn type="submit" @click="saveDialog" :loading="loading">Create</submit-btn>
    </v-card-actions>
  </base-dialog>
</template>

<script>
import { ref } from "vue"
import { useRouter } from "vue-router"

import { useFlatplan } from "../use"

export default {
  name: "FlatplanNewView",
  setup() {
    let router = useRouter()

    let bp_flatplan = useFlatplan()

    const form = ref(null)
    let formValid = ref(false)
    let loading = ref(false)

    let issueRules = [(v) => !!v || "Issue is required."]

    let issue = ref("")
    let singlePages = ref(bp_flatplan.singlePages.value) // default to current flatplan
    let pageOrientation = ref(bp_flatplan.pageOrientation.value) // default to current flatplan
    let clone = ref("categories")

    const closeDialog = () => {
      router.push({ name: "flatplan-show" })
    }

    const saveDialog = () => {
      form.value.validate()

      if (formValid.value) {
        loading.value = true
        bp_flatplan
          .create({ issue: issue.value, singlePages: singlePages.value, pageOrientation: pageOrientation.value, clone: clone.value })
          .then((response) => {
            closeDialog()
            router.push({
              name: "flatplan-show",
              params: { flatplanId: response.data._id },
            }).then(() => {
              location.reload()
            })
          })
          .catch((err) => {
            if (err.response.status === 402) {
              // an error occured - perhaps we have gone over the plan limits.
              // this 402 error would already have been caught somewhere else to display.
              // noop
            } else {
              throw err
            }
          })
          .finally(() => {
            loading.value = false
          })
      }
    }

    return {
      closeDialog,
      saveDialog,
      form,
      formValid,
      loading,
      issue,
      issueRules,
      singlePages,
      pageOrientation,
      clone,
      currentFlatplanIssue: bp_flatplan.issue,
    }
  },
}
</script>

<style lang="scss" scoped></style>
