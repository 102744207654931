import { computed } from "vue"
import { useDemoStore } from "./store"
import router from "@/router"

export const useDemo = function () {
  const demoStore = useDemoStore()

  const setup = async () => {
    return demoStore
      .createUser()
      .then(() => {
        return demoStore.setupAccount()
      })
      .then(() => {
        return demoStore.createFlatplan()
      })
      .then(() => {
        return demoStore.createContents()
      })
      .then(() => {
        return demoStore.initData()
      })
      .then(() => {
        redirectToHome()
      })
  }

  const redirectToHome = function () {
    router.push({
      name: "home",
    })
  }

  // store's state is reactively passed on (computed for readonly) - all state changes should occur within store
  return {
    state: computed(() => demoStore.state),
    setup,
  }
}
