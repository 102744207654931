import { computed } from "vue"

import { useContext } from "@/use/context"
import { useCommand } from "@/modules/command/use"
import { useTagStore } from "./store"

export const useTag = function () {
  let bp_command = useCommand()
  let bp_context = useContext()

  const tagStore = useTagStore()
  const { getById, getByIds } = tagStore

  let flatplanId = bp_context.flatplanId.value

  const update = ({ tagId, name, color, fromLiveUpdate }) => {
    if (fromLiveUpdate) {
      return tagStore.update({ flatplanId, tagId, name, color, storeOnly: true })
    }

    const tag = tagStore.getById(tagId)

    if (tag) {
      const prevAttributes = { name: tag.name, color: tag.color }

      return bp_command.add({
        execute: () => {
          return tagStore.update({ flatplanId, tagId, name, color })
        },
        undo: () => {
          return tagStore.update({ flatplanId, tagId, ...prevAttributes })
        },
      })
    } else {
      return Promise.resolve()
    }
  }

  const add = ({ name, color }) => {
    let tagId

    return bp_command.add({
      execute: () => {
        return tagStore.add({ flatplanId, name, color }).then((response) => {
          tagId = response.data._id
        })
      },
      undo: () => {
        return tagStore.del({ flatplanId, tagId })
      },
    })
  }

  // the idea is to add tag on the server first
  // but when we know what tag to add (an undo on a delete via Liveupdate, or a new one coming through
  // from another session via Liveupdate) - that's when we call this method
  // This method won't have an undo as this is in response to a liveupdate - so the data has already changed on the server
  const addFromLiveupdate = (tag) => {
    return tagStore.addFromLiveupdate(tag)
  }

  // pass in orderData if from LiveUpdate.
  const reorder = ({ orderData, fromLiveUpdate }) => {
    if (fromLiveUpdate) {
      return tagStore.reorder({ flatplanId, orderData, storeOnly: true })
    }

    const prevOrderData = tagStore.orderData

    return bp_command.add({
      execute: () => {
        return tagStore.reorder({ flatplanId })
      },
      undo: () => {
        return tagStore.reorder({ flatplanId, orderData: prevOrderData })
      },
    })
  }

  const reorderDraggable = (orderData) => {
    tagStore.reorderDraggable(orderData)
  }

  const del = ({ tagId, fromLiveUpdate }) => {
    if (fromLiveUpdate) {
      return tagStore.del({ flatplanId, tagId, storeOnly: true })
    }

    const prevTag = tagStore.getById(tagId)

    if (prevTag) {
      return bp_command.add({
        execute: () => {
          return tagStore.del({ flatplanId, tagId })
        },
        undo: () => {
          return tagStore.update({ flatplanId, tagId, ...prevTag })
        },
      })
    } else {
      return Promise.resolve()
    }
  }

  let generateTag = (name) => {
    return {
      _id: name,
      name: name,
      color: "#939396", // has to match API default colour
      create: true,
    }
  }

  // store's state is reactively passed on (computed for readonly) - all state changes should occur within store
  return {
    tags: computed(() => tagStore.ordered),
    draggableOrder: computed(() => tagStore.draggableOrder),
    getById,
    getByIds,
    reorder,
    reorderDraggable,
    update,
    del,
    add,
    generateTag,
    addFromLiveupdate,
    colorHash: computed(() => tagStore.ordered.map((c) => c.color || "").join("")), // we use this for keys in templates to figure out if tags have changed
  }
}
