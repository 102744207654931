<template>
  <base-toolbar class="rounded-ts-xl rounded-te-xl" data-test-id="toolbar">
    <BackToFlatplan :reload="true" />
  </base-toolbar>
</template>

<script>
import BackToFlatplan from "@/components/BackToFlatplan"

export default {
  name: "ProfileToolBar",
  components: {
    BackToFlatplan,
  },
}
</script>

<style lang="scss" scoped></style>
