import { computed } from "vue"

import { usePdfSettingStore } from "./store"

export const usePdfSetting = function () {
  const pdfSettingStore = usePdfSettingStore()
  const { setFormat, setOrientation } = pdfSettingStore

  // store's state is reactively passed on (computed for readonly) - all state changes should occur within store
  return {
    format: computed(() => pdfSettingStore.format),
    orientation: computed(() => pdfSettingStore.orientation),
    setFormat,
    setOrientation,
  }
}
