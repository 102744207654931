<template>
  <div v-if="layout === 'editor'">
    <v-navigation-drawer @update:model-value="leftDrawerStateChange" :model-value="showLeftDrawer">
      <AccountPicker />
      <v-divider></v-divider>

      <FlatplanPicker />

      <v-list density="compact">
        <v-list-item :to="{ name: 'flatplan-new' }" exact data-label="new flatplan nav">
          <v-list-item-title><strong class="font-weight-black">NEW</strong> flatplan</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="!isArchived" :to="{ name: 'flatplan-edit' }" exact data-label="settings nav">
          <v-list-item-title>Settings</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="!isArchived" :to="{ name: 'flatplan-notes' }" exact data-label="notes nav">
          <v-list-item-title>Notes</v-list-item-title>
        </v-list-item>
        <v-list-item :to="{ name: 'flatplan-delete' }" exact data-label="delete flatplan nav">
          <v-list-item-title v-if="!isArchived" class="text-error">Archive or delete &hellip;</v-list-item-title>
          <v-list-item-title v-else class="text-error">Delete &hellip;</v-list-item-title>
        </v-list-item>
      </v-list>

      <FlatplanSetupOptions v-if="!isArchived" />
      <div v-else class="ma-3">
        <v-divider></v-divider>
        <p class="mt-4 text-caption">This flatplan has been archived. No further changes are possible.</p>
      </div>

      <template v-slot:prepend>
        <div class="pa-3">
          <a href="/"><LogoFull class="ml-3" /></a>
        </div>
      </template>

      <template v-slot:append>
        <div class="d-flex justify-space-between align-baseline">
          <v-btn href="//www.blinkplan.com/docs" target="_blank">Documentation</v-btn>
        </div>
      </template>

    </v-navigation-drawer>

    <v-navigation-drawer @update:model-value="rightDrawerStateChange" :model-value="showRightDrawer" location="right" mobile-breakpoint="xs">
      <v-container>
        <v-row v-if="hasHoldingArea">
          <v-col><HoldingArea /></v-col>
        </v-row>
      </v-container>
      <template v-slot:prepend>
        <div class="pa-3">
          <PresentUsers />
        </div>
      </template>
    </v-navigation-drawer>

    <v-app-bar flat>
      <template v-slot:prepend>
        <v-app-bar-nav-icon @click.stop="setShowLeftDrawer">
          <v-icon color="primary">{{ showLeftDrawer ? mdiBackburger : mdiMenu }}</v-icon>
        </v-app-bar-nav-icon>
      </template>
      <v-app-bar-title>
        <a v-show="!showLeftDrawer" href="/"><LogoBlinkplan class="mt-3" /></a>
      </v-app-bar-title>

      <TheProgressBar />

      <v-btn v-if="isDemo" href="/signup" variant="flat" color="secondary">SIGNUP</v-btn>

      <template v-slot:append>
        <v-app-bar-nav-icon>
          <TheUserNav />
        </v-app-bar-nav-icon>
        <v-app-bar-nav-icon @click.stop="setShowRightDrawer">
          <v-icon color="primary">{{ showRightDrawer ? mdiArrowRight : trayIcon }}</v-icon>
        </v-app-bar-nav-icon>
      </template>

      <template v-slot:extension v-if="toolbar !== null">
        <FlatplanToolBar v-if="toolbar === 'flatplan'" />
        <CategoryToolBar v-if="toolbar === 'category'" />
        <TagToolBar v-if="toolbar === 'tag'" />
        <StitchInToolBar v-if="toolbar === 'stitchIn'" />
        <SectionToolBar v-if="toolbar === 'section'" />
        <ContentToolBar v-if="toolbar === 'content'" />
        <ContentUploadToolBar v-if="toolbar === 'content-upload'" />
        <NotesToolBar v-if="toolbar === 'notes'" />
        <SummaryToolBar v-if="toolbar === 'summary'" />
      </template>
      
    </v-app-bar>

    <v-main>
      <slot></slot>
      <TheUndoButton v-if="!isArchived" />
    </v-main>
  </div>
</template>

<script>
import { computed } from "vue"
import { useRouter } from "vue-router"
import { mdiMenu, mdiTray, mdiTrayFull, mdiBackburger, mdiArrowRight } from "@mdi/js"

import TheUserNav from "@/components/TheUserNav.vue"
import TheProgressBar from "@/components/TheProgressBar.vue"
import LogoFull from "@/components/Logos/LogoFull.vue"
import LogoBlinkplan from "@/components/Logos/LogoBlinkplan.vue"
import TheUndoButton from "@/modules/command/components/TheUndoButton"

import AccountPicker from "@/modules/account/components/AccountPicker.vue"
import HoldingArea from "@/modules/flatplan/components/HoldingArea.vue"
import CategoryToolBar from "@/modules/category/components/CategoryToolBar"
import TagToolBar from "@/modules/tag/components/TagToolBar"
import SectionToolBar from "@/modules/section/components/SectionToolBar"
import ContentToolBar from "@/modules/content/components/ContentToolBar"
import ContentUploadToolBar from "@/modules/content/components/ContentUploadToolBar"
import NotesToolBar from "@/modules/flatplan/components/NotesToolBar"
import SummaryToolBar from "@/modules/summary/components/SummaryToolBar"
import StitchInToolBar from "@/modules/stitchIn/components/StitchInToolBar"
import FlatplanToolBar from "@/modules/flatplan/components/FlatplanToolBar"
import FlatplanPicker from "@/modules/flatplan/components/FlatplanPicker"
import FlatplanSetupOptions from "@/modules/flatplan/components/FlatplanSetupOptions"

import PresentUsers from "@/modules/presence/components/PresentUsers"

import { useAuth } from "@/modules/auth/use"
import { usePage } from "@/modules/page/use"
import { useFlatplan } from "@/modules/flatplan/use"
import { useLiveupdate } from "@/modules/liveupdate/use"
import { useViewSetting } from "@/modules/viewSetting/use"
import { useToolbar } from "@/use/toolbar"

export default {
  name: "EditorLayout",
  components: {
    AccountPicker,
    TheUserNav,
    TheProgressBar,
    TheUndoButton,
    LogoFull,
    LogoBlinkplan,
    HoldingArea,
    CategoryToolBar,
    SectionToolBar,
    ContentToolBar,
    ContentUploadToolBar,
    TagToolBar,
    StitchInToolBar,
    FlatplanToolBar,
    FlatplanPicker,
    FlatplanSetupOptions,
    NotesToolBar,
    SummaryToolBar,
    PresentUsers,
  },
  setup() {
    let router = useRouter()
    let currentRoute = router.currentRoute

    useLiveupdate() // init websockets

    let bp_auth = useAuth()
    let bp_page = usePage()
    let bp_flatplan = useFlatplan()
    let bp_viewSetting = useViewSetting()
    let bp_toolbar = useToolbar()

    let showLeftDrawer = bp_viewSetting.showLeftDrawer
    let showRightDrawer = bp_viewSetting.showRightDrawer

    let setShowLeftDrawer = () => {
      bp_viewSetting.setShowLeftDrawer(!bp_viewSetting.showLeftDrawer.value)
    }

    let setShowRightDrawer = () => {
      bp_viewSetting.setShowRightDrawer(!bp_viewSetting.showRightDrawer.value)
    }

    let hasHoldingArea = computed(() => {
      let matched = currentRoute.value.matched ? [...currentRoute.value.matched] : []
      let closestRoute = matched.reverse().find((r) => r.meta.hasHoldingArea)
      return !bp_flatplan.isArchived.value && closestRoute ? closestRoute.meta.hasHoldingArea : false
    })

    let trayIcon = computed(() => (hasHoldingArea.value && bp_page.onHoldingArea.value.length > 0 ? mdiTrayFull : mdiTray))

    // we need this in case the drawer becomes a temporary drawer, and we click on the overlay to close.
    // in that case we don't get that click event, but we can listen for a state change on the drawer,
    // and can set the setting here too.
    let leftDrawerStateChange = (state) => {
      bp_viewSetting.setShowLeftDrawer(state)
    }

    let rightDrawerStateChange = (state) => {
      bp_viewSetting.setShowRightDrawer(state)
    }

    return {
      trayIcon,
      showLeftDrawer,
      setShowLeftDrawer,
      showRightDrawer,
      setShowRightDrawer,
      bp_page,
      toolbar: bp_toolbar.toolbar,
      hasHoldingArea,
      isArchived: bp_flatplan.isArchived,
      leftDrawerStateChange,
      rightDrawerStateChange,
      isDemo: bp_auth.isDemo,
      layout: computed(() => currentRoute.value.meta.layout),
      mdiMenu,
      mdiBackburger,
      mdiArrowRight
    }
  },
}
</script>
