<template>
  <div class="d-flex flex-column justify-center align-center" style="min-height: 100vh">
    <div class="text-h3 text-center">Test Error</div>
    <div class="text-caption text-center mt-2">
      Will capture: <strong>{{ captureErrors ? "Yes" : "No" }}</strong>
    </div>
    <div class="my-8"><v-btn @click="generate" color="primary">Generate</v-btn></div>
    <div class="text-caption mt-4"><a href="/" class="text-decoration-none">Home</a></div>
  </div>
</template>

<script>
import { useMonitoring } from "@/use/useMonitoring"
import { useNotification } from "@/modules/notification/use"

export default {
  name: "TestErrorView",
  setup() {
    let bp_monitoring = useMonitoring()
    let bp_notification = useNotification()

    let generate = () => {
      bp_notification.show({
        type: "success",
        message: "Generated",
      })
      nada.whatever
    }

    return {
      captureErrors: bp_monitoring.captureErrors(),
      generate,
    }
  },
}
</script>

<style lang="scss" scoped></style>
