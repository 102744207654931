<template>
  <div v-if="!!decodedNotes">
    <div v-html="decodedNotes" class="text-caption decoded-markup"></div>
  </div>
</template>

<script>
import { computed } from "vue"
import { useFlatplan } from "@/modules/flatplan/use"
import { decode } from "@/utils/general"

export default {
  name: "Notes",
  setup() {
    let bp_flatplan = useFlatplan()

    return {
      decodedNotes: computed(() => decode(bp_flatplan.notes.value))
    }
  },
}
</script>

<style lang="scss" scoped></style>
