<template>
  <div>
    <CommentBox :pageId="pageId" v-model:text="commentBody" @comment-added="commentAdded" />
    <transition-group tag="div" name="expand">
      <Comment v-for="comment in comments" :key="comment._id" :pageId="pageId" :comment="comment" @comment-deleted="commentDeleted" @comment-updated="commentUpdated" />
    </transition-group>
  </div>
</template>

<script>
import { ref, watch, computed, onMounted } from "vue"

import { usePage } from "../use"
import { useAccount } from "@/modules/account/use"
import { useComment } from "@/modules/comment/use"
import { sessionStorageObj } from "@/utils/storage"

import Comment from "@/modules/comment/components/Comment"
import CommentBox from "@/modules/comment/components/CommentBox"

export default {
  name: "EditPageComments",
  props: {
    pageId: {
      type: String,
      required: true,
    },
  },
  components: {
    Comment,
    CommentBox,
  },
  setup(props) {
    let bp_page = usePage()
    let page = bp_page.getById(props.pageId)

    let comments = ref([])

    let bp_account = useAccount()
    let bp_comment = useComment()

    // let's fetch the edit in-progress from sessionStorage. The EditPageView component has
    // placed a copy of the original into sessionStorage (Assumption: The EditPageView setup method runs before this setup method).
    // Let's save all changes back to sessionStorage,
    // and the EditPageView will pick it up again to save when done editing.
    let commentBody = ref(sessionStorageObj.getItem("editPage", "commentBody").data)

    const saveToLocalStorage = (value) => {
      sessionStorageObj.setItem("editPage", { data: value, dirty: true }, "commentBody")
    }

    watch(commentBody, (commentBody) => {
      saveToLocalStorage(commentBody)
    })

    let commentAdded = (comment) => {
      comments.value.splice(0, 0, comment)
      commentBody.value = ""
    }

    let commentDeleted = (id) => {
      let commentIndex = comments.value.findIndex((c) => c._id === id)
      if (commentIndex !== -1) {
        comments.value.splice(commentIndex, 1)
      }
    }

    let commentUpdated = (comment) => {
      let commentIndex = comments.value.findIndex((c) => c._id === comment._id)
      if (commentIndex !== -1) {
        comments.value.splice(commentIndex, 1, comment)
      }
    }

    onMounted(() => {
      bp_comment.fetchComments({ pageId: props.pageId }).then((response) => {
        comments.value = response.data
      })
      bp_account.fetchPeople()
    })

    return {
      page,
      comments: computed(() => {
        return comments.value.slice(0).sort((a, b) => {
          return new Date(b.meta.createdAt) - new Date(a.meta.createdAt)
        })
      }),
      commentBody,
      commentAdded,
      commentDeleted,
      commentUpdated,
    }
  },
}
</script>

<style lang="scss" scoped></style>
