<template>
  <v-chip size="x-small">
    <span>{{ section.count }}</span>&nbsp;x&nbsp;<strong>{{ section.size }}</strong>
  </v-chip>
</template>

<script>
export default {
  name: "SectionStat",
  props: {
    section: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped></style>
