// very crude helper to get/set key on a session storage json object
// keys are only one level deep

const getItem = (itemKey, key) => {
  let obj = JSON.parse(sessionStorage.getItem(itemKey))
  return key !== undefined ? obj[key] : obj
}
const setItem = (itemKey, value, key) => {
  let obj = JSON.parse(sessionStorage.getItem(itemKey))
  if (key === undefined) {
    obj = value
  } else {
    obj[key] = value
  }
  sessionStorage.setItem(itemKey, JSON.stringify(obj))
}

export const sessionStorageObj = {
  getItem,
  setItem,
}
