<template>
  <v-chip v-for="tag in tags" :key="tag._id" size="x-small">
    <strong>{{ tag.name }}</strong>
    <TagDot :color="tag.color" class="mr-3 ml-1" />
    <span class="pr-1"><small>x</small>{{ Math.round((detailsFor(tag).count + Number.EPSILON) * 100) / 100 }}&nbsp;&nbsp;<small>=</small>{{ ((detailsFor(tag).percentage + Number.EPSILON) * 100).toFixed(1) }}%</span>
  </v-chip>
</template>

<script>
import TagDot from "./TagDot"
import { useTag } from "../use"
import { useStats } from "../useStats"

export default {
  name: "TagStats",
  components: {
    TagDot,
  },
  setup() {
    let bp_tag = useTag()
    let bp_stats = useStats()

    return {
      tags: bp_tag.tags,
      detailsFor: bp_stats.detailsFor,
    }
  },
}
</script>

<style lang="scss" scoped></style>
