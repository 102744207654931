<template>
  <div :key="refresh" id="editor-workspace" style="margin-bottom: 200px" data-label="editor workspace">
    <!-- margin-bottom: this is so that page menus are not obscured by Intercom - this way we have room to scroll for Intercom to get out of the way -->
    <div class="mb-6" v-if="showIssue">
      <span class="text-secondary text-heading-6 font-weight-medium">{{ flatplanIssue }}</span>
    </div>
    <div style="clear: both"></div>
    <div v-if="coverPageId" class="d-flex">
      <div class="flatplan__cover-wrapper" data-draggable-for="coverPage" data-test-id="cover-wrapper">
        <div class="page-numbers__container">
          <div class="page__wrapper">
            <div class="page">
              <div class="text-caption page-number">{{ bp_pageNumbers.cover }}</div>
            </div>
          </div>
        </div>
        <v-sheet class="page__wrapper" :class="{ selected: coverSelected }" tile>
          <Page :pageId="coverPageId" @page-selected="toggleSelectCover" />
        </v-sheet>
        <div v-if="!showTypeStats && !showCategoryStats && !showTagStats" style="clear: both"></div>
      </div>
      <div v-if="showTypeStats" class="ml-4">
        <TypeStats />
        <div style="clear: both"></div>
      </div>
      <div v-if="showCategoryStats" class="ml-4">
        <CategoryStats />
        <div style="clear: both"></div>
      </div>
      <div v-if="showTagStats" class="ml-4">
        <TagStats />
        <div style="clear: both"></div>
      </div>
    </div>
    <div v-else>
      <div v-if="showTypeStats" class="mb-4">
        <TypeStats />
        <div style="clear: both"></div>
      </div>
      <div v-if="showCategoryStats" class="mb-4">
        <CategoryStats />
        <div style="clear: both"></div>
      </div>
      <div v-if="showTagStats" class="mb-4">
        <TagStats />
        <div style="clear: both"></div>
      </div>
    </div>
    <div id="flatplan-for-resize-sensor__wrapper" class="flatplan__wrapper">
      <div class="page-numbers__container">
        <v-sheet class="d-inline-block" :class="layoutClasses">
          <div v-for="(pageNumber, index) in bp_pageNumbers.inner" :key="`pageNumber-${index}`" class="page__wrapper">
            <div class="page">
              <div v-if="pageHasNotes(pageNumber)" class="text-caption page-number">
                <a :href="'#note-' + pageNumber" class="text-decoration-none text-primary">{{ pageNumber }}</a>
              </div>
              <div v-else class="text-caption page-number">{{ pageNumber }}</div>
            </div>
          </div>
        </v-sheet>
      </div>

      <draggable
        ref="sortableContainer"
        v-model="pageIds"
        :item-key="(i) => i"
        group="pagesGroup"
        data-draggable-for="flatplan"
        data-test-id="pages-container"
        class="d-inline-block pages__container"
        :class="layoutClasses"
        handle=".affordance"
        draggable=">*:not([data-locked='true'])"
        animation="0"
        swapThreshold="1"
        fallbackTolerance="0"
        :delay="isTouchDevice ? 150 : 0"
        touchStartThreshold="3"
        selectedClass="selected"
        ghostClass="draggable--ghost"
        swapClass="draggable--swap"
        @end="bp_pageSortable.onEnd"
        @add="bp_pageSortable.onAdd"
        @update="bp_pageSortable.onUpdate"
        @remove="bp_pageSortable.onRemove"
        @select="toggleSelectPage"
        @deselect="toggleSelectPage"
        :delayOnTouchOnly="true"
        :swap="true"
        :multi-drag="true"
        :scroll="true"
        :forceFallback="false"
        scrollSensitivity="150"
        scrollSpeed="30"
        :supportPointer="false"
      >
        <template v-slot:item="{ element, index }">
          <v-sheet :data-page-id="element" :data-locked="bp_pageSortable.isLocked(element)" class="page__wrapper">
            <Page :pageId="element" :stitchInId="stitchInIdForPageIndex(index)" />
          </v-sheet>
        </template>
      </draggable>
    </div>
    <div style="clear: both" v-if="showFlatplanNotes"></div>
    <div class="mt-4" v-if="showFlatplanNotes"><Notes /></div>
    <div style="clear: both"></div>
    <div class="mt-4" v-if="showPageNotes"><PageNotes :viewOnly="false" /></div>
    <QuickLook />
    <SlideShow />
  </div>
</template>

<script>
import { ref, computed, reactive, onUnmounted } from "vue"

import { useFlatplan } from "../use"
import { usePage } from "@/modules/page/use"
import { useFlatplanRowBreaks } from "../useFlatplanRowBreaks"
import { usePageSortable } from "@/modules/flatplan/usePageSortable"
import { usePageNumbers } from "@/modules/flatplan/usePageNumbers"
import { useBulkPageSelect } from "@/modules/flatplan/useBulkPageSelect"
import { useCategory } from "@/modules/category/use"
import { useTag } from "@/modules/tag/use"
import { useStitchIn } from "@/modules/stitchIn/use"
import { useViewSetting } from "@/modules/viewSetting/use"

import Page from "@/modules/page/components/Page"
import QuickLook from "@/modules/page/components/QuickLook"
import SlideShow from "@/modules/flatplan/components/SlideShow"
import draggable from "@/lib/draggable"
import TypeStats from "@/modules/type/components/TypeStats"
import CategoryStats from "@/modules/category/components/CategoryStats"
import TagStats from "@/modules/tag/components/TagStats"
import PageNotes from "./PageNotes"
import Notes from "./Notes"

export default {
  name: "Flatplan",
  components: {
    Page,
    QuickLook,
    SlideShow,
    draggable,
    TypeStats,
    CategoryStats,
    TagStats,
    PageNotes,
    Notes,
  },
  setup() {
    let sortableContainer = ref(null)

    let bp_flatplan = useFlatplan()
    let bp_page = usePage()
    let bp_pageSortable = usePageSortable({ sortableContainer, draggableFor: "flatplan" })
    let bp_pageNumbers = usePageNumbers()
    let bp_bulkPageSelect = useBulkPageSelect()
    let bp_viewSetting = useViewSetting()

    let { layoutClasses } = useFlatplanRowBreaks({
      domContainerId: "flatplan-for-resize-sensor__wrapper",
    })

    let bp_category = useCategory()
    let bp_tag = useTag()
    let bp_stitchIn = useStitchIn()

    let toggleSelectCover = (pageId) => {
      bp_bulkPageSelect.toggle(pageId)
    }

    let toggleSelectPage = (e) => {
      if (e.item) {
        bp_bulkPageSelect.toggle(e.item.dataset.pageId)
      } 
    }

    let pageHasNotes = (pageNo) => {
      if (bp_viewSetting.showNotes.value) {
        let page = bp_pageNumbers.pageByPageNo(pageNo)
        return !!page && !!page.notes
      } else {
        return false
      }
    }

    let showPageNotes = computed(() => bp_viewSetting.showNotes.value && bp_page.withNotes.value.length > 0)
    let showFlatplanNotes = computed(() => bp_viewSetting.showNotes.value && (bp_flatplan.notes.value || "").length > 0)

    onUnmounted(() => {
      bp_bulkPageSelect.clear()
    })

    return {
      isTouchDevice: window.__blinkplan_is_touch_device,
      sortableContainer,
      layoutClasses,
      bp_pageNumbers: reactive(bp_pageNumbers),
      coverPageId: bp_pageSortable.coverPageId,
      pageIds: bp_pageSortable.pageIds,
      bp_pageSortable,
      flatplanIssue: bp_flatplan.issue,
      refresh: computed(() => bp_category.colorHash.value + bp_tag.colorHash.value),
      showIssue: computed(() => !bp_viewSetting.showLeftDrawer.value),
      showTypeStats: bp_viewSetting.showTypeStats,
      showCategoryStats: bp_viewSetting.showCategoryStats,
      showTagStats: bp_viewSetting.showTagStats,
      toggleSelectPage,
      toggleSelectCover,
      stitchInIdForPageIndex: bp_stitchIn.idForPageIndex,
      coverSelected: computed(() => bp_bulkPageSelect.isSelected(bp_pageSortable.coverPageId.value)),
      pageHasNotes,
      showPageNotes,
      showFlatplanNotes,
    }
  },
}
</script>

<style lang="scss" scoped>
@import "@/styles/flatplan.scss";

.page__wrapper {
  height: calc(var(--bp-page-height) + #{$bp-page-affordance-height} + #{$bp-page-number-height}); // add spacing for affordance
}

.selected :deep(.affordance) {
  background-color: rgb(var(--v-theme-tertiary));
  color: rgb(var(--v-theme-on-tertiary));
}

.draggable--ghost {
  opacity: 0.4;
}

.draggable--swap :deep(.affordance) {
  background-color: rgb(var(--v-theme-quaternary));
  color: rgb(var(--v-theme-on-quaternary));
}
</style>
