<template>
  <v-row>
    <v-col cols="3" sm="2">
      <v-chip>{{ initials }}</v-chip>
    </v-col>
    <v-col cols="12" sm="10">
      <v-textarea :model-value="text" @update:modelValue="$emit('update:text', $event)" rows="2" auto-grow data-label="comment box" hide-details @focus="isActionVisible = true"></v-textarea>
      <div v-if="isActionVisible" class="d-flex mt-2">
        <v-btn v-if="!isNotifyVisible" variant="text" size="x-small" @click="isNotifyVisible = true" data-label="comment notify">Notify someone via email?</v-btn>
        <v-select v-else v-model="notify" :items="people" multiple chips hint="These people will receive an email" data-label="comment open notify" persistent-hint></v-select>
        <v-spacer></v-spacer>
        <v-btn color="secondary" @click="save" data-label="comment save">Comment</v-btn>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { ref, computed } from "vue"

import { useAuth } from "@/modules/auth/use"
import { useAccount } from "@/modules/account/use"
import { useComment } from "@/modules/comment/use"

export default {
  name: "CommentBox",
  props: {
    pageId: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: false,
      default: "",
    },
  },
  emits: ["update:text", "comment-added"],
  setup(props, context) {
    let bp_auth = useAuth()
    let bp_comment = useComment()
    let bp_account = useAccount()

    let isActionVisible = ref(false)
    let isNotifyVisible = ref(false)

    let notify = ref([])
    let people = computed(() => {
      return bp_account.people.value.map((p) => {
        return { title: p.name, value: p.nice_id }
      })
    })

    let name = bp_auth.name.value
    let initials = bp_auth.initials.value

    let resetForm = () => {
      isActionVisible.value = false
      isNotifyVisible.value = false
      notify.value = []
    }

    let save = () => {
      if (props.text.length > 0) {
        bp_comment.add({ pageId: props.pageId, commenter: name, initials, text: props.text, notifications: notify.value }).then(({ data }) => {
          resetForm()
          context.emit("comment-added", data)
        })
      }
    }

    return {
      initials,
      name,
      isActionVisible,
      isNotifyVisible,
      save,
      notify,
      people,
    }
  },
}
</script>

<style lang="scss" scoped>
.initials {
  width: 60px;
}
</style>
