<template>
  <base-dialog :model-value="true" @keydown.esc="closeDialog" size="small">
    <v-card-item>
      <v-card-title>
        <span class="text-h5" data-test-id="title">Add API key</span>
      </v-card-title>
    </v-card-item>
    <v-card-text v-if="showRawKey">
      <v-alert type="warning" class="mb-4">Please copy this key to a safe place — <strong>it won't be shown again!</strong></v-alert>
      <v-text-field
        label="API key"
        v-model="rawKey"
        autofocus
        :append-inner-icon="mdiContentCopy"
        @focus="handleFocus"
        @click:append-inner="copyToClipboard" />
    </v-card-text>  
    <v-card-text v-else>
      <p class="text-subtitle-1">Add a new API key to your Blinkplan account</p>
      <v-form ref="form" v-model="formValid" @submit.prevent="saveDialog" class="mt-4">
        <v-row>
          <v-col cols="12">
            <v-text-field label="Name" v-model="name" autofocus required :rules="nameRules" />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions v-if="showRawKey">
      <v-spacer></v-spacer>
      <submit-btn @click="closeDialog">Done</submit-btn>
    </v-card-actions>
    <v-card-actions v-else>
      <v-spacer></v-spacer>
      <cancel-btn @click="closeDialog">Close</cancel-btn>
      <submit-btn type="submit" @click="saveDialog">Add</submit-btn>
    </v-card-actions>
  </base-dialog>
</template>

<script>
import { ref } from "vue"
import { useRouter } from "vue-router"
import { mdiContentCopy } from "@mdi/js"

import { useApiKey } from "../use"
import { useNotification } from "@/modules/notification/use"

export default {
  name: "ApiKeyAddView",
  setup() {
    let router = useRouter()
    
    let bp_apiKey = useApiKey()
    let bp_notification = useNotification()

    const showRawKey = ref(false)
    let rawKey = ref("")

    const form = ref(null)
    let formValid = ref(false)
    let name = ref("")
    let nameRules = [(v) => !!v || "Name is required."]

    const handleFocus = (evt) => {
      evt.target.select()
    }

    const copyToClipboard = () => {
      navigator.clipboard.writeText(rawKey.value)
      .then(() => {
        bp_notification.show({
          type: "success",
          message: "API key copied to clipboard",
        })
      })
      .catch(err => {
        console.error('Failed to copy text:', err)
      })
    }

    const closeDialog = () => {
      router.push({ name: "api-keys" })
    }


    const saveDialog = () => {
      form.value.validate()

      if (formValid.value) {
        bp_apiKey
          .add({ name: name.value })
          .then((result) => {
            rawKey.value = result
            showRawKey.value = true

            bp_notification.show({
              type: "success",
              message: "API key added :)",
            })
          })
          .catch((err) => {
            // noop
            throw err
          })
      }
    }

    return {
      form,
      formValid,
      nameRules,
      closeDialog,
      saveDialog,
      name,
      showRawKey,
      rawKey,
      handleFocus,
      copyToClipboard,
      mdiContentCopy
    }
  },
}
</script>

<style lang="scss" scoped></style>
