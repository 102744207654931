<template>
  <base-dialog :model-value="open" @keydown.esc="closeDialog" size="medium">
    <v-card-item>
      <v-card-title>Bulk Upload</v-card-title>
      <v-card-subtitle>For pages {{ pageNos.join(", ") }}</v-card-subtitle>
    </v-card-item>
    <v-card-text>
      <v-row>
        <v-col cols="12" sm="6">
          Upload a single PDF containing multiple pages (thumbnails). Each page you have selected will be assigned a thumbnail from the PDF in order.
          <v-file-input
            accept="application/pdf"
            label="Upload thumbnails PDF"
            :loading="loading"
            :disabled="disabled"
            :rules="fileRules"
            prepend-icon=""
            :prepend-inner-icon="mdiImage"
            @update:modelValue="uploadFile"
            @click:clear="cancel"
            show-size
            class="mt-8"
            chips
          ></v-file-input>
        </v-col>
        <v-col cols="12" sm="6">
          <div class="d-flex flex-wrap">
            <div v-for="preview in previews" :key="preview.page">
              <PageThumbnail :thumbnail="preview" :clickable="false" size="sm" />
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <cancel-btn @click="closeDialog"> Cancel </cancel-btn>
      <submit-btn type="submit" @click="saveDialog" :disabled="loading">Apply</submit-btn>
    </v-card-actions>
  </base-dialog>
</template>

<script>
import { ref } from "vue"
import { mdiImage } from "@mdi/js"

import { usePage } from "../use"
import { usePageNumbers } from "@/modules/flatplan/usePageNumbers"
import { upload } from "@/utils/upload"
import { useNotification } from "@/modules/notification/use"
import { cdn } from "@/lib/cdn"

import PageThumbnail from "./PageThumbnail"

export default {
  name: "BulkUpload",
  props: {
    open: {
      type: Boolean,
      required: true,
      default: false,
    },
    pageIds: {
      type: Array,
      required: true,
      default: () => {
        return []
      },
    },
  },
  emits: ["update:open"],
  components: {
    PageThumbnail,
  },
  setup(props, context) {
    let bp_notification = useNotification()
    let bp_page = usePage()
    let bp_pageNumbers = usePageNumbers()
    let pages = JSON.parse(JSON.stringify(bp_page.getByIds(props.pageIds)))

    let pageNos = pages.map((p) => {
      return bp_pageNumbers.pageNoFor(p._id)
    })

    let previews = ref([])
    let fileRules = [(value) => !value || !value.length || value[0].size < 5000000 || "PDF size should be less than 5 MB"]
    let loading = ref(false)
    let disabled = ref(false)
    let uploadTask = null // so that we can cancel an upload task

    let assignThumbnails = (thumbnailKey, noOfPreviews) => {
      previews.value = []
      for (let pageIdx = 0; pageIdx < noOfPreviews; pageIdx++) {
        previews.value.splice(previews.value.length, 0, { key: thumbnailKey, page: pageIdx })
      }
    }

    assignThumbnails(null, props.pageIds.length)

    let cancel = () => {
      if (uploadTask) {
        uploadTask.cancel()
      }
    }

    let isFileValid = (files) => {
      let valid = true
      fileRules.forEach((rule) => {
        valid = valid && (rule(files) === true)
      })
      return valid
    }

    let uploadFile = async (file) => {
      // note: the html control used to send an array of files
      if (!!file) {
        if (isFileValid(file)) {

          // let file = files[0]

          loading.value = true
          disabled.value = true

          let uploader = upload(file)

          uploader
            .then((data) => {
              disabled.value = false // now that we have connected to Firebase etc, now there is a possibility to cancel upload

              if (data.uploadTask) {
                uploadTask = data.uploadTask

                uploadTask
                  .then(async (data) => {
                    if (data) {
                      let metadata = await cdn.metadata(data.metadata.fullPath)
                      assignThumbnails(data.metadata.fullPath, Math.min(metadata.pages ? metadata.pages : 0, props.pageIds.length))
                    }
                  })
                  .catch((err) => {
                    bp_notification.show({
                      type: "error",
                      message: err.message,
                    })
                  })
                  .finally(() => {
                    loading.value = false
                  })
              }
            })
            .catch(() => {
              loading.value = false
              disabled.value = false
            })
          }
      }
    }

    let closeDialog = () => {
      cancel()
      context.emit("update:open", false)
    }

    let saveDialog = () => {
      let pages = []
      bp_page.getByIds(props.pageIds).forEach((page, idx) => {
        if (idx < previews.value.length) {
          pages.push({ ...page, thumbnail: previews.value[idx] })
        }
      })

      bp_page.batchUpdate({ pages })

      closeDialog()
    }

    return {
      pageNos,
      previews,
      fileRules,
      uploadFile,
      cancel,
      loading,
      disabled,
      saveDialog,
      closeDialog,
      mdiImage,
    }
  },
}
</script>

<style lang="scss" scoped></style>
