<template>
  <div>
    <v-menu v-for="emailGroup in emailGroups" :key="emailGroup._id">
      <template v-slot:activator="{ props }">
        <v-btn v-bind="props" variant="text" color="primary" :prepend-icon="mdiAccountGroup">{{ emailGroup.name }}</v-btn>
      </template>
      <v-list>
        <v-list-item @click="pick(emailGroup)">
          <v-list-item-title>Pick emails</v-list-item-title>
        </v-list-item>
        <v-divider />
        <v-list-item @click="edit(emailGroup, $event)">
          <v-list-item-title>Edit</v-list-item-title>
        </v-list-item>
        <v-list-item @click="del(emailGroup)">
          <v-list-item-title>Delete</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-menu v-model="open" :close-on-content-click="false">
      <v-card>
        <v-form ref="form" v-model="formValid" @submit.prevent="saveDialog">
          <EmailGroupFormFields v-model:name="name" v-model:emails="emails" />
          <v-card-actions>
            <v-spacer></v-spacer>
            <cancel-btn @click="closeDialog"> Cancel </cancel-btn>
            <submit-btn type="submit">Save</submit-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { ref } from "vue"
import { mdiAccountGroup } from "@mdi/js"

import EmailGroupFormFields from "./EmailGroupFormFields"
import { useEmailGroup } from "@/modules/emailGroup/use"

export default {
  name: "EmailPicker",
  components: {
    EmailGroupFormFields,
  },
  emits: ["pick"],
  setup(props, context) {
    let bp_emailGroup = useEmailGroup()
    let currentEmailGroupId // very clumsy! Set when we choose edit, so that we can save ...

    let open = ref(false)
    const form = ref(null)
    let formValid = ref(false)
    let name = ref("")
    let emails = ref("")

    let pick = (emailGroup) => {
      context.emit("pick", bp_emailGroup.getById(emailGroup._id).emails)
    }

    let del = (emailGroup) => {
      bp_emailGroup.del({ emailGroupId: emailGroup._id })
    }

    let edit = (emailGroup, e) => {
      currentEmailGroupId = emailGroup._id

      name.value = emailGroup.name
      emails.value = emailGroup.emails

      open.value = true
    }

    let saveDialog = () => {
      if (formValid.value || formValid.value === null) { // HACK: first time the value might be null? Bug?
        bp_emailGroup.update({ emailGroupId: currentEmailGroupId, name: name.value, emails: emails.value })
        .then(() => {
          closeDialog()
        })
        .catch(() => {
          // noop
        })
      }
    }

    let closeDialog = () => {
      open.value = false
    }

    return {
      pick,
      del,
      edit,
      saveDialog,
      closeDialog,
      form,
      formValid,
      open,
      name,
      emails,
      emailGroups: bp_emailGroup.emailGroups,
      mdiAccountGroup,
    }
  },
}
</script>

<style lang="scss" scoped></style>
