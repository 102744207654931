import { createRouter, createWebHistory } from "vue-router"

import attachAuthGuards from "@/modules/auth/navGuards"
import attachPermitGuards from "@/modules/role/navGuards"
import attachBootstrapGuards from "@/modules/bootstrap/navGuards"

import EmptyRouterView from "@/views/EmptyRouterView.vue"
import VersionView from "@/views/VersionView.vue"
import TestErrorView from "@/views/TestErrorView.vue"
import NotEnoughPermissionsView from "@/views/NotEnoughPermissionsView.vue"

import NetworkIssue from "@/views/Error/NetworkIssue.vue"
import NotFound from "@/views/Error/NotFound.vue"
import ServerError from "@/views/Error/ServerError.vue"
import MaintenanceMode from "@/views/MaintenanceMode.vue"
import IncompatibleView from "@/views/IncompatibleView.vue"
import SetupDemoView from "@/modules/demo/components/SetupDemoView.vue"

import BillingView from "@/modules/billing/components/BillingView.vue"
import InvoicesView from "@/modules/billing/components/InvoicesView.vue"
import InvoiceView from "@/modules/billing/components/InvoiceView.vue"
import AccountEditView from "@/modules/account/components/AccountEditView.vue"
import AccountCancelView from "@/modules/billing/components/AccountCancelView.vue"

import PeopleView from "@/modules/person/components/PeopleView.vue"
import PersonView from "@/modules/person/components/PersonView.vue"
import InvitesView from "@/modules/invite/components/InvitesView.vue"
import InviteView from "@/modules/invite/components/InviteView.vue"
import AddInviteView from "@/modules/invite/components/AddInviteView.vue"
import InviteAcceptView from "@/modules/invite/components/InviteAcceptView.vue"

import FlatplanShowView from "@/modules/flatplan/components/FlatplanShowView.vue"
import FlatplanEditView from "@/modules/flatplan/components/FlatplanEditView.vue"
import FlatplanNewView from "@/modules/flatplan/components/FlatplanNewView.vue"
import FlatplanDeleteView from "@/modules/flatplan/components/FlatplanDeleteView.vue"
import FlatplanNotesView from "@/modules/flatplan/components/FlatplanNotesView.vue"
import FlatplanEmailView from "@/modules/flatplan/components/FlatplanEmailView.vue"

import EditPageView from "@/modules/page/components/EditPageView.vue"
import EditPageContent from "@/modules/page/components/EditPageContent.vue"
import EditPageNotes from "@/modules/page/components/EditPageNotes.vue"
import EditPageTags from "@/modules/page/components/EditPageTags.vue"
import EditPageComments from "@/modules/page/components/EditPageComments.vue"

import CategoriesShowView from "@/modules/category/components/CategoriesShowView.vue"
import EditCategoryView from "@/modules/category/components/EditCategoryView.vue"

import TagsShowView from "@/modules/tag/components/TagsShowView.vue"
import EditTagView from "@/modules/tag/components/EditTagView.vue"

import StitchInsShowView from "@/modules/stitchIn/components/StitchInsShowView.vue"
import EditStitchInView from "@/modules/stitchIn/components/EditStitchInView.vue"

import SectionsShowView from "@/modules/section/components/SectionsShowView.vue"

import ContentsShowView from "@/modules/content/components/ContentsShowView.vue"
import EditContentView from "@/modules/content/components/EditContentView.vue"
import ContentUploadFromFileView from "@/modules/content/components/ContentUploadFromFileView.vue"

import SummaryNotesView from "@/modules/summary/components/SummaryNotesView.vue"
import SummaryCommentsView from "@/modules/summary/components/SummaryCommentsView.vue"

import ApiKeysView from "@/modules/apiKey/components/ApiKeysView.vue"
import ApiKeyAddView from "@/modules/apiKey/components/ApiKeyAddView.vue"
import ApiKeyView from "@/modules/apiKey/components/ApiKeyView.vue"

import ShareView from "@/modules/share/components/ShareView.vue"
import PdfView from "@/modules/share/components/PdfView.vue"

import SignupView from "@/modules/auth/components/SignupView.vue"
import LoginView from "@/modules/auth/components/LoginView.vue"
import ForgotPasswordView from "@/modules/auth/components/ForgotPasswordView.vue"
import ResetPasswordView from "@/modules/auth/components/ResetPasswordView.vue"
import ProfileView from "@/modules/auth/components/ProfileView.vue"
import ImpersonateView from "@/modules/auth/components/ImpersonateView.vue"

import { useFlatplanStore } from "@/modules/flatplan/store"

const routes = [
  {
    path: "/",
    name: "home",
    redirect: { name: "editor" },
  },
  {
    path: "/demo",
    name: "setup-demo",
    component: SetupDemoView,
    props: true,
    meta: {
      layout: "plain",
    },
  },
  {
    path: encodeURI("/🐶/editor"),
    name: "editor",
    component: EmptyRouterView, // use a dummy component here - even though we redirect from here - else it fails
    meta: {
      layout: "editor",
      requiresAuth: true,
      bootstrapApp: true,
      bootstrapFlatplan: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.name === "editor") {
        // This route doesn't have the flatplanId params.
        // The bootstrapFlatplan (which would have already run by now (bootstrapFlatplan: true)) would have found or created a flatplanId.
        // Let's update the URL to reflect
        // the found flatplanId
        const flatplanStore = useFlatplanStore()

        if (flatplanStore.current._id) {
          router.push({
            name: "flatplan-show",
            params: {
              flatplanId: flatplanStore.current._id,
            },
          })  
        } else {
          console.log("No current flatplanId found for route change")
        }
      } else {
        next()
      }
    },
    children: [
      {
        path: ":flatplanId/categories",
        name: "categories-show",
        props: { default: true },
        meta: {
          toolbar: "category",
        },
        components: {
          default: CategoriesShowView,
        },
        children: [
          {
            path: ":categoryId",
            name: "category-edit",
            props: { default: true, dialog: true },
            components: {
              default: CategoriesShowView,
              dialog: EditCategoryView,
            },
          },
        ],
      },
      {
        path: ":flatplanId/tags",
        name: "tags-show",
        props: { default: true },
        meta: {
          toolbar: "tag",
        },
        components: {
          default: TagsShowView,
        },
        children: [
          {
            path: ":tagId",
            name: "tag-edit",
            props: { default: true, dialog: true },
            components: {
              default: TagsShowView,
              dialog: EditTagView,
            },
          },
        ],
      },
      {
        path: ":flatplanId/sections",
        name: "sections-show",
        props: { default: true },
        meta: {
          toolbar: "section",
        },
        components: {
          default: SectionsShowView,
        },
      },
      {
        path: ":flatplanId/stitchIns",
        name: "stitchIns-show",
        props: { default: true },
        meta: {
          toolbar: "stitchIn",
        },
        components: {
          default: StitchInsShowView,
        },
        children: [
          {
            path: ":stitchInId",
            name: "stitchIn-edit",
            props: { dialog: true },
            components: {
              default: StitchInsShowView,
              dialog: EditStitchInView,
            },
          },
        ],
      },
      {
        path: ":flatplanId/contents/uploadFromFile",
        name: "content-upload-from-file",
        props: { default: true },
        meta: {
          toolbar: "content-upload",
        },
        components: {
          default: ContentUploadFromFileView,
        },
      },
      {
        path: ":flatplanId/contents",
        name: "contents-show",
        props: { default: true },
        meta: {
          toolbar: "content",
        },
        components: {
          default: ContentsShowView,
        },
        children: [
          {
            path: ":contentId",
            name: "content-edit",
            props: { dialog: true },
            components: {
              default: ContentsShowView,
              dialog: EditContentView,
            },
          },
        ],
      },
      {
        path: ":flatplanId/notes",
        name: "flatplan-notes",
        props: { default: true },
        meta: {
          toolbar: "notes",
        },
        components: {
          default: FlatplanNotesView,
        },
      },
      {
        path: ":flatplanId/summary/notes",
        name: "summary-notes",
        props: { default: true },
        meta: {
          toolbar: "summary",
        },
        components: {
          default: SummaryNotesView,
        },
      },
      {
        path: ":flatplanId/summary/comments",
        name: "summary-comments",
        props: { default: true },
        meta: {
          toolbar: "summary",
        },
        components: {
          default: SummaryCommentsView,
        },
      },
      {
        path: ":flatplanId",
        name: "flatplan-show",
        props: { default: true },
        meta: {
          toolbar: "flatplan",
          hasHoldingArea: true,
        },
        components: {
          default: FlatplanShowView,
        },
        children: [
          {
            path: "edit",
            name: "flatplan-edit",
            props: { default: true, dialog: true },
            components: {
              default: FlatplanShowView,
              dialog: FlatplanEditView,
            },
          },
          {
            path: "new",
            name: "flatplan-new",
            props: { default: true, dialog: true },
            components: {
              default: FlatplanShowView,
              dialog: FlatplanNewView,
            },
          },
          {
            path: "email",
            name: "flatplan-email",
            props: { default: true, dialog: true },
            components: {
              default: FlatplanShowView,
              dialog: FlatplanEmailView,
            },
          },
          {
            path: "delete",
            name: "flatplan-delete",
            props: { default: true, dialog: true },
            components: {
              default: FlatplanShowView,
              dialog: FlatplanDeleteView,
            },
          },
          {
            path: ":pageId",
            props: { default: true, dialog: true },
            components: {
              default: FlatplanShowView,
              dialog: EditPageView,
            },
            children: [
              {
                path: "content",
                name: "page-edit-content",
                props: { default: true, dialog: true, tab: true },
                components: {
                  default: FlatplanShowView,
                  dialog: EditPageView,
                  tab: EditPageContent,
                },
              },
              {
                path: "notes",
                name: "page-edit-notes",
                props: { default: true, dialog: true, tab: true },
                components: {
                  default: FlatplanShowView,
                  dialog: EditPageView,
                  tab: EditPageNotes,
                },
              },
              {
                path: "tags",
                name: "page-edit-tags",
                props: { default: true, dialog: true, tab: true },
                components: {
                  default: FlatplanShowView,
                  dialog: EditPageView,
                  tab: EditPageTags,
                },
              },
              {
                path: "comments",
                name: "page-edit-comments",
                props: { default: true, dialog: true, tab: true },
                components: {
                  default: FlatplanShowView,
                  dialog: EditPageView,
                  tab: EditPageComments,
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: "/share/:accountId/:flatplanId",
    name: "share",
    component: ShareView,
    meta: {
      layout: "share",
      bootstrapShare: true,
      bootstrapFlatplan: true,
    },
  },
  {
    path: "/pdf/:accountId/:flatplanId",
    name: "pdf",
    component: PdfView,
    meta: {
      layout: "pdf",
      bootstrapShare: true,
      bootstrapFlatplan: true,
    },
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
    props: (route) => route.query,
    meta: {
      layout: "auth",
    },
  },
  {
    path: "/signup",
    name: "signup",
    component: SignupView,
    props: true,
    meta: {
      layout: "auth",
    },
  },
  {
    path: "/invite/:token",
    name: "invite-accept",
    component: InviteAcceptView,
    props: true,
    meta: {
      layout: "auth",
    },
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: ForgotPasswordView,
    props: (route) => route.query,
    meta: {
      layout: "auth",
    },
  },
  {
    path: "/reset-password",
    name: "reset-password",
    component: ResetPasswordView,
    props: (route) => route.query,
    meta: {
      layout: "auth",
    },
  },
  {
    path: "/impersonate",
    name: "impersonate",
    props: (route) => route.query,
    component: ImpersonateView,
    meta: {
      layout: "auth",
      requiresAuth: true,
      requiresAdm2n: true,
    },
  },
  {
    path: "/profile",
    name: "profile",
    component: ProfileView,
    meta: {
      requiresAuth: true,
      bootstrapApp: true,
      layout: "profile",
      toolbar: "profile",
    },
  },
  {
    path: "/billing", // Note: this needs to correspond to the API's Stripe success_url
    name: "billing",
    component: BillingView,
    props: true,
    meta: {
      layout: "account",
      requiresAuth: true,
      bootstrapApp: true,
      bootstrapAccount: true,
      restrictTo: ["billing"],
      toolbar: "billing",
    },
  },
  {
    path: "/invoices",
    name: "invoices",
    component: InvoicesView,
    props: true,
    meta: {
      layout: "account",
      requiresAuth: true,
      bootstrapApp: true,
      bootstrapAccount: true,
      restrictTo: ["billing"],
      toolbar: "billing",
    },
  },
  {
    path: "/invoices/:code",
    name: "invoice-show",
    component: InvoiceView,
    props: true,
    meta: {
      layout: "account",
      requiresAuth: true,
      bootstrapApp: true,
      bootstrapAccount: true,
      restrictTo: ["billing"],
      toolbar: "billing",
    },
  },
  {
    path: "/people",
    name: "people",
    component: PeopleView,
    props: true,
    meta: {
      layout: "account",
      requiresAuth: true,
      bootstrapApp: true,
      bootstrapAccount: true,
      toolbar: "people",
    },
  },
  {
    path: "/people/:niceId",
    name: "person-show",
    component: PersonView,
    props: true,
    meta: {
      layout: "account",
      requiresAuth: true,
      bootstrapApp: true,
      bootstrapAccount: true,
      toolbar: "person",
    },
  },
  {
    path: "/invites",
    name: "invites",
    component: InvitesView,
    props: true,
    meta: {
      layout: "account",
      requiresAuth: true,
      bootstrapApp: true,
      bootstrapAccount: true,
      toolbar: "invites",
    },
    children: [
      {
        path: "add",
        name: "invite-add",
        props: { default: true, dialog: true },
        components: {
          default: InvitesView,
          dialog: AddInviteView,
        },
      },
    ],
  },
  {
    path: "/invites/:token",
    name: "invite-show",
    component: InviteView,
    props: true,
    meta: {
      layout: "account",
      requiresAuth: true,
      bootstrapApp: true,
      bootstrapAccount: true,
      toolbar: "invite",
    },
  },
  {
    path: "/settings",
    name: "settings",
    props: { default: true },
    component: AccountEditView,
    meta: {
      layout: "account",
      requiresAuth: true,
      bootstrapApp: true,
      bootstrapAccount: true,
      restrictTo: ["admin", "billing"],
      toolbar: "billing",
    },
  },
  {
    path: "/api-keys",
    name: "api-keys",
    props: true,
    component: ApiKeysView,
    meta: {
      layout: "account",
      requiresAuth: true,
      bootstrapApp: true,
      bootstrapAccount: true,
      restrictTo: ["admin"],
      toolbar: "apiKeys",
    },
    children: [
      {
        path: "add",
        name: "api-key-add",
        props: { default: true, dialog: true },
        components: {
          default: ApiKeysView,
          dialog: ApiKeyAddView,
        },
      },
    ],
  },
  {
    path: "/api-keys/:key_hash",
    name: "api-key-show",
    props: true,
    component: ApiKeyView,
    meta: {
      layout: "account",
      requiresAuth: true,
      bootstrapApp: true,
      bootstrapAccount: true,
      restrictTo: ["admin"],
      toolbar: "apiKey",
    }
  },
  {
    path: "/cancel",
    name: "account-cancel",
    component: AccountCancelView,
    props: true,
    meta: {
      layout: "account",
      requiresAuth: true,
      bootstrapApp: true,
      bootstrapAccount: true,
      restrictTo: ["admin", "billing"],
      toolbar: "billing",
    },
  },
  {
    path: "/not-found",
    name: "404",
    component: NotFound,
    props: true,
    meta: {
      layout: "plain",
    },
  },
  {
    path: "/server-error",
    name: "500",
    component: ServerError,
    meta: {
      layout: "plain",
    },
  },
  {
    path: "/network-issue",
    name: "network-issue",
    component: NetworkIssue,
    props: (route) => route.query,
    meta: {
      layout: "plain",
    },
  },
  {
    path: encodeURI("/🐒/maintenance"),
    name: "maintenance-mode",
    component: MaintenanceMode,
    meta: {
      layout: "plain",
    },
  },
  {
    path: "/not-enough-permissions",
    name: "not-enough-permissions",
    component: NotEnoughPermissionsView,
    meta: {
      layout: "plain",
    },
  },
  {
    path: "/v",
    name: "version",
    component: VersionView,
    meta: {
      layout: "plain",
    },
  },
  {
    path: "/incompatible",
    name: "incompatible",
    component: IncompatibleView,
    meta: {
      layout: "plain",
    },
  },
  {
    path: "/err",
    name: "test-error",
    component: TestErrorView,
    meta: {
      layout: "plain",
    },
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: { name: "404", params: { resource: "page" } },
  },
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
})

// order is important
attachAuthGuards(router)
attachBootstrapGuards(router)
attachPermitGuards(router)

export default router
