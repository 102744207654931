// AuthService
import axios from "axios"

const baseURL = "//" + import.meta.env.VITE_ACCOUNT_ENDPOINT

export default {
  signup({ accountName, name, email, password }) {
    return axios.post(`${baseURL}/signup`, { user: { name, email, password }, account: { name: accountName } })
  },
  login(credentials) {
    return axios.post(`${baseURL}/login`, { user: credentials })
  },
  refreshToken(refresh_token) {
    return axios.post(`${baseURL}/token`, { refresh_token })
  },
  forgotPassword({ email }) {
    return axios.post(`${baseURL}/password`, { user: { email } })
  },
  resetPassword({ password, reset_password_token }) {
    return axios.put(`${baseURL}/password`, {
      user: { password, reset_password_token },
    })
  },
  update(credentials) {
    return axios.put(`${baseURL}/profile`, { user: credentials })
  },
  impersonate(email) {
    return axios.post(`${baseURL}/impersonate`, email)
  },
}
