import { ref } from "vue"
import { defineStore } from "pinia"

import DataService from "@/services/DataService.js"

import { useFlatplanStore } from "@/modules/flatplan/store"
import { usePageStore } from "@/modules/page/store"
import { useCategoryStore } from "@/modules/category/store"
import { useTagStore } from "@/modules/tag/store"
import { useSectionStore } from "@/modules/section/store"
import { useStitchInStore } from "@/modules/stitchIn/store"
import { useEmailGroupStore } from "@/modules/emailGroup/store"
import { useContentStore } from "@/modules/content/store"
import { usePage } from "../../page/use"

export const useBootstrapFlatplanStore = defineStore("bootstrapFlatplan", () => {
  const loadState = ref("ready")

  function lastFlatplanId(accountId) {
    let lastFlatplans = localStorage.getItem("lastFlatplans")
    lastFlatplans = lastFlatplans ? JSON.parse(lastFlatplans) : {}
    return lastFlatplans[accountId]
  }

  function fetch({ accountId, flatplanId }) {
    if (loadState.value === "doneInitializingData") {
      loadState.value = "fetchingAfterInitializing"
    } else {
      loadState.value = "fetching"
    }
    return DataService.bootstrap({ accountId, flatplanId })
      .then(({ data }) => {
        const currentData = data.flatplans.current
        const othersData = data.flatplans.others
        const archivedData = data.flatplans.archived

        if (!currentData) {
          if (loadState.value === "fetchingAfterInitializing") {
            throw "No flatplans were found for this account."
          } else {
            loadState.value = "noFlatplan"
            return null
          }
        }

        const flatplanStore = useFlatplanStore()
        const pageStore = usePageStore()
        const categoryStore = useCategoryStore()
        const tagStore = useTagStore()
        const sectionStore = useSectionStore()
        const stitchInStore = useStitchInStore()
        const emailGroupStore = useEmailGroupStore()
        const contentStore = useContentStore()

        flatplanStore.bootstrap({ currentData, othersData, archivedData })
        pageStore.bootstrap(data.pages)
        categoryStore.bootstrap(data.categories)
        tagStore.bootstrap(data.tags)
        sectionStore.bootstrap(data.sections)
        stitchInStore.bootstrap(data.stitchIns)
        emailGroupStore.bootstrap(data.emailGroups)
        contentStore.bootstrap(data.contents)

        const defaultsData = data.defaults

        if (defaultsData) {
          flatplanStore.bootstrapDefaults({ defaultsData })
        }

        loadState.value = "done"

        rememberFlatpan({ accountId, flatplanId: currentData._id })

        return data
      })
      .catch((err) => {
        console.error(err)
        loadState.value = "ready"
        throw(err)
      })
  }

  function initFlatplan({ accountId }) {
    loadState.value = "initializingData"
    return DataService.initBootstrap({ accountId })
      .then(({ data }) => {
        loadState.value = "doneInitializingData"
        return null
      })
      .catch((err) => {
        console.error(err)
        throw(err)
      })
  }

  // Use this one as the main init function - it takes care of the flow if we need to init data first
  function init({ accountId, flatplanId }) {
    if (loadState.value === "done") {
      return Promise.resolve()
    } else {
      // not sure why, but this.fetch is needed here for the tests when we stub fetch
      return this.fetch({ accountId, flatplanId }).then((data) => {
        if (data === null) {
          return initFlatplan({ accountId }).then(() => {
            return fetch({ accountId, flatplanId })
          })
        } else {
          return data
        }
      })
    }
  }

  function forgetLastFlatplan({ accountId }) {
    if (accountId) {
      let lastFlatplans = localStorage.getItem("lastFlatplans")
      lastFlatplans = lastFlatplans ? JSON.parse(lastFlatplans) : {}

      delete lastFlatplans[accountId]

      localStorage.setItem("lastFlatplans", JSON.stringify(lastFlatplans))
    }
  }

  function rememberFlatpan({ accountId, flatplanId }) {
    if (accountId) {
      let lastFlatplans = localStorage.getItem("lastFlatplans")
      lastFlatplans = lastFlatplans ? JSON.parse(lastFlatplans) : {}
      lastFlatplans = {
        ...lastFlatplans,
        [accountId]: flatplanId,
      }
      localStorage.setItem("lastFlatplans", JSON.stringify(lastFlatplans))
    }
  }

  return {
    loadState,
    lastFlatplanId,
    fetch,
    initFlatplan,
    init,
    forgetLastFlatplan,
  }
})
