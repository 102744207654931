<template>
  <v-card color="background" :width="isCoverPage ? '220' : '205'">
    <v-form ref="form" v-model="formValid" @submit.prevent="saveDialog">
      <v-card-text>
        <div v-if="isCoverPage">
          <v-radio-group v-model="displayCoverPageNumbering" dense>
            <v-radio key="cover" label="Call this page 'Cover'" :value="true"></v-radio>
            <v-radio key="start" label="Start with page #" :value="false"></v-radio>
            <v-text-field label="Start with page #" type="number" v-model="startWith" data-label="page numbering textbox" autofocus @focus="$event.target.select()" :disabled="displayCoverPageNumbering" />
          </v-radio-group>
        </div>
        <div v-else>
          <div v-if="ableToAdjust">
            <v-text-field label="Start with page #" type="number" v-model="startWith" data-label="page numbering textbox" autofocus @focus="$event.target.select()" required />
          </div>
          <div v-else>
            <p>
              Want to assign a number to page <strong>{{ currentPageNo }}</strong
              >?
            </p>
            <p>You will first need to remove <i>cover page numbering</i> from the <strong>Cover Page</strong>.</p>
          </div>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <cancel-btn @click="closeDialog">Cancel</cancel-btn>
        <submit-btn type="submit">Save</submit-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import { ref, computed } from "vue"

import { useFlatplan } from "@/modules/flatplan/use"
import { usePageNumbers } from "@/modules/flatplan/usePageNumbers"
import { usePage } from "@/modules/page/use"

export default {
  name: "PageChangeNumbering",
  props: {
    pageId: {
      type: String,
      required: true,
    },
    open: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:open"],
  setup(props, context) {
    let bp_page = usePage()
    let bp_flatplan = useFlatplan()
    let bp_pageNumbers = usePageNumbers()

    let form = ref(null)
    let formValid = ref(false)

    let displayCoverPageNumbering = ref(bp_flatplan.displayCoverPageNumbering.value)

    let pagePos = bp_page.onFlatplan.value.map((p) => p._id).indexOf(props.pageId)

    let currentPageNo = ref(bp_pageNumbers.pageNoFor(props.pageId))
    let startWith = ref(currentPageNo.value === "Cover" ? 1 : currentPageNo.value)

    let isCoverPage = computed(() => {
      return pagePos === 0
    })

    let ableToAdjust = computed(() => {
      return !["IFC", "IBC", "Back"].includes(currentPageNo.value)
    })

    let getPageNumberings = () => {
      let pageNumberings = bp_flatplan.pageNumberings.value

      if (displayCoverPageNumbering.value) {
        pageNumberings = pageNumberings.filter((p) => +p.pagePos !== 0)
      }

      pageNumberings = pageNumberings.filter((p) => +p.pagePos < pagePos)
      pageNumberings.push({ pagePos, startWith: parseInt(startWith.value) })
      return pageNumberings
    }

    let closeDialog = () => {
      context.emit("update:open", false)
    }

    let saveDialog = () => {
      form.value.validate()

      if (formValid.value) {
        let payload = {}

        if (isCoverPage.value) {
          if (!displayCoverPageNumbering.value) {
            payload.pageNumberings = getPageNumberings()
          }
          payload.displayCoverPageNumbering = displayCoverPageNumbering.value
        } else {
          payload.pageNumberings = getPageNumberings()
        }

        bp_flatplan.updatePageNumberings({ ...payload })
        closeDialog()
      }
    }

    return {
      form,
      formValid,
      isCoverPage,
      displayCoverPageNumbering,
      startWith,
      saveDialog,
      closeDialog,
      ableToAdjust,
      currentPageNo,
    }
  },
}
</script>

<style lang="scss" scoped></style>
