<template>
  <v-row justify="center">
    <v-col cols="12" sm="6" lg="3">
      <div v-if="acceptedAt" style="text-align: center">
        You have already accepted the invitation.<br />
        You did so on {{ fmtDateTime(acceptedAt) }}
      </div>
      <div v-else>
        <h4 class="text-h4">Let's get you setup</h4>

        <v-alert class="mt-4 mb-4" v-if="existing">We see you already have an account. <strong>Please login to accept.</strong></v-alert>
        <v-form class="mt-6" ref="form" v-model="formValid" @submit.prevent="accept">
          <v-text-field v-if="!existing" label="Your name" type="text" v-model="name" :rules="nameRules" data-test-id="name" required />
          <v-text-field label="Email" type="email" v-model="email" data-test-id="email" :rules="emailRules" hint="You can use another email address if you like" persistent-hint required />
          <v-text-field
            label="Password"
            :type="showPassword ? 'text' : 'password'"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPassword = !showPassword"
            v-model="password"
            :rules="passwordRules"
            data-test-id="password"
            counter="6"
            required
          />
          <v-alert :model-value="bp_auth.httpStatus === 401" type="error">
            <ul>
              <li v-for="(error, index) in bp_auth.errors" :key="`error-${index}`">
                {{ error }}
              </li>
            </ul>
          </v-alert>
          <submit-btn type="submit" data-test-id="submit" block :loading="bp_auth.loading"> Accept </submit-btn>
        </v-form>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { ref, reactive, toRefs, onMounted } from "vue"

import { DateTime } from "luxon"
import { useAuth } from "@/modules/auth/use"
import { useInvite } from "@/modules/invite/use"
import { useNotification } from "@/modules/notification/use"

export default {
  name: "InviteAcceptView",
  props: {
    token: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const bp_auth = useAuth()
    const bp_invite = useInvite()
    const bp_notification = useNotification()

    const existing = ref(false)
    const acceptedAt = ref(null)

    const form = ref(null)

    const state = reactive({
      email: "",
      emailRules: [
        (v) => !!v || "Email is required.",
        (v) => v.indexOf("@") !== 0 || "Email should have a username.",
        (v) => v.includes("@") || "Email should include an @ symbol.",
        (v) => v.includes(".") || "Email should include a period symbol.",
        (v) => v.indexOf(".") <= v.length - 3 || "Email should contain a valid domain extension.",
      ],
      password: "",
      passwordRules: [(v) => !!v || "Password is required.", (v) => v.length >= 6 || "Password should be at least six long."],
      name: "",
      nameRules: [(v) => !!v || v.length === 0 || "Name is required."],
      formValid: false,
      showPassword: false,
    })

    const accept = () => {
      form.value.validate()
      if (state.formValid || state.formValid === null) {
        bp_invite
          .accept({
            token: props.token,
            name: state.name,
            email: state.email,
            password: state.password,
          })
          .then(() => {
            location.href = "/"
          })
          .catch((err) => {
            if (err.response.status === 401) {
              bp_notification.show({
                type: "error",
                message: "Incorrect password ... :(",
              })
            }
          })
      } else {
        return false
      }
    }

    let fmtDateTime = (date) => {
      return DateTime.fromISO(date).toFormat("DD, t")
    }

    onMounted(() => {
      bp_invite.fetch({ token: props.token }).then(({ data }) => {
        existing.value = !!data.recipient
        acceptedAt.value = data.accepted_at
        state.email = data.email
      })
    })

    return {
      ...toRefs(state),
      bp_auth: reactive(bp_auth),
      form,
      accept,
      acceptedAt,
      existing,
      fmtDateTime,
    }
  },
}
</script>
