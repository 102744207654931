import { computed } from "vue"
import { useType } from "@/modules/type/use"
import { usePage } from "@/modules/page/use"
import { fragmentSize } from "@/modules/page/render/fragmentUtils"

const pageTypeSize = (page, type) => {
  return (
    page.fragments
      .filter((fragment) => (fragment.type || "editorial") === type)
      .reduce((acc, fragment) => {
        return acc + fragmentSize(fragment.shape)
      }, 0) || 0
  )
}

const pagesTypeSize = (pages, type) => {
  return (
    pages.reduce((acc, curr) => {
      return acc + pageTypeSize(curr, type)
    }, 0) || 0
  )
}

export const useStats = () => {
  let bp_type = useType()
  let bp_page = usePage()

  let items = computed(() => {
    let collect = []

    let pages = bp_page.pages.value.filter((p) => !p.isSpace)
    let totalPages = pages.length || 1

    bp_type.types.forEach((type) => {
      let fraction = pagesTypeSize(pages, type)
      let percentage = fraction / totalPages

      collect.push({
        type,
        fraction,
        percentage,
      })
    })
    return collect
  })

  const detailsFor = (type) => {
    return items.value.find((item) => item.type === type)
  }

  return {
    detailsFor,
  }
}
