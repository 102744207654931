<template>
  <base-dialog v-if="stitchInExists" :model-value="true" @keydown.esc="closeDialog" size="small">
    <v-form @submit.prevent="saveDialog">
      <v-card-item>
        <v-card-title>
          <span class="text-h5" data-test-id="title">{{ mode === "add" ? "Add" : "Edit" }} Stitch-In</span>
        </v-card-title>
      </v-card-item>
      <v-card-text>
        <v-autocomplete class="ml-2" v-model="pageNumber" :items="pageNumbers" placeholder="page" data-label="stitchIn pageNo textbox" autofocus />
        <v-textarea label="Front cover content" v-model="frontContent" rows="2" data-test-id="frontContent" />
        <v-textarea label="Back cover content" v-model="backContent" rows="2" data-test-id="backContent" />
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <cancel-btn @click="closeDialog">Close</cancel-btn>
        <submit-btn type="submit">Save</submit-btn>
      </v-card-actions>
    </v-form>
  </base-dialog>
</template>

<script>
import { ref } from "vue"
import { useRouter } from "vue-router"

import { useStitchIn } from "../use"
import { usePageNumbers } from "@/modules/flatplan/usePageNumbers"
import { usePageSetting } from "@/modules/pageSetting/use"
import { useNotification } from "@/modules/notification/use"

export default {
  name: "EditStitchInView",
  props: {
    stitchInId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    let router = useRouter()
    
    let bp_stitchIn = useStitchIn()
    let bp_pageNumbers = usePageNumbers()
    let bp_pageSetting = usePageSetting()

    let mode = props.stitchInId === "new" ? "add" : "edit"

    let pageNumbers = bp_pageNumbers.all.value.slice(bp_pageSetting.singlePages.value ? 0 : 1)

    let pageNumber = ref(null)
    let frontContent = ref(null)
    let backContent = ref(null)

    if (mode === "edit") {
      let stitchIn = bp_stitchIn.getById(props.stitchInId)

      if (stitchIn === undefined || stitchIn === null) {
        // we may have lost it via LiveUpdate, and we do a browser refresh?
        let bp_notification = useNotification()
        bp_notification.show({
          type: "error",
          message: "That stitch-in no longer exists",
        })
        router.push({ name: "stitchIns-show" })
        return {
          stitchInExists: false,
        }
      }

      pageNumber.value = bp_pageNumbers.all.value[stitchIn.pageNumber] // stitchIn.pageNumber here is really a page number index
      frontContent.value = stitchIn.frontContent
      backContent.value = stitchIn.backContent
    }

    const closeDialog = () => {
      router.push({ name: "stitchIns-show" })
    }

    const saveDialog = () => {
      if (mode === "edit") {
        bp_stitchIn
          .update({ stitchInId: props.stitchInId, pageNumber: bp_pageNumbers.all.value.indexOf(pageNumber.value), frontContent: frontContent.value, backContent: backContent.value })
          .then(() => {
            closeDialog()
          })
          .catch((err) => {
            // noop
            throw err
          })
      } else {
        bp_stitchIn
          .add({ pageNumber: bp_pageNumbers.all.value.indexOf(pageNumber.value), frontContent: frontContent.value, backContent: backContent.value })
          .then(() => {
            closeDialog()
          })
          .catch((err) => {
            // noop
            throw err
          })
      }
    }

    return {
      stitchInExists: true,
      closeDialog,
      saveDialog,
      pageNumbers,
      pageNumber,
      frontContent,
      backContent,
      mode,
    }
  },
}
</script>

<style lang="scss" scoped></style>
