<template>
  <transition-group tag="div" name="expand">
    <Comment v-for="comment in comments" :key="comment._id" :comment="comment" />
  </transition-group>
</template>

<script>
import { ref, onMounted } from "vue"

import { decode } from "@/utils/general"
import { useComment } from "@/modules/comment/use"

import Comment from "./Comment"

export default {
  name: "SummaryPageCommentsView",
  props: {
    pageId: {
      type: String,
      required: true,
    },
  },
  components: {
    Comment
  },
  setup(props) {

    let bp_comment = useComment()
    
    let comments = ref([])

    let decodedComment = (comment) => {
      return decode(comment.text)
    }

    onMounted(() => {
      bp_comment.fetchComments({ pageId: props.pageId }).then((response) => {
        comments.value = response.data
      })
    })

    return {
      decodedComment,
      comments,
    }
  },
}
</script>

<style lang="scss" scoped></style>
