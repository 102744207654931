import axios from "axios"

const url = (thumbnail, { height, width }) => {
  if (!!thumbnail && !!thumbnail.key) {
    // we can also use "localhost:5000/cdn" (or import.meta.env.VITE_THUMBNAIL_CDN_ENDPOINT) if we are using an emulator
    let opts = {}

    if (height !== undefined) {
      opts.h = height
    }
    if (width !== undefined) {
      opts.w = width
    }
    if (thumbnail.page !== undefined) {
      opts.pg = thumbnail.page
    }

    return "//" + import.meta.env.VITE_THUMBNAIL_CDN_ENDPOINT + "/" + encodeURIComponent(thumbnail.key) + `?${new URLSearchParams(opts)}`
  } else {
    return null
  }
}

const metadata = async (thumbnailKey) => {
  if (thumbnailKey) {
    return axios
      .get("//" + import.meta.env.VITE_THUMBNAIL_CDN_ENDPOINT + "/" + encodeURIComponent(thumbnailKey) + "?fm=json")
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        console.log(error)
        return {}
      })
  } else {
    return {}
  }
}

export const cdn = {
  url,
  metadata,
}
