<template>
  <base-dialog :model-value="true" @keydown.esc="closeDialog" size="small">
    <v-card-item>
      <v-card-title>
        <span class="text-h5" data-test-id="title">Send Invite</span>
      </v-card-title>
    </v-card-item>
    <v-card-text>
      <p class="text-subtitle-1">Invite a new person to your Blinkplan account</p>
      <p class="text-caption">This person will have <strong>full edit rights</strong> just like you - except they won't have superpowers.</p>
      <v-form ref="form" v-model="formValid" @submit.prevent="saveDialog" class="mt-4">
        <v-row>
          <v-col cols="12">
            <v-text-field label="Email" v-model="email" autofocus required :rules="emailRules" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <h6 class="text-subtitle-1">Which superpowers, if any, should they have?</h6>
            <v-checkbox v-model="adminSuperPower" label="Administrator" hide-details="false" dense :disabled="!permitAdmin"></v-checkbox>
            <p class="text-caption">Admins can remove users from your Blinkplan account, and grant admin powers to other people. We recommend only granting admin powers to people you really trust.</p>
            <v-checkbox v-model="billingSuperPower" label="Billing" hide-details="false" dense :disabled="!permitAdmin"></v-checkbox>
            <p class="text-caption">Billing liaisons can update billing information and view past invoices.</p>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <cancel-btn @click="closeDialog">Close</cancel-btn>
      <submit-btn type="submit" @click="saveDialog">Send the invite</submit-btn>
    </v-card-actions>
  </base-dialog>
</template>

<script>
import { ref } from "vue"
import { useRouter } from "vue-router"

import { useInvite } from "../use"
import { useNotification } from "@/modules/notification/use"
import { useContext } from "@/use/context"

export default {
  name: "AddInviteView",
  setup() {
    let router = useRouter()
    
    let bp_invite = useInvite()
    let bp_notification = useNotification()
    let bp_context = useContext()

    const form = ref(null)
    let formValid = ref(false)
    let email = ref("")
    let emailRules = [
      (v) => !!v || "Email is required.",
      (v) => v.indexOf("@") !== 0 || "Email should have a username.",
      (v) => v.includes("@") || "Email should include an @ symbol.",
      (v) => v.includes(".") || "Email should include a period symbol.",
      (v) => v.indexOf(".") <= v.length - 3 || "Email should contain a valid domain extension.",
    ]
    let adminSuperPower = ref(false)
    let billingSuperPower = ref(false)

    const closeDialog = () => {
      router.push({ name: "invites" })
    }

    const collectSuperPowers = () => {
      let roles = []
      if (adminSuperPower.value) {
        roles.push("admin")
      }
      if (billingSuperPower.value) {
        roles.push("billing")
      }
      return roles
    }

    const saveDialog = () => {
      form.value.validate()

      if (formValid.value) {
        bp_invite
          .add({ email: email.value, roles: collectSuperPowers().join(",") })
          .then(() => {
            closeDialog()
            bp_notification.show({
              type: "success",
              message: "Invite sent :)",
            })
          })
          .catch((err) => {
            // noop
            throw err
          })
      }
    }

    return {
      form,
      formValid,
      emailRules,
      closeDialog,
      saveDialog,
      email,
      adminSuperPower,
      billingSuperPower,
      permitAdmin: bp_context.permitAdmin,
    }
  },
}
</script>

<style lang="scss" scoped></style>
