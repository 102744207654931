import { marked } from "marked"

export let flatten = (arr) => {
  return [].concat(...arr)
}

export let snakeCase = (o) => {
  var newO, origKey, newKey, value
  if (o instanceof Array) {
    return o.map(function (value) {
      if (typeof value === "object") {
        value = snakeCase(value)
      }
      return value
    })
  } else {
    newO = {}
    for (origKey in o) {
      if (o.hasOwnProperty(origKey)) {
        newKey = origKey.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`).toString()
        value = o[origKey]
        if (value instanceof Array || (!!value && value.constructor === Object)) {
          value = snakeCase(value)
        }
        newO[newKey] = value
      }
    }
  }
  return newO
}

// export const capitalize = s => (s && s[0].toUpperCase() + s.slice(1)) || ""

// when we stringify and parse json, it encodes certain characters, like apostrophes, and semi-colons etc.
// To render the text of the fragment correctly, we'll need to decode it first
// We use a weird dom browser technique to do this.
export const htmlDecode = (encodedStr) => {
  let doc = new DOMParser().parseFromString(encodedStr, "text/html")
  return doc.documentElement.textContent
}

export const asyncSeries = (tasks) => {
  return tasks.reduce((promiseChain, currentTask) => {
    return promiseChain.then((chainResults) => currentTask().then((currentResult) => [...chainResults, currentResult]))
  }, Promise.resolve([]))
}

export const decode = (markdown) => {
  markdown = htmlDecode(markdown) 
  markdown = markdown.replace(/\n(?=\n)/g, "\n\n<br/>\n")
  return marked(markdown, { gfm: true, breaks: true, mangle: false, headerIds: false })
}

export const createTextLinks = (text) => {
  return (text || "").replaceAll(/(((https?\:\/\/)|(www\.))([\w|\.]+))/gi, function (url) {
    var hyperlink = url
    if (!hyperlink.match("^https?://")) {
      hyperlink = "http://" + hyperlink
    }
    return '<a href="' + hyperlink + '" target="_blank">' + url + "</a>"
  })
}
