import { computed } from "vue"
import { useTag } from "@/modules/tag/use"

export const useTagSortable = () => {
  let bp_tag = useTag()

  const tagIds = computed({
    get: () => {
      return bp_tag.draggableOrder.value
    },
    set: (value) => {
      bp_tag.reorderDraggable(value)
    },
  })

  const onUpdate = (e) => {
    bp_tag.reorder({})
  }

  const onAdd = (e) => {
    bp_tag.reorder({})
  }

  return {
    tagIds,
    onAdd,
    onUpdate,
  }
}
