import { computed } from "vue"
import { useCommand } from "@/modules/command/use"
import { useEmailGroupStore } from "./store"

export const useEmailGroup = function () {
  let bp_command = useCommand()
  const emailGroupStore = useEmailGroupStore()
  const { getById } = emailGroupStore

  const update = ({ emailGroupId, name, emails, fromLiveUpdate }) => {
    if (fromLiveUpdate) {
      return emailGroupStore.update({ emailGroupId, name, emails, storeOnly: true })
    }

    const emailGroup = emailGroupStore.getById(emailGroupId)

    if (emailGroup) {
      const prevAttributes = { name: emailGroup.name, emails: emailGroup.emails }

      return bp_command.add({
        execute: () => {
          return emailGroupStore.update({ emailGroupId, name, emails })
        },
        undo: () => {
          return emailGroupStore.update({ emailGroupId, ...prevAttributes })
        },
      })
    }
  }

  const add = ({ name, emails }) => {
    let emailGroupId

    return bp_command.add({
      execute: () => {
        return emailGroupStore.add({ name, emails }).then((response) => {
          emailGroupId = response.data._id
        })
      },
      undo: () => {
        return emailGroupStore.del({ emailGroupId })
      },
    })
  }

  // the idea is to add emailGroup on the server first
  // but when we know what emailGroup to add (an undo on a delete via Liveupdate, or a new one coming through
  // from another session via Liveupdate) - that's when we call this method
  // This method won't have an undo as this is in response to a liveupdate - so the data has already changed on the server
  const addFromLiveupdate = (emailGroup) => {
    return emailGroupStore.addFromLiveupdate(emailGroup)
  }

  const del = ({ emailGroupId, fromLiveUpdate }) => {
    if (fromLiveUpdate) {
      return emailGroupStore.del({ emailGroupId, storeOnly: true })
    }

    const prevEmailGroup = emailGroupStore.getById(emailGroupId)

    if (prevEmailGroup) {
      return bp_command.add({
        execute: () => {
          return emailGroupStore.del({ emailGroupId })
        },
        undo: () => {
          return emailGroupStore.update({ emailGroupId, ...prevEmailGroup })
        },
      })
    }
  }

  // store's state is reactively passed on (computed for readonly) - all state changes should occur within store
  return {
    emailGroups: computed(() => emailGroupStore.emailGroups),
    getById,
    update,
    del,
    add,
    addFromLiveupdate,
  }
}
