<template>
  <v-progress-linear :active="loading" :indeterminate="loading" absolute location="bottom" height="3" color="primary"></v-progress-linear>
</template>

<script>
import { storeToRefs } from 'pinia'
import { useRootStore } from "@/stores/root"

export default {
  name: "TheProgressBar",
  setup() {
    const rootStore = useRootStore()
    const { loading } = storeToRefs(rootStore)
  
    return {
      loading
    }
  },
}
</script>
