import { ref, computed } from "vue"
import { defineStore } from "pinia"
import axios from "axios"
import * as Sentry from "@sentry/browser"
import LogRocket from "logrocket"

import AuthService from "@/services/AuthService.js"
import { useMonitoring } from "@/use/useMonitoring"

export const useAuthStore = defineStore("auth", () => {
  const name = ref(null)
  const email = ref(null)
  const initials = ref(null)
  const niceId = ref(null)
  const currentRefreshToken = ref(null)
  const token = ref(null)
  const isDemo = ref(false)
  const isAdm2n = ref(false)
  const enableDiagnostics = ref(false)

  const loggedIn = computed(() => {
    return !!token.value
  })

  function setAuthData(authData) {
    name.value = authData.name
    email.value = authData.email
    initials.value = authData.initials
    niceId.value = authData.niceId
    token.value = authData.token
    currentRefreshToken.value = authData.refresh_token
    isDemo.value = authData.isDemo
    isAdm2n.value = authData.isAdm2n
    enableDiagnostics.value = authData.enableDiagnostics

    localStorage.setItem("auth", JSON.stringify(authData)) // not state = when we read it back from localStorage we send the same authData back to this mutation
    axios.defaults.headers.common["Authorization"] = `Bearer ${authData.token}`
  }

  function signup(credentials) {
    return AuthService.signup(credentials)
      .then(({ data }) => {
        setAuth(data)
        return Promise.resolve()
      })
      .catch((err) => {
        throw err
      })
  }

  function setAuth(data) {
    console.log("setting auth")
    setAuthData(data)

    // for Intercom via Google Tag Manager
    // Don't add data layer for demo users
    if (!data.isDemo) {
      window.dataLayer.push({
        user: {
          id: data.niceId,
          hash: data.hash,
          email: data.email,
          name: data.name,
          created_at: data.created_at,
        },
      })
    }

    // to identify user on Sentry
    let bp_monitoring = useMonitoring()
    if (bp_monitoring.captureErrors()) {
      try {
        console.log("Setting user for Sentry: ", data.email)
        Sentry.setUser({ email: data.email })
      } catch (err) {
        console.log("Error setting user for Sentry:", err)
      }
    }
    // to identify user on Sentry
    if (bp_monitoring.captureDiagnostics()) {
      try {
        console.log("Setting user for LogRocket: ", data.email)
        LogRocket.identify(data.email)
      } catch (err) {
        console.log("Error setting user for LogRocket:", err)
      }
    }
  }

  function login(credentials) {
    return AuthService.login(credentials)
      .then(({ data }) => {
        setAuth(data)
        return Promise.resolve()
      })
      .catch((err) => {
        throw err
      })
  }

  function refreshToken() {
    return AuthService.refreshToken(currentRefreshToken.value)
      .then(({ data }) => {
        setAuth(data)
        // let's return the new access token for axios interceptors
        return Promise.resolve(data)
      })
      .catch((err) => {
        throw err
      })
  }

  function forgotPassword({ email }) {
    return AuthService.forgotPassword({ email }).catch((err) => {
      throw err
    })
  }

  function resetPassword({ password, reset_password_token }) {
    return AuthService.resetPassword({ password, reset_password_token })
      .then(({ data }) => {
        setAuth(data)
        // let's return the new access token for axios interceptors
        return Promise.resolve(data)
      })
      .catch((err) => {
        throw err
      })
  }

  function update(credentials) {
    return AuthService.update(credentials)
      .then((response) => {
        setAuth(response.data)
        return Promise.resolve(response)
      })
      .catch((err) => {
        throw err
      })
  }

  function logout({ loggedOutReason }) {
    localStorage.removeItem("auth")
    
    if (loggedOutReason) {
      let qsObj = Object.fromEntries(new URLSearchParams(window.location.search.slice(1)))
      qsObj.loggedOutReason = loggedOutReason
      let qs = new URLSearchParams(qsObj).toString()

      location.assign(window.location.pathname + "?" + qs)
    } else {
      location.reload()
    }
  }

  function impersonate(email) {
    return AuthService.impersonate(email)
      .then(({ data }) => {
        setAuth(data)
        return Promise.resolve()
      })
      .catch((err) => {
        throw err
      })
  }

  return {
    name,
    email,
    initials,
    niceId,
    currentRefreshToken,
    token,
    isDemo,
    isAdm2n,
    enableDiagnostics,
    loggedIn,
    signup,
    setAuth,
    login,
    refreshToken,
    forgotPassword,
    resetPassword,
    update,
    logout,
    impersonate,
  }
})
