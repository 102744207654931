<template>
  <div class="fixed-wrapper mt-4 mr-4">
    <v-tooltip location="bottom" open-delay="250">
      <template v-slot:activator="{ props }">
        <v-btn v-bind="props" icon class="undo" size="x-small" color="yellow-darken-1" variant="elevated" :loading="loading" :disabled="!any || loading" @click="undo" data-label="undo">
          <v-icon size="large">{{ mdiUndo }}</v-icon>
        </v-btn>
      </template>
      <span>Undo your last changes</span>
    </v-tooltip>
  </div>
</template>

<script>
import { ref, computed } from "vue"
import { mdiUndo } from "@mdi/js"

import { useCommand } from "@/modules/command/use"

export default {
  name: "TheUndoButton",
  setup() {
    let bp_command = useCommand()
    let loading = ref(false)

    let any = computed(() => {
      return bp_command.count.value > 0
    })

    let undo = () => {
      loading.value = true
      bp_command.undo().finally(() => {
        loading.value = false
      })
    }

    return {
      any,
      loading,
      undo,
      mdiUndo
    }
  },
}
</script>

<style lang="scss" scoped>
.fixed-wrapper {
  position: absolute;
  top: var(--v-layout-top);
  right: var(--v-layout-right);
  width: 32px;
  height: 32px;
}
.undo {
  position: fixed;
  z-index: 5; // else - because of the absolute/fixed positioning we lose it underneath the flatplan
}
</style>
