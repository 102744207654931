<template>
  <v-container fluid>
    <h4 class="text-h4">
      Billing for <span class="font-weight-black">{{ accountName }}</span>
    </h4>
    <br />
    <v-alert v-if="!!account.plan" type="info"
      >You are on the <span class="font-weight-black">{{ account.plan.name }}</span> plan.</v-alert
    >
    <br />
    <div v-if="!isDemo">
      <div v-if="needsPayment && !!account.plan" class="text-error">
        <p class="font-weight-black">Your account is no longer active.</p>
        <p v-if="account.stripe_customer_code">Please update your payment details to re-activate your account.</p>
        <p v-if="account.plan.trial">Please upgrade to a paid plan.</p>
      </div>
      <p v-if="!!account.stripe_current_period_end">
        Your current billing period expires <span class="font-weight-bold">{{ fmtDate(account.stripe_current_period_end) }}.</span>
      </p>
      <p v-if="hasNextPayment">
        Your next payment of <span class="font-weight-bold">${{ account.plan.price }}</span> will automatically happen on <span class="font-weight-bold">{{ fmtDate(account.stripe_current_period_end) }}.</span>
      </p>

      <div class="mt-6" v-if="!!account.stripe_customer_code"><PaymentDetails :account="account" /></div>
      <hr />
      <div v-if="customAccountPlans.length > 0" class="mt-6">
        <h6 class="text-h6">Available custom plans</h6>
        <v-row>
          <v-col cols="12" sm="2" v-for="plan in customAccountPlans" :key="plan.id"><AccountPlan :currentAccountPlanId="account.plan.id" :accountPlan="plan" :accountStats="accountStats" /></v-col>
        </v-row>
      </div>
      <div class="mt-6">
        <h6 class="text-h6"><span v-if="customAccountPlans.length > 0">Standard plans</span><span v-else>Available plans</span></h6>
        <v-row>
          <v-col cols="12" sm="2" v-for="plan in accountPlans" :key="plan.id"><AccountPlan :currentAccountPlanId="account.plan.id" :accountPlan="plan" :accountStats="accountStats" /></v-col>
        </v-row>
      </div>
    </div>
  </v-container>
</template>

<script>
import { onMounted } from "vue"
import { DateTime } from "luxon"

import { useBilling } from "../use"

import PaymentDetails from "./PaymentDetails"
import AccountPlan from "./AccountPlan"

export default {
  name: "BillingView",
  components: {
    PaymentDetails,
    AccountPlan,
  },
  setup() {
    let bp_billing = useBilling()

    let account = bp_billing.account
    let accountPlans = !account.value.plan.trial ? bp_billing.accountPlans.value.filter((p) => !p.trial) : bp_billing.accountPlans
    let customAccountPlans = bp_billing.customAccountPlans
    let accountStats = bp_billing.accountStats

    let fmtDate = (date) => {
      return DateTime.fromISO(date).toFormat("DD")
    }

    let fetchAccountStats = () => {
      bp_billing.fetchAccountStats()
    }

    onMounted(() => {
      fetchAccountStats()
    })

    return {
      accountName: bp_billing.accountName,
      account,
      hasNextPayment: bp_billing.hasNextPayment,
      needsPayment: bp_billing.needsPayment,
      fmtDate,
      accountPlans,
      customAccountPlans,
      accountStats,
      isDemo: bp_billing.isDemo,
    }
  },
}
</script>
