<template>
  <div class="d-flex flex-column justify-center align-center" style="min-height: 100vh">
    <a href="/"><LogoOnly :height="100" /></a>
    <div class="text-h3 text-center mt-8">Oh no, sorry</div>
    <div class="text-h5 mt-8">There was an error on the server. We'll look into it.</div>
  </div>
</template>

<script>
import LogoOnly from "@/components/Logos/LogoOnly.vue"

export default {
  name: "ServerErrorView",
  components: {
    LogoOnly,
  },
}
</script>

<style lang="scss" scoped></style>
