<template>
  <v-container fluid>
    <h4 class="text-h4">API Keys for <span class="font-weight-black">{{ accountName }}</span></h4>
    <br/>
    <div class="text-body-1">
      <p>API keys allow you to programatically perform actions against the Blinkplan API on behalf of your account. For example, they could be used for to keep your ads/content up to date from your own system.</p>
      <br/>
      <p>For more information on how to use the web API, see our <a :href="apiDocsEndpoint" target="_blank" color="primary">documentation</a>.</p>
    </div>
    <br/>
    <v-card v-for="apiKey in apiKeys" :key="apiKey.id" class="mb-4" @click="showKey(apiKey.key_hash)">
      <v-card-title>{{ apiKey.name || "<blank>" }}</v-card-title>
      <v-card-subtitle>Key: ************{{ apiKey.last_4 }}</v-card-subtitle>
      <v-card-text>
        <span class="">Last used: </span>
        <span class="text-medium-emphasis">{{ lastUsedAgo(apiKey.last_used_at) }}</span>
        <br/>
        <span class="">Created: </span>
        <span class="text-medium-emphasis">{{ fmtDateTime(apiKey.created_at) }}</span>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { useRouter } from "vue-router"
import { DateTime } from "luxon"
import { useApiKey } from "../use"
import { useContext } from "@/use/context"

export default {
  name: "ApiKeysView",
  setup() {
    let router = useRouter()
    let bp_apiKey = useApiKey()
    let bp_context = useContext()

    let apiKeys = bp_apiKey.apiKeys

    let lastUsedAgo = (lastUsedAt) => {
      return DateTime.fromISO(lastUsedAt).toRelative()
    }

    let fmtDateTime = (date) => {
      return DateTime.fromISO(date).toFormat("DD, t")
    }

    let showKey = (key_hash) => {
      router.push({ name: "api-key-show", params: { key_hash } })
    }

    return {
      accountName: bp_context.accountName,
      apiKeys,
      lastUsedAgo,
      fmtDateTime,
      showKey,
      apiDocsEndpoint: "//" + import.meta.env.VITE_API_DOCS_ENDPOINT
    }
  },
}
</script>

<style lang="scss" scoped></style>