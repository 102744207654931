import { ref, onMounted, onUnmounted } from "vue"
import { upload } from "@/utils/upload"
import { useNotification } from "@/modules/notification/use"

export const useFileDragDrop = function (pageEl, uploaded) {
  let fileDragOver = ref(false)
  let fileUploading = ref(false)
  let canCancel = ref(false)
  let uploadTask

  let fileRules = [(value) => !value || value.size < 3000000 || "Thumbnail size should be less than 3 MB"]

  let isFile = (e) => {
    if (e.dataTransfer.types) {
      for (let i = 0; i < e.dataTransfer.types.length; i++) {
        // don't use forEach - else the return will be relative to the forEach
        if (e.dataTransfer.types[i] === "Files") {
          return true
        }
      }
      return false
    }
  }

  let preventDefaultFileDragDrop = (e) => {
    if (isFile(e)) {
      e.preventDefault()
      e.stopPropagation()
    }
  }

  let handleFileDrop = (e) => {
    preventDefaultFileDragDrop(e)
    if (isFile(e)) {
      fileDragOver.value = false

      if (!fileUploading.value) {
        let file = e.dataTransfer.files[0]

        if (/\.(jpe?g|png|gif|pdf)$/i.test(file.name)) {
          uploadFile(file)
        }
      }
    }
  }

  let handleFileOver = (e) => {
    preventDefaultFileDragDrop(e)
    if (isFile(e)) {
      fileDragOver.value = true
    }
  }

  let handleFileLeave = (e) => {
    preventDefaultFileDragDrop(e)

    if (isFile(e)) {
      fileDragOver.value = false
    }
  }

  let isFileValid = (file) => {
    let valid = true
    fileRules.forEach((rule) => {
      valid = valid && rule(file) === true
    })
    return valid
  }

  let cancel = () => {
    if (uploadTask) {
      uploadTask.cancel()
    }
  }

  let uploadFile = (file) => {
    if (!!file && isFileValid(file)) {
      uploadTask = null
      fileUploading.value = true
      canCancel.value = false

      let uploader = upload(file)

      uploader
        .then((data) => {
          canCancel.value = true // now that we have connected to Firebase etc, now there is a possibility to cancel upload

          if (data.uploadTask) {
            uploadTask = data.uploadTask

            uploadTask
              .then((data) => {
                if (data) {
                  uploaded(data.metadata.fullPath)
                }
              })
              .catch((err) => {
                let bp_notification = useNotification()
                bp_notification.show({
                  type: "error",
                  message: err.message,
                })
              })
              .finally(() => {
                fileUploading.value = false
                canCancel.value = false
              })
          }
        })
        .catch(() => {
          fileUploading.value = false
          canCancel.value = false
        })
    }
  }

  onMounted(() => {
    if (pageEl.value) {
      ;["drag", "dragstart", "dragover"].forEach((evt) => {
        pageEl.value.addEventListener(evt, preventDefaultFileDragDrop)
      })
      pageEl.value.addEventListener("drop", handleFileDrop)
      pageEl.value.addEventListener("dragenter", handleFileOver)
      pageEl.value.addEventListener("dragleave", handleFileLeave)
      pageEl.value.addEventListener("dragend", handleFileLeave)
    }
  })

  onUnmounted(() => {
    if (pageEl.value) {
      ;["drag", "dragstart", "dragover"].forEach((evt) => {
        pageEl.value.removeEventListener(evt, preventDefaultFileDragDrop)
      })
      pageEl.value.removeEventListener("drop", handleFileDrop)
      pageEl.value.removeEventListener("dragenter", handleFileOver)
      pageEl.value.removeEventListener("dragleave", handleFileLeave)
      pageEl.value.removeEventListener("dragend", handleFileLeave)
    }
  })

  return {
    fileDragOver,
    fileUploading,
    canCancel,
    cancel,
  }
}
