<template>
  <v-row>
    <v-col cols="8">
      <v-chip closable :close-icon="mdiDeleteOutline" @click:close.prevent="del" :to="{ name: 'tag-edit', params: { tagId } }" data-test-id="tag">
        <v-icon start>{{ mdiDragVertical }}</v-icon>
        <TagDot :color="tag.color" class="mr-3" />
        <div class="pr-3" data-test-id="name">{{ tag.name }}</div>
      </v-chip>
    </v-col>
    <v-col cols="2" data-test-id="count">{{ stats.count }}</v-col>
    <v-col cols="2" data-test-id="percentage">{{ Math.round((stats.percentage + Number.EPSILON) * 100) }}%</v-col>
  </v-row>
</template>

<script>
import { computed, reactive } from "vue"
import { mdiDragVertical, mdiDeleteOutline } from "@mdi/js"

import { useTag } from "../use"
import { useStats } from "../useStats"

import TagDot from "./TagDot"

export default {
  name: "TagItem",
  props: {
    tagId: {
      type: String,
      required: true,
    },
  },
  components: {
    TagDot,
  },
  setup(props) {
    let bp_stats = useStats()
    let bp_tag = useTag()

    let tag = reactive(bp_tag.getById(props.tagId))

    let del = () => {
      bp_tag.del({ tagId: props.tagId })
    }

    return {
      tag,
      del,
      stats: computed(() => {
        return bp_stats.detailsFor(tag)
      }),
      mdiDragVertical,
      mdiDeleteOutline,
    }
  },
}
</script>

<style lang="scss" scoped></style>
