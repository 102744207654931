import { ref } from "vue"
import { defineStore } from "pinia"

import AccountService from "@/services/AccountService.js"
import DataService from "@/services/DataService.js"

import { useAuthStore } from "@/modules/auth/store"
import { usePageStore } from "@/modules/page/store"
import { useContentStore } from "@/modules/content/store"
import { usePage } from "../../page/use"

export const useDemoStore = defineStore("demo", () => {
  const state = ref("initial")
  const createdAccountId = ref("")
  const createdFlatplanId = ref("")
  const createdCategoryIds = ref([])
  const createdContentIds = ref([])
  const createdTagIds = ref([])

  const pageStore = usePageStore()
  const contentStore = useContentStore()

  function createUser() {
    if (state.value === "doneCreatingUser") {
      return Promise.resolve()
    } else {
      state.value = "creatingUser"
      return AccountService.createDemoUser().then(({ data }) => {
        state.value = "doneCreatingUser"

        const authStore = useAuthStore()
        authStore.setAuth(data)
        return Promise.resolve()
      })
    }
  }

  function setupAccount() {
    if (state.value === "doneSettingUpAccount") {
      return Promise.resolve()
    } else {
      state.value = "settingUpAccount"
      return AccountService.setupDemo().then(({ data }) => {
        state.value = "doneSettingUpAccount"
        createdAccountId.value = data.secret_id
        sessionStorage.setItem("currentAccountId", createdAccountId.value)
        return Promise.resolve()
      })
    }
  }

  function createFlatplan() {
    if (state.value === "doneCreatingFlatplan") {
      return Promise.resolve()
    } else {
      state.value = "creatingFlatplan"
      return DataService.initBootstrap({ accountId: createdAccountId.value })
        .then(({ data }) => {
          state.value = "doneCreatingFlatplan"
          createdFlatplanId.value = data.flatplan._id
          createdCategoryIds.value = data.categories.map((c) => c._id)
          createdTagIds.value = data.tags.map((t) => t._id)
          return Promise.resolve()
        })
        .catch((err) => {
          // noop
          return Promise.reject(err)
        })
    }
  }

  function createContents() {
    if (state.value === "doneCreatingContents") {
      return Promise.resolve()
    } else {
      state.value = "creatingContents"

      let contents = [
        { identifier: "ad1", text: "Who let the dogs out?", pageNos: ["middle", "30"] },
        { identifier: "ad2", text: "Where do we begin?", pageNos: ["42"] },
      ]

      return contentStore
        .batchAdd({ flatplanId: createdFlatplanId.value, contents })
        .then(({ data }) => {
          state.value = "doneCreatingContents"
          createdContentIds.value = data.addedContents.map((c) => c._id)
          return Promise.resolve()
        })
        .catch((err) => {
          // noop
          return Promise.reject(err)
        })
    }
  }

  function initData() {
    if (state.value === "doneInitializingData") {
      return Promise.resolve()
    } else {
      state.value = "initializingData"

      let pages = []

      let text = [
        "Eat My Hat",
        "Burst Your Bubble",
        "Go Out On a Limb",
        "Fool's Gold",
        "Playing Possum",
        "Give a Man a Fish",
        "Down For The Count",
        "Up In Arms",
        "Under the Weather",
        "Under Your Nose",
        "Right Off the Bat",
        "Cut To The Chase",
        "In a Pickle",
        "Roll With the Punches",
        "Throw In the Towel",
        "Right Out of the Gate",
        "Fit as a Fiddle",
        "Happy as a Clam",
        "On the Ropes",
        "Rain on Your Parade",
        "Tug of War",
        "Shot In the Dark",
        "Easy As Pie",
        "Love Birds",
        "Lickety Split",
        "Keep Your Eyes Peeled",
        "Scot-free",
        "Knuckle Down",
        "Back to Square One",
        "Between a Rock and a Hard Place",
        "Jumping the Gun",
        "Cut The Mustard",
      ]

      for (let i = 0; i < 32; i++) {
        let tagRefs = []

        if (Math.floor(Math.random() * Math.floor(3)) === 0) {
          tagRefs.push(createdTagIds.value[Math.floor(Math.random() * Math.floor(8))])
          if (Math.floor(Math.random() * Math.floor(3)) === 0) {
            tagRefs.push(createdTagIds.value[Math.floor(Math.random() * Math.floor(8))])
          }
        }

        pages.push({
          fragments: [
            {
              shape: "full",
              text: text[i],
              topLeftX: 0,
              topLeftY: 0,
              categoryId: createdCategoryIds.value[Math.floor(Math.random() * Math.floor(3))],
              type: ["ad", "editorial"][Math.floor((Math.random() + 0.25) * Math.floor(2))], // lean toward editorial
            },
          ],
          tagRefs: tagRefs,
          thumbnail: {
            key: "blinkplan-demo-thumbnails.pdf",
            page: i,
          },
        })
      }

      createdContentIds.value.forEach((contentId) => {
        pages.push({
          fragments: [
            {
              shape: "full",
              text: null,
              contentId,
              topLeftX: 0,
              topLeftY: 0,
              categoryId: createdCategoryIds.value[Math.floor(Math.random() * Math.floor(3))],
              type: ["ad", "editorial"][Math.floor((Math.random() + 0.25) * Math.floor(2))], // lean toward editorial
            },
          ],
          tagRefs: [],
          thumbnail: null,
        })
      })

      return pageStore
        .addNoOfPages({ flatplanId: createdFlatplanId.value, pages })
        .then(({ data }) => {
          state.value = "done"
          return Promise.resolve()
        })
        .catch((err) => {
          // noop
          return Promise.reject(err)
        })
    }
  }

  return {
    state,
    createdAccountId,
    createdFlatplanId,
    createdCategoryIds,
    createdTagIds,
    createUser,
    setupAccount,
    createFlatplan,
    createContents,
    initData,
  }
})
