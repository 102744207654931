<template>
  <v-container fluid>
    <h4 class="text-h4 mb-4">
      Invoices for <span class="font-weight-black">{{ accountName }}</span>
    </h4>
    <v-row>
      <v-col cols="12" sm="6">
        <v-row class="invoice" v-for="invoice in invoices" :key="invoice.code" @click="showInvoice(invoice.code)">
          <v-col cols="8" sm="4">{{ fmtDate(invoice.timestamp) }}</v-col>
          <v-col cols="4" sm="2" class="text-caption">${{ (invoice.total / 100).toFixed(2) }}</v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="3">
        <InvoiceDetails />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { useRouter } from "vue-router"
import { DateTime } from "luxon"

import { useBilling } from "../use"

import InvoiceDetails from "./InvoiceDetails"

export default {
  name: "InvoicesView",
  components: {
    InvoiceDetails,
  },
  setup() {
    let router = useRouter()
    let bp_billing = useBilling()

    let account = bp_billing.account
    let invoices = bp_billing.invoices

    let fmtDate = (date) => {
      return DateTime.fromISO(date).toFormat("DD")
    }

    let showInvoice = (code) => {
      router.push({ name: "invoice-show", params: { code } })
    }

    return {
      accountName: bp_billing.accountName,
      account,
      fmtDate,
      invoices,
      showInvoice,
    }
  },
}
</script>

<style scoped>
.invoice {
  cursor: pointer;
}
</style>
