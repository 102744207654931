import { ref, computed } from "vue"
import { useRouter } from "vue-router"

import EditorLayout from "@/layouts/EditorLayout"
import AuthLayout from "@/layouts/AuthLayout"
import AccountLayout from "@/layouts/AccountLayout"
import ProfileLayout from "@/layouts/ProfileLayout"
import PlainLayout from "@/layouts/PlainLayout"
import ShareLayout from "@/layouts/ShareLayout"
import PdfLayout from "@/layouts/PdfLayout"

export const useLayouts = (route) => {
  let router = useRouter()

  const pickLayout = (layout) => {
    if (layout === "editor") return EditorLayout
    if (layout === "auth") return AuthLayout
    if (layout === "account") return AccountLayout
    if (layout === "profile") return ProfileLayout
    if (layout === "share") return ShareLayout
    if (layout === "plain") return PlainLayout
    if (layout === "pdf") return PdfLayout

    return null
  }

  const getLayout = (route) => {
    let matched = route.matched ? [...route.matched] : []
    let closestRouteWithLayout = matched.reverse().find((r) => r.meta.layout)
    return closestRouteWithLayout ? closestRouteWithLayout.meta.layout : null
  }

  // init layoutName to our initial route when we started the app/page
  let layoutName = ref(getLayout(route))

  // we made a reactive value that is mutated on route changes,
  // which is what Vue Router didn’t gave us out of the box.
  router.beforeEach((to, from) => {
    layoutName.value = getLayout(to)
  })

  let layout = computed(() => {
    return pickLayout(layoutName.value) || "div"
  })

  return {
    layout,
    layoutName,
  }
}
