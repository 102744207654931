<template>
  <base-dialog v-if="pageExists" :model-value="true" @keydown.esc="closeDialog" size="large" scrollable>
    <form @submit.prevent="saveDialog" style="background-color: inherit">
      <v-card-item>
        <v-card-title class="d-flex">
          <span class="text-h5" data-test-id="title">Edit Page {{ pageNo }}</span>
          <v-spacer></v-spacer>
          <v-menu v-if="!onHoldingArea" v-model="showMove" :close-on-content-click="false">
            <template v-slot:activator="{ props }">
              <v-btn v-bind="props" variant="text" color="primary">
                <v-icon start>{{ mdiArrowRight }}</v-icon
                >{{ moveText }}
              </v-btn>
            </template>
            <PageMove :pageId="pageId" v-model:open="showMove" @moved="moved" />
          </v-menu>
        </v-card-title>
      </v-card-item>
      <v-card-text>
        <v-container>
          <v-tabs v-model="tab" color="black" center-active grow show-arrows="mobile" bg-color="background">
            <v-tab v-for="item of tabs" :key="item.name" :to="item.route" :data-label="`${item.name} tab`">
              <v-badge v-if="item.hasBadge" :content="item.badgeContent" :color="item.badgeColor" :dot="item.badgeDot" floating>{{ item.name }}</v-badge>
              <span v-else>{{ item.name }}</span>
            </v-tab>
          </v-tabs>
          <v-window class="mt-4" color="background" v-model="tab">
            <router-view name="tab" @busy="disableSave = $event" @dirty="dirty = true"></router-view>
          </v-window>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <cancel-btn @click="closeDialog" data-label="close page">Close</cancel-btn>
        <submit-btn type="submit" :disabled="disableSave || !dirty" data-label="save page">Save</submit-btn>
      </v-card-actions>
    </form>
  </base-dialog>
</template>

<script>
import { ref, computed, inject } from "vue"
import { useRouter } from "vue-router"
import { mdiArrowRight } from "@mdi/js"

import { usePage } from "../use"
import { usePageNumbers } from "@/modules/flatplan/usePageNumbers"
import { useNotification } from "@/modules/notification/use"
import PageMove from "./PageMove"

import { Bus } from "@/Bus"

import { sessionStorageObj } from "@/utils/storage"

export default {
  name: "EditPageView",
  props: {
    pageId: {
      type: String,
      required: true,
    },
  },
  components: {
    PageMove,
  },
  setup(props) {
    let router = useRouter()
    let mobile = inject("mobile")
    
    let bp_page = usePage()
    let bp_pageNumbers = usePageNumbers()
    let pageNo = bp_pageNumbers.pageNoFor(props.pageId)

    let disableSave = ref(false)
    let dirty = ref(false)
    let showMove = ref(false)
    let newOrder = null
    let moveText = ref("Move")
    let movedPageIds = ref([])

    // we make a copy of the page from the store, and we save it in sessionStorage
    // we are going to have to interface with sessionStorage between tabs
    // as each tab is its own URL, so there is no real parent-child relationship
    // The component saves off the original page, and eventually saves it again to
    // the store.
    let page = bp_page.getById(props.pageId)

    if (page === undefined || page === null) {
      // we may have lost it via LiveUpdate, and we do a browser refresh?
      let bp_notification = useNotification()
      bp_notification.show({
        type: "error",
        message: "That page no longer exists",
      })
      router.push({ name: "editor" })
      return {
        pageExists: false,
      }
    }

    let tab = ref(null)
    let tabs = computed(() => {
      return [
        { name: "Content", route: { name: "page-edit-content", params: { pageId: props.pageId } }, hasBadge: false },
        { name: "Notes", route: { name: "page-edit-notes", params: { pageId: props.pageId } }, hasBadge: !!page.notes, badgeDot: true, badgeColor: "tertiary" },
        { name: "Tags", route: { name: "page-edit-tags", params: { pageId: props.pageId } }, hasBadge: false },
        { name: "Comments", route: { name: "page-edit-comments", params: { pageId: props.pageId } }, hasBadge: page.commentCount > 0, badgeContent: page.commentCount, badgeColor: "grey" },
      ]
    })

    sessionStorageObj.setItem("editPage", {
      pageId: props.pageId,
      content: { data: { fragments: page.fragments, template: page.template }, dirty: false },
      tags: { data: { tagRefs: page.tagRefs, createTags: [] }, dirty: false },
      thumbnail: { data: page.thumbnail ? page.thumbnail : {}, dirty: false }, // (original used to display resized preview)
      commentBody: { data: "", dirty: false },
      notes: { data: page.notes, dirty: false },
    })

    const moved = ({ order, affectedPageIds, description }) => {
      newOrder = order
      moveText.value = description
      movedPageIds.value = affectedPageIds
      dirty.value = true
    }

    const closeDialog = () => {
      // TODO: Check if ok to close
      let returnTo = "flatplan-show"
      let query = router.currentRoute.value.query

      if (query.returnTo) {
        returnTo = query.returnTo
      }
      router.push({ name: returnTo })
    }

    const collectData = () => {
      let editPageData = sessionStorageObj.getItem("editPage")

      let updateData = {}

      if (editPageData.content && editPageData.content.dirty) {
        updateData.fragments = editPageData.content.data.fragments
        updateData.template = editPageData.content.data.template
      }

      if (editPageData.thumbnail && editPageData.thumbnail.dirty) {
        updateData.thumbnail = editPageData.thumbnail.data
      }

      if (editPageData.tags && editPageData.tags.dirty) {
        updateData.tags = editPageData.tags.data
      }

      if (editPageData.notes && editPageData.notes.dirty) {
        updateData.notes = editPageData.notes.data
      }

      if (newOrder) {
        updateData.order = newOrder
      }

      return updateData
    }

    const saveDialog = () => {
      let updateData = collectData()

      if (updateData.fragments !== undefined || updateData.thumbnail !== undefined || updateData.tags !== undefined || updateData.order !== undefined || updateData.notes !== undefined) {
        bp_page
          .updatePage({ pageId: props.pageId, ...updateData })
          .then(() => {
            movedPageIds.value.forEach((pageId) => {
              Bus.$emit("page-changed", { pageId })
            })
          })
        closeDialog()
      }
    }

    return {
      pageExists: !!page,
      tab,
      tabs,
      mobile,
      closeDialog,
      saveDialog,
      pageNo,
      showMove,
      moved,
      disableSave,
      dirty,
      moveText,
      onHoldingArea: computed(() => {
        return bp_page.onHoldingArea.value.findIndex((p) => p._id === props.pageId) !== -1
      }),
      mdiArrowRight,
    }
  },
}
</script>

<style scoped></style>
