<template>
  <v-main v-if="layout === 'pdf'">
    <slot></slot>
  </v-main>
</template>

<script>
import { computed } from "vue"
import { useRouter } from "vue-router"

export default {
  name: "PdfLayout",
  setup() {
    let router = useRouter()
    let currentRoute = router.currentRoute

    return {
      layout: computed(() => currentRoute.value.meta.layout),
    }
  }
}
</script>
