<template>
  <v-menu v-model="showAddPages" :close-on-content-click="false" max-width="200">
    <template v-slot:activator="{ props }">
      <v-btn v-bind="props" :variant="!highlightAdd ? 'text' : 'flat'" color="primary" @click="showAddPages = true" :prepend-icon="mdiPlus">
        <span v-if="!mobile">Add</span>
      </v-btn>
    </template>
    <AddPages v-model:open="showAddPages" />
  </v-menu>
</template>

<script>
import { ref, computed, inject } from "vue"
import { mdiPlus } from "@mdi/js"

import { usePage } from "@/modules/page/use"
import AddPages from "@/modules/page/components/AddPages"

export default {
  name: "AddOptions",
  components: {
    AddPages,
  },
  setup() {
    let mobile = inject("mobile")

    let showAddPages = ref(false)

    const bp_page = usePage()

    return {
      mobile,
      showAddPages,
      highlightAdd: computed(() => bp_page.pages.value.length === 0),
      mdiPlus,
    }
  },
}
</script>

<style lang="scss" scoped></style>
