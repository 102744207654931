<template>
  <div>
    <v-file-input
      accept=".tsv, .csv"
      label="Upload CSV"
      :loading="loading"
      :disabled="disabled"
      :rules="fileRules"
      prepend-icon=""
      :prepend-inner-icon="mdiFileDelimitedOutline"
      @update:modelValue="uploadFile"
      @click:clear="cancel"
      show-size
      chips
      class="mt-8"
    ></v-file-input>
    <v-container v-if="!!result.errors && result.errors.length > 0" data-test-id="upload-errors" fluid>
      <v-alert type="error"
        >There was an issue with your file:<br />
        <v-list-item v-for="(error, idx) in result.errors" :key="'error-' + idx">
          <v-list-item-title>Row {{ error.row }}: {{ error.message }}</v-list-item-title>
        </v-list-item>
      </v-alert>
    </v-container>
    <cancel-btn v-if="!uploaded" @click="cancel" class="mt-4 float-right">Cancel</cancel-btn>
  </div>
</template>

<script>
import { ref } from "vue"
import { useRouter } from "vue-router"
import { mdiFileDelimitedOutline } from "@mdi/js"
import * as Papa from "papaparse"

import { useNotification } from "@/modules/notification/use"

export default {
  name: "ContentUploadButton",
  emits: ["uploaded"],
  setup(props, context) {
    let router = useRouter()
    let bp_notification = useNotification()

    let fileRules = [(value) => !value || !value.length || value[0].size < 1000000 || "CSV size should be less than 1 MB"]
    let loading = ref(false)
    let disabled = ref(false)
    let uploaded = ref(false)
    let uploadTask = null // so that we can cancel an upload task
    let result = ref({})

    let cancel = () => {
      if (uploadTask) {
        uploadTask.cancel()
      }
      router.push({ name: "contents-show" })
    }

    let isFileValid = (file) => {
      let valid = true
      fileRules.forEach((rule) => {
        valid = valid && rule(file) === true
      })
      return valid
    }

    let uploadFile = async (file) => {
      // note: the html control used to send an array of files
      if (!!file) {
        if (isFileValid(file)) {

          loading.value = true
          disabled.value = true
          uploaded.value = false
          context.emit("uploaded", []) // to 'reset' the confirm view in case of second upload attempt

          const reader = new FileReader()

          // executed when a file is loaded
          reader.onload = (e) => {
            // get the text from CSV file
            const text = e.target.result

            // parse it
            // first row is a header row which we will chop off when we process
            // (that way we don't import garbage - possibly at the loss of the first row if user doesn't read instructions)
            result.value = Papa.parse(text, { skipEmptyLines: true })
            uploaded.value = result.value.errors.length === 0

            if (uploaded.value) {
              context.emit("uploaded", result.value.data)
            }
          }

          reader.onloadend = (e) => {
            loading.value = false
            disabled.value = false
          }

          reader.onerror = (err) => {
            bp_notification.show({
              type: "error",
              message: err.message,
            })
          }

          // load the input file to the reader
          // we could put a check here to see if it is an excel file,
          // in which case use: fileReader.readAsDataURL(file)
          reader.readAsText(file)

        }
      }
    }

    return {
      fileRules,
      uploadFile,
      cancel,
      loading,
      disabled,
      uploaded,
      result,
      mdiFileDelimitedOutline,
    }
  },
}
</script>

<style lang="scss" scoped></style>
