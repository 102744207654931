import { useBootstrapAccountStore } from "./store"

export const useBootstrapAccount = function () {
  const bootstrapAccountStore = useBootstrapAccountStore()

  const { fetch } = bootstrapAccountStore

  return {
    fetch,
  }
}
