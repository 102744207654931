<template>
  <v-snackbar v-model="show" :timeout="-1" multi-line rounded color="yellow-lighten-5">
    <div v-if="!refreshClicked">
      A new version of the app is available.

        <v-btn class="ml-2" color="primary" @click="refresh" size="small" raised elevation="8"
        ><v-icon v-if="mobile">{{ mdiRefresh }}</v-icon> <span v-else>Refresh</span></v-btn
        >
    </div>
    <div v-else>
      If this page does not refresh, please do a
      <a href="https://www.blinkplan.com/docs/refresh" target="_blank"
        >hard refresh <v-icon size="small" color="primary">{{ mdiOpenInNew }}</v-icon></a
      >
    </div>
  </v-snackbar>
</template>

<script>
import { ref, onMounted, getCurrentInstance, inject } from "vue"
import { mdiRefresh, mdiOpenInNew } from "@mdi/js"

export default {
  name: "TheAppReloader",
  data: () => ({
    mdiRefresh,
    mdiOpenInNew,
  }),
  setup() {
    const instance = getCurrentInstance()
    const root = instance.proxy

    let mobile = inject("mobile")
    let show = ref(false)
    let registration = null
    let refreshClicked = ref(false)

    const refresh = function () {
      setTimeout(() => {
        refreshClicked.value = true
      }, 1000)

      if (!registration || !registration.waiting) {
        console.log("No waiting registration", registration)
        return
      }
      // trigger the message event listener inside our service worker file by passing it a skipWaiting message
      console.log("Posting to service worker to skip waiting.")
      registration.waiting.postMessage("SKIP_WAITING")
    }

    onMounted(() => {
      document.addEventListener("newAppAvailable", (e) => {
        console.log("newAppAvailable")
        registration = e.detail
        show.value = true
      })
    })

    // so that the app will refresh once the updated service worker takes control of the page
    if (navigator.serviceWorker) {
      // navigator.serviceWorker is not available in Private Window in Firefox
      navigator.serviceWorker.addEventListener("controllerchange", () => {
        console.log("serviceWorker.controllerchange fired")
        window.location.reload()
      })
    }

    return {
      mobile,
      show,
      refresh,
      refreshClicked,
    }
  },
}
</script>
