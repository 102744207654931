<template>
  <div ref="el"></div>
</template>

<script>
import { ref, watch, nextTick } from "vue"

import { useCategory } from "@/modules/category/use"
import { useContent } from "@/modules/content/use"
import { usePageSetting } from "@/modules/pageSetting/use"
import renderFragments from "../render/renderFragments"

export default {
  name: "PageSvg",
  props: {
    fragments: {
      type: Array,
      required: true,
    },
    withFill: {
      type: Boolean,
      required: false,
      default: true,
    },
    forceFillColor: {
      type: String,
      required: false,
    },
    pathColor: {
      type: String,
      required: false,
    },
    withText: {
      type: Boolean,
      required: false,
      default: true,
    },
    withAdCorners: {
      type: Boolean,
      required: false,
      default: true,
    },
    withSizeTooltip: {
      type: Boolean,
      required: false,
      default: false,
    },
    notifyOnClick: {
      type: Boolean,
      required: false,
      default: false,
    },
    highlightOnClick: {
      type: Boolean,
      required: false,
      default: false,
    },
    highlightFragmentIndex: {
      type: Number,
      required: false,
      default: null,
    },
  },
  emits: ["fragment-click"],
  setup(props, context) {
    let bp_pageSetting = usePageSetting()
    let bp_category = useCategory()
    let bp_content = useContent()

    let el = ref(null)

    let highlightFragmentIndex = ref(props.highlightFragmentIndex)
    let fragments = ref(props.fragments || [])

    // default to a full page fragment if there are no fragments.
    // This can occur when migrating from the old version to this version of Blinkplan
    // The old version rendered a border around the SVG - this one doesn't - so we need
    // at least some fragment to render ...
    if (fragments.value.length === 0) {
      fragments.value.push({
        shape: "full",
        text: "",
        topLeftX: 0,
        topLeftY: 0,
      })
    }

    let clickCallback = (evt) => {
      if (props.highlightOnClick) {
        highlightFragmentIndex.value = evt.fragmentIndex
      }
      context.emit("fragment-click", { fragmentIndex: evt.fragmentIndex })
    }

    let render = renderFragments({
      categories: bp_category.categories.value.concat(bp_category.noneCategory),
      withFill: props.withFill,
      forceFillColor: props.forceFillColor,
      pathColor: props.pathColor,
      withText: props.withText,
      withAdCorners: props.withAdCorners,
      withSizeTooltip: props.withSizeTooltip,
      notifyOnClick: props.notifyOnClick,
      clickCallback,
    })

    // can't watch the styles of the DOM element - so need to watch this getter instead
    watch(
      [bp_pageSetting.pageWidth, highlightFragmentIndex, fragments, bp_category.categories, bp_content.contents],
      ([pageWidth, highlightFragmentIndex, fragments, categories, contents]) => {
        nextTick(() => {
          if (el.value) {
            let svgEl = el.value.querySelector("svg")
            if (svgEl) {
              svgEl.remove()
            }

            render({
              el: el.value,
              fragments: fragments,
              width: bp_pageSetting.pageWidth.value,
              height: bp_pageSetting.pageHeight.value,
              highlightFragmentIndex,
            })
          }
        })
      },
      { immediate: true, deep: true }
    )

    return {
      el,
    }
  },
}
</script>

<style lang="scss" scoped></style>
