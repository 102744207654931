<template>
  <v-menu location="center">
    <template v-slot:activator="{ props }">
      <div v-bind="props" class="stitch-in" :class="{ viewonly: viewOnly }"></div>
    </template>
    <v-card color="background">
      <v-card-text>
        <div>
          <span class="text-body-1">Front content</span><br />
          <span class="text-caption">{{ stitchIn.frontContent || "None specified" }}</span>
        </div>
        <div class="mt-2">
          <span class="text-body-1">Back content</span><br />
          <span class="text-caption">{{ stitchIn.backContent || "None specified" }}</span>
        </div>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import { useStitchIn } from "../use"

export default {
  name: "StitchInLine",
  props: {
    stitchInId: {
      type: String,
      required: false,
    },
    viewOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props) {
    let bp_stitchIn = useStitchIn()
    let stitchIn = bp_stitchIn.getById(props.stitchInId)

    return {
      stitchIn,
    }
  },
}
</script>

<style lang="scss" scoped>
.stitch-in {
  position: absolute;
  right: -3px;
  top: #{$bp-page-affordance-height};
  height: calc(var(--bp-page-height));
  border: 3px dashed rgb(var(--v-theme-tertiary));
  cursor: pointer;
  z-index: 2;
}
.viewonly.stitch-in {
  top: 0px; // because we are not displaying the affordance bar
}
</style>
