<template>
  <v-sheet class="d-inline-block" :color="color" :width="size" :height="size" rounded="circle"></v-sheet>
</template>
<script>
export default {
  name: "TagDot",
  props: {
    color: {
      type: String,
    },
    size: {
      type: [Number, String],
      default: 10,
    },
  },
}
</script>

<style lang="scss" scoped></style>
