<template>
  <div>
    <h4 class="text-h4">Stitch-Ins</h4>
    <br />
    <v-alert type="info">To be stitched-in <strong>after</strong> the given the page number.</v-alert>

    <v-container data-test-id="stitchIns">
      <v-row v-if="stitchIns.length > 0">
        <v-col cols="1" class="text-subtitle-1">Page</v-col>
        <v-col cols="5" class="text-subtitle-1">Front content</v-col>
        <v-col cols="5" class="text-subtitle-1">Back content</v-col>
        <v-col cols="1"> </v-col>
      </v-row>
      <StitchInItem v-for="stitchIn in stitchIns" :key="stitchIn._id" :stitchInId="stitchIn._id" />
    </v-container>
  </div>
</template>

<script>
import StitchInItem from "./StitchInItem"

import { useStitchIn } from "../use"

export default {
  name: "StitchInsList",
  components: {
    StitchInItem,
  },
  setup() {
    let bp_stitchIn = useStitchIn()

    return {
      stitchIns: bp_stitchIn.stitchIns,
    }
  },
}
</script>

<style lang="scss" scoped></style>
