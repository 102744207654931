import { computed } from "vue"
import { usePageSettingStore } from "./store"
import pageSizings from "@/modules/page/sizings"

export const usePageSetting = function () {
  const pageSettingStore = usePageSettingStore()
  const { setDisplay, setPageSize, setSinglePages, setShowThumbnails, setPageOrientation, pageWidthForSize, pageHeightForSize } = pageSettingStore

  const pageSizes = [
    { value: "sm", text: "Small" },
    { value: "md", text: "Medium" },
    { value: "lg", text: "Large" },
  ]

  // store's state is reactively passed on (computed for readonly) - all state changes should occur within store
  return {
    display: computed(() => pageSettingStore.display),
    pageSize: computed(() => pageSettingStore.pageSize),
    singlePages: computed(() => pageSettingStore.singlePages),
    pageOrientation: computed(() => pageSettingStore.pageOrientation),
    pageWidth: computed(() => pageSettingStore.pageWidth),
    pageHeight: computed(() => pageSettingStore.pageHeight),
    pageGap: computed(() => pageSettingStore.pageGap),
    pageSpreadGap: computed(() => pageSettingStore.pageSpreadGap),
    rowGutter: computed(() => pageSettingStore.rowGutter),
    aspectRatio: computed(() => pageSettingStore.pageWidth / pageSettingStore.pageHeight),
    showThumbnails: computed(() => pageSettingStore.showThumbnails),
    pageSizes,
    pageWidthForSize,
    pageHeightForSize,
    setDisplay,
    setPageSize,
    setSinglePages,
    setPageOrientation,
    setShowThumbnails,
  }
}
