<template>
  <base-toolbar>
    <BackToFlatplan :reload="true" />
    <v-btn variant="text" :to="{ name: 'people' }" :prepend-icon="mdiArrowLeft" color="primary" exact>
      <span v-if="!mobile">Back</span>
    </v-btn>
  </base-toolbar>
</template>

<script>
import { inject } from "vue"
import { mdiArrowLeft } from "@mdi/js"

import BackToFlatplan from "@/components/BackToFlatplan"

export default {
  name: "PersonToolBar",
  components: {
    BackToFlatplan,
  },
  setup() {
    let mobile = inject("mobile")

    return {
      mobile,
      mdiArrowLeft
    }
  },
}
</script>

<style lang="scss" scoped></style>
