import { MetricsVisualizer } from './visualizer'

export class ConnectionTester {
  static async measureConnectionDetails() {
    const connectionDetails = {}
    const startTotal = performance.now()

    // Get detailed network info first
    connectionDetails.networkInfo = this.getDetailedNetworkInfo()

    // Test both services in parallel but handle errors individually
    const results = await Promise.allSettled([
      this.testEndpoint('account.blinkplan.com'),
      this.testEndpoint('data.blinkplan.com')
    ])

    connectionDetails.services = {
      account: results[0].status === 'fulfilled' ? results[0].value : {
        service: 'account',
        duration: performance.now() - startTotal,
        status: 'error',
        error: results[0].reason?.message
      },
      data: results[1].status === 'fulfilled' ? results[1].value : {
        service: 'data',
        duration: performance.now() - startTotal,
        status: 'error',
        error: results[1].reason?.message
      }
    }

    connectionDetails.totalTime = performance.now() - startTotal

    // Add console visualization
    MetricsVisualizer.logConnectionDetailsChart(connectionDetails)

    return connectionDetails
  }

  static getDetailedNetworkInfo() {
    const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection
    return {
      online: navigator.onLine,
      type: connection?.type || 'unknown',
      effectiveType: connection?.effectiveType || 'unknown',
      downlink: connection?.downlink || 'unknown',
      rtt: connection?.rtt || 'unknown',
      saveData: connection?.saveData || false,
      deviceMemory: navigator?.deviceMemory,
      hardwareConcurrency: navigator?.hardwareConcurrency,
      // Check if service worker is active
      serviceWorkerStatus: navigator.serviceWorker?.controller ? 'active' : 'inactive',
      // Previous page visibility state
      wasHidden: document.hidden,
      timestamp: new Date().toISOString()
    }
  }

  static async testEndpoint(domain) {
    const startTime = performance.now()
    
    try {
      await fetch(`https://${domain}`, {
        method: 'HEAD',
        mode: 'no-cors',
        cache: 'no-store'
      })

      return {
        service: domain.split('.')[0],
        duration: performance.now() - startTime,
        status: 'success'
      }
    } catch (error) {
      return {
        service: domain.split('.')[0],
        duration: performance.now() - startTime,
        status: 'error',
        error: error.message
      }
    }
  }
}