<template>
  <div>
    <template v-if="bp_auth.loggedIn">
      <v-menu transition="scale-transition">
        <template v-slot:activator="{ props }">
          <v-icon v-bind="props" color="primary" size="x-large">{{ mdiAccountCircle }}</v-icon>
        </template>
        <v-list lines="two" density="compact" nav>
          <v-list-subheader>
            <v-list-item>
              <v-list-item-title><strong>{{ accountName }}</strong></v-list-item-title>
            </v-list-item>
          </v-list-subheader>
          <v-list-item v-if="permitBilling" :to="{ name: 'billing' }" exact>
            <v-list-item-title
              ><v-icon>{{ mdiDomain }}</v-icon> Account</v-list-item-title
            >
          </v-list-item>
          <v-list-item v-else-if="permitAdmin" :to="{ name: 'people' }" exact>
            <v-list-item-title
              ><v-icon>{{ mdiDomain }}</v-icon> Account</v-list-item-title
            >
          </v-list-item>
          <v-divider v-if="permitBilling || permitAdmin"></v-divider>
          <v-list-item href="//www.blinkplan.com/docs" target="_blank">
            <v-list-item-title
              ><v-icon>{{ mdiHelpCircleOutline }}</v-icon> Documentation</v-list-item-title
            >
          </v-list-item>
          <v-divider></v-divider>
          <v-list-subheader>
            <v-list-item>
              <v-list-item-title>{{ bp_auth.name }}</v-list-item-title>
              <v-list-item-subtitle>
                {{ bp_auth.email }}
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item v-if="bp_auth.isAdm2n">
              <v-list-item-subtitle>
                <router-link class="text-caption text-primary text-decoration-none" :to="{ name: 'impersonate' }">Impersonate</router-link><br />
                <router-link class="text-caption text-primary text-decoration-none" :to="{ name: 'version' }">Version</router-link><br />
              </v-list-item-subtitle>
            </v-list-item>
          </v-list-subheader>
          <v-list-item :to="{ name: 'profile' }" exact>
            <v-list-item-title
              ><v-icon>{{ mdiAccountCog }}</v-icon> Your profile</v-list-item-title
            >
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item @click="bp_auth.logout({})" data-test-id="logout">
            <v-list-item-title>Log out</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>

    <template v-else>
      <v-btn variant="text" rounded exact :to="{ name: 'login' }" data-test-id="login"> Login </v-btn>
    </template>
  </div>
</template>

<script>
import { reactive, inject } from "vue"
import { mdiAccountCircle, mdiAccountCog, mdiDomain, mdiHelpCircleOutline } from "@mdi/js"

import { useAuth } from "@/modules/auth/use"
import { useContext } from "@/use/context"

export default {
  data: () => ({
    mdiAccountCircle,
    mdiAccountCog,
    mdiDomain,
    mdiHelpCircleOutline
  }),
  setup() {
    let mobile = inject("mobile")

    const bp_auth = useAuth()
    const bp_context = useContext()

    return {
      mobile,
      bp_auth: reactive(bp_auth),
      permitBilling: bp_context.permitBilling,
      permitAdmin: bp_context.permitAdmin,
      accountName: bp_context.accountName
    }
  },
}
</script>

<style lang="scss" scoped>
.user {
  cursor: pointer;
}
</style>
