<template>
  <v-chip v-for="type in types" :key="type" size="x-small">
    <strong>{{ type.replace(/^\w/, (c) => c.toUpperCase()) }}</strong>
    <span class="pl-2"><small>x</small>{{ Math.round((detailsFor(type).fraction + Number.EPSILON) * 100) / 100 }}&nbsp;&nbsp;<small>=</small>{{ ((detailsFor(type).percentage + Number.EPSILON) * 100).toFixed(1) }}%</span>
  </v-chip>
</template>

<script>
import { useType } from "../use"
import { useStats } from "../useStats"

export default {
  name: "TypeStats",
  setup() {
    let bp_type = useType()
    let bp_stats = useStats()

    return {
      types: bp_type.types,
      detailsFor: bp_stats.detailsFor,
    }
  },
}
</script>

<style lang="scss" scoped></style>
