<template>
  <v-row justify="center">
    <v-col cols="12" sm="6" lg="3">
      <h4 class="text-h4">Impersonate</h4>

      <v-form class="mt-6" @submit.prevent="impersonate">
        <v-text-field label="Email" type="email" v-model="email" data-test-id="email" required autofocus />
        <submit-btn type="submit" class="mr-4" data-test-id="submit" :loading="bp_auth.loading" block> Continue </submit-btn>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import { reactive, toRefs } from "vue"

import { useAuth } from "@/modules/auth/use"

export default {
  name: "ImpersonateView",
  props: {
    useEmail: {
      type: String,
      required: false,
      default: "",
    },
  },
  setup(props) {
    const bp_auth = useAuth()

    const state = reactive({
      email: props.useEmail,
    })

    const impersonate = () => {
      bp_auth.impersonate({ email: state.email.trim() })
    }

    return {
      ...toRefs(state),
      impersonate,
      bp_auth: reactive(bp_auth),
    }
  },
}
</script>
