<template>
  <v-menu v-if="accounts.length > 1">
    <!-- we need max-height to get a scrollbar when we have too many flatplans -->
    <template v-slot:activator="{ props }">
      <v-btn v-bind="props" color="primary" variant="text" data-test-id="menuButton">
        {{ accounts[0].name }}<v-icon end>{{ mdiChevronDown }}</v-icon>
      </v-btn>
    </template>
    <v-list density="compact">
      <v-list-item v-for="acc in accounts" :key="acc.id" @click="changeCurrentAccount(acc)">
        <v-list-item-title>
          <span :class="{ 'muted--text': !acc.active }">{{ acc.name }}</span>
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { useRouter } from "vue-router"

import { useAccount } from "../use"
import { mdiChevronDown } from "@mdi/js"

export default {
  setup() {
    let router = useRouter()
    let bp_account = useAccount()

    let changeCurrentAccount = (account) => {
      let currentRoute = router.currentRoute.value
      let route = currentRoute.fullPath

      if (currentRoute.matched.some((record) => record.name === "editor")) {
        route = "/" // if we're on the editor we will have paths that include flatplanId etc ...
      }

      bp_account.changeCurrentAccount({ account, route })
    }

    return {
      accounts: bp_account.accounts,
      changeCurrentAccount,
      mdiChevronDown
    }
  },
}
</script>

<style lang="scss" scoped></style>
