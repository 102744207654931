<template>
  <v-row class="stitchIn" @click="openEditDialog">
    <v-col cols="1">
      <div class="text-subtitle-1" data-test-id="pageNumber">{{ translatePageNumber(stitchIn.pageNumber) }}</div>
    </v-col>
    <v-col cols="5">
      <div class="text-caption" data-test-id="frontContent">{{ stitchIn.frontContent }}</div>
    </v-col>
    <v-col cols="5">
      <div class="text-caption" data-test-id="backContent">{{ stitchIn.backContent }}</div>
    </v-col>
    <v-col cols="1">
      <v-btn :icon="mdiDeleteOutline" @click.stop="del" variant="text" size="small"></v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { reactive } from "vue"
import { useRouter } from "vue-router"
import { mdiDeleteOutline } from "@mdi/js"

import { useStitchIn } from "../use"

export default {
  name: "StitchInItem",
  props: {
    stitchInId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    let router = useRouter()
    let bp_stitchIn = useStitchIn()

    let stitchIn = reactive(bp_stitchIn.getById(props.stitchInId))

    let del = () => {
      bp_stitchIn.del({ stitchInId: props.stitchInId })
    }

    const openEditDialog = () => {
      router.push({ name: "stitchIn-edit", params: { stitchInId: props.stitchInId } })
    }

    return {
      stitchIn,
      del,
      openEditDialog,
      translatePageNumber: bp_stitchIn.translatePageNumber,
      mdiDeleteOutline
    }
  },
}
</script>

<style lang="scss" scoped>
.stitchIn {
  cursor: pointer;
}
</style>
