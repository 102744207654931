<template>
  <AssignTags v-model:tags="tags" />
</template>

<script>
import { usePage } from "../use"
import { useTag } from "@/modules/tag/use"
import AssignTags from "@/modules/tag/components/AssignTags"

import { sessionStorageObj } from "@/utils/storage"
import { ref, watch } from "vue"

export default {
  name: "EditPageTags",
  props: {
    pageId: {
      type: String,
      required: true,
    },
  },
  components: {
    AssignTags,
  },
  setup(props, context) {
    let bp_page = usePage()
    let page = bp_page.getById(props.pageId)

    let bp_tag = useTag()

    // let's fetch the edit in-progress from sessionStorage. The EditPageView component has
    // placed a copy of the original into sessionStorage (Assumption: The EditPageView setup method runs before this setup method).
    // Let's save all changes back to sessionStorage,
    // and the EditPageView will pick it up again to save when done editing.
    let tagRefs = ref(sessionStorageObj.getItem("editPage", "tags").data.tagRefs)
    let createTags = ref(sessionStorageObj.getItem("editPage", "tags").data.createTags)

    const saveToLocalStorage = (value) => {
      sessionStorageObj.setItem("editPage", { data: value, dirty: true }, "tags")
      context.emit("dirty")
    }

    let tags = ref(bp_tag.getByIds(tagRefs.value))

    watch(
      tags,
      (tags) => {
        saveToLocalStorage({ tagRefs: tags.filter((t) => !t.create).map((t) => t._id), createTags: tags.filter((t) => t.create).map((t) => t.name) })
      },
      { immediate: true, deep: true }
    )

    // let's init the tags if we switch between tabs
    createTags.value.forEach((tag) => {
      tags.value.push(bp_tag.generateTag(tag))
    })

    return {
      page,
      tags,
    }
  },
}
</script>

<style lang="scss" scoped></style>
