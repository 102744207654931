import { firebaseApp } from "@/initializers/firebase"
import { getAuth, signInAnonymously } from "firebase/auth"
import { getStorage, ref, uploadBytesResumable } from "firebase/storage"
import { generateId } from "@/utils/generateId"
import { useContext } from "@/use/context"
import { useNotification } from "@/modules/notification/use"

export const upload = (file) => {
  let bp_context = useContext()
  let bp_notification = useNotification()

  let fileName = `${generateId(8)}-${generateId(8)}`

  const firebaseAuth = getAuth(firebaseApp)
  const firebaseStorage = getStorage(firebaseApp)

  return new Promise((resolve, reject) => {
    signInAnonymously(firebaseAuth)
      .then(() => {
        let imageKey = `${bp_context.accountId.value}/${bp_context.flatplanId.value}/___${fileName}` // ___ ... in case we can upload more - to make it easier to find/debug

        let imageRef = ref(firebaseStorage, imageKey)

        let uploadTask = uploadBytesResumable(imageRef, file, {
          contentType: file.type,
        })

        resolve({ uploadTask })
      })
      .catch((error) => {
        bp_notification.show({
          type: "error",
          message: error.message,
        })
      })
  })
}
