// Styles
import "vuetify/styles"

// Composables
import { createVuetify } from "vuetify"
import { aliases, mdi } from "vuetify/iconsets/mdi-svg"
import { VBtn } from 'vuetify/components'

export default createVuetify({
  theme: {
    defaultTheme: 'light',
    themes: {
      light: {
        colors: {
          primary: "#019FAF",
          secondary: "#99aa00",
          tertiary: "#4DD0E1",
          quaternary: "#FDFF6E",
          error: "#ff5722",
          warning: "#f76707",
          info: "#4dd0e1",
          success: "#99AA00",
          // progress: "#cddc39",
          muted: "#CCC",
          background: "#edf0f2",
        }
      },
    },
    options: {
      customProperties: true,
      variations: false,
    },
  },
  aliases: {
    SubmitBtn: VBtn,
    CancelBtn: VBtn,
  },
  defaults: {
    VAppBar: {
      color: "white",
    },
    VBtn: {
      variant: "flat",
    },
    SubmitBtn: {
      variant: "flat",
      color: "primary"
    },
    CancelBtn: {
      variant: "outlined",
      color: "primary"
    },
    VCard: {
      variant: "flat",
      color: "background",
    },
    VToolbar: {
      variant: "flat",
      density: "compact",
    },
    VSelect: {
      density: "compact",
      color: "primary",
      bgColor: "white",
      variant: "filled"
    },
    VTextField: {
      density: "compact",
      bgColor: "white",
      color: "primary",
      variant: "filled",
      placeholder: " ",
    },
    VTextarea: {
      density: "compact",
      bgColor: "white",
      color: "primary",
      variant: "filled",
      placeholder: " ",
    },
    VCheckbox: {
      density: "compact",
    },
    VRadioGroup: {
      density: "compact",
    },
    VAlert: {
      density: "compact",
      variant: "outlined",
      border: "start",
    },
    VFileInput: {
      density: "compact",
      bgColor: "white",
      color: "primary",
      variant: "filled",
    },
    VCombobox: {
      density: "compact",
      bgColor: "white",
      color: "primary",
      variant: "filled",
    },
    VAutocomplete: {
      density: "compact",
      bgColor: "white",
      color: "primary",
      variant: "filled",
    },
    VList: {
      density: "compact"
    },
    VListItem: {
      density: "compact"
    },
    VSheet: {
      color: "transparent"
    }
  },
  icons: {
    defaultSet: "mdi",
    aliases,
    sets: {
      mdi,
    },
  },
})