<template>
  <v-row justify="center">
    <v-col cols="12" sm="6" lg="3">
      <p>Enter the email address associated with your account and we'll send you a link to reset your password.</p>
      <v-form class="mt-6" ref="form" v-model="formValid" @submit.prevent="forgotPassword">
        <v-text-field label="Email" type="email" v-model="email" data-test-id="email" :rules="emailRules" required />
        <v-alert :model-value="bp_auth.httpStatus === 406" type="error" class="my-4"> We couldn't find that email. </v-alert>

        <submit-btn type="submit" class="mr-4" data-test-id="submit" :loading="bp_auth.loading" block> Continue </submit-btn>
      </v-form>
      <div class="mt-12">
        You remembered your password?
        <router-link :to="{ name: 'login', query: { useEmail: email } }" class="text-primary text-decoration-none"> Login here. </router-link>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { reactive, toRefs, ref } from "vue"

import { useNotification } from "@/modules/notification/use"
import { useAuth } from "@/modules/auth/use"

export default {
  name: "ForgotPasswordView",
  props: {
    useEmail: {
      type: String,
      required: false,
      default: "",
    },
  },
  setup(props) {
    const bp_auth = useAuth()
    const bp_notification = useNotification()

    const form = ref(null)

    const state = reactive({
      email: props.useEmail,
      emailRules: [
        (v) => !!v || "Email is required.",
        (v) => v.indexOf("@") !== 0 || "Email should have a username.",
        (v) => v.includes("@") || "Email should include an @ symbol.",
        (v) => v.includes(".") || "Email should include a period symbol.",
        (v) => v.indexOf(".") <= v.length - 3 || "Email should contain a valid domain extension.",
      ],
      formValid: false,
    })

    const forgotPassword = () => {
      form.value.validate()
      if (state.formValid || state.formValid === null) { // JD: HACK/BUG
        bp_auth.forgotPassword({ email: state.email.trim() })
        .then(() => {
          bp_notification.show({
            type: "success",
            message: "Password reset link was emailed to you. Check your inbox.",
          })
        })
        .catch(() => {
          // noop
        })
      }
    }

    return {
      ...toRefs(state),
      forgotPassword,
      form,
      bp_auth: reactive(bp_auth),
    }
  },
}
</script>
