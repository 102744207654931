<template>
  <base-toolbar>
    <BackToFlatplan />
  </base-toolbar>
</template>

<script>
import BackToFlatplan from "@/components/BackToFlatplan"

export default {
  name: "ContentUplodToolBar",
  components: { BackToFlatplan },
}
</script>

<style lang="scss" scoped></style>
