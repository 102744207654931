<template>
  <v-container fluid>
    <div class="setup">
      <div v-if="bp_demo.state.value === 'creatingUser'">Creating demo user</div>
      <div v-else-if="bp_demo.state.value === 'settingUpAccount'">Setting up account</div>
      <div v-else-if="bp_demo.state.value === 'creatingFlatplan'">Creating flatplan</div>
      <div v-else-if="bp_demo.state.value === 'creatingContents'">Creating contents</div>
      <div v-else-if="bp_demo.state.value === 'initializingData'">Initializing data</div>
      <div v-else-if="bp_demo.state.value === 'done'">All setup :)</div>
      <div v-else>Initializing</div>
    </div>
  </v-container>
</template>

<script>
import { useDemo } from "@/modules/demo/use"
import { onMounted } from "vue"

export default {
  name: "SetupDemoView",
  setup() {
    let bp_demo = useDemo()

    onMounted(() => {
      bp_demo.setup()
    })

    return {
      bp_demo,
    }
  },
}
</script>

<style scoped>
.setup {
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 25%;
  height: 400px;
  align-items: center;
  justify-content: center;
}
</style>
