import { ref, computed } from "vue"
import { defineStore } from "pinia"

import pageSizings from "@/modules/page/sizings"
import AccountService from "@/services/AccountService.js"

let pageSizing = (display, size, orientation) => {
  let pageSizingDisplay = pageSizings[display || "screen"]
  let pageSizingDisplaySize = pageSizingDisplay[size || "md"]
  return pageSizingDisplaySize[orientation || "portrait"]
}

export const usePageSettingStore = defineStore("pageSetting", () => {
  // if we are not setting this state via actions on initial app load,
  // then we should match the css custom properties in the style sheet,
  // to reflect the sizes for this default state to avoid confusion
  const display = ref("screen") // or "pdf"
  const pageSize = ref((localStorage.getItem("pageSize") === "undefined" ? null : localStorage.getItem("pageSize")) || "md")
  const pageOrientation = ref("portrait")
  const singlePages = ref(false)
  const showThumbnails = ref((localStorage.getItem("showThumbnails") || "true") == "true")

  const pageWidth = computed(() => {
    return pageSizing(display.value, pageSize.value, pageOrientation.value).width
  })

  const pageHeight = computed(() => {
    return pageSizing(display.value, pageSize.value, pageOrientation.value).height
  })

  const pageGap = computed(() => {
    return singlePages.value ? pageSizing(display.value, pageSize.value, pageOrientation.value).singleGap : pageSizing(display.value, pageSize.value, pageOrientation.value).gap
  })

  const pageSpreadGap = computed(() => {
    return pageSizing(display.value, pageSize.value, pageOrientation.value).spreadGap
  })

  const rowGutter = computed(() => {
    return pageSizing(display.value, pageSize.value, pageOrientation.value).rowGutter
  })

  function pageWidthForSize(size) {
    return pageSizing(display.value, size, pageOrientation.value).width
  }

  function pageHeightForSize(size) {
    return pageSizing(display.value, size, pageOrientation.value).height
  }

  function setCssProperties() {
    document.documentElement.style.setProperty("--bp-page-width", `${pageWidth.value}px`)
    document.documentElement.style.setProperty("--bp-page-height", `${pageHeight.value}px`)
    document.documentElement.style.setProperty("--bp-page-gap", `${pageGap.value}px`)
    document.documentElement.style.setProperty("--bp-page-spread-gap", `${pageSpreadGap.value}px`)
    document.documentElement.style.setProperty("--bp-page-row-gutter", `${rowGutter.value}px`)
  }

  function setCssPageStyles() {
    setCssProperties()
  }

  function setDisplay(data) {
    display.value = data // can be 'screen' or 'pdf
    setCssProperties()
  }

  function setPageSize(data) {
    pageSize.value = data
    localStorage.setItem("pageSize", pageSize.value || "md")
    setCssProperties()
  }

  function setSinglePages(data) {
    singlePages.value = data
    setCssProperties()
  }

  function setPageOrientation(data) {
    pageOrientation.value = data
    setCssProperties()
  }

  function setShowThumbnails(data) {
    showThumbnails.value = data
    localStorage.setItem("showThumbnails", showThumbnails.value)
  }

  return {
    display,
    pageSize,
    pageOrientation,
    singlePages,
    showThumbnails,
    pageWidth,
    pageHeight,
    pageGap,
    rowGutter,
    pageSpreadGap,
    pageWidthForSize,
    pageHeightForSize,
    setCssPageStyles,
    setPageSize,
    setSinglePages,
    setPageOrientation,
    setShowThumbnails,
    setDisplay,
  }
})
