<template>
  <v-container fluid>
    <div class="mb-6 text-secondary text-heading-6 font-weight-medium">{{ flatplanIssue }}</div>
    <Flatplan />
    <div style="clear: both" v-if="showFlatplanNotes"></div>
    <div class="mt-4" v-if="showFlatplanNotes"><Notes /></div>
    <div style="clear: both" v-if="showPageNotes"></div>
    <div class="mt-4" v-if="showPageNotes"><PageNotes :viewOnly="true" /></div>
  </v-container>
</template>

<script>
import { computed } from "vue"
import Flatplan from "@/modules/viewOnly/components/Flatplan"
import Notes from "@/modules/flatplan/components/Notes"
import PageNotes from "@/modules/flatplan/components/PageNotes"

import { useFlatplan } from "@/modules/flatplan/use"
import { usePage } from "@/modules/page/use"
import { useViewSetting } from "@/modules/viewSetting/use"

export default {
  name: "ShareView",
  components: {
    Flatplan,
    Notes,
    PageNotes,
  },
  setup() {
    let bp_flatplan = useFlatplan()
    let bp_page = usePage()
    let bp_viewSetting = useViewSetting()

    return {
      flatplanIssue: bp_flatplan.issue,
      showFlatplanNotes: computed(() => bp_viewSetting.showNotes.value && bp_flatplan.notes !== ""),
      showPageNotes: computed(() => bp_viewSetting.showNotes.value && bp_page.withNotes.value.length > 0),
    }
  },
}
</script>

<style lang="scss" scoped></style>
