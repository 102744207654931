import { computed } from "vue"
import { useCategory } from "@/modules/category/use"

export const useCategorySortable = () => {
  let bp_category = useCategory()

  const categoryIds = computed({
    get: () => {
      return bp_category.draggableOrder.value
    },
    set: (value) => {
      bp_category.reorderDraggable(value)
    },
  })

  const onUpdate = (e) => {
    bp_category.reorder({})
  }

  const onAdd = (e) => {
    bp_category.reorder({})
  }

  return {
    categoryIds,
    onAdd,
    onUpdate,
  }
}
