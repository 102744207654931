<template>
  <v-container v-if="person" fluid>
    <h4 class="text-h4 mb-1">{{ person.name }}<span v-if="you" class="text-secondary text-h6">&nbsp;(you)</span></h4>
    <span class="text-subtitle-1"
      ><a :href="`mailto:${person.email}`">{{ person.email }}</a></span
    >
    <hr class="mt-4 mb-4" />
    <v-row>
      <v-col cols="8" sm="4">
        <h6 class="text-h6">{{ person.name }}'s superpowers</h6>
        <v-checkbox v-model="adminSuperPower" @click="updateAdminPermission" label="Administrator" hide-details="false" dense :disabled="you || !permitAdmin"></v-checkbox>
        <p class="text-caption">Admins can remove users from your Blinkplan account, and grant admin powers to other people. We recommend only granting admin powers to people you really trust.</p>
        <v-checkbox v-model="billingSuperPower" @click="updateBillingPermission" label="Billing" hide-details="false" dense :disabled="!permitAdmin"></v-checkbox>
        <p class="text-caption">Billing liaisons can update billing information and view past invoices.</p>
      </v-col>
      <v-col cols="4" sm="2"><v-btn v-if="!you && permitAdmin" color="error" variant="text" @click="del">Remove from account</v-btn></v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ref, reactive, computed } from "vue"
import { useRouter } from "vue-router"

import { usePerson } from "../use"
import { useBilling } from "@/modules/billing/use"
import { useAuth } from "@/modules/auth/use"
import { useContext } from "@/use/context"

export default {
  name: "PersonView",
  props: {
    niceId: {
      type: [String, Number],
      required: true,
    },
  },
  setup(props) {
    let router = useRouter()
    let bp_person = usePerson()
    let bp_billing = useBilling()
    let bp_auth = useAuth()
    let bp_context = useContext()

    let person = reactive(bp_person.getByNiceId(props.niceId))
    let account = bp_billing.account.value

    let adminSuperPower = ref(person.roles.filter((r) => r.resource_id === account.id).findIndex((r) => r.name === "admin") >= 0)
    let billingSuperPower = ref(person.roles.filter((r) => r.resource_id === account.id).findIndex((r) => r.name === "billing") >= 0)

    let updateAdminPermission = () => {
      let flippedValue = adminSuperPower.value ? 0 : 1
      bp_person.updatePermission({ niceId: person.nice_id, role: "admin", value: flippedValue }).catch((err) => {
        adminSuperPower.value = flippedValue ? 0 : 1
      })
    }

    let updateBillingPermission = () => {
      let flippedValue = billingSuperPower.value ? 0 : 1
      bp_person.updatePermission({ niceId: person.nice_id, role: "billing", value: flippedValue }).catch((err) => {
        billingSuperPower.value = flippedValue ? 0 : 1
      })
    }

    let del = () => {
      bp_person.del({ niceId: props.niceId }).then(() => {
        router.push({ name: "people" })
      })
    }

    return {
      person,
      adminSuperPower,
      billingSuperPower,
      you: computed(() => {
        return person.email === bp_auth.email.value
      }),
      updateAdminPermission,
      updateBillingPermission,
      del,
      permitAdmin: bp_context.permitAdmin,
    }
  },
}
</script>

<style scoped></style>
