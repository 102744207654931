import { ref, computed } from "vue"
import { defineStore } from "pinia"

import DataService from "@/services/DataService.js"

export const useSectionStore = defineStore("section", () => {
  const sections = ref([])

  const coverSections = computed(() => {
    return sections.value.filter((s) => s.type === "Cover")
  })

  const contentSections = computed(() => {
    return sections.value.filter((s) => s.type === "Content")
  })

  function setSection({ sectionId, type, count, size }) {
    let sectionIndex = sections.value.findIndex((c) => c._id === sectionId)
    if (sectionIndex !== -1) {
      let section = sections.value[sectionIndex]
      section.type = type
      section.count = count
      section.size = size
      sections.value.splice(sectionIndex, 1, section)
    } else {
      // we have undeleted - so we end up here
      sections.value.splice(0, 0, { _id: sectionId, type, count, size })
    }
  }

  function removeSection({ sectionId }) {
    let sectionIndex = sections.value.findIndex((c) => c._id === sectionId)
    if (sectionIndex !== -1) {
      sections.value.splice(sectionIndex, 1)
    }
  }

  function addSection(section) {
    let sectionIndex = sections.value.findIndex((c) => c._id === section._id)
    if (sectionIndex === -1) {
      // insert if new
      sections.value.splice(0, 0, section)
    } else {
      // replace if exists
      sections.value.splice(sectionIndex, 1, section)
    }
  }

  function getById(id) {
    return sections.value.find((s) => s._id === id)
  }

  function bootstrap(data) {
    sections.value = data
  }

  function update({ flatplanId, sectionId, type, count, size, storeOnly }) {
    let prevAttributes = JSON.parse(JSON.stringify(getById(sectionId) || {}))

    setSection({ sectionId, type, count, size })

    if (!storeOnly) {
      return DataService.updateSection({ accountId: sessionStorage.getItem("currentAccountId"), flatplanId, sectionId, payload: { type, count, size } }).catch((err) => {
        setSection({ sectionId, ...prevAttributes })
        return Promise.reject(err)
      })
    } else {
      return Promise.resolve()
    }
  }

  function del({ sectionId, flatplanId, storeOnly }) {
    let prevSection = JSON.parse(JSON.stringify(getById(sectionId)))

    removeSection({ sectionId })

    if (!storeOnly) {
      return DataService.deleteSection({ accountId: sessionStorage.getItem("currentAccountId"), flatplanId, sectionId }).catch((err) => {
        addSection(prevSection)
        return Promise.reject(err)
      })
    } else {
      return Promise.resolve()
    }
  }

  // add - we generate on server first, then mutate store
  function add({ flatplanId, type, count, size }) {
    return DataService.addSection({ accountId: sessionStorage.getItem("currentAccountId"), flatplanId, payload: { type, count, size } }).then((response) => {
      addSection(response.data)

      return Promise.resolve(response)
    })
  }

  // this method is used when section was added via Liveupdate (so it already exists on the server)
  function addFromLiveupdate(section) {
    addSection(section)
  }

  return {
    sections,
    coverSections,
    contentSections,
    getById,
    bootstrap,
    update,
    del,
    add,
    addFromLiveupdate,
  }
})
