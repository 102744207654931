<template>
  <v-combobox
    v-model="currentTags"
    v-model:search="search"
    :items="items"
    item-title="name"
    item-value="_id"
    @update:model-value="input"
    @click:clear="input"
    :menu-props="{ openOnFocus: true, closeOnContentClick: true }"
    :delimiters="[',', ';']"
    hide-selected
    chips
    closable-chips
    multiple
    clearable
    label="Assign tags"
  >
    <template v-slot:chip="data">
      <v-chip v-bind="data.attrs" :model-value="data.props.modelValue" closable @click:close="remove(data.item)" size="small">
        <template v-slot:prepend>
          <TagDot :color="data.item.raw.color" size="15" />
        </template>
        <span class="ml-2">{{ data.item.title }}</span>
      </v-chip>
    </template>
    
    <template v-slot:item="data">
      <v-list-item>
        <v-list-item-title>
          <v-chip v-bind="data.props" size="small">
            <template v-slot:prepend>
              <TagDot :color="data.item.raw.color" size="15" />
            </template>
            <span class="ml-2">{{ data.item.title }}</span>
          </v-chip>
        </v-list-item-title>
      </v-list-item>
    </template>
    
    <template v-slot:no-data>
      <v-list-item>
        <v-list-item-title>
          No tags matching "<strong>{{ search }}</strong
          >". Press <kbd>enter</kbd> to create a new one.
        </v-list-item-title>
      </v-list-item>
    </template>
  </v-combobox>
</template>

<script>
import { ref, watch } from "vue"

import TagDot from "./TagDot"
import { useTag } from "@/modules/tag/use"

export default {
  name: "AssignTags",
  components: { TagDot },
  props: {
    tags: {
      type: Array,
      required: true,
    },
  },
  emits: ["update:tags"],
  setup(props, context) {
    let bp_tag = useTag()
    let search = ref(null)

    let currentTags = ref(props.tags)

    let remove = (item) => {
      const index = currentTags.value.map((t) => t._id).indexOf(item.raw._id)
      if (index >= 0) {
        currentTags.value.splice(index, 1)
      }
    }

    let input = () => {
      currentTags.value.forEach((tag, idx) => {
        if (typeof tag === "string") {
          currentTags.value.splice(idx, 1, bp_tag.generateTag(tag))
        }
      })
    }

    watch(
      currentTags,
      (tags) => {
        context.emit("update:tags", tags)
      },
      { immediate: true, deep: true }
    )

    return {
      currentTags,
      search,
      items: bp_tag.tags,
      remove,
      input,
    }
  },
}
</script>

<style lang="scss" scoped></style>
