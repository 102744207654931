<template>
  <div class="d-flex flex-column justify-center align-center" style="min-height: 100vh">
    <div class="text-h3 text-center">You don't have enough permissions to view this page.</div>
    <div class="text-h5 mt-8">Blinkplan has two kinds of superpowers: <strong>Billing</strong>, and <strong>Admin</strong>.</div>
    <div class="text-body-1 mt-8">You will need to ask somebody who has <strong>Admin</strong> superpowers to give you either <strong>Billing</strong> or <strong>Admin</strong> superpowers too.</div>
    <div class="text-caption mt-4"><a href="/" class="text-decoration-none">Home</a></div>
  </div>
</template>

<script>
export default {
  name: "NotEnoughPermissionsView",
}
</script>
