import LogRocket from "logrocket"
import * as gitVersion from "@/git-version.json"
import { DateTime } from "luxon"
import { useMonitoring } from "@/use/useMonitoring"

// LogRocket is expensive.
let bp_monitoring = useMonitoring()

if (bp_monitoring.captureDiagnostics()) {
  if (window.location.hostname !== "localhost") {
    if (!bp_monitoring.isShare()) {
      if (bp_monitoring.enableDiagnostics()) {
        console.log("Diagnostics enabled")
        LogRocket.init("blinkplan/app", {
          release: DateTime.fromFormat(gitVersion.lastCommitTime, "EEE LLL d TT yyyy ZZZ").toFormat("yyyy-MM-dd T"),
        })
      } else {
        console.log("LogRocket not intialized due to diagnostics not enabled.")
      }
    } else {
      console.log("LogRocket not intialized due to being a share view.")
    }
  } else {
    console.log("LogRocket not intialized due to being on localhost.")
  }
} else {
  console.log("LogRocket not intialized due to not capturing diagnostics.")
}
