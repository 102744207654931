<template>
  <div>
    <h4 class="text-h4">Ads/Content to be Placed</h4>
    <br />
    <v-alert type="info">
      <ol>
        <li>Here you can <strong>import</strong> contents that you want placed on your flatplan.</li>
        <li>To then <strong>place</strong> the content, use the Edit Page dialog box in the Flatplan Editor.</li>
      </ol>
    </v-alert>

    <v-container data-test-id="contents" fluid :data-for-rerender="forceRerender">
      <v-row v-if="contents.length > 0">
        <v-col cols="1" justify="start" class="text-subtitle-1 text-grey">ID</v-col>
        <v-col cols="4" class="text-subtitle-1">Text</v-col>
        <v-col cols="4" class="text-subtitle-1">Suggested</v-col>
        <v-col cols="2" class="text-subtitle-1">Placed</v-col>
        <v-col cols="1"> </v-col>
      </v-row>
      <ContentItem v-for="content in contents" :key="content._id" :contentId="content._id" />
    </v-container>
  </div>
</template>

<script>
import { ref, computed, watch } from "vue"
import ContentItem from "./ContentItem"

import { useContent } from "../use"
import { usePage } from "@/modules/page/use"

export default {
  name: "ContentsList",
  components: {
    ContentItem,
  },
  setup() {
    let bp_content = useContent()
    let bp_page = usePage()

    let forceRerender = ref("")

    watch(
      bp_page.pages,
      () => {
        // the placed page numbers are dependent on pages
        // pages fragments can be updated, pages can be reordered (page no changes) etc
        // let's just watch out for any changes and rerender contents
        forceRerender.value = Math.random()
      },
      { immediate: true, deep: true }
    )

    return {
      contents: computed(() => {
        return bp_content.contents.value.sort(bp_content.compareText)
      }),
      forceRerender,
    }
  },
}
</script>

<style lang="scss" scoped></style>
