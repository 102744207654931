<template>
  <div v-if="layout === 'share'">
    <v-app-bar flat>
      <v-app-bar-title>
        <h4 class="text-primary text-h6">{{ accountName }}</h4>
      </v-app-bar-title>

      <template v-slot:append>
        <a href="//www.blinkplan.com" class="ml-1 d-block"><LogoFull class="mt-3" /></a>
      </template>
      
      <TheProgressBar />

      <template v-slot:extension>
        <ToolBar />
      </template>
    </v-app-bar>
    <v-main>
      <slot></slot>
    </v-main>
  </div>
</template>

<script>
import { computed } from "vue"
import { useRouter } from "vue-router"

import { useAccount } from "@/modules/account/use"
import LogoFull from "@/components/Logos/LogoFull.vue"
import TheProgressBar from "@/components/TheProgressBar.vue"
import ToolBar from "@/modules/share/components/ToolBar"
import { useLiveupdate } from "@/modules/liveupdate/use"


export default {
  name: "ShareLayout",
  components: {
    LogoFull,
    TheProgressBar,
    ToolBar,
  },
  setup() {
    let router = useRouter()
    let currentRoute = router.currentRoute

    useLiveupdate() // init websockets

    let bp_account = useAccount()
    let accountName = computed(() => {
      return bp_account.accounts.value.length > 0 ? bp_account.accounts.value[0].name : ""
    })

    return {
      accountName,
      layout: computed(() => currentRoute.value.meta.layout),
    }
  },
}
</script>
