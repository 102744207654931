import { ref } from "vue"
import { defineStore } from "pinia"

import AccountService from "@/services/AccountService.js"

export const usePdfSettingStore = defineStore("pdfSetting", () => {
  const format = ref(localStorage.getItem("pdfFormat") || "A4")
  const orientation = ref(localStorage.getItem("pdfOrientation") || "portrait")

  function setFormat(data) {
    format.value = data
    localStorage.setItem("pdfFormat", format.value)
  }

  function setOrientation(data) {
    orientation.value = data
    localStorage.setItem("pdfOrientation", orientation.value)
  }

  return {
    format,
    orientation,
    setFormat,
    setOrientation,
  }
})
